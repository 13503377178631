import styled from 'styled-components';
import { size } from 'shared/mixins';
import { colors } from 'shared/variables';
import { eyebrow } from 'shared/extends';

export const Container = styled.div`
  width: 100%;

  &:not(.disabled):hover {
    cursor: pointer;
    svg {
      fill: ${colors.highlight};
    }
  }

  .disabled & {
    svg {
      fill: ${colors.brownLight};
    }
  }
`;

export const InputContent = styled.div`
  position: relative;

  svg {
    fill: ${colors.primary};
    position: absolute;
    bottom: 18px;
    right: 25px;
    pointer-events: none;
    ${size('14px')};
  }
`;

export const Timezone = styled.span`
  pointer-events: none;
  ${eyebrow};
  color: ${colors.primary};
  position: absolute;
  bottom: 15px;
  right: 20px;
`;
