import classNames from 'classnames';
import { IconWarning } from 'components/IconsView';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Label } from '../styles';
import {
  Container,
  Error,
  Input,
  InputContainer,
  InputContent,
  MaxLength,
} from './styles';

interface Props {
  name: string;
  className?: string;
  label?: string;
  value?: string;
  required?: boolean;
  showRequired?: boolean;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  errorLimited?: boolean;
  disabled?: boolean;
  type?: string;
  optional?: boolean;
  maxLength?: number;
  minMax?: { min?: number; max?: number };
  handleChange?: (value: string) => void;
  handleBlur?: (value: string) => void;
  showEllipses?: boolean;
  pattern?: string;
  step?: string;
  description?: string;
  hideErrorMessage?: boolean;
}

const InputView = ({
  className,
  name,
  label,
  value,
  required,
  showRequired,
  placeholder,
  error,
  errorMessage,
  errorLimited,
  disabled = false,
  optional,
  type,
  handleChange,
  handleBlur,
  minMax,
  maxLength,
  showEllipses,
  pattern,
  step,
  description,
  hideErrorMessage,
}: Props): JSX.Element => {
  const { register, setValue, clearErrors } = useFormContext();
  const [val, setVal] = useState(value || '');
  const [charLength, setCharLength] = useState(0);
  const InputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const input = InputRef.current;

    if (!input) return;

    if (type === 'number' || type === 'currency') {
      input.addEventListener(
        'keypress',
        function (evt: { which: number; preventDefault: () => void }) {
          if (evt.which === 46) {
          } else if (evt.which < 48 || evt.which > 57) {
            evt.preventDefault();
          }
        }
      );
    }

    return () => {
      if (type === 'number' || type === 'currency') {
        input.removeEventListener(
          'keypress',
          function (evt: { which: number; preventDefault: () => void }) {
            console.log('evt', evt.which);
            if (evt.which === 46) {
            } else if (evt.which < 48 || evt.which > 57) {
              evt.preventDefault();
            }
          }
        );
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (val !== value) {
      setVal(value || '');
    }
    if (value) {
      setValue(name, value, { shouldDirty: true });
    }
    if (maxLength) {
      setCharLength(value?.length || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (register) {
      register(name, { required });
    }
    if (value) {
      setValue(name, value, { shouldDirty: true });
    }
    if (maxLength) {
      setCharLength(value?.length || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register, name]);

  const formatMoney = (v: number) => {
    const format = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    const vFormat = format.format(v);
    return vFormat.replaceAll('$', '').replaceAll(',', '');
  };

  const onValChange = useCallback(
    (e) => {
      let v = e.target.value;
      if (pattern) {
        const patternRegex = new RegExp(pattern, 'g');
        v = v.replace(patternRegex, '');
      }
      if (type === 'number' && maxLength) {
        v = v.substring(0, maxLength);
      }
      if (type === 'number') {
        v = v.replace(/[^0-9.]/g, '');
      }
      setVal(v);
      setValue(name, v, { shouldDirty: true });
      if (handleChange) {
        handleChange(v);
      }
      if (v && (errorLimited || error)) {
        clearErrors(name);
      }

      if (maxLength) {
        setCharLength(v?.length || 0);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, setValue, error, errorLimited]
  );
  const onValBlur = useCallback(
    (e) => {
      let v = e.target.value;
      if (pattern) {
        const patternRegex = new RegExp(pattern, 'g');
        v = v.replace(patternRegex, '');
      }
      if (type === 'number' && maxLength) {
        v = v.substring(0, maxLength);
      }
      if (type === 'number') {
        v = v.replace(/[^0-9.]/g, '');
        setVal(v);
      }
      if (type === 'currency') {
        if (v !== '') {
          v = formatMoney(v);
          setVal(v);
          setValue(name, v, { shouldDirty: true });
        }
      }

      if (handleBlur) {
        handleBlur(v);
      }
      if (val && !(errorLimited || error)) {
        clearErrors(name);
      }

      if (showEllipses) {
        if (InputRef.current) {
          InputRef.current.readOnly = false;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, setValue, val, error, errorLimited]
  );

  const onFocus = useCallback(
    () => {
      if (showEllipses) {
        if (InputRef.current) {
          InputRef.current.readOnly = true;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, showEllipses]
  );

  return (
    <Container>
      {label && (
        <Label htmlFor={name} className={error ? 'error' : ''}>
          {label}
          {optional && <span className="optional">Optional</span>}
          {showRequired && <span className="optional">Required</span>}
          {description && <p className="description">{description}</p>}
        </Label>
      )}
      <InputContent
        className={classNames(`form-text ${className}`, {
          currency: type === 'currency',
        })}
      >
        <InputContainer>
          <Input
            id={name}
            data-testid={name}
            name={name}
            type={type === 'currency' ? 'number' : type || 'text'}
            ref={InputRef}
            value={val}
            autoComplete="off"
            onChange={onValChange}
            onBlur={onValBlur}
            onFocus={onFocus}
            className={classNames({
              error: error,
              errorLimited: errorLimited,
              showEllipses: showEllipses,
            })}
            placeholder={!errorLimited ? placeholder : ''}
            disabled={disabled}
            {...(maxLength ? { maxLength: maxLength } : '')}
            {...minMax}
            {...((type === 'currency' || type === 'number') && {
              step: step || '0.01',
            })}
            // {...(pattern ? { pattern: pattern } : '')}
          />
          {maxLength && type !== 'number' && (
            <MaxLength>
              {charLength}/{maxLength}
            </MaxLength>
          )}
        </InputContainer>
        {/* {errorLimited && <IconWarning className="errorLimited" />} */}
        {error && !hideErrorMessage && (
          <Error className="error">
            <IconWarning />
            {errorMessage || 'Fill out this field'}
          </Error>
        )}
      </InputContent>
    </Container>
  );
};

export default InputView;
