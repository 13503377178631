import styled from 'styled-components';
import { colors } from '../../../../shared/variables';
import { font, fontSize } from '../../../../shared/typography';
import { bodyCopySmall } from '../../../../shared/extends';

export const Container = styled.div`
  margin-top: 27px;
`;

export const Content = styled.div`
  margin: 0 -20px -8px 0;
`;
export const Pill = styled.div`
  ${bodyCopySmall};
  background: ${colors.white};
  border: 1px solid ${colors.grey};
  border-radius: 0;
  padding: 4px 10px 4px 8px;
  margin: 0 20px 8px 0;
  display: inline-block;

  span {
    color: #8a816d;
  }

  svg {
    fill: ${colors.primary};
    margin-right: 6px;
  }

  &:hover {
    color: ${colors.highlight};
    svg {
      fill: ${colors.highlight};
    }
    cursor: pointer;
  }
`;

export const PillClear = styled.button`
  background: transparent;
  border: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
`;

export const Reset = styled.button`
  background: transparent;
  border: none;
  border-radius: 0;
  color: ${colors.brownLight};
  cursor: pointer;
  padding: 0;
  text-decoration: underline;
  text-transform: uppercase;
  ${font('secondary', 'black')};
  ${fontSize(12, 20, 1.4)};

  &:hover {
    color: ${colors.highlight};
  }
`;
