import styled from 'styled-components';
import { colors, padding, maxWidth } from '../../shared/variables';
import bkgTexture from '../../images/bg_texture-dust-white.jpg';
import { mq } from 'shared/mixins';

export const Container = styled.section`
  background: url(${bkgTexture}) 0 0 repeat, ${colors.greyLight};
  padding: 34px ${padding.desktop}px 160px;
  max-width: ${maxWidth}px;

  ${mq.tablet`
    padding: 80px ${padding.tablet}px 160px;
  `}

  ${mq.phoneWide`
    padding: 80px ${padding.mobile}px;
  `}
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 62px;
  margin-top: 32px;
  // max-width: ${maxWidth - padding.desktop * 2}px;
  position: relative;
  // opacity: 0;

  ${mq.tabletWide`
    grid-template-columns: repeat(2, 1fr);
  `}

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }

  ${mq.tablet`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${mq.tablet`
    grid-template-columns: 1fr;
  `}
`;
