import NavigationI from 'data/types/Navigation.types';
import React from 'react';

interface NavLinksContextProps {
  navLinks: NavigationI[];
}

const NavLinksContext = React.createContext<NavLinksContextProps | undefined>(
  undefined
);

export default NavLinksContext;
