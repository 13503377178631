import React from 'react';
import {
  Block,
  Container,
  Content,
  Description,
  Eyebrow,
  PageLink,
  Title,
  SpanContainer,
} from './styles';
import { IconArrow } from '../IconsView';
import QuicklinksI from 'data/types/Quicklinks.types';

interface Props {
  data: QuicklinksI[];
  type?: string;
}

const QuicklinksView = ({ data, type }: Props): JSX.Element => {
  return (
    <Container className={type}>
      <Eyebrow>Quicklinks</Eyebrow>
      <Content>
        {data.map((item) =>
          item.externalLink ? (
            <Block
              key={item.title}
              as="a"
              href={item.externalLink || ''}
              target="_blank"
            >
              <Title>{item.title}</Title>
              <Description>{item.body}</Description>
              <PageLink>
                <SpanContainer>
                  <span>{item.linkTitle}</span>
                </SpanContainer>
                <IconArrow />
              </PageLink>
            </Block>
          ) : (
            <Block key={item.title} to={`/${item.slug}`}>
              <Title>{item.title}</Title>
              <Description>{item.body}</Description>
              <PageLink>
                <SpanContainer>
                  <span>{item.linkTitle}</span>
                </SpanContainer>
                <IconArrow />
              </PageLink>
            </Block>
          )
        )}
      </Content>
    </Container>
  );
};

export default QuicklinksView;
