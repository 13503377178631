import styled from 'styled-components';
import { mq, size } from '../../shared/mixins';
import {
  colors,
  padding,
  maxWidth,
  customBezierEase,
} from '../../shared/variables';
import { H3 } from '../../shared/typography';
import bkgTexture from '../../images/bg_texture-dust-white.jpg';
import { bodyCopySmall, eyebrow } from 'shared/extends';
import Button from 'components/Button';

export const Container = styled.section`
  background: url(${bkgTexture}) 0 0 repeat, ${colors.greyLight};
  padding: 0 ${padding.desktop}px 160px;
  max-width: ${maxWidth}px;
  width: 100%;

  ${mq.tablet`
    padding: 0 ${padding.tablet}px 160px;
  `}

  ${mq.phoneWide`
    padding: 0 27px 80px ${padding.mobile}px;
  `}
`;

export const Content = styled.div`
  margin-top: 32px;
`;

export const YearHeader = styled(H3)`
  color: ${colors.brownLight};
  margin-bottom: 16px;
`;

export const HolidayList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const HolidayItem = styled.li`
  ${bodyCopySmall};
  margin-bottom: 12px;
  display: grid;
  grid-template-columns: 110px 1fr;
  align-items: baseline;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const DisclaimerList = styled.ul`
  margin: 40px 0 0 0;
  padding: 0;
  list-style: none;
`;

export const DisclaimerItem = styled.li`
  ${bodyCopySmall};
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const NotFound = styled.div`
  ${eyebrow};
  color: ${colors.brownLight};
  margin-top: 40px;
  text-align: center;
  width: 100%;
`;

export const PrinterButton = styled(Button)`
  padding: 0;
  margin-top: 51px;

  &:hover {
    svg {
      fill: ${colors.highlight};
    }
  }

  svg {
    ${size('16px')}
    fill: ${colors.primary};
    margin-right: 10px;
    margin-bottom: -2px;
    transition: all 0.3s ${customBezierEase};
  }

  ${mq.tablet`
    display: none;
  `}
`;
