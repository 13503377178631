import React, { useMemo } from 'react';
import IntroView from 'components/IntroView';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import * as queries from 'graphql/queries';
import * as queriesSlug from 'utils/queries';
import FreelanceDirectoryView from 'components/FreelanceDirectoryView';
import { Helmet } from 'react-helmet';
import LoaderView from 'components/LoaderView';

const LIST_FREELANCERS = gql(queries.listFreelancers);
const GET_PAGE_BY_SLUG = gql(queriesSlug.getPageBySlug);

const FreelanceDirectory = ({ slug }: { slug: string }): JSX.Element => {
  const { data: listFreelancersData, loading } = useQuery(LIST_FREELANCERS);
  const freelancers = useMemo(() => {
    return listFreelancersData?.listFreelancers?.items;
  }, [listFreelancersData]);

  const { data: slugData, loading: slugLoading } = useQuery(GET_PAGE_BY_SLUG, {
    variables: {
      slug,
    },
  });

  const pageData = useMemo(() => {
    return slugData?.getPageBySlug.items[0];
  }, [slugData]);

  if (loading || slugLoading) {
    return <LoaderView />;
  }

  return (
    <>
      <Helmet>
        <title>{pageData?.title}</title>
        <meta name="description" content={pageData?.description || ''} />
      </Helmet>
      <IntroView type="subpage" data={pageData} />
      <FreelanceDirectoryView data={freelancers} />
    </>
  );
};

export default FreelanceDirectory;
