import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Container,
  Eyebrow,
  Item,
  List,
  Loading,
  LoadMore,
  NoMoreLoad,
  MemberImg,
  MemberItem,
  ResultsList,
} from './styles';
import { IconArrow } from '../../../IconsView';
import headshot from '../../../SearchView/headshot.png';
import * as queries from 'graphql/queries';
import gql from 'graphql-tag';
import * as localQueries from './queries';
import { useQuery } from '@apollo/client';
import { debounce } from 'lodash';
import { PulseLoader } from 'react-spinners';
import classNames from 'classnames';
import { stopBackgroundScroll } from 'utils/functions';
import {
  GetPageQuery,
  ListMembersQuery,
  ListPagesQuery,
  Member,
  Page,
} from 'API';
import NavLinksContext from 'data/context/NavLinksContext';

interface Props {
  className?: string;
  handleCancel: () => void;
  handlePageScroll: () => void;
  isShowing: boolean;
  searchTerm: string;
  itemUrlMap: {
    id: string;
    url: string;
  }[];
}
const LIST_PAGES = gql(localQueries.listPages);
const LIST_MEMBERS = gql(queries.listMembers);
const GET_PAGE = gql(queries.getPage);

const Results = ({
  className,
  handleCancel,
  handlePageScroll,
  isShowing,
  searchTerm,
  itemUrlMap = [],
}: Props): JSX.Element => {
  const navLinksContext = useContext(NavLinksContext);

  const ListRef = useRef<HTMLDivElement>(null);
  const ResultRef = useRef<HTMLDivElement>(null);
  const ElRef = useRef<HTMLDivElement>(null);
  const [eyebrowText, setEyebrowText] = useState('');
  const [fakeLoadMore, setFakeLoadMore] = useState(false);
  const [noMoreLoad, setNoMoreLoad] = useState(false);
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const allSearchResults = useRef<(Page | Member)[]>([]);
  const [searchResults, setSearchResults] = useState<(Page | Member)[]>([]);
  const searchResultsRef = useRef<(Page | Member)[]>([]);
  const setSearchResultsFunc = (input: (Page | Member)[]) => {
    setSearchResults(input);
    searchResultsRef.current = input;
  };

  const limitResults = useRef(7);

  interface ListMembersQueryI {
    listMembers?: ListMembersQuery['listMembers'];
  }

  interface LinkI {
    linkTo: string;
    sort: number;
  }

  // until we figure out how to combine the logic for this SearchView component and the Results
  // component for MobileNavigationView, make sure the queries for pages and members match
  const { data: memberData, loading: memberLoading } =
    useQuery<ListMembersQueryI>(LIST_MEMBERS, {
      variables: {
        filter: {
          nameLow: {
            contains: searchTerm.toLowerCase(),
          },
        },
      },
      skip: !searchTerm,
    });

  interface SuggestedPageQueryI {
    getPage?: GetPageQuery['getPage'];
  }

  // Fetch dummy page that houses meta used for 'suggested' in search
  const { data: suggestedPageData, loading: suggestedPageLoading } =
    useQuery<SuggestedPageQueryI>(GET_PAGE, {
      variables: { id: 'page-suggested-search' },
    });

  interface ListPagesQueryI {
    listPages?: ListPagesQuery['listPages'];
  }

  const {
    data: listData,
    loading: listLoading,
    fetchMore: listMore,
  } = useQuery<ListPagesQueryI>(LIST_PAGES, {
    variables: {
      filter: {
        status: { eq: 'active' },
        layout: { ne: 'template' },
        or: [
          {
            headlineLow: {
              contains: searchTerm.toLowerCase(),
            },
          },
          {
            bodyLow: {
              contains: searchTerm.toLowerCase(),
            },
          },
        ],
      },
    },
    skip: !searchTerm,
  });

  const [prepData, setPrepData] = useState(false);
  useEffect(() => {
    setPrepData(true);
    const callMorePages = async (pageToken: string) => {
      const morePages = await listMore({ variables: { nextToken: pageToken } });
      return morePages?.data?.listPages?.items;
    };

    const pageList = listData?.listPages?.items || [];
    const memberList = memberData?.listMembers?.items || [];
    const pageToken = listData?.listPages?.nextToken;

    let list = [...memberList, ...pageList];

    if (pageToken) {
      callMorePages(pageToken).then((morePages) => {
        if (Array.isArray(morePages)) {
          list = list.concat(morePages);
          allSearchResults.current = list;
          const cutList = list?.slice(0, limitResults.current);
          setSearchResultsFunc(cutList);
          setPrepData(false);
        }
      });
    } else {
      allSearchResults.current = list;
      const cutList = list?.slice(0, limitResults.current);
      setSearchResultsFunc(cutList);
      setPrepData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listData, memberData, limitResults]);

  const datasLoading = useMemo(() => {
    return listLoading || memberLoading;
  }, [listLoading, memberLoading]);

  useEffect(() => {
    if (!searchTerm) {
      setNoMoreLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const handleScroll = () => {
    const resultList = ResultRef?.current;

    if (!resultList) return;

    if (!searchResultsRef?.current || !allSearchResults?.current) return;

    if (
      resultList.offsetHeight + resultList.scrollTop >=
        resultList.scrollHeight &&
      searchResultsRef.current.length < allSearchResults.current.length
    ) {
      setFakeLoadMore(
        searchResultsRef.current.length < allSearchResults.current.length
      );

      setNoMoreLoad(false);

      setTimeout(() => {
        setFakeLoadMore(false);
        const cutList = allSearchResults.current.slice(
          0,
          searchResultsRef.current.length + limitResults.current
        );
        setSearchResultsFunc(cutList);
      }, 500);
    } else if (
      resultList.offsetHeight + resultList.scrollTop >=
        resultList.scrollHeight &&
      resultList.offsetHeight +
        resultList.scrollTop +
        resultList.scrollHeight !==
        0 &&
      allSearchResults.current.length > 0 &&
      searchResultsRef.current.length === allSearchResults.current.length &&
      !fakeLoadMore &&
      !datasLoading
    ) {
      setTimeout(() => {
        setNoMoreLoad(true);
      }, 1000);
    } else if (allSearchResults.current.length === 0) {
      setNoMoreLoad(true);
    }
  };

  useEffect(() => {
    //figure out how many items can fit on the page
    if (!ListRef.current) return;
    const listItemHeight = 67;
    const startOfList = ListRef.current?.offsetTop || 0;
    const docHeight = window?.innerHeight || 0;
    const navbarHeight =
      document.querySelector('.mobile-nav')?.clientHeight || 0;
    const remainder = docHeight - navbarHeight - startOfList;
    const itemsPerPage = Math.floor(remainder / listItemHeight);
    limitResults.current = itemsPerPage;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowing]);

  useEffect(() => {
    const resultList = ResultRef?.current;

    resultList?.addEventListener('scroll', debounce(handleScroll, 500));
    resultList?.addEventListener('scroll', () => {
      handlePageScroll();
    });

    return () => {
      resultList?.removeEventListener('scroll', debounce(handleScroll, 500));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Listen to search term changes, and show/hide suggestions default pages, else clear it
  useEffect(() => {
    // sanity check
    if (
      !navLinksContext?.navLinks ||
      suggestedPageLoading ||
      !suggestedPageData
    ) {
      return;
    }

    // Clear search results once a search term exists
    if (searchTerm) {
      setSearchResultsFunc([]);

      return;
    }

    // Show suggestions only when there is no search term
    const suggestedPagesData: Page[] = [];

    // Get suggested page Id's from dummy page meta
    const suggestedPageIds = JSON.parse(suggestedPageData.getPage?.meta || '')
      .sort((a: LinkI, b: LinkI) => a.sort - b.sort)
      .map((link: LinkI) => link.linkTo);

    // Find and create pages from suggested nav link matches
    suggestedPageIds.forEach((suggestedId: string) => {
      navLinksContext?.navLinks.forEach((navLink) => {
        // Check top level links
        if (suggestedId === navLink.id) {
          suggestedPagesData.push({
            __typename: 'Page',
            id: navLink.id,
            title: navLink.label || '',
            slug: navLink.slug,
            pageId: navLink.id,
          });
          return;
        }

        // Check sub links
        navLink.subItems?.forEach((subItem) => {
          if (suggestedId === subItem.id) {
            suggestedPagesData.push({
              __typename: 'Page',
              id: subItem.id,
              title: subItem.label || '',
              slug: `${navLink.slug}/${subItem.slug}`,
              pageId: subItem.id,
            });
            return;
          }
        });
      });
    });

    // Set suggested pages to results
    setSearchResultsFunc(suggestedPagesData);
  }, [searchTerm, navLinksContext, suggestedPageData, suggestedPageLoading]);

  // Eyebrow Text Logic
  useEffect(() => {
    // Suggestions Logic
    if (!searchTerm) {
      // Sanity Check
      if (suggestedPageLoading || !suggestedPageData) {
        return setEyebrowText('');
      }

      // Set no label if no suggested pages exist
      const ids = JSON.parse(suggestedPageData.getPage?.meta || '');
      if (!ids.length) {
        return setEyebrowText('');
      }

      // Suggested pages exist, show label
      return setEyebrowText('Suggestions.');
    }

    // Regular Search Logic
    return (!datasLoading && searchResults.length > 0) ||
      (!datasLoading && prepData) ||
      datasLoading
      ? setEyebrowText('Results.')
      : setEyebrowText('No Results Found.');
  }, [
    datasLoading,
    prepData,
    searchResults.length,
    searchTerm,
    setEyebrowText,
    suggestedPageData,
    suggestedPageLoading,
  ]);

  return (
    <Container ref={ElRef} className={classNames(className, 'mobileSearch')}>
      <ResultsList ref={ResultRef}>
        <Eyebrow>{eyebrowText}</Eyebrow>
        <List ref={ListRef}>
          {searchResults?.map((item) => {
            if (item.__typename === 'Member') {
              return (
                <MemberItem
                  key={item.id}
                  to={`../around-office/team-directory?name=${item.name}`}
                  onClick={() => {
                    stopBackgroundScroll('close');
                    handleCancel();
                  }}
                  tabIndex={1}
                >
                  <MemberImg
                    style={{
                      backgroundImage: `url(${
                        itemUrlMap.find((x) => x.id === item.id)?.url ||
                        headshot
                      })`,
                    }}
                  />
                  {item.name} <IconArrow />
                </MemberItem>
              );
            }

            // Page Entry
            let formatSlug = `/${item.slug}`;
            if (item.parentPage) {
              formatSlug = `/${item.parentPage.slug}/${item.slug}`;
            }
            return (
              <Item key={item.id} onClick={handleCancel} to={formatSlug}>
                {item.title}
                <IconArrow />
              </Item>
            );
          })}
          {fakeLoadMore && (
            <LoadMore>
              <PulseLoader />
              Loading More Results
            </LoadMore>
          )}
        </List>
        {datasLoading && searchTerm && (
          <Loading>
            <PulseLoader />
            Loading More Results
          </Loading>
        )}
        {noMoreLoad && searchTerm && !datasLoading ? (
          <NoMoreLoad>No Additional Results</NoMoreLoad>
        ) : (
          ''
        )}
      </ResultsList>
    </Container>
  );
};

export default Results;
