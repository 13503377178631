export const getPageBySlug = /* GraphQL */ `
  query GetPageBySlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPageBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        status
        createdAt
        updatedAt
        title
        slug
        description
        headline
        body
        inlineBody
        ctaText
        ctaLink
        headlineLow
        bodyLow
        layout
        pageId
        local
        publishedAt
        image
        sort
        meta
        parentPage {
          title
        }
      }
      nextToken
    }
  }
`;
