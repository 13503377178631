export const listPagesByPageId = /* GraphQL */ `
  query ListPagesByPageId(
    $pageId: ID
    $sort: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPagesByPageId(
      pageId: $pageId
      sort: $sort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        slug
        pages {
          items {
            id
            title
            slug
            pages {
              items {
                id
                title
                slug
                pages {
                  items {
                    id
                    title
                    slug
                    pages {
                      items {
                        id
                        title
                        slug
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;
