import styled from 'styled-components';
import { size } from '../../../../shared/mixins';
import { colors, customBezierEase } from '../../../../shared/variables';
import { font } from '../../../../shared/typography';
import {
  bodyCopySmall,
  subHeadLarge,
  eyebrow,
  eyebrowSmall,
  listStyles,
} from '../../../../shared/extends';

export const Container = styled.div<{ hide: boolean }>`
  ${(props) =>
    props.hide &&
    `
    display: none;
  `}
`;

export const Avatar = styled.div`
  border-radius: 100%;
  background-size: cover;
  background-position: center, center;
  ${size('158px')};
`;

export const Name = styled.h4`
  ${subHeadLarge};
  margin: 25px 0 5px;
`;

export const Title = styled.p`
  ${eyebrowSmall};
  margin: 0 0 14px;
  ${font('body', 'black')};
`;

export const Detail = styled.p`
  ${bodyCopySmall};
  margin: 0;
`;

export const More = styled.div`
  margin-top: 20px;
  max-height: 20px;
  overflow: hidden;
  transition: max-height 0.5s ${customBezierEase};

  &.open {
    max-height: 100%;
  }
`;
export const MoreHandle = styled.button`
  ${eyebrow};
  color: ${colors.primary};
  border: 0;
  border-radius: 0;
  background: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0;
  transition: all 0.3s ${customBezierEase};

  svg {
    fill: ${colors.primary};
    margin-left: 7px;
    transform: rotate(90deg);
    pointer-events: none;
    transition: all 0.3s ${customBezierEase};
    ${size('5px', '8px')};

    .open & {
      transform: rotate(270deg);
    }
  }

  &:hover {
    color: ${colors.highlight};

    svg {
      fill: ${colors.highlight};
    }
  }
`;
export const Info = styled.div`
  ${bodyCopySmall};
  color: ${colors.brownLight};
  margin: 0 0 20px 0;

  &:first-of-type {
    margin: 9px 0 20px 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  ul {
    ${listStyles};
  }
`;
export const Label = styled.span`
  font-weight: bold;
`;
