import styled from 'styled-components';
import { mq } from '../../shared/mixins';
import { colors, customBezierEase, padding } from '../../shared/variables';
import {
  bodyCopyBold,
  bodyCopySmall,
  eyebrowRegular,
  eyebrowSmall,
  gridDesktop,
  h3,
} from '../../shared/extends';
import CalendarView from 'components/CalendarView';

export const Container = styled.section`
  padding: 80px ${padding.desktop}px 80px;

  ${mq.tablet`
    padding: 80px ${padding.tablet}px 120px;
  `}

  ${mq.phoneWide`
    padding: 80px ${padding.mobile}px;
  `}
`;

export const Eyebrow = styled.h4`
  ${eyebrowRegular};
  color: ${colors.primary};
  grid-column: 1 / span 12;
  padding: 0 0 4.1px;
  text-transform: uppercase;
`;

export const Content = styled.div`
  ${gridDesktop};

  ${mq.desktop`
    display: flex;
    flex-direction: column;
  `}
`;

export const Calendar = styled(CalendarView)`
  grid-column: 1 / span 5;
`;

export const Events = styled.div`
  grid-column: 6 / span 7;
  padding: 0 0 0 15%;

  ${mq.desktop`
    padding: 0;
    margin-top: 60px;
  `}

  ${mq.tablet`
    margin-top: 120px;
  `}
`;

export const EventMonth = styled.span`
  ${eyebrowSmall};
  display: block;
  color: ${colors.primary};
`;

export const EventList = styled.div`
  grid-column: 6 / span 7;
  overflow-y: auto;
  height: 320px;
  margin-top: 8px;
  padding-right: 61px;

  ${mq.desktop`
    height: 375px;
    padding-right: 0px;
  `}

  ${mq.tablet`
    height: 375px;
    padding-right: 0px;
  `}
`;

export const EventTitle = styled.p`
  ${h3};
  margin: 0 0 3px;
  text-transform: uppercase;
  transition: color 0.4s ease;
  word-wrap: break-word;
`;

export const Event = styled.div`
  padding: 16px 0 14px;
  border-bottom: 1px solid ${colors.greyLightLight};

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border: 0;
  }

  &.active {
    ${EventTitle} {
      color: ${colors.highlight};
      transition: color 0.3s ${customBezierEase};
    }
  }
`;

export const EventDate = styled.span`
  ${bodyCopyBold};
  margin: 0 16px 0 0;
  text-transform: uppercase;
`;

export const EventTime = styled.span`
  ${bodyCopySmall};
  margin: 0;
`;

export const EventNone = styled.p`
  ${eyebrowRegular};
  color: ${colors.brownLight};
  margin: 0;
  padding: 10px 0;
`;
