import { font, fontSize } from 'shared/typography';
import { colors, customBezierEase, padding } from 'shared/variables';
import { mq } from 'shared/mixins';
import styled, { keyframes } from 'styled-components';
import { bodyCopySmall } from 'shared/extends';

export const Container = styled.footer<{ is404: boolean }>`
  padding: 0 ${padding.desktop}px;
  position: relative;
  margin-top: auto;

  svg {
    fill: ${colors.brownLight};
    width: 75px;
  }

  p {
    color: ${colors.brownLight};
    margin: 0 0 0 8px;
    ${font('body')};
    ${fontSize(14, 24)};

    &.mobile {
      font-weight: bold;
      display: none;
    }
  }

  /* ${mq.tabletWide`
    p {
      &.desktop {
        display: none;
      }

      &.mobile {
        display: block;
      }
    }
  `} */
  ${mq.tablet`
    padding: 0px ${padding.tablet}px;

    p {
      text-align: center;
      margin: 0;
    }
  `}
  ${mq.phoneWide`
    padding: 0px ${padding.mobile}px;
  `}

  ${(props) =>
    props.is404 &&
    `
    svg {
      fill: ${colors.white};
    }
    p {
      color: ${colors.white};
    }
  `}
`;

export const Content = styled.div`
  border-top: 1px solid ${colors.grey};
  display: flex;
  align-items: center;
  height: 84px;

  ${mq.tablet`
    flex-direction: column;
    justify-content: center;
    height: auto;
    padding: 29px 0 31px;
  `}
`;

export const SayingList = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  max-height: calc(var(--doc-height) - 40px);
  overflow-y: auto;
`;

const SayingDropIn = keyframes`
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
`;

export const Saying = styled.div`
  ${bodyCopySmall};
  background: ${colors.brownLight};
  padding: 12px 16px;
  border-radius: 3px;
  color: ${colors.white};
  animation: ${SayingDropIn} 0.5s ${customBezierEase};
  max-width: 300px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;
