import { useFormContext } from 'react-hook-form';
import React, { useEffect, useState, useRef } from 'react';
import {
  ArrowContainer,
  ButtonCover,
  Container,
  IconContainer,
  Input,
  Label,
  ModalContainer,
} from './styles';
import dayjs from 'dayjs';
import { IconCalendar } from 'components/IconsView';
import CalendarView from 'components/CalendarView';
import classNames from 'classnames';

interface Props {
  name: string;
  label?: string;
  defaultValue?: string;
  error?: boolean;
  errorLimited?: boolean;
  required?: boolean;
  format?: string;
  className?: string;
  changeDate?: (date: string) => void;
  changeValidity?: (value: boolean) => void;
  allowWeekends?: boolean;
}

const DateSelectorView = ({
  name,
  label,
  defaultValue,
  changeDate,
  changeValidity,
  error,
  errorLimited,
  required,
  format,
  className,
  allowWeekends,
}: Props): JSX.Element => {
  const ModalRef = useRef<null | HTMLDivElement>(null);
  const ButtonRef = useRef<null | HTMLButtonElement>(null);
  const { register, setValue, clearErrors } = useFormContext();
  const [date, setDate] = useState('');
  // eslint-disable-next-line
  const [validDate, setValidDate] = useState(true);
  const [openCalendar, setOpenCalendar] = useState(false);
  const bounceClickRef = useRef(false);

  useEffect(() => {
    if (date !== defaultValue) {
      setDate(defaultValue || '');
    }
    if (defaultValue) {
      setValue(name, defaultValue, { shouldDirty: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  useEffect(() => {
    if (register) {
      register(name, { required });
    }
    if (defaultValue) {
      setValue(name, defaultValue, { shouldDirty: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register, name]);

  useEffect(() => {
    if (changeValidity) {
      changeValidity(validDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validDate]);

  const handleDateChange = (value: string) => {
    setDate(value);

    // instead of waiting for the text input to blur well change it when its valid
    if (dayjs(value).isValid()) {
      if (changeDate) {
        changeDate(value);
      }
    }
  };

  const updateDate = (newDate: string) => {
    if (dayjs(newDate).isValid()) {
      setDate(newDate);
      setValue(name, newDate, { shouldDirty: true });
      if (changeDate) {
        changeDate(newDate);
      }

      if (errorLimited || error) {
        clearErrors(name);
      }

      setOpenCalendar(false);
      setTimeout(() => {
        setOpenCalendar(false);
      }, 200);
    }
  };

  const handleOpenCalendar = () => {
    setOpenCalendar((prev) => !prev);
  };

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const handleModalBackgroundClick = (e: any) => {
    if (!bounceClickRef.current) {
      bounceClickRef.current = true;
      if (!ModalRef.current?.contains(e.target as HTMLElement)) {
        handleOpenCalendar();
      }

      setTimeout(() => {
        bounceClickRef.current = false;
      }, 200);
    }
  };

  useEffect(() => {
    if (openCalendar) {
      document.addEventListener('click', handleModalBackgroundClick);
      return () => {
        document.removeEventListener('click', handleModalBackgroundClick);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openCalendar]);

  // const jumpToForm = ModalRef.current?.scrollIntoView();

  const renderDateSelector = (): JSX.Element => {
    return (
      <Container
        aria-label="open-calendar"
        className={classNames('date', className, {
          error: error,
          errorLimited: errorLimited,
        })}
      >
        {/* {errorLimited && <IconWarning className="icon-warning" />} */}
        <ButtonCover
          type="button"
          onClick={() => {
            if (!openCalendar) {
              handleOpenCalendar();
            }
            ModalRef.current?.scrollIntoView({
              block: 'center',
              behavior: 'smooth',
            });
          }}
          ref={ButtonRef}
        />
        <Input
          aria-label={name}
          value={
            dayjs(date).isValid()
              ? dayjs(date).format(format || 'MM/DD/YYYY')
              : date
          }
          name={name}
          data-testid={name}
          onChange={(e) => handleDateChange(e.target.value)}
          onBlur={(e) => updateDate(e.target.value)}
          className={!validDate ? 'valid' : ''}
          id={name}
          disabled
        />
        <ArrowContainer>
          <IconContainer>
            <IconCalendar />
          </IconContainer>
        </ArrowContainer>
        <ModalContainer
          ref={ModalRef}
          className={classNames('modal-calendar', { open: openCalendar })}
        >
          <CalendarView
            start={date || ''}
            end={date || ''}
            dateSelected={updateDate}
            allowWeekends={allowWeekends}
            allowWFHDays={true}
          />
        </ModalContainer>
      </Container>
    );
  };

  if (label) {
    return (
      <Label className={classNames('form-date', { error: error })}>
        <span>{label}</span>
        {renderDateSelector()}
      </Label>
    );
  }

  return renderDateSelector();
};

export default DateSelectorView;
