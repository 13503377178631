import styled from 'styled-components';
import { size } from '../../../../shared/mixins';
import { colors, customBezierEase } from '../../../../shared/variables';
import {
  bodyCopySmall,
  eyebrowSmall,
  listStyles,
  resetButtonStyles,
} from '../../../../shared/extends';

const tableDesktop = `1fr 100px repeat(2, 1fr) 12px`;
export const Icon = styled.div`
  ${resetButtonStyles};
  position: relative;
  display: inline-block;
  align-self: center;
  margin-left: auto;
  ${size('12px')}

  &:before, &:after {
    background-color: ${colors.primary};
    content: '';
    left: 50%;
    opacity: 1;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.2s ${customBezierEase};
    ${size('12px', '2px')}
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-90deg);
  }

  &.active {
    &:after {
      opacity: 0;
      transform: translate(-50%, -50%);
    }
  }
`;
export const Container = styled.div`
  background: ${colors.white};
  color: ${colors.brownLight};
  margin-top: 16px;
  padding: 15px 30px;

  &.hasDetails {
    cursor: pointer;
    .body-container:not(.is-touch) &:hover {
      ${Icon} {
        &::before,
        &:after {
          background: ${colors.highlight};
        }
      }
    }
  }
`;
export const Top = styled.div`
  ${bodyCopySmall};
  display: grid;
  grid-template-columns: ${tableDesktop};
  grid-column-gap: 16px;
  align-items: center;
  margin: 0;
  outline: 0;
  text-align: left;
  width: 100%;

  span {
    word-break: break-all;
  }
`;
export const DetailContainer = styled.div`
  ${bodyCopySmall}
  max-height: 0;
  overflow: hidden;
  transition: 0.2s ${customBezierEase};
`;
export const Detail = styled.div`
  ${bodyCopySmall};
  padding: 20px 0 10px;
  display: grid;
  grid-template-columns: ${tableDesktop};
  grid-template-rows: auto 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 20px;
`;

export const InfoContainer = styled.div`
  display: grid;
  grid-row-gap: 20px;
  grid-column: 2 / span 1;
  grid-row: 1 / span 2;
`;

export const Info = styled.div`
  &.capabilities {
    grid-column: 1 / span 1;
    grid-row: span 2;
  }

  &.notes {
    grid-column: 3 / span 1;
    grid-row: 1 / span 2;
  }

  &.status {
    grid-column: 4 / span 1;
    grid-row: 1 / span 2;
  }

  ul {
    ${listStyles};
    padding-left: 16px;
  }
`;

export const InfoTitle = styled.span`
  ${eyebrowSmall};
  display: block;
`;

export const FreeLink = styled.a`
  ${bodyCopySmall};
  word-break: break-all;

  &:hover {
    color: ${colors.highlight};
  }
`;
