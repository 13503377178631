import { IconArrow, IconWarning } from 'components/IconsView';
import NotificationI from 'data/types/Notification.types';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { breakpoints } from 'shared/variables';
import {
  debounce,
  viewWYSIWYG,
  formatHeaderNodes,
  formatBodyLinks,
  isExternalLink,
} from 'utils/functions';
import {
  Container,
  Content,
  Description,
  Eyebrow,
  Notification,
  NotificationMessage,
  NotificationTitle,
  PageLink,
  Search,
  Title,
} from './styles';
import LoaderView from 'components/LoaderView';
import PageI from 'data/types/Page.types';

interface Props {
  type: string;
  data: PageI | null;
  notification?: NotificationI;
}

const IntroView = ({ type, data, notification }: Props): JSX.Element => {
  const [isTablet, setIsTablet] = useState(false);
  const [bodyData, setBodyData] = useState('');

  useEffect(() => {
    const topBodyContent = document.querySelector('#top-body-content');
    topBodyContent?.scrollIntoView();
  }, []);

  useEffect(() => {
    setIsTablet(window.innerWidth < breakpoints.tablet);
    window.addEventListener(
      'resize',
      debounce(function () {
        setIsTablet(window.innerWidth < breakpoints.tablet);
      })
    );

    return () => {
      window.addEventListener(
        'resize',
        debounce(function () {
          setIsTablet(window.innerWidth < breakpoints.tablet);
        })
      );
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBody = async (content: string) => {
    const body = await viewWYSIWYG(content);
    const bodyFormatted = formatHeaderNodes(body);
    const bodyLinks = formatBodyLinks(bodyFormatted);

    let bodyEmptyCheck = bodyLinks;
    switch (bodyLinks.trim()) {
      case '':
      case '<p></p>':
      case '<p> </p>':
      case '<p><br></p>':
      case '<p><br /></p>':
        bodyEmptyCheck = '';
        break;
    }

    setBodyData(bodyEmptyCheck);
  };

  useEffect(() => {
    setBodyData(data?.body || '');

    if (data?.body) {
      getBody(data?.body);
    }
  }, [data]);

  return (
    <Container className={type}>
      <Content>
        {!isTablet && <Search />}
        {data ? (
          <>
            <Eyebrow className="eyebrow">
              {data?.pageId === 'main'
                ? 'Five & Done Intranet'
                : data?.parentPage?.title || 'Five & Done Intranet'}
            </Eyebrow>
            <Title>{data?.headline}</Title>
            {bodyData && (
              <Description
                className={classNames('intro-description', {
                  long: bodyData.length > 500,
                })}
                dangerouslySetInnerHTML={{
                  __html: bodyData,
                }}
              />
            )}
            {data?.ctaLink &&
              data?.ctaText &&
              (isExternalLink(data.ctaLink) ? (
                <PageLink as="a" href={data.ctaLink} target="_blank">
                  <div>
                    <span>{data?.ctaText}</span>
                  </div>
                  <IconArrow />
                </PageLink>
              ) : (
                <PageLink to={data?.ctaLink}>
                  <div>
                    <span>{data?.ctaText}</span>
                  </div>
                  <IconArrow />
                </PageLink>
              ))}
            {notification && (
              <Notification to={notification.link}>
                <div className="warning">
                  <IconWarning />
                </div>
                <div className="message">
                  <NotificationTitle>{notification.title}</NotificationTitle>
                  <NotificationMessage>
                    {notification.message}
                  </NotificationMessage>
                </div>
                <div className="arrow">
                  <IconArrow />
                </div>
              </Notification>
            )}
          </>
        ) : (
          <LoaderView />
        )}
      </Content>
    </Container>
  );
};

export default IntroView;
