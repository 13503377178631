import { css, keyframes } from 'styled-components';

export const colors = {
  primary: '#F1B64F',
  highlight: '#FB4640',
  highlightDelete: '#E13E38',
  greyLight: 'rgba(246, 246, 242, 0.5);',
  greyMedium: '#EAEAE5',
  greyMediumMedium: '#7d7f7c',
  greyLightLight: '#E0DFD7',
  brownLightLight: '#B2AD9F',
  brownLight: '#8A816D',
  brownMedium: '#4D453D',
  lightYellow: '#F6F6F2',
  white: '#FFFFFF',
  black: '#000000',
  grey: '#E0DFD7',
  blue: '#0000AA',
  focus: '#007CEE',
  blueDark: '#1D1E27',
};

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    transform: translate(120%, 0%);
    opacity: 0;
  }
`;

const fadeIn = keyframes`
  0% {
    transform: translate(-100%, 0%);
    opacity: 0;
  }

  100% {
    transform: translate(0%, 0%);
    opacity: 1;
  }
`;

const fadeOutBackward = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    transform: rotate(180deg) translate(120%, 0%);
    opacity: 0;
  }
`;

const fadeInBackward = keyframes`
  0% {
    transform: rotate(180deg) translate(-100%, 0%);
    opacity: 0;
  }

  100% {
    transform: rotate(180deg) translate(0%, 0%);
    opacity: 1;
  }
`;

export const MOUSE_EASE = 0.32;
export const SCROLL_EASE = 0.325;
export const customBezierEase = `cubic-bezier(0.075, 0.82, 0.165, 1)`;
export const iconArrowAnimation = css`
  animation: ${fadeOut} 0.1s linear,
    ${fadeIn} 0.3s cubic-bezier(0.65, 0, 0.35, 1) 0.1s;
`;
export const iconArrowAnimationBackward = css`
  animation: ${fadeOutBackward} 0.1s linear,
    ${fadeInBackward} 0.3s cubic-bezier(0.65, 0, 0.35, 1) 0.1s;
`;

export const padding = {
  listItem: {
    padding: 1.8,
    gap: 1.3,
  },
  // desktop: 110,
  mobile: 30,
  tablet: 75,
  desktop: 114,
};

export const border = {
  listItem: {
    border: `1px solid ${colors.greyLightLight}`,
  },
};
type BreakpointsI = {
  [key: string]: number;
};

export const breakpoints: BreakpointsI = {
  phone: 375,
  phoneWide: 480,
  phablet: 560,
  tabletSmall: 640,
  navMobile: 800,
  tablet: 768,
  searchTablet: 900,
  tabletWide: 1024,
  desktop: 1248,
  desktopWide: 1440,
};

export const maxWidth = 1200;
export const contentMaxWidth = 980;

export const mobileNavHeight = '82px';
