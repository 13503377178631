import classNames from 'classnames';
import { IconSimpleArrowSmall } from 'components/IconsView';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Background,
  Container,
  Option,
  OptionContainer,
  Options,
  SelectContainer,
  Selected,
} from './styles';
import { Label } from 'components/FormComponents/styles';

type SelectI = {
  label: string;
  value: string;
};

interface Props {
  name: string;
  options: SelectI[];
  className?: string;
  label?: string;
  value?: string;
  required?: boolean;
  error?: boolean;
  errorLimited?: boolean;
  defaultOption?: string;
  optional?: boolean;
  handleChange?: (value: string) => void;
}

const SelectView = ({
  className,
  name,
  label,
  options,
  value,
  required,
  optional,
  defaultOption,
  error,
  errorLimited,
  handleChange,
}: Props): JSX.Element => {
  const SelectRef = useRef<HTMLDivElement>(null);
  // const SelectedRef = useRef<HTMLButtonElement>(null);
  const OptionsRef = useRef<HTMLDivElement>(null);
  const { register, setValue, clearErrors } = useFormContext();
  const [val, setVal] = useState(value || '');
  const [selected, setSelected] = useState('');
  const [availableOptions, setAvailableOptions] = useState<SelectI[]>([]);
  const [openWidth, setOpenWidth] = useState('100%');
  // const [openPoint, setOpenPoint] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (val !== value) {
      setVal(value || '');
    }
    if (value) {
      setValue(name, value, { shouldDirty: true });
    }

    if (defaultOption) {
      setSelected(defaultOption);
    } else if (value && options.length > 0) {
      const option = options?.find((x: SelectI) => x.value === value);
      if (option) {
        setSelected(option.label);
      }
    } else {
      setSelected(options[0].label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, defaultOption, options]);

  useEffect(() => {
    setAvailableOptions(options.filter((x: SelectI) => x.value !== selected));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (register) {
      register(name, { required });
    }
    if (value) {
      setValue(name, value, { shouldDirty: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register, name]);

  const onValChange = useCallback(
    (v, vl) => {
      setVal(v);
      setValue(name, v, { shouldDirty: true });
      setSelected(vl);
      if (SelectRef.current) {
        SelectRef.current.classList.remove('open');
        setOpenWidth('100%');
      }
      if (v && (errorLimited || error)) {
        clearErrors(name);
      }
      if (handleChange) {
        handleChange(v);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, setValue, errorLimited, error, handleChange]
  );

  return (
    <Container ref={SelectRef} className={`form-select ${className}`}>
      {label && (
        <Label htmlFor={name} className={error ? 'error' : ''}>
          {label}
          {optional && <span className="optional">Optional</span>}
        </Label>
      )}
      <SelectContainer
        className={classNames('select', {
          error: error,
          errorLimited: errorLimited,
        })}
      >
        <Selected
          style={{
            width: openWidth,
          }}
          // ref={SelectedRef}
          id={name}
          data-testid={name}
          type="button"
          className="select-selected"
          onClick={() => {
            if (SelectRef.current) {
              SelectRef.current.classList.toggle('open');
              if (
                OptionsRef.current &&
                SelectRef.current.classList.contains('open')
              ) {
                setOpenWidth(`${OptionsRef?.current?.scrollWidth}px` || '100%');
              } else {
                setOpenWidth('100%');
              }
            }

            // if (SelectedRef.current) {
            //   setOpenPoint({
            //     top: SelectedRef.current.getBoundingClientRect().top,
            //     left: SelectedRef.current.getBoundingClientRect().left,
            //   });
            // }
          }}
          // onBlur={onValBlur}
        >
          <span>{selected}</span>
          <IconSimpleArrowSmall />
        </Selected>
        {/* {errorLimited && <IconWarning className="error-icon" />} */}
        <Background
          onClick={() => {
            if (SelectRef.current) {
              SelectRef.current.classList.toggle('open');
              if (
                OptionsRef.current &&
                SelectRef.current.classList.contains('open')
              ) {
                setOpenWidth(`${OptionsRef?.current?.scrollWidth}px` || '100%');
              } else {
                setOpenWidth('100%');
              }
            }
          }}
        />
        <Options
          ref={OptionsRef}
          style={{
            width: openWidth,
            // top: `${openPoint.top}px`,
            // left: `${openPoint.left}px`,
            // position: 'fixed',
          }}
        >
          {availableOptions.map((option: SelectI) => (
            <OptionContainer
              key={option.value}
              type="button"
              tabIndex={openWidth === '100%' ? -1 : 0}
              onClick={() => onValChange(option.value, option.label)}
            >
              <Option>{option.label}</Option>
            </OptionContainer>
          ))}
        </Options>
      </SelectContainer>
    </Container>
  );
};

export default SelectView;
