import React, { useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Container,
  Detail,
  Info,
  Label,
  More,
  MoreHandle,
  Name,
  Title,
} from './styles';
import headshot from '../../headshot.png';
import { IconSimpleArrow } from 'components/IconsView';
import MemberI from 'data/types/Member.types';
import classNames from 'classnames';
import { breakpoints } from 'shared/variables';
import { debounce } from 'utils/functions';
import { Storage } from 'aws-amplify';

interface Props {
  data: MemberI;
  hide?: boolean;
  matchName?: string;
  departmentTranslations: { label: string; value: string; key?: string }[];
}

const EmployeeCard = ({
  data,
  hide = false,
  matchName,
  departmentTranslations,
}: Props): JSX.Element => {
  const MoreLinkRef = useRef<HTMLDivElement>(null);
  const ElRef = useRef<HTMLDivElement>(null);
  const [isTablet, setIsTabletState] = useState(false);
  const isTabletRef = useRef(isTablet);
  const setIsTablet = (input: boolean) => {
    isTabletRef.current = input;
    setIsTabletState(input);
  };
  const [employeeAvatar, setEmployeeAvatar] = useState<null | string>(null);

  useEffect(() => {
    setIsTablet(window.innerWidth < breakpoints.tablet);

    window.addEventListener(
      'resize',
      debounce(function () {
        setIsTablet(window.innerWidth < breakpoints.tablet);
      })
    );

    return () => {
      window.removeEventListener(
        'resize',
        debounce(function () {
          setIsTablet(window.innerWidth < breakpoints.tablet);
        })
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (matchName === data.name) {
      const mores = Array.from(
        document.querySelectorAll('.employeeCards-more')
      ).filter((more) => more.classList.contains('open'));
      mores.forEach((more) => more.classList.remove('open'));
      // setActive(!active);
      MoreLinkRef.current?.classList.add('open');

      // scroll to top of card
      const el = ElRef.current;
      if (el) {
        const content: HTMLDivElement | HTMLBodyElement | null =
          document.querySelector('.body-content');
        // window.innerWidth > breakpoints.tablet
        //   document.querySelector('.body-content')
        //   : document.querySelector('body');
        if (content) {
          content.scrollTo({
            top: 100,
          });
          setTimeout(() => {
            const rect = el.getBoundingClientRect();
            const top = rect.top + window.pageYOffset - (isTabletRef ? 82 : 0);
            console.log(`top: ${top}, content: ${content}`);
            content.scrollTo({
              top,
              behavior: 'smooth',
            });
          }, 200);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchName]);

  const getEmployeeAvatar = async () => {
    if (data.image) {
      const image = await Storage.get(data.image);
      setEmployeeAvatar(image);
    } else {
      setEmployeeAvatar(headshot);
    }
  };

  useEffect(() => {
    getEmployeeAvatar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Container hide={hide} ref={ElRef}>
      <Avatar
        style={{ backgroundImage: `url(${employeeAvatar || headshot})` }}
      />
      <Name>{data.name}</Name>
      {data.title && <Title>{data.title}</Title>}
      {data.phone && <Detail>{data.phone}</Detail>}
      <More ref={MoreLinkRef} className={classNames('employeeCards-more')}>
        <MoreHandle
          onClick={() => {
            if (MoreLinkRef.current?.classList.contains('open')) {
              MoreLinkRef.current?.classList.remove('open');
            } else {
              const mores = Array.from(
                document.querySelectorAll('.employeeCards-more')
              ).filter((more) => more.classList.contains('open'));
              mores.forEach((more) => more.classList.remove('open'));
              // setActive(!active);
              MoreLinkRef.current?.classList.add('open');
            }
          }}
        >
          More Info <IconSimpleArrow />
        </MoreHandle>
        {data.email && (
          <Info>
            <span>{data.email}</span>
          </Info>
        )}
        {data.office.name && (
          <Info>
            <Label>Location: </Label>
            <span>{data.office.name}</span>
          </Info>
        )}
        {data.department && (
          <Info>
            <Label>Department: </Label>
            <span>
              {departmentTranslations.find((x) => x.value === data.department)
                ?.label || data.department}
            </span>
          </Info>
        )}
        {data.reportTo && (
          <Info>
            <Label>Reports To: </Label>
            <span>{data.reportTo}</span>
          </Info>
        )}
        {data.projects && (
          <Info>
            <Label>Primary Projects: </Label>
            <ul>
              {data.projects.map((project: string) => {
                return <li key={`${data.name || ''}-${project}`}>{project}</li>;
              })}
            </ul>
          </Info>
        )}
      </More>
    </Container>
  );
};

export default EmployeeCard;
