import styled from 'styled-components';
import { mq, size } from '../../shared/mixins';
import {
  colors,
  customBezierEase,
  padding,
  iconArrowAnimation,
} from '../../shared/variables';
import { font, fontSize } from '../../shared/typography';
import {
  bodyCopySmall,
  eyebrow,
  eyebrowRegular,
  gridDesktop,
  resetButtonStyles,
} from 'shared/extends';
import { zIndex } from 'shared/zIndex';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  ${gridDesktop};
  z-index: ${zIndex.search};
  max-height: calc(100vh - 46px);
`;

export const CancelButton = styled.button`
  ${resetButtonStyles};
  color: ${colors.brownLight};
  text-decoration: underline;
  text-transform: uppercase;
  position: fixed;
  top: 24px;
  right: 32px;
  border-radius: 25px;
  z-index: 1;
  pointer-events: none;
  border: 2px solid ${colors.primary};
  opacity: 0;
  padding: 14px;
  transition: border 0.3s ${customBezierEase};
  ${size('51px')}

  .active & {
    opacity: 1;
    pointer-events: all;
  }

  svg {
    fill: ${colors.primary};
  }

  path {
    transition: stroke 0.3s ${customBezierEase};
  }

  .body-container:not(.is-touch) &:hover {
    border-color: ${colors.highlight};
    path {
      stroke: ${colors.highlight};
    }
  }
`;

export const LeftSideButton = styled.button`
  ${resetButtonStyles};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: initial;
  content: '';
  opacity: 0;
  pointer-events: none;
  ${size(`calc(233px + ${padding.desktop}px)`, '100vh')};

  .active & {
    opacity: 1;
    pointer-events: all;
  }
`;
export const RightSideButton = styled.button`
  ${resetButtonStyles};
  position: fixed;
  top: 0;
  cursor: initial;
  right: 0;
  z-index: 1;
  content: '';
  opacity: 0;
  pointer-events: none;
  ${size(`${padding.desktop}px`, '100vh')};

  .active & {
    opacity: 1;
    pointer-events: all;
  }
`;

export const Search = styled.div`
  max-width: 482px;
  grid-column: 1 / span 6;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.brownLight};
  position: relative;
  // opacity: 0;

  .active & {
    z-index: 1;
  }

  span {
    background-color: ${colors.brownMedium};
    content: '';
    bottom: -1px;
    left: 0;
    position: absolute;
    transition: width 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    ${size('0px', '1px')}
  }

  svg {
    fill: ${colors.white};
    transition: all 0.2s ${customBezierEase};
  }

  .subpage & {
    svg {
      fill: ${colors.brownLight};

      &:hover {
        cursor: pointer;
        fill: ${colors.highlight};
      }
    }
  }

  .active & {
    svg {
      fill: ${colors.brownLight};

      &:hover {
        cursor: pointer;
        fill: ${colors.highlight};
      }
    }
  }

  ${mq.searchTablet`
    grid-column: 1 / span 9;
    max-width: 100%;
  `}

  ${mq.tablet`
    grid-column: 1 / span 12;

    svg {
      fill: ${colors.brownLight} !important;
    }

    span {
      background-color: ${colors.brownLight} !important;
    }
  `}

  input::placeholder {
    transition: all 0.2s ${customBezierEase};
  }

  &:hover {
    input {
      cursor: pointer;
      &::placeholder {
        color: ${colors.brownLight};
      }
    }

    svg {
      fill: ${colors.brownLight};
    }
  }
`;

const placeholderStyles = `
  color: rgba(246, 246, 242, 0.5);

  .subpage & {
    color: ${colors.grey};
  }
  
  .active & {
    color: ${colors.grey};
  }
`;
export const Input = styled.input`
  border: 0;
  background: transparent;
  color: ${colors.brownLight};
  padding: 0 0 10px 0;
  width: 100%;
  ${font('primary', 'black')}
  ${fontSize(24, 27, 0)}

  &::placeholder {
    ${placeholderStyles}

    ${mq.tablet`
      color: ${colors.grey};
    `}
  }

  ::-webkit-input-placeholder {
    ${placeholderStyles}

    ${mq.tablet`
      color: ${colors.grey};
    `}
  }
  &::-moz-placeholder {
    ${placeholderStyles}

    ${mq.tablet`
      color: ${colors.grey};
    `}
  }
  &:-ms-input-placeholder {
    ${placeholderStyles}

    ${mq.tablet`
      color: ${colors.grey};
    `}
  }
  &:-moz-placeholder {
    ${placeholderStyles}

    ${mq.tablet`
      color: ${colors.grey};
    `}
  }

  &:focus {
    outline: 0;

    & + span {
      width: 100%;
      width: 100% !important;
    }
  }
`;

export const ClearButton = styled.button`
  ${resetButtonStyles};
  ${eyebrowRegular};
  color: ${colors.brownLight};
  text-align: right;
  text-decoration: underline;

  &:hover {
    color: ${colors.highlight};
  }
`;

export const Backdrop = styled.div`
  background-color: ${colors.white};
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  transform: translateY(-100%);
  ${size('100%')}

  .active & {
    transform: translateY(0);
    pointer-events: all;
  }

  ${mq.tablet`
    display: none;
  `}
`;

export const Results = styled.div`
  position: relative;
  pointer-events: none;
  opacity: 0;
  display: none;
  grid-column: 1 / span 9;
  overflow-y: auto;

  .active & {
    display: block;
    z-index: 1;
    pointer-events: all;
    opacity: 1;
  }

  ${mq.searchTablet`
    grid-column: 1 / span 12;
  `}
`;

export const Eyebrow = styled.span`
  ${eyebrow};
  color: ${colors.primary};
  display: block;
  margin: 100px 0 26px;

  &.hide {
    display: none;
  }
`;

export const List = styled.div`
  margin: 0;
  padding: 0 0 75px;
  position: relative;
  list-style: none;
  overflow: hidden;
  // overflow-y: auto;
`;

export const ItemLink = styled(Link)`
  color: ${colors.brownLight};
  text-decoration: none;
  padding: 0 0 15px;
  margin-bottom: 33px;
  display: flex;
  border-bottom: 1px solid ${colors.brownLight};
  align-items: center;
  justify-content: space-between;
  ${font('primary', 'bold')};
  ${fontSize(24, 27, 0)};

  svg {
    fill: ${colors.highlight};
    ${size('16px', '7px')}
  }

  &:hover {
    color: ${colors.highlight};

    svg {
      ${iconArrowAnimation};
    }
  }
`;

export const MemberLink = styled(ItemLink)`
  justify-content: flex-start;

  svg {
    margin-left: auto;
  }

  &:hover {
    color: ${colors.highlight};

    svg {
      ${iconArrowAnimation};
    }
  }
`;

export const MemberImg = styled.div`
  border-radius: 100%;
  background-size: cover;
  background-position: center, center;
  background-color: ${colors.brownLight};
  margin-right: 35px;
  ${size('120px')};
`;

export const Loading = styled.div`
  ${bodyCopySmall};
  margin: 0 auto;
  width: 100%;
  text-align: center;
  color: ${colors.highlight};

  > span {
    margin-right: 16px;

    span {
      background-color: ${colors.highlight};
      ${size('6px')};
    }
  }
`;

export const LoadMore = styled(Loading)`
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
`;

export const NoMoreLoad = styled(Loading)`
  margin-bottom: 20px;
  margin-top: -20px;
`;
