import styled from 'styled-components';
import { mq, size } from '../../../../shared/mixins';
import { colors, customBezierEase } from '../../../../shared/variables';
import { resetButtonStyles } from '../../../../shared/extends';
import InputView from 'components/FormComponents/InputView';

export const Input = styled(InputView)``;

export const TrashButton = styled.button`
  ${resetButtonStyles};

  ${size('auto', '50px')};

  svg {
    fill: ${colors.primary};
  }

  ${mq.tablet`
    text-align: left;
    ${size('auto')};
  `}

  &:hover {
    svg {
      fill: ${colors.highlight};
    }
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns:
    110px repeat(2, minmax(100px, 1fr)) 68px repeat(2, 100px)
    17px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  line-height: normal;

  input {
    outline-color: ${colors.greyMedium};
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }

  select {
    outline-color: ${colors.greyMedium};
  }

  .select,
  .date {
    outline-color: ${colors.greyMedium};
  }

  &:not(:first-child) {
    /* ${Input} {
      max-height: 50px;
    } */
  }

  &:first-child {
    ${TrashButton} {
      margin-top: 29px;
    }
  }

  ${mq.dablet`
    grid-template-columns: 1fr;
    grid-row-gap: 8px;
    padding: 16px;
    background-color: ${colors.white};

    &:not(:first-child) {
      ${Input} {
        max-height: initial;
      }
    }

    &:first-child {
      ${TrashButton} {
        margin-top: 0px;
      }
    }

    .modal-calendar {
      right: 0;
    }
  `}
`;

export const TextareaContainer = styled.div<{ index: number }>`
  position: relative;
  max-height: 79px;

  textarea {
    outline-color: ${colors.greyMedium};
    min-height: 50px;
    max-height: 50px;
    transition: width 0.2s ${customBezierEase}, height 0.2s ${customBezierEase},
      position 0s linear 0.2s;

    &:focus {
      position: absolute;
      top: 0;
      left: 0;
      max-height: 150px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      transition: width 0.2s ${customBezierEase},
        height 0.2s ${customBezierEase};
      z-index: 1;
      ${size('260px', '150px')}
    }
  }

  ${mq.tablet`
    height: 79px;

    textarea {
      &:focus {
        // position: relative; 
        max-height: initial;
        z-index: 2;
        ${size('100%', '500px')}
      }
    }
  `}
`;
