import OfficeI from './Office.types';

interface PageI {
  id: string;
  label?: string;
  status?: string;
  createdAt?: string;
  updatedAt?: string;
  title: string;
  slug: string;
  description?: string;
  eyebrow?: string;
  headline?: string;
  body?: string;
  ctaText?: string;
  ctaLink?: string;
  local?: string;
  pageId?: string;
  layout?: string;
  pages?: { items: PageI[] };
  parentPage?: PageI;
  sort?: number;
  officeId?: string;
  office?: OfficeI;
  meta?: string;
}

export enum PageStatus {
  active = 'active',
  pending = 'pending',
  disabled = 'disabled',
}

export default PageI;
