import React, { useMemo } from 'react';
import * as queries from 'utils/queries';
import * as localQueries from './queries';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import IntroView from 'components/IntroView';
import { Helmet } from 'react-helmet';
import LoaderView from 'components/LoaderView';
import HolidaysView from 'components/HolidaysView';

const GET_PAGE_BY_SLUG = gql(queries.getPageBySlug);
const LIST_YEARS = gql(localQueries.listYears);

const Holidays = ({ slug }: { slug: string }): JSX.Element => {
  const { data: slugData, loading: slugLoading } = useQuery(GET_PAGE_BY_SLUG, {
    variables: {
      slug,
    },
  });

  const { data: listData, loading: listLoading } = useQuery(LIST_YEARS);

  const pageData = useMemo(() => {
    return slugData?.getPageBySlug.items[0];
  }, [slugData]);

  const yearData = useMemo(() => {
    return listData?.listYears.items;
  }, [listData]);

  if (slugLoading || listLoading) {
    return <LoaderView />;
  }

  return (
    <>
      <Helmet>
        <title>{pageData?.title}</title>
        <meta name="description" content={pageData?.description || ''} />
      </Helmet>
      <IntroView type="subpage" data={pageData} />
      <HolidaysView data={yearData} />
    </>
  );
};

export default Holidays;
