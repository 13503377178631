import React, { useEffect, useMemo, useState } from 'react';
import {
  AccordionContainer,
  Box,
  ClearButton,
  Container,
  Content,
  Input,
  NotFound,
  OfficeTabs,
  SearchContainer,
  SectionHeader,
} from './styles';
import { TabI } from 'data/types/Tab.types';
import Accordion from 'components/Accordion';
import { FAQI, FAQSectionI } from 'data/types/Faq.types';
import { IconSearch, IconX } from 'components/IconsView';
import { Office } from 'API';

interface Props {
  faqSections: FAQSectionI[];
  offices: Office[];
}

const FAQView = ({ faqSections, offices }: Props): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeOfficeFAQs, setActiveOfficeFAQs] = useState<FAQSectionI[]>([]);

  // Tabs logic
  const tabs = useMemo(() => {
    const temp: TabI[] = [];

    const sortedOffices = offices.sort(
      (a, b) => (a?.sort || 0) - (b?.sort || 0)
    );

    // Check active offices only
    sortedOffices.forEach((office) => {
      if (office.active) {
        temp.push({ label: office.name || '' });
      }
    });

    return temp;
  }, [offices]);

  // Active FAQ's Logic
  useEffect(() => {
    const filteredFAQSections: FAQSectionI[] = [];
    const currentOffice = offices[selectedTab];

    // Loop through each section
    faqSections.forEach((section) => {
      const activeFAQs: FAQI[] = [];
      section.faqs.forEach((faq) => {
        // If not assigned to any office, skip
        if (!faq.officeIDs) {
          return;
        }

        // If faq has current office in its list, then add to active
        if (faq.officeIDs.includes(currentOffice.id)) {
          // Check if there are search terms
          if (
            searchTerm.length &&
            faq.question.toLowerCase().includes(searchTerm.toLowerCase())
          ) {
            activeFAQs.push(faq);
          } else if (!searchTerm.length) {
            activeFAQs.push(faq);
          }
        }
      });

      // Sort FAQ's
      const sortedActiveFAQs = activeFAQs.sort((x, y) => x.sort - y.sort);

      // Add modified section to show current active section/faqs
      if (activeFAQs.length) {
        filteredFAQSections.push({
          id: section.id,
          title: section.title,
          sort: section.sort,
          faqs: sortedActiveFAQs, // modified faqs based on office
          createdAt: section.createdAt,
          updatedAt: section.updatedAt,
        });
      }
    });

    // Return sorted section results
    setActiveOfficeFAQs(filteredFAQSections.sort((x, y) => x.sort - y.sort));
  }, [faqSections, selectedTab, searchTerm, offices]);

  const renderFAQs = (section: FAQI[]) => {
    return section
      ?.slice()
      ?.sort((x, y) => x.sort - y.sort)
      .map((item) => {
        return (
          <Accordion
            key={item.id}
            keepOpen={false}
            title={item.question}
            body={item.answer}
          />
        );
      });
  };

  return (
    <Container>
      <Content>
        <OfficeTabs
          tabs={tabs || []}
          returnActive={(selected) => {
            setSelectedTab(selected);
          }}
          noBreak={true}
        />
        <SearchContainer>
          <Input
            aria-label="search"
            value={searchTerm}
            onChange={(el) => setSearchTerm(el.target.value)}
            placeholder={`Search FAQ ${offices?.[selectedTab].name}`}
            type="text"
          />
          <span />
          {searchTerm ? (
            <ClearButton
              aria-label="clear search"
              type="button"
              onClick={() => setSearchTerm('')}
            >
              <IconX />
            </ClearButton>
          ) : (
            <IconSearch />
          )}
        </SearchContainer>

        {searchTerm && (
          <Box key={'searchkey'}>
            <SectionHeader>Search Result: "{searchTerm}"</SectionHeader>
          </Box>
        )}

        {activeOfficeFAQs?.map((section) => {
          return (
            <Box key={section.id}>
              <SectionHeader>{section.title}</SectionHeader>
              <AccordionContainer>
                {section?.faqs && renderFAQs(section?.faqs)}
              </AccordionContainer>
            </Box>
          );
        })}

        {!activeOfficeFAQs.length && searchTerm.length ? (
          <Box key={'noresults'}>
            <NotFound>No Results Found.</NotFound>
          </Box>
        ) : (
          <></>
        )}

        {!activeOfficeFAQs.length && !searchTerm.length ? (
          <Box key={'noresults'}>
            <NotFound>No FAQs available for this office at this time.</NotFound>
          </Box>
        ) : (
          <></>
        )}
      </Content>
    </Container>
  );
};

export default FAQView;
