import styled from 'styled-components';
import { colors } from 'shared/variables';
import { font, fontSize } from 'shared/typography';

export const Wrapper = styled.div`
  background-color: ${colors.white};
  padding: 2rem;
`;

export const Label = styled.label`
  color: ${colors.brownMedium};
  ${fontSize(14)};
  font-family: ${font('body')};
  &:before {
    content: '';
    background: white;
    border-radius: 100%;
    border: 1.5px solid ${colors.primary};
    display: inline-block;
    width: 30px;
    height: 30px;
    position: relative;
    top: -0.4em;
    margin-right: 1em;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    transition: all 150ms ease;
  }
  &:empty {
    &:before {
      margin-right: 0;
    }
  }
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;

  &:checked {
    + ${Label} {
      &:before {
        background-color: ${colors.primary};
        box-shadow: inset 0 0 0 5px white;
      }
    }
    &:disabled {
      + ${Label} {
        color: ${colors.greyMediumMedium};
        &:before {
          box-shadow: inset 0 0 0 4px white;
          border-color: white;
          background: ${colors.greyLightLight};
        }
      }
    }
  }

  &:disabled {
    + ${Label} {
      color: ${colors.greyMediumMedium};
      &:before {
        box-shadow: inset 0 0 0 4px white;
        border-color: white;
        background: white;
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;

  &.error {
    ${Label}:before {
      border-color: ${colors.highlight};
    }
  }
`;
