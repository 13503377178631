import styled from 'styled-components';
import { size } from '../../shared/mixins';
import { colors, customBezierEase } from '../../shared/variables';
import { font, fontSize } from '../../shared/typography';
import { eyebrow, resetButtonStyles } from '../../shared/extends';

export const Container = styled.div`
  position: relative;
`;

export const TabContainer = styled.div`
  border-bottom: 1px solid ${colors.greyLightLight};
  position: relative;
  display: flex;

  &.hide {
    display: none;
  }
`;

export const Tab = styled.button<{ active: boolean }>`
  background: transparent;
  color: ${colors.primary};
  border: 0;
  cursor: pointer;
  margin: 0 50px 0 0;
  padding: 0 0 2px;
  text-transform: uppercase;
  transition: 0.5s ${customBezierEase};
  position: relative;
  white-space: nowrap;
  ${font('secondary', 'black')};
  ${fontSize(12, 20)};

  &.hide {
    display: none;
  }

  &:last-child {
    margin: 0;
  }

  &:hover {
    color: ${colors.highlight};
  }

  &:focus {
    outline: none;
  }

  &:after {
    background: transparent;
    content: '';
    display: block;
    top: calc(100% + 10px);
    left: 0px;
    transition: 0.3s ${customBezierEase};
    position: absolute;
    ${size('100%', '3px')}
  }

  ${(props) =>
    props.active &&
    `
    color: ${colors.brownLight};

    &:hover {
      color: ${colors.brownLight};
    }

    &:after {
      background: ${colors.brownLight};
      top: calc(100% - 2px);
    }
  `}
`;

export const More = styled.div`
  opacity: 0;
  pointer-events: none;
  position: absolute;
  margin-left: auto;

  &.show {
    position: relative;
    opacity: 1;
    pointer-events: all;
  }
`;

export const MoreButton = styled.button`
  background: transparent;
  color: ${colors.primary};
  border: 1px solid ${colors.greyLightLight};
  border-bottom: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0 0 0;
  padding: 5px 16px 5px;
  text-transform: uppercase;
  transition: 0.5s ${customBezierEase};
  position: relative;
  white-space: nowrap;
  ${font('secondary', 'black')};
  ${fontSize(12, 20)};

  svg {
    fill: ${colors.primary};
    margin-left: 7px;
    ${size('8px', '6px')};
  }

  &:hover {
    color: ${colors.brownLight};
    svg {
      fill: ${colors.brownLight};
    }
  }

  &.open {
    svg {
      transform: rotate(180deg);
    }
  }
`;

export const MoreList = styled.ul`
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 13px 20px 13px 16px;
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  list-style: none;
  margin: 0;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-10px);
  z-index: 1;
  transition: opacity 0.2s ${customBezierEase},
    transform 0.3s ${customBezierEase};

  &.show {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0px);
  }
`;
export const MoreItem = styled.li`
  text-align: center;
`;

export const MoreTab = styled.button<{ active: boolean }>`
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  color: ${colors.primary};
  margin: 9px 0;
  text-transform: uppercase;
  white-space: nowrap;
  ${font('secondary', 'black')};
  ${fontSize(12, 20)};

  &:hover {
    color: ${colors.brownLight};
  }
  ${(props) =>
    props.active &&
    `
  color: ${colors.brownLight};

  &:after {
    background: ${colors.brownLight};
    top: calc(100% - 2px);
  }
`}
`;

export const DropdownContainer = styled.div`
  display: none;
  position: relative;
  width: 100%;
  &.show {
    display: block;
  }
`;

export const Top = styled.button`
  ${resetButtonStyles};
  ${eyebrow};
  background: ${colors.white};
  color: ${colors.brownLight};
  border: 1px solid ${colors.grey};
  padding: 5px 13px 6px;
  display: flex;
  cursor: pointer;
  align-items: center;
  width: 100%;

  svg {
    fill: ${colors.primary};
    margin-left: 6px;
    ${size('8px', '6px')};
  }

  &:hover {
    color: ${colors.brownLight};
    svg {
      fill: ${colors.brownLight};
    }
  }

  &.open {
    svg {
      transform: rotate(180deg);
    }
  }
`;

export const Dropdown = styled.ul`
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 24px 16px;
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  list-style: none;
  margin: 0;
  opacity: 0;
  width: 100%;
  max-height: 330px;
  overflow-y: auto;
  pointer-events: none;
  z-index: 1;
  transform: translateY(-10px);
  transition: opacity 0.2s ${customBezierEase},
    transform 0.3s ${customBezierEase};

  &.show {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0px);
  }
`;
export const Item = styled.li`
  margin: 0 0 24px;
  padding: 0;
  width: 100%;

  &:last-child {
    margin: 0;
  }
`;
export const ItemButton = styled.button`
  ${resetButtonStyles};
  ${eyebrow};
  background: ${colors.white};
  color: ${colors.primary};
  width: 100%;
  text-align: left;

  svg {
    fill: ${colors.brownLight};
    margin-left: 6px;
    ${size('10px', '8px')};
  }
`;
