import styled from 'styled-components';
import { mq, size } from '../../shared/mixins';
import {
  colors,
  padding,
  maxWidth,
  customBezierEase,
} from '../../shared/variables';
import { font, fontSize, H4 } from '../../shared/typography';
import bkgTexture from '../../images/bg_texture-dust-white.jpg';
import {
  bodyCopyBold,
  bodyCopySmall,
  eyebrow,
  resetButtonStyles,
} from '../../shared/extends';
import InputView from 'components/FormComponents/InputView';
import TextAreaView from 'components/FormComponents/TextAreaView';
import Button from 'components/Button/Button';

export const Container = styled.section`
  background: url(${bkgTexture}) 0 0 repeat, ${colors.greyLight};
  padding: 0 ${padding.desktop}px 160px;

  ${mq.tablet`
    padding: 0 ${padding.tablet}px 100px;
  `}

  ${mq.phoneWide`
    padding: 0 ${padding.mobile}px 80px;
  `}
`;

export const Content = styled.form`
  max-width: ${maxWidth - padding.desktop * 2}px;
  display: flex;
  flex-direction: column;
`;

export const NameInput = styled(InputView)`
  max-width: 316px;
  // margin-bottom: 32px;

  ${mq.tablet`
    max-width: 100%;
  `}
`;

export const ExpenseList = styled.div`
  display: grid;
  grid-row-gap: 16px;
  padding: 32px 0 65px;
  // overflow-x: auto;

  ${mq.phoneWide`
    padding: 24px 0;
    grid-row-gap: 24px;
  `}
`;

export const Middle = styled.div`
  // margin-top: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${mq.dablet`
    flex-direction: column;
    align-items: flex-start;
  `}
`;

export const AddButton = styled(Button)<{ tertiary: boolean }>`
  ${bodyCopyBold};
  padding: 0;

  span {
    display: flex;
    align-items: center;
  }

  svg {
    transition: fill 0.2s ${customBezierEase};
    fill: ${colors.primary};
    margin-right: 8px;
    ${size('21px')};
  }

  ${mq.dablet`
    margin-bottom: 24px;
  `}

  &:hover {
    color: ${colors.highlight};

    svg {
      fill: ${colors.highlight};
    }
  }
`;

export const Total = styled(H4)`
  color: ${colors.brownLight};
  margin: 0;

  span {
    margin-left: 40px;
  }

  ${mq.dablet`
    border-top: 1px solid ${colors.greyMedium};
    padding-top: 24px;
    width: 100%;
  `}
`;

export const Notes = styled(TextAreaView)`
  width: 100%;
  margin: 32px 0;

  ${mq.tablet`
    margin: 49px 0 24px;
  `}
`;

export const SubmitButton = styled(Button)<{ primary: boolean }>`
  ${eyebrow};
  margin-left: auto;
  margin-top: 32px;
  padding: 15px 38px;

  &.disabled {
    // pointer-events: none;
    background: ${colors.grey};
    color: ${colors.brownLightLight};

    &:hover {
      background: ${colors.grey};
      cursor: not-allowed;
    }
  }
  &.submitting {
    position: relative;
    pointer-events: none;
    width: 150px;
  }

  ${mq.tablet`
    float: unset;
    width: 100%;
    `}

  ${mq.phoneWide`
    margin-top: 24px;
  `}
`;

export const SubmitLoader = styled.div`
  height: 40px;
  position: absolute;
  transform: translateY(-50%) scale(0.5);
  left: 48px;
  top: calc(50% - 3px);
`;

export const Error = styled.p`
  ${bodyCopySmall};
  color: ${colors.highlight};
  font-weight: 700;
  margin: 8px 0 0;
  margin-left: auto;

  svg {
    fill: ${colors.highlight};
    margin: 0 8px -4px 0;
  }
`;

export const Success = styled.div`
  width: 50%;
  max-width: 460px;

  ${mq.tabletWide`
  width: 75%;
    `}
  ${mq.tablet`
  width: 100%;
    `}
`;

export const Eyebrow = styled.span`
  ${eyebrow};
  color: ${colors.primary};
`;

export const Message = styled.p`
  color: ${colors.brownLight};
  margin: 17px 0 40px;
  ${font('primary', 'black')};
  ${fontSize(24, 27)}
`;

export const CreateAnother = styled.button`
  ${resetButtonStyles};
  ${bodyCopySmall};
  color: ${colors.highlight};

  svg {
    fill: ${colors.highlight};
    margin-left: 9px;
    ${size('16px', '7px')};
  }
`;
