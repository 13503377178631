import classNames from 'classnames';
import { Input } from 'components/FormComponents/InputView/styles';
import { Label } from 'components/FormComponents/styles';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { validateTime } from 'utils/functions';
import { Container, InputContent, Timezone } from './styles';

interface ErrorProps {
  [name: string]: boolean;
}

interface Props {
  name: string;
  error?: boolean;
  disabled?: boolean;
  optional?: boolean;
  label?: string;
  value?: string;
  required?: boolean;
  placeholder?: string;
  handleError: (val: ErrorProps) => void;
  timezone: string;
}

const Time = ({
  name,
  error,
  disabled,
  optional,
  label,
  value,
  required,
  placeholder,
  handleError,
  timezone,
}: Props): JSX.Element => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [val, setVal] = useState(value || '');
  const InputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const input = InputRef.current;

    if (!input) return;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (disabled) {
      setValue(name, '');
      setVal('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  useEffect(() => {
    if (val !== value) {
      setVal(value || '');
    }
    if (value) {
      setValue(name, value, { shouldDirty: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (register) {
      register(name, { required });
    }
    if (value) {
      setValue(name, value, { shouldDirty: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register, name]);

  /** remove the : and number if delete is pressed */
  const handleKeyDown = useCallback(
    (el) => {
      const v = el.target.value;
      if (v.slice(-1) === ':' && el.keyCode === 8) {
        const formattedV = v.slice(0, -2);
        setVal(formattedV);
        setValue(name, `${formattedV}`);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, setValue, val]
  );

  const handleChange = useCallback(
    (el) => {
      const v = el.target.value;
      // console.log('v: ', name, v);
      // if (name === 'endTime' && v === '') {
      //   console.log('empty return time!');
      //   handleError({ wfhShort: false, endTime: false });
      //   // handleError({ endTime: false });
      // }
      let formatV = v;

      const isValid = validateTime(formatV);
      if (isValid) {
        // split after the valid time
        const splitIndex = formatV.indexOf(':') + 3;
        const time = formatV.slice(0, splitIndex);
        let other = formatV.slice(splitIndex);
        if (other) {
          other = other
            ?.replace(/[^apmAPM ]/g, '') // only a|p|A|P|m|M|
            ?.replace(/(.)(?=.*\1)/g, ''); // only one of each

          // keeps space in front
          if (other[0] !== ' ') {
            other = ` ${other.trim()}`;
          }

          // keeps day zone formatted
          const zone = other.slice(1, other.length);
          if (zone) {
            if (zone.length > 2) {
              other = ` ${zone.substring(0, 2)}`;
            }
            // m cant go first
            if (zone[0].toLowerCase() === 'm') {
              other = other.replace(/m|M/g, '');
            }
            if (zone.toLowerCase().match(/ap|pa|aa|pp/g)) {
              other = ` ${zone[0].toUpperCase()}M`;
            }
          }
        }
        formatV = time + other;
      } else {
        formatV = v.replace(/[^0-9:]/g, '');

        const getNumbers = parseInt(v);

        /* This is checking if the input has a colon and if it does, it will add a colon to the input. */
        if (!v.includes(':') && getNumbers > -1) {
          if (getNumbers >= 2 && getNumbers <= 12) {
            // safe numbers to assume the hour is done
            formatV += ':';
          } else if (v === '01') {
            // special case if they put a 01 then add the :
            formatV += ':';
          } else if (getNumbers >= 13 && getNumbers <= 15) {
            // 13 -15 translates to 1:30 - 1:50
            formatV = `1:${v[1]}`;
          } else if (getNumbers > 15) {
            //trying to enter 16 or something which translates to 1:60 and isnt a real time
            formatV = v[0];
          } else if (getNumbers === 0) {
            // if they put just zeros only allow one
            formatV = '0';
          }
        } else if (v.includes(':')) {
          // dont all 60+ in the minutes only 0-5 as the first digit in minutes
          let minutes = formatV.split(':')[1];
          let hours = formatV.split(':')[0].substring(0, 2);
          if (hours > 12) {
            hours = hours.substring(0, 1);
          }
          minutes = minutes.replace(/[^0-5]/g, '').substring(0, 2);
          formatV = `${hours}:${minutes}`;
        }
        formatV = formatV.replace('::', ':');
      }

      // stop from allowing more than 00:00 AM
      setVal(formatV);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, setValue, val]
  );

  const roundTime = (hour: number, minute: number): string => {
    let hours = hour;
    let minutes = '';
    if (name === 'startTime') {
      if (minute >= 0 && minute <= 14) {
        minutes = '00';
      } else if (minute >= 15 && minute <= 29) {
        minutes = '15';
      } else if (minute >= 30 && minute <= 44) {
        minutes = '30';
      } else if (minute >= 45 && minute <= 59) {
        minutes = '45';
      }
    }

    if (name === 'endTime') {
      if (minute >= 1 && minute <= 15) {
        minutes = '15';
      } else if (minute >= 16 && minute <= 30) {
        minutes = '30';
      } else if (minute >= 31 && minute <= 45) {
        minutes = '45';
      } else if (minute >= 46) {
        minutes = '00';
        hours += 1;
      } else if (minute === 0) {
        minutes = '00';
      }
    }
    return `${hours}:${minutes}`;
  };

  const handleBlur = useCallback(
    (el) => {
      let v = el.target.value;
      if (!v) {
        setVal('');
        setValue(name, '');
        return;
      }

      if (v === '12:') {
        v = '12:00';
      } else if (v === '1' || v === '01:') {
        v = '1:00';
      } else if (v === '2:' || v === '02:') {
        v = '2:00';
      } else if (v === '3:' || v === '03:') {
        v = '3:00';
      } else if (v === '4:' || v === '04:') {
        v = '4:00';
      } else if (v === '5:' || v === '05:') {
        v = '5:00';
      } else if (v === '6:' || v === '06:') {
        v = '6:00';
      } else if (v === '7:' || v === '07:') {
        v = '7:00';
      } else if (v === '8:' || v === '08:') {
        v = '8:00';
      } else if (v === '9:' || v === '09:') {
        v = '9:00';
      } else if (v === '10:') {
        v = '10:00';
      } else if (v === '11:') {
        v = '11:00';
      }

      let formattedV = v;
      const vSplit = v.split(' ');
      const onlyTime = vSplit[0];

      const isValid = /^([0]?[1-9]|[1][0-2]):([0-5][0-9])$/.test(onlyTime);
      const hasAmPm = vSplit.find((i: string) => i.match(/(AM|PM|am|pm)/g));

      /* Checking if the time is valid and if it is, it will set the error to true, if not, it will set
      the error to false. */
      if (isValid) {
        handleError({
          [name === 'startTime' ? 'validStart' : 'validEnd']: true,
        });
        let hours = parseInt(v.split(':')[0]);
        let minutes = parseInt(v.split(':')[1]);

        /* Rounding the time to the nearest 15 minutes. */
        formattedV = roundTime(hours, minutes);

        hours = parseInt(formattedV.split(':')[0]);
        minutes = parseInt(formattedV.split(':')[1]);

        /* This is checking if the time has AM or PM. If it doesn't, it will add AM or PM depending on
        the time. */
        if (!hasAmPm) {
          if ((hours >= 1 && hours <= 6) || hours === 12) {
            formattedV += ` PM`;
          } else {
            formattedV += ` AM`;
          }
        } else {
          formattedV += ` ${hasAmPm}`;
        }
      } else {
        handleError({
          [name === 'startTime' ? 'validStart' : 'validEnd']: false,
        });
      }
      console.log(formattedV);
      setVal(formattedV.toUpperCase());
      setValue(name, `${formattedV.toUpperCase()}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, setValue, val]
  );

  return (
    <Container className={classNames({ disabled: disabled })}>
      {label && (
        <Label
          htmlFor={name}
          className={classNames({ error: error || errors?.[name] })}
        >
          {label}
          {optional && <span className="optional">Optional</span>}
        </Label>
      )}
      <InputContent>
        <Input
          id={name}
          data-testid={name}
          name={name}
          type="text"
          ref={InputRef}
          value={val}
          autoComplete="off"
          className={classNames({
            error: error || errors?.[name],
          })}
          placeholder={placeholder}
          disabled={disabled}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />
        <Timezone>{timezone}</Timezone>
      </InputContent>
    </Container>
  );
};

export default Time;
