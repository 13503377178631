import { TabDropdownOptionI } from 'data/types/TabDropdown.types';
import React, { useCallback, useEffect, useState } from 'react';
import slugify from 'slugify';
import { Container, Content, CustomCheckbox, Search } from './styles';
import { useForm, FormProvider } from 'react-hook-form';
import { IconSearch } from 'components/IconsView';
import { Input } from 'components/FormComponents/InputView/styles';

interface Props {
  data: TabDropdownOptionI[];
  className?: string;
  tab?: string;
  searchable?: boolean;
  hide?: boolean;
  handleActive: (name: string, value: boolean) => void;
}

const Dropdown = ({
  className,
  data,
  handleActive,
  tab,
  searchable = false,
  hide,
}: Props): JSX.Element => {
  const [filteredData, setFilteredData] = useState<TabDropdownOptionI[]>([]);
  const methods = useForm();
  useEffect(() => {
    if (data) {
      setFilteredData(data);
    }
  }, [data]);
  const handleCheckbox = (name: string, value: boolean) => {
    handleActive(name, value);
  };
  console.log('data:', data);
  const handleSearch = useCallback(
    (el: React.ChangeEvent<HTMLInputElement>) => {
      const search = el.target.value;
      const filtered = data.filter((item) => {
        return item.label.toLowerCase().includes(search.toLowerCase());
      });
      setFilteredData(filtered);
    },
    [data]
  );
  if (hide) {
    return <></>;
  }
  return (
    <Container className={className}>
      <FormProvider {...methods}>
        <Content>
          {searchable && (
            <Search>
              {/* borrow styles of input from form components */}
              <Input
                type="text"
                onChange={handleSearch}
                aria-label={`tab-search-${tab}`}
              />
              <IconSearch />
            </Search>
          )}
          {filteredData.map((item: TabDropdownOptionI) => {
            return (
              <CustomCheckbox
                key={`${tab}-${item.label}`}
                className={'tab-checkbox'}
                //when we get concrete values remove item.label but since its all strings it stays
                name={`${tab}-${
                  item.value || item.label || slugify(item.label.toLowerCase())
                }`}
                label={item.label}
                onChange={(name, value) => handleCheckbox(item.label, value)}
              />
            );
          })}
        </Content>
      </FormProvider>
    </Container>
  );
};

export default Dropdown;
