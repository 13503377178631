import styled from 'styled-components';
import { colors } from '../../../shared/variables';
import { bodyCopySmall } from '../../../shared/extends';
import { size } from 'shared/mixins';

export const Container = styled.div``;

export const TextareaContent = styled.div`
  position: relative;

  svg {
    fill: ${colors.highlight};
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    ${size('16px')};
  }

  p.description {
    ${bodyCopySmall}
    color: ${colors.brownLight};
    text-transform: none;
    margin-top: 0px;
  }
`;

export const TextArea = styled.textarea`
  ${bodyCopySmall};
  border: 0;
  border-radius: 0;
  padding: 15px 20px;
  width: 100%;
  resize: none;
  outline: 1px solid ${colors.white};
  outline-offset: -1px;
  min-height: 190px;

  &.error {
    outline: 1px solid ${colors.highlight};
  }
  &.errorLimited {
    outline: 2px solid ${colors.highlight};

    &:focus {
      + svg {
        display: none;
      }
    }
  }
`;
