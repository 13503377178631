import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import * as queries from 'graphql/queries';
import * as localQueries from './queries';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { useFeatures } from 'flagged';
import {
  Backdrop,
  CancelButton,
  ClearButton,
  Container,
  Eyebrow,
  Input,
  ItemLink,
  LeftSideButton,
  List,
  Loading,
  LoadMore,
  MemberImg,
  MemberLink,
  NoMoreLoad,
  Results,
  RightSideButton,
  Search,
} from './styles';
import { IconArrow, IconSearch } from '../IconsView';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { PulseLoader } from 'react-spinners';
import { stopBackgroundScroll } from 'utils/functions';
import headshot from './headshot.png';
import { Storage } from 'aws-amplify';
import { ReactComponent as CloseX } from 'images/close-x.svg';
import {
  Member,
  Page,
  GetPageQuery,
  ListPagesQuery,
  ListMembersQuery,
} from 'API';
import NavLinksContext from 'data/context/NavLinksContext';

interface Props {
  className?: string;
  handleOpen?: (action: string) => void;
}

const LIST_PAGES = gql(localQueries.listPages);
const LIST_MEMBERS = gql(queries.listMembers);
const GET_PAGE = gql(queries.getPage);

// only shows on desktop, search for mobile is in MobileNavigationView
const SearchView = ({ className, handleOpen }: Props): JSX.Element => {
  const features = useFeatures();
  const navLinksContext = useContext(NavLinksContext);
  const ListRef = useRef<HTMLDivElement>(null);
  const ResultRef = useRef<HTMLDivElement>(null);
  const InputRef = useRef<HTMLInputElement>(null);
  const ElRef = useRef<HTMLDivElement>(null);
  const CancelRef = useRef<HTMLButtonElement>(null);
  const [searchActive, setSearchActiveFunc] = useState(false);
  const searchActiveRef = useRef(false);
  const setSearchActive = (state: boolean) => {
    setSearchActiveFunc(state);
    searchActiveRef.current = state;
  };
  const [searchTerm, setSearchTerm] = useState('');
  const [fakeLoadMore, setFakeLoadMore] = useState(false);
  const [noMoreLoad, setNoMoreLoad] = useState(false);
  const [eyebrowText, setEyebrowText] = useState('');

  const allSearchResults = useRef<(Page | Member)[]>([]);
  const [searchResults, setSearchResults] = useState<(Page | Member)[]>([]);
  const searchResultsRef = useRef<(Page | Member)[]>([]);
  const setSearchResultsFunc = (input: (Page | Member)[]) => {
    setSearchResults(input);
    searchResultsRef.current = input;
  };
  const limitResults = useRef(7);

  interface ListMembersQueryI {
    listMembers?: ListMembersQuery['listMembers'];
  }

  interface LinkI {
    linkTo: string;
    sort: number;
  }

  // until we figure out how to combine the logic for this SearchView component and the Results
  // component for MobileNavigationView, make sure the queries for pages and members match
  const { data: memberData, loading: memberLoading } =
    useQuery<ListMembersQueryI>(LIST_MEMBERS, {
      variables: {
        filter: {
          nameLow: {
            contains: searchTerm.toLowerCase(),
          },
        },
      },
      skip: !searchTerm,
    });

  interface SuggestedPageQueryI {
    getPage?: GetPageQuery['getPage'];
  }

  // Fetch dummy page that houses meta used for 'suggested' in search
  const { data: suggestedPageData, loading: suggestedPageLoading } =
    useQuery<SuggestedPageQueryI>(GET_PAGE, {
      variables: { id: 'page-suggested-search' },
    });

  interface ListPagesQueryI {
    listPages?: ListPagesQuery['listPages'];
  }

  const {
    data: listData,
    loading: listLoading,
    fetchMore: listMore,
  } = useQuery<ListPagesQueryI>(LIST_PAGES, {
    variables: {
      filter: {
        status: { eq: 'active' },
        layout: { ne: 'template' },
        or: [
          {
            headlineLow: {
              contains: searchTerm.toLowerCase(),
            },
          },
          {
            bodyLow: {
              contains: searchTerm.toLowerCase(),
            },
          },
        ],
      },
    },
    skip: !searchTerm,
  });

  const [itemUrlMap, setItemUrlMap] = useState<{ id: string; url: string }[]>(
    []
  );

  const getImages = async (list: Member[]) => {
    const temp = [];
    for (let m = 0; m < list.length; m++) {
      const item = list[m];
      if (item.image) {
        const imageLink = await Storage.get(item.image);
        temp.push({ id: item.id, url: imageLink });
      }
    }

    if (temp.length) {
      setItemUrlMap(temp);
    }
  };

  useEffect(() => {
    const memberList = memberData?.listMembers?.items || [];
    getImages(memberList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberData?.listMembers?.items]);

  const [prepData, setPrepData] = useState(false);
  useEffect(() => {
    setPrepData(true);
    const callMorePages = async (pageToken: string) => {
      const morePages = await listMore({ variables: { nextToken: pageToken } });
      return morePages?.data?.listPages?.items;
    };

    const pageList = listData?.listPages?.items || [];
    const memberList = memberData?.listMembers?.items || [];
    const pageToken = listData?.listPages?.nextToken;

    let list = [...memberList, ...pageList];

    if (pageToken) {
      callMorePages(pageToken).then((morePages) => {
        if (Array.isArray(morePages)) {
          list = list.concat(morePages);
          allSearchResults.current = list;
          const cutList = list?.slice(0, limitResults.current);
          setSearchResultsFunc(cutList);
          setPrepData(false);
        }
      });
    } else {
      allSearchResults.current = list;
      const cutList = list?.slice(0, limitResults.current);
      setSearchResultsFunc(cutList);
      setPrepData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listData, memberData, limitResults]);

  const datasLoading = useMemo(() => {
    return listLoading || memberLoading;
  }, [listLoading, memberLoading]);

  const handleSearchTerm = (el: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = el.target;
    setSearchTerm(value);
    setNoMoreLoad(false);
  };
  const handleChange = useMemo(() => debounce(handleSearchTerm, 300), []);

  const handleScroll = () => {
    const resultList = ResultRef?.current;

    if (!resultList) return;

    if (!searchResultsRef?.current || !allSearchResults?.current) return;

    if (
      resultList.offsetHeight + resultList.scrollTop >=
        resultList.scrollHeight &&
      searchResultsRef.current.length < allSearchResults.current.length
    ) {
      setFakeLoadMore(
        searchResultsRef.current.length < allSearchResults.current.length
      );

      setNoMoreLoad(false);

      setTimeout(() => {
        setFakeLoadMore(false);
        const cutList = allSearchResults.current.slice(
          0,
          searchResultsRef.current.length + limitResults.current
        );
        setSearchResultsFunc(cutList);
      }, 500);
    } else if (
      resultList.offsetHeight + resultList.scrollTop >=
        resultList.scrollHeight &&
      searchResultsRef.current.length === allSearchResults.current.length &&
      !fakeLoadMore &&
      !datasLoading
    ) {
      setNoMoreLoad(true);
    }
  };

  useEffect(() => {
    //figure out how many items can fit on the page
    if (!ListRef.current) return;
    const listItemHeight = 75;
    const startOfList = ListRef.current?.offsetTop || 0;
    const startOfResult = ResultRef.current?.offsetTop || 0;
    const docHeight = window?.innerHeight || 0;
    const remainder = docHeight - startOfList - startOfResult;
    const itemsPerPage = Math.floor(remainder / listItemHeight);
    limitResults.current = itemsPerPage;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchActive]);

  // eslint-disable-next-line
  const handleKeyUp = (event: KeyboardEvent) => {
    if (searchActiveRef.current) {
      if (event.key === 'Escape') {
        setSearchActive(false);
        setSearchTerm('');
        setSearchResultsFunc([]);
        InputRef.current?.blur();
        stopBackgroundScroll('close');
      }
    }
  };

  useEffect(() => {
    const resultList = ResultRef?.current;
    resultList?.addEventListener('scroll', debounce(handleScroll, 500));

    window.addEventListener('keyup', handleKeyUp);

    return () => {
      handleChange.cancel();
      resultList?.removeEventListener('scroll', debounce(handleScroll, 500));
      window.removeEventListener('keyup', handleKeyUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Listen to search term changes, and show/hide suggestions default pages, else clear it
  useEffect(() => {
    // sanity check
    if (
      !navLinksContext?.navLinks ||
      suggestedPageLoading ||
      !suggestedPageData
    ) {
      return;
    }

    // Clear search results once a search term exists
    if (searchTerm) {
      setSearchResultsFunc([]);

      return;
    }

    // Show suggestions only when there is no search term
    const suggestedPagesData: Page[] = [];

    // Get suggested page Id's from dummy page meta
    const suggestedPageIds = JSON.parse(suggestedPageData.getPage?.meta || '')
      .sort((a: LinkI, b: LinkI) => a.sort - b.sort)
      .map((link: LinkI) => link.linkTo);

    // Find and create pages from suggested nav link matches
    suggestedPageIds.forEach((suggestedId: string) => {
      navLinksContext?.navLinks.forEach((navLink) => {
        // Check top level links
        if (suggestedId === navLink.id) {
          suggestedPagesData.push({
            __typename: 'Page',
            id: navLink.id,
            title: navLink.label || '',
            slug: navLink.slug,
            pageId: navLink.id,
          });
          return;
        }

        // Check sub links
        navLink.subItems?.forEach((subItem) => {
          if (suggestedId === subItem.id) {
            suggestedPagesData.push({
              __typename: 'Page',
              id: subItem.id,
              title: subItem.label || '',
              slug: `${navLink.slug}/${subItem.slug}`,
              pageId: subItem.id,
            });
            return;
          }
        });
      });
    });

    // Set suggested pages to results
    setSearchResultsFunc(suggestedPagesData);
  }, [searchTerm, navLinksContext, suggestedPageData, suggestedPageLoading]);

  const handleClear = () => {
    if (!InputRef.current) return;
    InputRef.current.value = '';
    setSearchTerm('');
    setNoMoreLoad(false);
  };

  const handleTab: React.KeyboardEventHandler<HTMLElement> = (e) => {
    if (e.keyCode === 32 && e.target === CancelRef.current) {
      setSearchActive(false);
      handleClear();
      stopBackgroundScroll('close');
      if (handleOpen) {
        handleOpen('close');
      }
      return;
    } else {
      setTimeout(() => {
        if (
          e.target === CancelRef.current &&
          searchResults &&
          searchResults?.length > 0
        ) {
          InputRef?.current?.focus();
        } else {
          const container = ElRef.current;
          const results = ResultRef.current;
          const element = document.activeElement;
          if (!(container?.contains(element) || results?.contains(element))) {
            setSearchActive(false);
            handleClear();
            stopBackgroundScroll('close');
            if (handleOpen) {
              handleOpen('close');
            }
          }
        }
      }, 500);
    }
  };

  // Eyebrow Text Logic
  useEffect(() => {
    // Suggestions Logic
    if (!searchTerm) {
      // Sanity Check
      if (suggestedPageLoading || !suggestedPageData) {
        return setEyebrowText('');
      }

      // Set no label if no suggested pages exist
      const ids = JSON.parse(suggestedPageData.getPage?.meta || '');
      if (!ids.length) {
        return setEyebrowText('');
      }

      // Suggested pages exist, show label
      return setEyebrowText('Suggestions.');
    }

    // Regular Search Logic
    return (!datasLoading && searchResults.length > 0) ||
      (!datasLoading && prepData) ||
      datasLoading
      ? setEyebrowText('Results.')
      : setEyebrowText('No Results Found.');
  }, [
    datasLoading,
    prepData,
    searchResults.length,
    searchTerm,
    setEyebrowText,
    suggestedPageData,
    suggestedPageLoading,
  ]);

  return (
    <Container
      ref={ElRef}
      className={classNames(className, 'searchView', {
        active: searchActive,
      })}
    >
      {features.navV2 ? null : (
        <Search>
          <Input
            onFocus={() => {
              stopBackgroundScroll('open');
              setSearchActive(true);
              if (handleOpen) {
                handleOpen('open');
              }
            }}
            placeholder="Search for topics..."
            type="text"
            onChange={handleChange}
            aria-label="search input"
            ref={InputRef}
            tabIndex={1}
          />
          <span />
          {searchTerm ? (
            <ClearButton
              aria-label="clear search"
              type="button"
              onClick={() => {
                handleClear();
                InputRef?.current?.focus();
              }}
              tabIndex={2}
            >
              Clear
            </ClearButton>
          ) : (
            <IconSearch />
          )}
        </Search>
      )}
      <LeftSideButton
        aria-label="close search left side"
        type="button"
        onClick={() => {
          setSearchActive(false);
          handleClear();
          stopBackgroundScroll('close');
          if (handleOpen) {
            handleOpen('close');
          }
        }}
        tabIndex={-1}
      />
      <RightSideButton
        aria-label="close search right side"
        type="button"
        onClick={() => {
          setSearchActive(false);
          handleClear();
          stopBackgroundScroll('close');
          if (handleOpen) {
            handleOpen('close');
          }
        }}
        tabIndex={-1}
      />
      <CancelButton
        tabIndex={searchActive ? 3 : -1}
        type="button"
        onClick={() => {
          setSearchActive(false);
          handleClear();
          stopBackgroundScroll('close');
          if (handleOpen) {
            handleOpen('close');
          }
        }}
        onKeyDown={handleTab}
        ref={CancelRef}
        aria-label="cancel"
      >
        <CloseX />
      </CancelButton>
      <Backdrop
        aria-label="search backdrop"
        className={classNames({ active: searchActive })}
      />
      <Results ref={ResultRef} className={classNames({ active: searchActive })}>
        <Eyebrow>{eyebrowText}</Eyebrow>
        <List ref={ListRef}>
          {searchResults?.map((item) => {
            // Team Member Entry
            if (item.__typename === 'Member') {
              return (
                <MemberLink
                  onKeyDown={handleTab}
                  key={item.id}
                  to={`../around-office/team-directory?name=${item.name}`}
                  onClick={() => {
                    setSearchActive(false);
                    handleClear();
                    stopBackgroundScroll('close');
                    if (handleOpen) {
                      handleOpen('close');
                    }
                  }}
                  tabIndex={1}
                >
                  <MemberImg
                    style={{
                      backgroundImage: `url(${
                        itemUrlMap.find((x) => x.id === item.id)?.url ||
                        headshot
                      })`,
                    }}
                  />
                  {item.name}
                  <IconArrow />
                </MemberLink>
              );
            }

            // Page Entry
            let formatSlug = `/${item.slug}`;
            if (item.parentPage) {
              formatSlug = `/${item.parentPage.slug}/${item.slug}`;
            }
            return (
              <ItemLink
                onKeyDown={handleTab}
                key={item.id}
                to={formatSlug}
                onClick={() => {
                  setSearchActive(false);
                  handleClear();
                  stopBackgroundScroll('close');
                  if (handleOpen) {
                    handleOpen('close');
                  }
                }}
                tabIndex={1}
              >
                {item.title}
                <IconArrow />
              </ItemLink>
            );
          })}
          {fakeLoadMore && (
            <LoadMore>
              <PulseLoader />
              Loading More Results
            </LoadMore>
          )}
        </List>
        {datasLoading && searchTerm && (
          <Loading>
            <PulseLoader />
            Loading More Results
          </Loading>
        )}
        {noMoreLoad && searchTerm && (
          <NoMoreLoad>No Additional Results</NoMoreLoad>
        )}
      </Results>
    </Container>
  );
};

export default SearchView;
