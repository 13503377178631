import React, { useMemo } from 'react';
import IntroView from 'components/IntroView';
import QuicklinksView from 'components/QuicklinksView';
import gql from 'graphql-tag';
import { ApolloClient, InMemoryCache, useQuery } from '@apollo/client';
import * as localQueries from './queries';
import * as queries from 'graphql/queries';
import { listQuicklinksByPageId } from 'containers/MainPage/queries';
import { UserI } from 'data/types/User.types';
import { FiveDidProjectI } from 'data/types/FiveDid';
import { Helmet } from 'react-helmet';
import QuicklinksI from 'data/types/Quicklinks.types';
import LoaderView from 'components/LoaderView';
import EventsView from 'components/EventsView';
import FiveDidView from 'components/FiveDidView';
import InstagramFeed from 'components/InstagramFeed';

const GET_EVENTS = gql(queries.getEvents);
const GET_PAGE = gql(queries.getPage);
const LIST_PROJECTS = gql(localQueries.listProjects);
const LIST_QUICKLINKS_BY_PAGE = gql(listQuicklinksByPageId);
const gqlClient = new ApolloClient({
  uri: 'https://v35xyib7avau5hlixxznax3qpm.appsync-api.us-west-2.amazonaws.com/graphql',
  cache: new InMemoryCache(),
  headers: {
    'X-Api-Key': 'da2-mhi3ktgqizfcjgjcof5bxekfse',
  },
});

interface Props {
  user: UserI;
}

function Home({ user }: Props): JSX.Element {
  // const googleCal =
  //   'fiveanddone.com_chr9oqdv1aj8ti3732mdd7fm48@group.calendar.google.com';

  const { data: didData } = useQuery(LIST_PROJECTS, {
    client: gqlClient,
    variables: {
      filter: { did: { contains: 'ce113d8c-7b6c-4a51-9a8c-3ab5284ef48a' } },
    },
  });

  const { data: quicklinksData, loading: quicklinksLoading } = useQuery(
    LIST_QUICKLINKS_BY_PAGE,
    {
      variables: {
        pageId: 'page-home',
      },
    }
  );

  const { data: slugData, loading: slugLoading } = useQuery(GET_PAGE, {
    variables: {
      id: 'page-home',
    },
  });

  const pageData = useMemo(() => {
    return slugData?.getPage;
  }, [slugData]);

  const calID = useMemo(() => {
    if (!pageData) return;
    const meta = JSON.parse(pageData.meta);
    return meta?.calLink || null;
  }, [pageData]);

  const eventsCalPhrases = useMemo(() => {
    if (!pageData) return;
    const meta = JSON.parse(pageData.meta);
    return meta?.eventsCalPhrases || null;
  }, [pageData]);
  console.log('eventsCalPhrases: ', eventsCalPhrases);

  const { data: eventsData, loading: eventsLoading } = useQuery(GET_EVENTS, {
    variables: {
      input: {
        calID,
        filterPhrases: eventsCalPhrases,
      },
    },
    skip: !calID,
  });

  const allEvents = useMemo(() => {
    return eventsData?.getEvents?.events || [];
  }, [eventsData]);

  const projects = useMemo(() => {
    const didProjects =
      didData?.listProjects?.items.filter(
        (x: FiveDidProjectI) => !!x.did && x.did.length > 0
      ) || [];
    const fiveDidProjects = didProjects.map((proj: FiveDidProjectI) => {
      return {
        id: proj.id,
        slug: proj.slug,
        name: proj.name,
        updatedAt: proj.updatedAt,
        thumb: proj?.thumb || '',
      };
    });
    return fiveDidProjects;
  }, [didData]);

  const quicklinks =
    useMemo(() => {
      return quicklinksData?.listQuicklinksByPageId?.items
        .map((item: QuicklinksI) => {
          if (
            item.link?.status === 'disabled' &&
            ((item.link?.parentPage &&
              item.link?.parentPage.status === 'disabled') ||
              !item.link?.parentPage)
          ) {
            return;
          }
          let formattedSlug = '';
          if (item.linkId && item.link) {
            formattedSlug = item.link.slug;
            if (item.link.parentPage) {
              formattedSlug = `${item.link.parentPage.slug}/${formattedSlug}`;
            }
          }
          return {
            id: item.id,
            title: item.title,
            body: item.body,
            linkTitle: item.linkTitle,
            ...(item.externalLink
              ? { externalLink: item.externalLink }
              : { slug: formattedSlug }),
          };
        })
        .filter((item: QuicklinksI) => item !== undefined);
    }, [quicklinksData]) || [];

  if (slugLoading || quicklinksLoading) {
    return <LoaderView />;
  }

  return (
    <div>
      {/* a div bc parent div is a flex and it messes with fivDids height for some reason */}
      <Helmet>
        <title>{pageData?.title}</title>
        <meta name="description" content={pageData?.description || ''} />
      </Helmet>
      <IntroView type="page" data={pageData} />
      <QuicklinksView data={quicklinks} type="home" />
      <InstagramFeed />
      <EventsView
        data={allEvents}
        loading={eventsLoading}
        tz={user?.timezone || 'PST'}
      />
      <FiveDidView data={projects} />
    </div>
  );
}

export default Home;
