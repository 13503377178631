import TabsDropdown from 'components/TabsDropdown';
import MemberI from 'data/types/Member.types';
import { TabDropdownI } from 'data/types/TabDropdown.types';
import React, { useEffect, useState } from 'react';
import EmployeeCard from './Components/EmployeeCard';
import { Container, Content } from './styles';

interface Props {
  name?: string;
  data: MemberI[] | null;
  tabs: TabDropdownI[];
  departmentTranslations: { label: string; value: string; key?: string }[];
}

interface FilterI {
  name: string;
  parent: string;
}

const CompanyDirectoryView = ({
  data,
  tabs,
  departmentTranslations,
  name,
}: Props): JSX.Element => {
  const [filteredData, setFilteredData] = useState<MemberI[]>([]);

  useEffect(() => {
    if (!data) return;
    setFilteredData(data);
  }, [data]);

  const filterData = (filters: FilterI[]) => {
    if (!data) return;
    if (!filters) {
      setFilteredData(data);
      return;
    }
    let unfiltered = [...data];
    const location = filters.filter((x: FilterI) => x.parent === 'location');
    const department = filters.filter(
      (x: FilterI) => x.parent === 'department'
    );
    const title = filters.filter((x: FilterI) => x.parent === 'title');
    const reportsTo = filters.filter((x: FilterI) => x.parent === 'reports-to');
    const primaryProjects = filters.filter(
      (x: FilterI) => x.parent === 'primary-projects'
    );
    if (location.length > 0) {
      unfiltered = unfiltered.filter((x: MemberI) => {
        return location.some((y: FilterI) => y.name === x.office.name);
      });
    }
    if (department.length > 0) {
      unfiltered = unfiltered.filter((x: MemberI) => {
        const translation = departmentTranslations.find(
          (d) => d.value === x.department
        );
        return department.some((y: FilterI) => y.name === translation?.label);
      });
    }
    if (title.length > 0) {
      unfiltered = unfiltered.filter((x: MemberI) => {
        return title.some((y: FilterI) => y.name === x.title);
      });
    }
    if (reportsTo.length > 0) {
      unfiltered = unfiltered.filter((x: MemberI) => {
        return reportsTo.some((y: FilterI) => y.name === x.reportTo);
      });
    }
    if (primaryProjects.length > 0) {
      unfiltered = unfiltered.filter((x: MemberI) => {
        return primaryProjects.some((y: FilterI) =>
          x.projects ? x.projects.includes(y.name) : false
        );
      });
    }

    setFilteredData(unfiltered);

    // const filtered = data.filter((item) => {
    //   return item.name.toLowerCase().includes(filters.toLowerCase());
    // });
    // setFilteredData(filtered);
  };

  return (
    <Container>
      <TabsDropdown tabs={tabs} returnActive={(a) => filterData(a)} />
      <Content>
        {filteredData?.map((item: MemberI) => {
          return (
            <EmployeeCard
              departmentTranslations={departmentTranslations}
              matchName={name}
              key={item.id}
              hide={false}
              data={item}
            />
          );
        })}
      </Content>
    </Container>
  );
};

export default CompanyDirectoryView;
