import NavigationI from 'data/types/Navigation.types';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const Redirect = ({ navLinks }: { navLinks: NavigationI[] }): JSX.Element => {
  const location = useLocation().pathname;
  const history = useHistory();

  useEffect(() => {
    if (navLinks.length > 0) {
      // * convoluted way to check if we need to go to a 404 page
      if (location === '/') return;
      const paths = location.split('/').filter((i: string) => i !== '');

      const matchParent = navLinks.find((link) => link.slug === paths[0]);

      //* Don't redirect if its the wiki
      if (
        paths[0] === 'riddle-griddle' ||
        paths[0] === 'lunch-table' ||
        paths[0] === 'dev-wiki' ||
        paths[0] === 'logout'
      ) {
        return;
      }
      console.log(navLinks, location, paths);

      if (matchParent) {
        console.log('matched parents');
        // * matches parent so check if theres a second path otherwise do nothing
        if (paths[1]) {
          if (matchParent.subItems) {
            if (
              matchParent.subItems.find(
                (i) => i.slug === paths[1]?.split('?')[0]
              )
            ) {
              // * let pass
            } else {
              console.log('redirect, did not match child');
              // * there are child pages but the second path doesnt match those
              history.replace('/404');
            }
          } else {
            console.log('redirect, looking for a non existent child');
            // * the path is looking for a child page when the parent has no child
            history.replace('/404');
          }
        }
      } else {
        console.log('redirect, didnt match parent');
        // * doesnt match any parent slugs page does not exist
        history.replace('/404');
      }
    }
  }, [navLinks, location, history]);

  return <div />;
};

export default Redirect;
