import { css } from 'styled-components';

interface AspectRatioFuncI {
  width: number;
  height: number;
}

export const AspectRatioFunc = ({
  width,
  height,
}: AspectRatioFuncI): string => `position: relative;
  &:before {
    content: '';
    float: left;
    padding-bottom: ${(height / width) * 100}%;
  }
  &:after {
    content: '';
    display: table;
    clear: both;
  }`;

export const size = (width: string, height?: string): string => `
  width: ${width};
  height: ${height || width};
`;

type BreakpointsI = {
  [key: string]: string;
};

export const breakpoints: BreakpointsI = {
  phone: '375',
  phoneWide: '480',
  phablet: '560',
  tabletSmall: '640',
  navMobile: '800',
  tablet: '768',
  searchTablet: '900',
  tabletWide: '1024',
  dablet: '1150',
  desktop: '1248',
  desktopWide: '1440',
};

export const mq = Object.keys(breakpoints).reduce(
  // eslint-disable-next-line
  (accumulator: any, label: string) => {
    let width = label;
    if (breakpoints[label] !== undefined) {
      width = breakpoints[label];
    }
    // eslint-disable-next-line
    accumulator[label] = (...args: [any]) => css`
      @media (max-width: ${width}px) {
        ${css(...args)};
      }
    `;
    return accumulator;
  },
  {}
);
export const mqMin = Object.keys(breakpoints).reduce(
  // eslint-disable-next-line
  (accumulator: any, label: string) => {
    let width = label;
    if (breakpoints[label] !== undefined) {
      width = breakpoints[label];
    }
    // eslint-disable-next-line
    accumulator[label] = (...args: [any]) => css`
      @media (min-width: ${width + 1}px) {
        ${css(...args)};
      }
    `;
    return accumulator;
  },
  {}
);
