import styled from 'styled-components';
import { mq, size } from '../../../shared/mixins';
import { colors, customBezierEase } from '../../../shared/variables';
import {
  bodyCopySmall,
  eyebrow,
  resetButtonStyles,
} from '../../../shared/extends';

export const Container = styled.div`
  ${resetButtonStyles};
  ${bodyCopySmall};
  background: ${colors.white};
  outline: 1px solid white;
  outline-offset: -1px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${size('100%', '50px')}

  &.error {
    outline-color: ${colors.highlight};
  }
  &.errorLimited {
    outline-color: ${colors.highlight};
    outline-width: 2px;
  }

  svg.icon-warning {
    fill: ${colors.highlight};
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    ${size('16px')};
  }
`;

export const IconContainer = styled.div`
  align-items: center;
  background: transparent;
  border-radius: 0;
  border: 0;
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
  margin: 0;
  outline: 0;
  padding: 0;
  cursor: pointer;

  &:focus {
    path {
      fill: ${colors.brownLight};
    }
  }

  &:hover {
    path {
      fill: ${colors.highlight};
    }
  }
`;

export const ArrowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 16px;
  transition: 0.3s ${customBezierEase};
  ${size('40px', '50px')}
`;

export const Input = styled.input`
  ${bodyCopySmall};
  background: transparent;
  border: 0;
  color: ${colors.brownMedium};
  cursor: pointer;
  border-radius: 0;
  outline: 0;
  padding: 15px 8px 12px 16px;
  transition: 0.3s ${customBezierEase};
  ${size('100%', '50px')};
`;

export const ModalContainer = styled.div`
  background: ${colors.white};
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.05);
  padding: 16px;
  z-index: 4;
  top: calc(100% + 10px);
  position: absolute;
  pointer-events: none;
  transition: 0.3s ${customBezierEase};
  width: 375px;
  opacity: 0;

  &.open {
    opacity: 1;
    cursor: auto;
    pointer-events: all;
  }

  ${mq.phone`
    max-width: 283px;
    width: 100%;
  `}

  ${mq.tablet`
    width: 100%;
  `}
`;

export const Label = styled.div`
  width: 100%;
  display: inline-block;

  > span {
    ${eyebrow};
    color: ${colors.brownMedium};
    display: inline-block;
    margin-bottom: 9px;

    ${mq.tablet`
      margin-bottom: 6px;
    `}
  }

  &.error {
    > span {
      color: ${colors.highlight};
    }
  }
`;

export const ButtonCover = styled.button`
  ${resetButtonStyles};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;

  .body-container:not(.is-touch) &:hover {
    ~ ${ArrowContainer} .icon-calendar path {
      fill: ${colors.highlight};
    }
  }
`;
