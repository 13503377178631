import styled from 'styled-components';
import { colors } from '../../shared/variables';
import { bodyCopySmall, eyebrow, eyebrowSmall } from '../../shared/extends';
import { mq } from 'shared/mixins';

export const Label = styled.label`
  ${eyebrow};
  color: ${colors.brownMedium};
  display: block;
  margin-bottom: 9px;

  &.error {
    color: ${colors.highlight};
  }

  span.optional {
    ${eyebrowSmall};
    color: ${colors.brownLight};
    padding-left: 12px;
  }

  p.description {
    ${bodyCopySmall}
    color: ${colors.brownLight};
    text-transform: none;
    margin-top: 10px;
  }

  ${mq.tablet`
    margin-bottom: 6px;
  `}
`;
