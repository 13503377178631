import styled from 'styled-components';
import { mq, size } from '../../shared/mixins';
import { colors, customBezierEase } from '../../shared/variables';
import { bodyCopySmall, WYSIWYGStyles } from '../../shared/extends';
import { font, fontSize } from 'shared/typography';

export const Icon = styled.div`
  position: absolute;
  right: 25px;
  top: 14px;
  transition: 0.5s ${customBezierEase};
  ${size('22px')}

  &:before,
  &:after {
    background-color: ${colors.primary};
    content: '';
    left: 50%;
    opacity: 1;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.4s ${customBezierEase},
      opacity 0.4s ${customBezierEase},
      background-color 0.4s ${customBezierEase};
    ${size('12px', '2px')}
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-90deg);
  }

  .active & {
    &:after {
      opacity: 0;
      transform: translate(-50%, -50%);
    }
  }
`;

export const Container = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.grey};
  margin-bottom: 20px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  .body-container:not(.is-touch) &:hover {
    div.accordion-question {
      color: ${colors.highlight};
    }
    button:before,
    button:after {
      background-color: ${colors.highlight};
    }
    cursor: pointer;

    ${Icon} {
      &::before,
      &:after {
        background: ${colors.highlight};
      }
    }
  }
`;

export const Question = styled.div`
  ${bodyCopySmall};
  background: transparent;
  border-radius: 0;
  cursor: pointer;
  margin: 0;
  min-height: 50px;
  outline: 0;
  padding: 16px 60px 14px 24px;
  text-align: left;
  width: 100%;
  transition: 0.5s ${customBezierEase};
`;

export const Answer = styled.div`
  ${bodyCopySmall}
  max-height: 0px;
  overflow: hidden;
  max-width: 630px;
  transition: 0.2s ${customBezierEase};
`;

export const Copy = styled.div`
  padding: 15px 24px 34px;
  transition: 0.2s ${customBezierEase};

  margin-top: 32px;

  ${mq.tablet`
    margin-top: 8px;
  `}

  > *:first-child {
    margin-top: 0;
  }

  p,
  blockquote,
  ul,
  ol,
  table,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    max-width: 740px;
  }

  p,
  table {
    color: ${colors.brownLight};
    margin: 0;
    ${font('body')}
    ${fontSize(14, 20)}
  }

  ${mq.tabletWide`
    table {
      ${fontSize(10, 16)}
    }
  `}

  blockquote {
    color: ${colors.brownLight};
    font-style: italic;
    ${font('primary', 'light')};
    ${fontSize(16, 24, -0.2)};
  }

  ol,
  ul {
    color: ${colors.brownLight};
    margin: 0;
    padding: 0;
    list-style: none;
    ${font('body')};
    ${fontSize(14, 20)};
  }

  a {
    color: ${colors.highlight};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  h1 {
    color: ${colors.brownLight};
    ${font('primary', 'black')};
    ${fontSize(65, 67, -0.2)};
  }
  h2 {
    color: ${colors.brownLight};
    ${font('primary', 'black')};
    ${fontSize(48, 52, -0.2)};
  }
  h3 {
    color: ${colors.brownLight};
    ${font('primary', 'black')};
    ${fontSize(24, 27, 0)};
  }
  h4 {
    color: ${colors.brownLight};
    ${font('primary', 'medium')};
    ${fontSize(20, 24, 0)};
  }

  img {
    max-width: 100%;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    tr {
    }
    td {
      border: 1px solid ${colors.brownMedium};
      padding: 5px;
      min-height: 30px;
    }
  }
  ${WYSIWYGStyles};
`;
