import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { Storage } from 'aws-amplify';

require('date-time-format-timezone');

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);

export const convertTimezones = (tz: string): string => {
  switch (tz) {
    case 'PST':
      return 'America/Los_Angeles';
    case 'CST':
      return 'America/Chicago';
    case 'MST':
      return 'America/Denver';
    case 'EST':
      return 'America/New_York';
    case 'HST':
      return 'Pacific/Honolulu';
    default:
      return 'America/Los_Angeles';
  }
};

export const getDates = (startDate: string, endDate: string): string[] => {
  const dates = [];
  const currentDate = new Date(startDate);
  const endingDate = new Date(endDate);
  while (dayjs(currentDate).isSameOrBefore(endingDate)) {
    dates.push(dayjs(currentDate).format('l'));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
};

// eslint-disable-next-line
export const debounce = (func: (f: any) => void, timeout?: number) => {
  // eslint-disable-next-line
  let timer: any;
  // eslint-disable-next-line
  return function (event: any) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(func, timeout || 100, event);
  };
};

export const stopBackgroundScroll = (action: string): void => {
  const content: HTMLDivElement | HTMLBodyElement | null =
    window.innerWidth > 768
      ? document.querySelector('.body-content')
      : document.querySelector('body');
  if (!content) return;
  if (action === 'open') {
    content.style.overflow = 'hidden';
    content.dataset.top = `${content.scrollTop}`;
    content.scrollTop = 0; // go back to top to reset scroll position;
  } else {
    content.style.overflow = '';
    content.scrollTop = parseInt(content.dataset.top || '0'); // reset scroll
  }
};

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes';

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const viewWYSIWYG = async (content: string): Promise<string> => {
  if (content) {
    let frankenstein = '';

    const imageArray = content.split('<img');
    for (let i = 0; i < imageArray.length; i++) {
      const item = imageArray[i];
      if (i === 0) {
        frankenstein += item;
      } else {
        // get the key (' src="')
        const startOfKey = 6;
        const endOfKey = item.indexOf('">');
        const key = item.slice(startOfKey, endOfKey);

        // get the url from S3
        const url = await Storage.get(key);

        // stitch the item back together
        const imageEnds = item.indexOf('">');
        const restOfItem = item.slice(imageEnds);
        const stitchedItem = `<img src="${url}${restOfItem}`;

        // attach to the content
        frankenstein += stitchedItem;
      }
    }

    // return reformatted content
    return frankenstein;
  }
  return '';
};

export const formatHeaderNodes = (content: string): string => {
  // Strip Header elements of italic/underline/bold child nodes
  const placeholder = document.createElement('div');
  placeholder.innerHTML = content;

  for (const key in placeholder.children) {
    if (Object.prototype.hasOwnProperty.call(placeholder.children, key)) {
      const element = placeholder.children[key];

      if (element.tagName.toLowerCase().includes('h')) {
        element.innerHTML = element.textContent || '';
      }
    }
  }

  return placeholder.innerHTML;
};

export const isExternalLink = (url: string): boolean => {
  const tmp = document.createElement('a');
  tmp.href = url;
  return tmp.host !== window.location.host;
};

export const formatBodyLinks = (body: string): string => {
  const splitBody = body.split('href="');
  let frankenstein = '';
  splitBody.map((item: string, i: number) => {
    if (i === 0) {
      frankenstein += item;
      return;
    }
    const temp = item.replace('target="_blank"', '');
    const link = temp.slice(0, temp.indexOf('"'));
    const linkEnd = temp.slice(temp.indexOf('"'));
    if (isExternalLink(link)) {
      frankenstein += ` target="_blank" href="${link}`;
    } else {
      frankenstein += ` href="${link}`;
    }
    frankenstein += `${linkEnd}`;
  });
  return frankenstein;
};

export const validateTime = (time: string): boolean => {
  return /^([0]?[1-9]|[1][0-2]):([0-5][0-9])/.test(time);
};

export const cleanFileName = (text: string): string => {
  if (!text) {
    return '';
  }

  return text.replace(/[^a-zA-Z0-9.-]/g, '').trim();
};
