import styled, { keyframes } from 'styled-components';
import { size, mq } from '../../shared/mixins';
import {
  colors,
  padding,
  maxWidth,
  iconArrowAnimation,
  customBezierEase,
} from '../../shared/variables';
import { font, fontSize } from '../../shared/typography';
import bkgTexture from '../../images/bg_texture-dust-white.jpg';
import { Link } from 'react-router-dom';
import { bodyCopySmall } from '../../shared/extends';

const slideOut = keyframes`
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(120%);
    opacity: 0%;
  }
`;

const slideIn = keyframes`
  0% {
    transform: translate(-100%, 0%);
    opacity: 0%;
  }

  100% {
    transform: translate(0%, 0%);
    opacity: 100%;
  }
`;

export const Container = styled.section`
  background: url(${bkgTexture}) 0 0 repeat, ${colors.greyLight};
  max-width: ${maxWidth}px;
  // min-height: 442px;
  padding: 94px ${padding.desktop}px 200px;
  position: relative;

  ${mq.tablet`
    padding: 94px ${padding.tablet}px 120px;
  `}
  ${mq.phoneWide`
    padding: 94px ${padding.mobile}px 80px;
  `}

  &.home {
    padding: 94px ${padding.desktop}px 120px;

    ${mq.tablet`
      padding: 94px ${padding.tablet}px 100px;
    `}
    ${mq.phoneWide`
      padding: 94px ${padding.mobile}px 80px;
    `}
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 42px;

  ${mq.phoneWide`
    // display: block;
    grid-template-columns: 1fr;
    grid-row-gap: 48px;
  `}
`;

export const Eyebrow = styled.h4`
  color: ${colors.primary};
  grid-column: 1 / span 12;
  margin: 0 0 31px;
  text-transform: uppercase;
  ${font('secondary', 'black')}
  ${fontSize(12, 20)}
`;

export const Block = styled(Link)`
  grid-column: span 4;
  text-decoration: none;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto auto 1fr;
  grid-column-gap: 10px;

  &:hover {
    cursor: pointer;
    span {
      animation: ${slideOut} 0.5s ease-in-out,
        ${slideIn} 0.3s ${customBezierEase} 0.3s;
    }
    svg {
      ${iconArrowAnimation};
    }
  }

  ${mq.tabletWide`
    grid-column: span 6;
    grid-template-columns: repeat(6, 1fr);
  `}
  ${mq.phoneWide`
    grid-column: span 1;
    display: block;
    max-width: 235px;
  `}
`;

export const Title = styled.h4`
  grid-column: 1 / span 3;
  color: ${colors.brownLight};
  margin: 0 0 5px;
  word-break: break-word;
  ${font('primary', 'black')};
  ${fontSize(24, 27)};

  ${mq.tabletWide`
    grid-column: 1 / span 5;
  `}
`;

export const Description = styled.p`
  grid-column: 1 / span 3;
  color: ${colors.brownLight};
  margin: 0 0 5px;
  word-break: break-word;
  ${bodyCopySmall};

  ${mq.tabletWide`
    grid-column: 1 / span 5;
  `}
`;

export const SpanContainer = styled.div`
  overflow: hidden;
`;

export const PageLink = styled.div`
  ${bodyCopySmall};
  grid-column: 1 / span 3;
  align-items: center;
  color: ${colors.highlight};
  display: flex;
  margin: 10px 0 0;
  word-break: break-word;
  margin-bottom: auto;

  span {
    display: inline-block;
  }

  svg {
    fill: ${colors.highlight};
    margin-left: 9px;
    flex: 0 0 auto;
    ${size('12px', '5px')};
  }

  ${mq.tabletWide`
    grid-column: 1 / span 4;
  `}
`;

export const ExternalLink = styled(PageLink)``;
