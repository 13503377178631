import classNames from 'classnames';
import { IconArrowBoxy } from 'components/IconsView';
import React, { useEffect, useRef, useState } from 'react';
import { Container } from './styles';
import { debounce } from 'utils/functions';

const BackToTopView = (): JSX.Element => {
  const [isActive, setIsActive] = useState(false);
  const BodyRef = useRef<HTMLDivElement | HTMLBodyElement | null>(null);
  const TopBodyRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = () => {
    const scroll = BodyRef.current?.scrollTop || 0;
    const quadViewPort = window.innerHeight * 4;
    setIsActive(scroll > quadViewPort);
  };

  useEffect(() => {
    const contentBody =
      window.innerWidth > 768
        ? document.querySelector('.body-content')
        : document.querySelector('body');
    BodyRef.current =
      window.innerWidth > 768
        ? document.querySelector('.body-content')
        : document.querySelector('body');
    TopBodyRef.current = document.querySelector('#top-body-content');
    if (contentBody) {
      contentBody.addEventListener('scroll', debounce(handleScroll));
    }

    return () => {
      if (contentBody) {
        contentBody.removeEventListener('scroll', debounce(handleScroll));
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollToTop = () => {
    // Scroll to top logic
    if (TopBodyRef.current) {
      // TopBodyRef.current.scrollTop = 0;
      TopBodyRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Container
      type="button"
      onClick={() => scrollToTop()}
      className={classNames({ active: isActive })}
    >
      Back To Top
      <IconArrowBoxy />
    </Container>
  );
};

export default BackToTopView;
