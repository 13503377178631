import { IconMultiPage, IconVideoTriangle } from 'components/IconsView';
import { eyebrowRegular } from 'shared/extends';
import { AspectRatioFunc, mq, size } from 'shared/mixins';
import { font, fontSize } from 'shared/typography';
import {
  colors,
  customBezierEase,
  iconArrowAnimation,
  padding,
} from 'shared/variables';
import styled from 'styled-components';
import bkgTexture from '../../images/bg_texture-dust-off-white.jpg';

export const Container = styled.div`
  padding: 95px 0 0;
  background: url(${bkgTexture}) 0 0 repeat, ${colors.greyLight};

  ${mq.tablet`
    padding: 50px 0 0;
  `}

  ${mq.phoneWide`
    padding: 32px 0 0;
  `}
`;

export const Header = styled.div`
  margin: 0 ${padding.desktop}px;

  ${mq.tablet`
    margin: 0 ${padding.tablet}px;
  `}

  ${mq.phoneWide`
    margin: 0 ${padding.mobile}px;
  `}
`;

export const Eyebrow = styled.a`
  ${eyebrowRegular};
  color: ${colors.primary};
  display: inline-flex;
  text-decoration: none;
  align-items: center;
  margin-bottom: 36px;

  span {
    overflow: hidden;
    display: flex;
    align-items: center;

    span {
      display: inline-block;
    }
  }

  svg {
    fill: ${colors.highlight};
    margin-left: 9px;
    flex: 0 0 auto;
    ${size('12px', '5px')};
  }

  &:hover {
    cursor: pointer;
    span span {
      animation: slideOut 0.5s ease-in-out,
        slideIn 0.3s ${customBezierEase} 0.3s;
    }
    svg {
      ${iconArrowAnimation};
    }
  }

  ${mq.phoneWide`
    margin-bottom: 32px;
  `}

  @keyframes slideOut {
    0% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(120%);
      opacity: 0%;
    }
  }

  @keyframes slideIn {
    0% {
      transform: translate(-100%, 0%);
      opacity: 0%;
    }

    100% {
      transform: translate(0%, 0%);
      opacity: 100%;
    }
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 4px;

  ${mq.tabletWide`
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, 1fr);
  `}
`;

export const Square = styled.a`
  ${AspectRatioFunc({ width: 1, height: 1 })}
  background-color: ${colors.grey};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  grid-column: span 1;
  position: relative;

  &:first-child {
    grid-column: span 2;
    grid-row: span 2;
  }

  body:not(.is-touch) &:hover {
    cursor: pointer;
  }

  ${mq.tabletWide`
    &:first-child {
      grid-column: span 3;
      grid-row: span 3;
    }
  `}
`;

export const LatestPost = styled.span`
  ${font('primary', 'black')}
  ${fontSize(10, 10)}
  background-color: ${colors.blueDark};
  padding: 2px 4px 3px;
  border-radius: 1px;
  color: ${colors.white};
  position: absolute;
  top: 8px;
  left: 8px;
`;

export const Date = styled.span`
  ${font('body', 'medium')}
  ${fontSize(10, 10)}
  color: ${colors.blueDark};
  text-transform: uppercase;
  padding: 2px 4px;
  border-radius: 1px;
  background-color: ${colors.white};
  position: absolute;
  bottom: 8px;
  left: 8px;
`;

export const VideoNotif = styled(IconVideoTriangle)`
  ${size('10%')};
  min-width: 50px;
  min-height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.05));
`;

export const SlideNotif = styled(IconMultiPage)`
  ${size('12px')};
  position: absolute;
  top: 10px;
  right: 10px;
`;
