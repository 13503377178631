/**
 *
 * Accordion
 *
 */

import React, { useEffect, useRef, useState } from 'react';
import {
  formatBodyLinks,
  formatHeaderNodes,
  viewWYSIWYG,
} from 'utils/functions';

import { Container, Icon, Question, Answer, Copy } from './styles';

interface Props {
  title: string;
  body?: string;
  children?: React.ReactNode;
  keepOpen?: boolean;
  dontOpen?: boolean;
}

function Accordion({
  title,
  body,
  children,
  keepOpen,
  dontOpen,
}: Props): JSX.Element {
  const El = useRef<HTMLDivElement>(null);
  const AnswerRef = useRef<HTMLDivElement>(null);
  const CopyRef = useRef<HTMLDivElement>(null);
  const [bodyData, setBodyData] = useState('');

  const getBody = async (content: string) => {
    const bodyContent = await viewWYSIWYG(content);
    const bodyFormatted = formatHeaderNodes(bodyContent);
    const bodyLinks = formatBodyLinks(bodyFormatted);

    setBodyData(bodyLinks);
  };

  useEffect(() => {
    setBodyData(body || '');

    if (body) {
      getBody(body);
    }
  }, [body]);

  const handleActive = () => {
    if (!El.current) {
      return;
    }

    if (!dontOpen) {
      const ElClass = El.current.classList;

      if (ElClass.contains('active')) {
        ElClass.remove('active');
        if (AnswerRef.current) {
          AnswerRef.current.style.maxHeight = '0px';
        }
      } else {
        if (!keepOpen) {
          const items = Array.from(
            document.querySelectorAll('.accordion-item')
          ).filter((item) => item.classList.contains('active'));
          items.forEach((item) => {
            item.classList.remove('active');
            const answer: HTMLDivElement | null =
              item.querySelector('.accordion-answer');
            if (answer) {
              answer.style.maxHeight = '0px';
            }
          });
        }
        ElClass.add('active');
        if (AnswerRef.current) {
          AnswerRef.current.style.maxHeight =
            AnswerRef.current.scrollHeight + 'px';
        }
      }
    }
  };

  return (
    <Container
      role="button"
      aria-label={'click to expand'}
      onClick={() => handleActive()}
      className="accordion-item"
      ref={El}
    >
      <Question className="accordion-question">{title}</Question>
      <Answer
        className="accordion-answer"
        ref={AnswerRef}
        style={{ maxHeight: '0px' }}
      >
        {body && (
          <Copy ref={CopyRef} dangerouslySetInnerHTML={{ __html: bodyData }} />
        )}
        {children && <Copy>{children}</Copy>}
      </Answer>
      {!dontOpen && <Icon />}
    </Container>
  );
}

Accordion.propTypes = {};

export default Accordion;
