import styled from 'styled-components';
import { size, mq } from '../../shared/mixins';
import {
  colors,
  padding,
  customBezierEase,
  maxWidth,
} from '../../shared/variables';
import { font, fontSize, H3 } from '../../shared/typography';
import bkgTexture from '../../images/bg_texture-dust-white.jpg';
import {
  bodyCopyBold,
  bodyCopySmall,
  eyebrow,
  gridDesktop,
  resetButtonStyles,
} from '../../shared/extends';
import CheckboxView from 'components/FormComponents/CheckboxView';
import RadioView from 'components/FormComponents/RadioView';
import InputView from 'components/FormComponents/InputView';
import TextAreaView from 'components/FormComponents/TextAreaView';
import CalendarView from 'components/CalendarView';
import Button from 'components/Button/Button';

export const Container = styled.section`
  background: url(${bkgTexture}) 0 0 repeat, ${colors.greyLight};
  padding: 0 ${padding.desktop}px 160px;

  ${mq.tablet`
    padding: 0 ${padding.tablet}px 80px;
  `}

  ${mq.phoneWide`
    padding: 0 ${padding.mobile}px 80px;
  `}
`;

export const Content = styled.form`
  ${gridDesktop};
  // max-width: ${maxWidth}px;
  max-width: ${maxWidth - padding.desktop * 2}px;
  opacity: 0;
  display: none;

  &.active {
    opacity: 1;
    display: grid;
  }

  ${mq.tabletWide`
    grid-template-rows: auto auto;
  `}
`;

export const Steps = styled.div`
  grid-column: 1 / span 5;

  &.right {
    grid-column: 8 / span 5;
    grid-row: 1 / span 2;
    display: flex;
    flex-direction: column;
  }

  ${mq.desktop`
    grid-column: 1 / span 7;
    
    &.right {
      padding-left: 30px;
    }
  `}

  ${mq.tabletWide`
    grid-column: 1 / span 12;

    &.right {
      grid-column: 1 / span 12;
      grid-row: none;
      padding-left: 0;
    }
  `}
`;

export const Label = styled.span`
  ${eyebrow};
  color: ${colors.brownMedium};
  display: inline-block;
`;

export const StepLabel = styled.span`
  ${eyebrow};
  display: block;
  color: ${colors.primary};
  margin: 17px 0;
`;

export const Title = styled(H3)`
  color: ${colors.brownLight};
  margin: 0 0 24px;

  .right & {
    margin: 0 0 19px;
  }
`;

export const Description = styled.p`
  ${bodyCopySmall}
  color: ${colors.brownLight};
  text-transform: none;
  margin-top: 10px;

  a {
    color: ${colors.highlight};
  }
`;

export const WFHAlert = styled.p`
  ${font('body')};
  color: ${colors.brownLight};
`;

export const DateSelectorContainer = styled.div`
  margin-top: 1px;
`;

export const RadioGroup = styled.div`
  margin-top: 17px;
  margin-bottom: 13px;
  display: flex;
  flex-direction: row;
  gap: 32px;
`;

export const Radio = styled(RadioView)``;

export const Checkbox = styled(CheckboxView)`
  grid-column: 1 / span 5;
  margin-top: 32px;

  label {
    > div {
      background: ${colors.white};
      border: 1.5px solid ${colors.primary};

      svg {
        fill: ${colors.white};
      }
    }

    input {
      &:checked + div {
        background: ${colors.primary};
        svg {
          fill: ${colors.white};
        }
      }
      &:disabled + div {
        background: ${colors.greyMedium};
        border: 0;
        cursor: default;

        svg {
          fill: ${colors.greyMedium};
        }
      }
      &:focus + div {
        outline-offset: -1px;
      }
    }
  }
`;

export const Input = styled(InputView)`
  margin-bottom: 35px;

  label {
    color: ${colors.brownMedium};
  }
`;

export const TextArea = styled(TextAreaView)`
  margin-bottom: 36px;

  label {
    color: ${colors.brownMedium};
  }
`;

export const Calendar = styled(CalendarView)`
  padding: 16px 24px;
  background: ${colors.white};
`;

export const SubmitButton = styled(Button)<{ primary: boolean }>`
  ${eyebrow};
  margin-left: auto;

  &.disabled {
    // pointer-events: none;
    background: ${colors.grey};
    color: ${colors.brownLightLight};

    &:hover {
      cursor: not-allowed;
      background: ${colors.grey};
      color: ${colors.brownLightLight};
    }
  }

  &.submitting {
    position: relative;
    pointer-events: none;
    ${size('150px', '50px')};
  }

  ${mq.tablet`
    width: 100%;
  `}
`;

export const SubmitLoader = styled.div`
  height: 40px;
  position: absolute;
  transform: translateY(-50%) scale(0.5);
  left: 48px;
  top: calc(50% - 3px);
`;

export const HolidayLabel = styled.p`
  ${bodyCopyBold};
  color: ${colors.primary};
  margin-top: 8px;
`;

export const Error = styled.p`
  ${bodyCopySmall};
  color: ${colors.highlight};
  margin: 8px 0 0;

  &.right {
    text-align: right;
  }

  ${mq.tablet`
    &.right {
      text-align: left;
    }
  `}
`;

export const Preview = styled.div`
  margin-top: 32px;
  padding-top: 32px;
  border-top: 1px solid ${colors.grey};

  ${mq.tabletWide`
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid ${colors.grey};
  `}
`;

export const Submission = styled.div`
  opacity: 0;
  display: none;
  transition: opacity 0.5s ${customBezierEase};

  &.active {
    opacity: 1;
    display: block;
  }

  h3 {
    margin: 0;
    max-width: 460px;
  }

  ${Preview} {
    border-top: 0;
  }

  .eyebrow {
    ${eyebrow};
    color: ${colors.brownLight};
    margin-top: 24px;
    display: block;
  }

  .notes {
    ${bodyCopySmall};
    margin: 0 0;
    max-width: 430px;
  }
`;

export const AnotherOne = styled.button`
  ${resetButtonStyles};
  ${bodyCopySmall};
  color: ${colors.highlight};
  display: flex;
  align-items: center;
  margin-top: 48px;

  svg {
    fill: ${colors.highlight};
    margin-left: 9px;
    ${size('12px', '5px')};
  }
`;

export const Request = styled.div`
  color: ${colors.brownLight};
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 365px;
  margin: 8px 0;
  ${font('body')};
  ${fontSize(20, 26)};
`;
export const Dates = styled.div`
  color: ${colors.brownLight};
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 365px;
  margin-bottom: 8px;
  ${font('body')};
  ${fontSize(20, 26)};

  &.hasData {
    justify-content: flex-start;
  }

  ${mq.tablet`
    span:nth-child(3) {
      text-align: right;
    }
  `}
`;

export const Times = styled.div`
  ${bodyCopySmall};
  color: ${colors.brownLight};
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 365px;

  &.hasData {
    justify-content: flex-start;
  }

  ${mq.tablet`
    margin-top: 8px;

    span:nth-child(3) {
      text-align: right;
    }
  `}
`;

export const Dot = styled.div`
  content: '';
  display: inline-block;
  background: ${colors.brownLightLight};
  border-radius: 100%;
  margin: 0 9px;
  flex: 0 0 auto;
  ${size('3px')}
`;
