import { IconLogoWordmark } from 'components/IconsView';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Content, Saying, SayingList } from './styles';
import { prompts } from './prompts';

interface IProps {
  names: { name: string }[];
}

const FooterView = ({ names }: IProps): JSX.Element => {
  const location = useLocation();
  const [list, setList] = useState<string[]>([]);
  const [timerId, setTimerId] = useState<NodeJS.Timeout>();

  const getRandomString = () => {
    const templateString = prompts[Math.floor(Math.random() * prompts.length)];
    const name = names[Math.floor(Math.random() * names.length)].name;
    return templateString.replace('[name]', name);
  };

  const handleMouseOver = () => {
    if (timerId) {
      // Timer already set, do nothing
      return;
    }
    const newTimerId = setTimeout(() => {
      console.log('first');
      setList((prevList) => [...prevList, getRandomString()]);
      const intervalId = setInterval(() => {
        setList((prevList) => {
          if (prevList.length >= 7) {
            return [...prevList.slice(1), getRandomString()];
          } else {
            return [...prevList, getRandomString()];
          }
        });
      }, 7000);
      setTimerId(intervalId);
    }, 5000);
    setTimerId(newTimerId);
  };

  const handleMouseOut = () => {
    if (timerId) {
      clearInterval(timerId);
      setTimerId(undefined);

      let newTimerId: NodeJS.Timeout | null = null;
      newTimerId = setTimeout(() => {
        setList([]);
        clearTimeout(newTimerId as NodeJS.Timeout);
      }, 5000);
    }
  };

  return (
    <Container is404={location.pathname === '/404'}>
      <Content onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
        <IconLogoWordmark />
        <p>
          Copyright {new Date().getFullYear()}. This shit is highly
          CONFIDENTIAL.
        </p>
      </Content>
      <SayingList>
        {list.map((item) => {
          return <Saying>{item}</Saying>;
        })}
      </SayingList>
    </Container>
  );
};

export default FooterView;
