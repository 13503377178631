import styled from 'styled-components';
import { size, mq } from '../../shared/mixins';
import { colors, padding, maxWidth } from '../../shared/variables';
import { font, fontSize } from '../../shared/typography';
import bkgTexture from '../../images/bg_texture-dust-white.jpg';
import {
  bodyCopySmall,
  eyebrow,
  gridDesktop,
  resetButtonStyles,
} from '../../shared/extends';
import Tabs from 'components/Tabs';

export const Container = styled.section`
  background: url(${bkgTexture}) 0 0 repeat, ${colors.greyLight};
  padding: 94px ${padding.desktop}px 200px;
  width: 100%;

  ${mq.tablet`
    padding: 80px ${padding.tablet}px 160px;
  `}

  ${mq.phoneWide`
    padding: 80px ${padding.mobile}px;
  `}
`;

export const Content = styled.div`
  ${gridDesktop};
  // max-width: ${maxWidth}px;
  max-width: ${maxWidth - padding.desktop * 2}px;
`;

export const OfficeTabs = styled(Tabs)`
  grid-column: 1 / span 12;
`;

const placeholderStyles = `
    color: ${colors.brownLight};
`;

export const SearchContainer = styled.div`
  grid-column: 1 / span 4;
  display: flex;
  align-items: center;
  margin-top: 54px;
  /* border-bottom: 1px solid ${colors.grey}; */
  position: relative;

  span {
    background-color: ${colors.grey};
    content: '';
    bottom: -1px;
    left: 0;
    position: absolute;
    transition: width 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    ${size('0px', '1px')}
  }

  path {
    fill: ${colors.brownLight};
  }

  ${mq.tabletWide`
    grid-column: 1 / span 12;
  `}
`;

export const Input = styled.input`
  ${bodyCopySmall};
  border: 0;
  background: transparent;
  color: ${colors.brownLight};
  padding: 0 0 4px 0;
  width: 100%;

  ::-webkit-input-placeholder {
    ${placeholderStyles}
  }
  &::-moz-placeholder {
    ${placeholderStyles}
  }
  &:-ms-input-placeholder {
    ${placeholderStyles}
  }
  &:-moz-placeholder {
    ${placeholderStyles}
  }

  &:hover {
    & + span {
      width: 100%;
      width: 100% !important;
    }
  }
  &:focus {
    outline: 0;

    ::-webkit-input-placeholder {
      color: ${colors.brownLightLight};
    }
    &::-moz-placeholder {
      color: ${colors.brownLightLight};
    }
    &:-ms-input-placeholder {
      color: ${colors.brownLightLight};
    }
    &:-moz-placeholder {
      color: ${colors.brownLightLight};
    }

    & + span {
      width: 100%;
      width: 100% !important;
    }
  }
`;

export const ClearButton = styled.button`
  ${resetButtonStyles};
  svg {
    ${size('12px')}
  }
`;

export const AccordionContainer = styled.div`
  grid-column: 1 / span 12;
`;

export const SectionHeader = styled.h3`
  color: ${colors.brownLight};
  margin: 0 0 16px;
  ${font('primary', 'black')}
  ${fontSize(24, 27)}
`;

export const Box = styled.div`
  margin-top: 53px;
  grid-column: 1 / span 12;
`;

export const NotFound = styled.div`
  ${eyebrow};
  color: ${colors.primary};
  margin-top: 80px;
  text-align: center;
`;
