import IntroView from 'components/IntroView';
import LoaderView from 'components/LoaderView';
import QuicklinksView from 'components/QuicklinksView';
import React, { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import * as queries from 'utils/queries';
import * as localQueries from './queries';
import { Helmet } from 'react-helmet';
import QuicklinksI from 'data/types/Quicklinks.types';

const GET_PAGE_BY_SLUG = gql(queries.getPageBySlug);
const LIST_QUICKLINKS_BY_PAGE = gql(localQueries.listQuicklinksByPageId);

function MainPage({ slug }: { slug: string }): JSX.Element {
  const { data: slugData, loading: slugLoading } = useQuery(GET_PAGE_BY_SLUG, {
    variables: {
      slug,
    },
  });

  const pageData = useMemo(() => {
    return slugData?.getPageBySlug.items[0];
  }, [slugData]);

  const { data: quicklinksData, loading: quicklinksLoading } = useQuery(
    LIST_QUICKLINKS_BY_PAGE,
    {
      variables: {
        pageId: pageData?.id,
      },
    }
  );

  const quicklinks =
    useMemo(() => {
      return quicklinksData?.listQuicklinksByPageId?.items
        .map((item: QuicklinksI) => {
          if (
            item.link?.status === 'disabled' ||
            (item.link?.parentPage &&
              item.link?.parentPage.status === 'disabled') ||
            !item.link?.parentPage
          ) {
            return;
          }
          let formattedSlug = '';
          if (item.linkId && item.link) {
            formattedSlug = item.link.slug;
            if (item.link.parentPage) {
              formattedSlug = `${item.link.parentPage.slug}/${formattedSlug}`;
            }
          }
          return {
            id: item.id,
            title: item.title,
            body: item.body,
            linkTitle: item.linkTitle,
            ...(item.externalLink
              ? { externalLink: item.externalLink }
              : { slug: formattedSlug }),
          };
        })
        .filter((item: QuicklinksI) => item !== undefined);
    }, [quicklinksData]) || [];

  if (slugLoading || quicklinksLoading) {
    return <LoaderView />;
  }
  return (
    <>
      <Helmet>
        <title>{pageData?.title}</title>
        <meta name="description" content={pageData?.description || ''} />
      </Helmet>
      <IntroView type="page" data={pageData} />
      <QuicklinksView data={quicklinks} />
    </>
  );
}

export default MainPage;
