import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  Checkbox,
  Container,
  Content,
  Error,
  Information,
  Input,
  Preview,
  Row,
  Side,
  Step,
  SubmitButton,
  Title,
} from './styles';
import { UserI } from 'data/types/User.types';
import InputView from 'components/FormComponents/InputView';
import { Label } from 'components/FormComponents/styles';
import { colors } from 'shared/variables';
import { IconCopy } from 'components/IconsView';
import PhoneInputView from '../FormComponents/PhoneInputView';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

interface Props {
  user: UserI;
}

const EmailSignatureGeneratorView = ({ user }: Props): JSX.Element => {
  const methods = useForm();
  const userEmail = user.email;
  const userName = user.name;
  const [copied, setCopied] = useState(false);

  function listener(e: ClipboardEvent, str: string) {
    if (e.clipboardData) {
      e.clipboardData.setData('text/html', str);
      e.clipboardData.setData('text/plain', str);
      e.preventDefault();
    }
  }

  const copyToClip = async (str: string, strTwo: string) => {
    if (!navigator.clipboard) {
      document.addEventListener('copy', (e) => listener(e, strTwo));
      document.execCommand('copy');
      document.removeEventListener('copy', (e) => listener(e, strTwo));
    } else {
      console.log(navigator.userAgent.search('Firefox'));
      if (navigator.userAgent.search('Firefox') === -1) {
        console.log('clipboard item');
        const blob = new Blob([str], { type: 'text/html' });
        const clipData = [
          new ClipboardItem({
            'text/html': blob,
          }),
        ];
        await navigator.clipboard.write(clipData);
      } else {
        console.log('execcommand');
        document.addEventListener('copy', (e) => listener(e, strTwo));
        document.execCommand('copy');
        document.removeEventListener('copy', (e) => listener(e, strTwo));
      }
    }
  };

  const cleanPhone = (phone: string) => {
    if (phone) {
      return phone.replace(/\D/g, '');
    }
    return '';
  };

  const formatPhoneDots = (phone: string) => {
    const slice = [phone.slice(0, 3), phone.slice(3, 6), phone.slice(6, 10)];
    return `${slice[0]}${slice[1] && `.${slice[1]}`}${
      slice[2] && `.${slice[2]}`
    }`;
  };

  const formatEmail = () => {
    const email = methods.watch('email') || userEmail;
    if (!email) return '';
    const emailName = email.split('@')[0];
    const emailDomain = email.split('@')[1];
    const emailSite = emailDomain.split('.');

    return (
      <>
        {emailName}
        <span>@</span>
        {emailSite[0]}
        <span>.</span>
        {emailSite[1]}
      </>
    );
  };

  return (
    <Container>
      <FormProvider {...methods}>
        <Content
          onSubmit={methods.handleSubmit(async (input) => {
            console.log(input);
            setCopied(true);
            setTimeout(() => {
              setCopied(false);
            }, 5000);

            //check phone number
            if (input.phone && input.phone.length !== 10) {
              methods.setError('phone', { type: 'manual' });
            }

            const preview: HTMLDivElement | null =
              document.querySelector('#preview');
            const previewTwo: HTMLDivElement | null =
              document.querySelector('#previewTwo');
            if (preview && previewTwo) {
              copyToClip(preview.innerHTML, previewTwo.innerHTML);
              console.log(preview.innerHTML);
            }
          })}
        >
          <Side>
            <Step>Step 1</Step>
            <Title>Add Your Information</Title>
            <Row>
              <InputView
                name="name"
                label="Employee Name"
                value={userName}
                disabled={true}
              />
            </Row>
            <Row>
              <InputView
                name="email"
                label="Email Address"
                value={userEmail}
                disabled={true}
              />
              <Checkbox name="hideEmail" label="Hide Email" />
            </Row>
            <Row>
              <Input
                name="job"
                label="Job Title"
                required={true}
                showRequired={true}
                maxLength={50}
                error={methods?.formState?.errors?.job}
                placeholder="Enter Title Here"
              />
            </Row>
            <Row>
              <PhoneInputView
                name="phone"
                label="Phone Number"
                value="9494491212"
                extraLabel="Optional to update"
              />
            </Row>
          </Side>
          <Side>
            <Step>Step 2</Step>
            <Title>Preview and Copy for Use</Title>
            {/* <Row> */}
            <Label>Preview</Label>
            <Preview id="preview">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: 0,
                        fontFamily: 'HelveticaNeue-Light, Arial, sans-serif',
                        fontWeight: 700,
                        color: colors.brownMedium,
                        fontSize: '12px',
                        lineHeight: '18px',
                        width: '100%',
                      }}
                      colSpan={2}
                    >
                      {methods.watch('name') || userName}.
                    </td>
                  </tr>
                  {methods.watch('job') && methods.watch('job').length > 0 && (
                    <tr>
                      <td
                        data-testid="jobTitle"
                        style={{
                          padding: 0,
                          fontFamily: 'HelveticaNeue-Light, Arial, sans-serif',
                          fontWeight: 400,
                          color: colors.brownLight,
                          fontSize: '12px',
                          lineHeight: '18px',
                        }}
                        colSpan={2}
                      >
                        {methods.watch('job')} at Five & Done.
                      </td>
                    </tr>
                  )}
                  {(methods.watch('phone') || !methods.watch('hideEmail')) && (
                    <tr>
                      {methods.watch('phone') ? (
                        <td
                          data-testid="phone"
                          style={{
                            padding: 0,
                            fontFamily:
                              'HelveticaNeue-Light, Arial, sans-serif',
                            fontWeight: 400,
                            color: colors.brownLight,
                            fontSize: '12px',
                            lineHeight: '18px',
                            // ...{ textDecoration: 'none !important' },
                            // ...{ textDecoration: 'none' },
                          }}
                        >
                          <a
                            ref={(node) => {
                              if (node) {
                                node.style.setProperty(
                                  'text-decoration',
                                  'none',
                                  'important'
                                );
                                node.style.setProperty(
                                  'color',
                                  colors.brownLight,
                                  'important'
                                );
                              }
                            }}
                            href={`tel:${cleanPhone(methods.watch('phone'))}`}
                            style={{
                              fontFamily:
                                'HelveticaNeue-Light, Arial, sans-serif',
                              fontWeight: 400,
                              color: colors.brownLight,
                              fontSize: '12px',
                              lineHeight: '18px',
                              textDecoration: 'none',
                            }}
                          >
                            {formatPhoneDots(
                              cleanPhone(methods.watch('phone'))
                            )}
                            .
                          </a>
                        </td>
                      ) : (
                        <td
                          data-testid="phone"
                          style={{
                            padding: 0,
                            fontFamily:
                              'HelveticaNeue-Light, Arial, sans-serif',
                            fontWeight: 400,
                            color: colors.brownLight,
                            fontSize: '12px',
                            lineHeight: '18px',
                            // ...{ textDecoration: 'none !important' },
                            // ...{ textDecoration: 'none' },
                          }}
                        >
                          <a
                            ref={(node) => {
                              if (node) {
                                node.style.setProperty(
                                  'text-decoration',
                                  'none',
                                  'important'
                                );
                                node.style.setProperty(
                                  'color',
                                  colors.brownLight,
                                  'important'
                                );
                              }
                            }}
                            href={`tel:${cleanPhone(methods.watch('phone'))}`}
                            style={{
                              fontFamily:
                                'HelveticaNeue-Light, Arial, sans-serif',
                              fontWeight: 400,
                              color: colors.brownLight,
                              fontSize: '12px',
                              lineHeight: '18px',
                              textDecoration: 'none',
                            }}
                          >
                            {formatPhoneDots('9494491212')}.
                          </a>
                        </td>
                      )}
                      {!methods.watch('hideEmail') && (
                        <td
                          style={{
                            padding: 0,
                            fontFamily:
                              'HelveticaNeue-Light, Arial, sans-serif',
                            fontWeight: 400,
                            color: colors.brownLight,
                            fontSize: '12px',
                            lineHeight: '18px',
                          }}
                          data-testid="previewEmail"
                        >
                          <a
                            ref={(node) => {
                              if (node) {
                                node.style.setProperty(
                                  'text-decoration',
                                  'none',
                                  'important'
                                );
                                node.style.setProperty(
                                  'color',
                                  colors.brownLight,
                                  'important'
                                );
                              }
                            }}
                            style={{
                              fontFamily:
                                'HelveticaNeue-Light, Arial, sans-serif',
                              fontWeight: 400,
                              color: `${colors.brownLight}`,
                              fontSize: '12px',
                              lineHeight: '18px',
                              // ...{ textDecoration: 'none !important' },
                              textDecoration: 'none',
                            }}
                            href={`mailto:${
                              methods.watch('email') || userEmail
                            }`}
                          >
                            {formatEmail()}.
                          </a>
                        </td>
                      )}
                    </tr>
                  )}
                  <tr>
                    <td height="11" style={{ height: '11px' }} />
                  </tr>
                  <tr>
                    <td colSpan={2} width="22">
                      <a
                        ref={(node) => {
                          if (node) {
                            node.style.setProperty(
                              'text-decoration',
                              'none',
                              'important'
                            );
                            node.style.setProperty(
                              'color',
                              colors.brownLight,
                              'important'
                            );
                          }
                        }}
                        style={{
                          fontFamily: 'HelveticaNeue-Light, Arial, sans-serif',
                          fontWeight: 400,
                          color: `${colors.brownLight}`,
                          fontSize: '12px',
                          lineHeight: '18px',
                          // ...{ textDecoration: 'none !important' },
                          textDecoration: 'none',
                          width: '35px',
                          display: 'inline-block',
                          height: '50px',
                        }}
                        href="https://www.fiveanddone.com"
                      >
                        <span
                          style={{
                            display: 'inline-block',
                            width: '28px',
                            height: '80px',
                            background:
                              'center / contain no-repeat url("https://amplify-platform-katie-163550-deployment.s3.us-west-2.amazonaws.com/public/five-yellow-2.png")',
                          }}
                        />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Preview>
            <Preview id="previewTwo">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: 0,
                        fontFamily: 'HelveticaNeue-Light, Arial, sans-serif',
                        fontWeight: 700,
                        color: colors.brownMedium,
                        fontSize: '12px',
                        lineHeight: '18px',
                        width: '100%',
                      }}
                      colSpan={2}
                    >
                      {methods.watch('name') || userName}.
                    </td>
                  </tr>
                  {methods.watch('job') && methods.watch('job').length > 0 && (
                    <tr>
                      <td
                        style={{
                          padding: 0,
                          fontFamily: 'HelveticaNeue-Light, Arial, sans-serif',
                          fontWeight: 400,
                          color: colors.brownLight,
                          fontSize: '12px',
                          lineHeight: '18px',
                        }}
                        colSpan={2}
                      >
                        {methods.watch('job')} at Five & Done.
                      </td>
                    </tr>
                  )}
                  {(methods.watch('phone') || !methods.watch('hideEmail')) && (
                    <tr>
                      {methods.watch('phone') ? (
                        <td
                          style={{
                            padding: 0,
                            fontFamily:
                              'HelveticaNeue-Light, Arial, sans-serif',
                            fontWeight: 400,
                            color: colors.brownLight,
                            fontSize: '12px',
                            lineHeight: '18px',
                            // ...{ textDecoration: 'none !important' },
                            // ...{ textDecoration: 'none' },
                          }}
                        >
                          <a
                            ref={(node) => {
                              if (node) {
                                node.style.setProperty(
                                  'text-decoration',
                                  'none',
                                  'important'
                                );
                                node.style.setProperty(
                                  'color',
                                  colors.brownLight,
                                  'important'
                                );
                              }
                            }}
                            href={`tel:${cleanPhone(methods.watch('phone'))}`}
                            style={{
                              fontFamily:
                                'HelveticaNeue-Light, Arial, sans-serif',
                              fontWeight: 400,
                              color: colors.brownLight,
                              fontSize: '12px',
                              lineHeight: '18px',
                              textDecoration: 'none',
                            }}
                          >
                            {formatPhoneDots(
                              cleanPhone(methods.watch('phone'))
                            )}
                            .
                          </a>
                        </td>
                      ) : (
                        <td
                          style={{
                            padding: 0,
                            fontFamily:
                              'HelveticaNeue-Light, Arial, sans-serif',
                            fontWeight: 400,
                            color: colors.brownLight,
                            fontSize: '12px',
                            lineHeight: '18px',
                            // ...{ textDecoration: 'none !important' },
                            // ...{ textDecoration: 'none' },
                          }}
                        >
                          <a
                            ref={(node) => {
                              if (node) {
                                node.style.setProperty(
                                  'text-decoration',
                                  'none',
                                  'important'
                                );
                                node.style.setProperty(
                                  'color',
                                  colors.brownLight,
                                  'important'
                                );
                              }
                            }}
                            href={`tel:${cleanPhone(methods.watch('phone'))}`}
                            style={{
                              fontFamily:
                                'HelveticaNeue-Light, Arial, sans-serif',
                              fontWeight: 400,
                              color: colors.brownLight,
                              fontSize: '12px',
                              lineHeight: '18px',
                              textDecoration: 'none',
                            }}
                          >
                            {formatPhoneDots('9494491212')}.
                          </a>
                        </td>
                      )}
                      {!methods.watch('hideEmail') && (
                        <td
                          style={{
                            padding: 0,
                            fontFamily:
                              'HelveticaNeue-Light, Arial, sans-serif',
                            fontWeight: 400,
                            color: colors.brownLight,
                            fontSize: '12px',
                            lineHeight: '18px',
                          }}
                        >
                          <a
                            ref={(node) => {
                              if (node) {
                                node.style.setProperty(
                                  'text-decoration',
                                  'none',
                                  'important'
                                );
                                node.style.setProperty(
                                  'color',
                                  colors.brownLight,
                                  'important'
                                );
                              }
                            }}
                            style={{
                              fontFamily:
                                'HelveticaNeue-Light, Arial, sans-serif',
                              fontWeight: 400,
                              color: `${colors.brownLight}`,
                              fontSize: '12px',
                              lineHeight: '18px',
                              // ...{ textDecoration: 'none !important' },
                              textDecoration: 'none',
                            }}
                            href={`mailto:${
                              methods.watch('email') || userEmail
                            }`}
                          >
                            {formatEmail()}.
                          </a>
                        </td>
                      )}
                    </tr>
                  )}
                  <tr>
                    <td height="11" style={{ height: '11px' }} />
                  </tr>
                  <tr>
                    <td colSpan={2} width="22">
                      <a
                        ref={(node) => {
                          if (node) {
                            node.style.setProperty(
                              'text-decoration',
                              'none',
                              'important'
                            );
                            node.style.setProperty(
                              'color',
                              colors.brownLight,
                              'important'
                            );
                          }
                        }}
                        style={{
                          fontFamily: 'HelveticaNeue-Light, Arial, sans-serif',
                          fontWeight: 400,
                          color: `${colors.brownLight}`,
                          fontSize: '12px',
                          lineHeight: '18px',
                          // ...{ textDecoration: 'none !important' },
                          textDecoration: 'none',
                          width: '35px',
                          display: 'inline-block',
                          height: '50px',
                        }}
                        href="https://www.fiveanddone.com"
                      >
                        <div
                          style={{
                            width: '28px',
                            height: '80px',
                            background:
                              'center center / contain no-repeat url("https://amplify-platform-katie-163550-deployment.s3.us-west-2.amazonaws.com/public/five-yellow-2.png")',
                          }}
                        />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Preview>
            <SubmitButton
              primary={true}
              type="submit"
              aria-label="Copy to clipboard"
              className={classNames({ disabled: !methods.watch('job') })}
            >
              {copied ? (
                <>Copied!</>
              ) : (
                <>
                  <IconCopy /> Copy to Clipboard
                </>
              )}
            </SubmitButton>
            {Object.keys(methods?.formState?.errors).length > 0 && (
              <Error>Please fill out all required fields.</Error>
            )}
            <Information>
              For information on how to implement your new signature, please
              reference the{' '}
              <Link to="/technology/email-signature-setup">following page</Link>
            </Information>
            {/* </Row> */}
          </Side>
        </Content>
      </FormProvider>
    </Container>
  );
};

export default EmailSignatureGeneratorView;
