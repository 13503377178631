// @refresh reset
import InputView from 'components/FormComponents/InputView';
import { IconArrow, IconBubble } from 'components/IconsView';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Bubble,
  Container,
  Hover,
  PageButton,
  PasswordContainer,
  PasswordForm,
  SubmitButton,
  Wiki,
  WikiList,
} from './styles';
import Joel from 'images/joel.png';
import dayjs from 'dayjs';
import * as queries from './queries';
import gql from 'graphql-tag';
import { useLazyQuery, useMutation } from '@apollo/client';
import PageI, { PageStatus } from 'data/types/Page.types';
import PageList from './Components/PageList/PageList';
import * as mutations from 'graphql/mutations';
const CREATE_PAGE = gql(mutations.createPage);
import { useHistory } from 'react-router-dom';

const LIST_PAGES = gql(queries.listPagesByPageId);
import slugify from 'slugify';

const tempTitles = [
  '101 ways to accidentally delete/remove data',
  'How to merge arrays and create friends',
  'What are the chances this title shows up?',
  "Learning the ins and outs of the dead language PHP (it's not dead, I know, just wishful thinking)",
  'Internet Explorer and You',
  "So you're code is changing you don't know what to do about it",
  "Help! I'm trapped in the code! My name is Daryl and I was tricked into selling my soul to a Promise Genie!",
  '3 ways to spice up your console.logs',
  'Unhelpful tips in creating comments',
  'Why leave helpful commenting in your code when you can rewrite the entire Twilight books instead',
  "When you have a question and google isn't able to decipher what you want",
  '99 problems and all of them were avoidable',
  "99 warnings and 1 actual error that I can't find under all the warnings",
  'Trying my best and crying about it later PT 3',
  "Why timeouts are your friend not food (it's a nemo reference)",
  'Timeouts, the duct tape of code',
  'How to worship Jess Bezos, a Keenan Biography',
  '"Have you tried turning it on and off again?" & 10 other answers for questions about a bug you created but will ignore in a useless attempt to avoid fixing it',
  'How to YOLO deploy like a winner',
  'Skip PRs, work off master',
  'How to cowboy code like 2005',
  '10 reasons why code reviews are annoying',
  'How to gaslight QA and PMs',
  'How to pretend you fixed an issue and fool QA and PMs',
];

const WikiHome = (): JSX.Element => {
  const history = useHistory();
  const methods = useForm();
  const [showWiki, setShowWiki] = useState(false);
  const [data, setDataState] = useState<PageI[]>([]);
  const dataRef = useRef<PageI[]>(data);
  const setData = (v: PageI[]) => {
    setDataState(v);
    dataRef.current = v;
  };

  const [createPage] = useMutation(CREATE_PAGE);

  const [listPages] = useLazyQuery(LIST_PAGES, {
    onCompleted: ({ listPagesByPageId: wikiList }) => {
      setData(wikiList?.items || []);
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const itemStr = localStorage.getItem('is-a-dev');
    if (itemStr) {
      const item = JSON.parse(itemStr);
      if (dayjs(item.expire).isAfter(dayjs())) {
        setShowWiki(true);
        listPages({
          variables: {
            pageId: 'dev-wiki',
          },
        });
      } else {
        localStorage.removeItem('is-a-dev');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const newPage = useCallback(
    async (pageId: string, sort: number) => {
      const random = Math.floor(Math.random() * tempTitles.length);
      const title = tempTitles[random];
      const page = await createPage({
        variables: {
          input: {
            pageId,
            status: PageStatus.disabled,
            title: title,
            slug: slugify(title).toLowerCase(),
            sort,
          },
        },
      });
      const id = page.data.createPage.id;
      setTimeout(() => {
        history.push(`/dev-wiki/${id}`);
      }, 500);
    },
    [createPage, history]
  );

  return (
    <Container>
      {showWiki ? (
        <Wiki>
          <WikiList>
            <PageList
              pageData={data}
              createPage={newPage}
              refetch={() =>
                listPages({
                  variables: {
                    pageId: 'dev-wiki',
                  },
                })
              }
              showDrawer={true}
            />
            <PageButton
              onClick={() => {
                newPage('dev-wiki', dataRef.current.length);
              }}
            >
              Create
            </PageButton>
          </WikiList>
        </Wiki>
      ) : (
        <PasswordContainer>
          <FormProvider {...methods}>
            <PasswordForm
              onSubmit={methods.handleSubmit((props) => {
                console.log(props);
                const item = JSON.stringify({
                  expire: dayjs().add(3, 'day').toISOString(),
                });
                localStorage.setItem('is-a-dev', item);
                setShowWiki(true);
                listPages({
                  variables: {
                    pageId: 'dev-wiki',
                  },
                });
              })}
            >
              <InputView
                name="password"
                type="password"
                required
                placeholder="What's the secret code?"
                handleChange={(v) => {
                  if (v !== 'password') {
                    methods.setError('password', { type: 'custom' });
                  }
                }}
                errorMessage="Incorrect"
                error={methods.formState.errors?.password}
              />
              <SubmitButton type="button">
                <IconArrow />
              </SubmitButton>
              <Hover type="submit">
                <Bubble>
                  <span>***</span>
                  <IconBubble />
                </Bubble>
                <img src={Joel} />
              </Hover>
            </PasswordForm>
          </FormProvider>
        </PasswordContainer>
      )}
    </Container>
  );
};

export default WikiHome;
