import LoaderView from 'components/LoaderView';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import * as queries from 'utils/queries';
import * as mutations from 'graphql/mutations';
import { Helmet } from 'react-helmet';
import {
  AddButton,
  CalendarContainer,
  Cell,
  Container,
  EditButton,
  Form,
  Header,
  Row,
  Table,
} from './styles';
import { v4 as uuidv4 } from 'uuid';
import InputView from 'components/FormComponents/InputView';
import { useForm, FormProvider } from 'react-hook-form';
import SelectView from 'components/FormComponents/SelectView';
import DateSelectorView from 'components/FormComponents/DateSelectorView';
import { IconPencil } from 'components/IconsView';
import dayjs from 'dayjs';
import CalendarView from 'components/CalendarView';

const GET_PAGE_BY_SLUG = gql(queries.getPageBySlug);
const UPDATE_PAGE = gql(mutations.updatePage);

interface LunchPlace {
  id: string;
  name: string;
  link?: string;
  type: string;
  price: string;
  location: string;
  tags: string;
  lastAteAt: string;
}

const foodTypes = [
  {
    value: 'American',
    label: 'American',
  },
  {
    value: 'American / Mexican',
    label: 'American / Mexican',
  },
  {
    value: 'Asian',
    label: 'Asian',
  },
  {
    value: 'Chinese',
    label: 'Chinese',
  },
  {
    value: 'Hawaiian',
    label: 'Hawaiian',
  },
  {
    value: 'Indian',
    label: 'Indian',
  },
  {
    value: 'Italian',
    label: 'Italian',
  },
  {
    value: 'Japanese',
    label: 'Japanese',
  },
  {
    value: 'Korean',
    label: 'Korean',
  },
  {
    value: 'Mexican',
    label: 'Mexican',
  },
  {
    value: 'Mediterranean',
    label: 'Mediterranean',
  },
  {
    value: 'Pinoy',
    label: 'Pinoy',
  },
  {
    value: 'Thai',
    label: 'Thai',
  },
  {
    value: 'Vietnamese',
    label: 'Vietnamese',
  },
];

const foodPrice = [
  { label: '$', value: '$' },
  { label: '$$', value: '$$' },
  { label: '$$$', value: '$$$' },
];

const LunchTable = (): JSX.Element => {
  const methods = useForm();
  const [tableData, setTableData] = useState<LunchPlace[]>([]);
  const [editing, setEditing] = useState<{ id: string; type: string } | null>(
    null
  );
  const [sort, setSort] = useState<{ type: string; dir: 'asc' | 'desc' }>({
    type: 'name',
    dir: 'desc',
  });
  const [resetForm, setResetForm] = useState(false);

  const [updatePage] = useMutation(UPDATE_PAGE);

  const { data: slugData, loading: slugLoading } = useQuery(GET_PAGE_BY_SLUG, {
    variables: {
      slug: 'lunch-table',
    },
  });

  const pageData = useMemo(() => {
    return slugData?.getPageBySlug.items[0];
  }, [slugData]);

  useEffect(() => {
    if (pageData?.meta) {
      const temp = JSON.parse(pageData?.meta || '');
      if (temp.length > 0) {
        setTableData(temp);
      }
    }
  }, [pageData]);

  const handleSort = (type: string) => {
    const sortDir =
      type === sort.type ? (sort.dir === 'asc' ? 'desc' : 'asc') : 'asc';
    setTableData((prev) => {
      switch (type) {
        case 'date':
          return prev.sort((a, b) => {
            if (dayjs(a.lastAteAt).isAfter(dayjs(b.lastAteAt))) {
              return sortDir === 'desc' ? 1 : -1;
            } else if (dayjs(a.lastAteAt).isBefore(dayjs(b.lastAteAt))) {
              return sortDir === 'desc' ? -1 : 1;
            }
            return 0;
          });
        case 'name':
        default:
          return prev.sort((a, b) => {
            if (a?.name < b?.name) {
              return sortDir === 'asc' ? -1 : 1;
            } else if (a?.name > b?.name) {
              return sortDir === 'asc' ? 1 : -1;
            }
            return 0;
          });
      }
    });
    setSort({ type, dir: sortDir });
  };

  const handleUpdateRow = async (id: string, type: string, value: string) => {
    const temp = tableData;
    console.log(temp[tableData.findIndex((x) => x.id === id)], type, value);
    // @ts-ignore
    temp[tableData.findIndex((x) => x.id === id)][type] = value;
    await updatePage({
      variables: {
        input: {
          id: 'page-lunch-table',
          meta: JSON.stringify(temp),
        },
      },
    });
    setEditing(null);
  };

  const renderTable = useCallback(() => {
    return tableData.map((row: LunchPlace) => {
      return (
        <Row key={row.name}>
          <Cell>
            {editing?.id === row.id && editing?.type === 'name' ? (
              'edit'
            ) : (
              <>
                {row.link ? (
                  <a href={row.link}>
                    <span>{row.name}</span>
                  </a>
                ) : (
                  <span>{row.name}</span>
                )}
              </>
            )}
            <EditButton
            // onClick={() => {
            //   setEditing({ id: row.id, type: 'name' });
            // }}
            >
              <IconPencil />
            </EditButton>
          </Cell>
          <Cell>
            <span>{row.type}</span>
            <EditButton
              onClick={() => {
                setEditing({ id: row.id, type: 'type' });
              }}
            >
              <IconPencil />
            </EditButton>
          </Cell>
          <Cell>
            <span>{row.price}</span>
            <EditButton>
              <IconPencil />
            </EditButton>
          </Cell>
          <Cell>
            <span>{row.lastAteAt}</span>
            {editing?.id === row.id && editing?.type === 'lastAteAt' ? (
              <CalendarContainer>
                <CalendarView
                  start={row.lastAteAt}
                  end={row.lastAteAt}
                  dateSelected={(date) =>
                    handleUpdateRow(editing.id, editing.type, date)
                  }
                  allowWeekends={false}
                  allowWFHDays={true}
                />
              </CalendarContainer>
            ) : (
              <></>
            )}
            <EditButton
              onClick={() => {
                setEditing({ id: row.id, type: 'lastAteAt' });
              }}
            >
              <IconPencil />
            </EditButton>
          </Cell>
          <Cell>
            <span>{row.tags}</span>

            <EditButton>
              <IconPencil />
            </EditButton>
          </Cell>
          <Cell className="location">
            <a href={row.location}>
              <span>Map</span>
            </a>
            <EditButton>
              <IconPencil />
            </EditButton>
          </Cell>
        </Row>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing?.id, editing?.type, tableData]);

  const handleSubmit = async (input: any) => {
    console.log(input);
    const temp = tableData;
    temp.push({ id: uuidv4(), ...input });
    await updatePage({
      variables: {
        input: {
          id: 'page-lunch-table',
          meta: JSON.stringify(temp),
        },
      },
    });
    setResetForm(true);
    setTimeout(() => {
      setResetForm(false);
    }, 1000);
  };

  if (slugLoading) {
    return <LoaderView />;
  }

  return (
    <Container>
      <Helmet>
        <title>{pageData?.title}</title>
        <meta name="description" content={pageData?.description || ''} />
      </Helmet>
      <Table>
        <Header>
          <Cell onClick={() => handleSort('name')}>Name</Cell>
          <Cell>Type</Cell>
          <Cell>Price</Cell>
          <Cell onClick={() => handleSort('date')}>Last Ate At</Cell>
          <Cell>Tags</Cell>
          <Cell>Location</Cell>
        </Header>
        {renderTable()}
      </Table>
      {resetForm ? (
        <></>
      ) : (
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(handleSubmit)}>
            <InputView
              name="name"
              label="Name"
              required={true}
              error={methods.formState.errors?.name}
            />
            <InputView
              name="link"
              label="Link"
              required={false}
              error={methods.formState.errors?.link}
            />
            <SelectView
              name="type"
              label="Type of Food"
              required={true}
              options={foodTypes}
              defaultOption="Select a Type"
              error={methods.formState.errors?.type}
            />
            <SelectView
              name="price"
              label="Price"
              required={true}
              options={foodPrice}
              defaultOption="Select a Price"
              error={methods.formState.errors?.price}
            />
            <DateSelectorView
              name={'lastAteAt'}
              label="Last Ate At"
              required={true}
              // format="YYYY-MM-DDTHH:mm:ssZ[Z]"
              error={methods.formState.errors?.lastAteAt}
              allowWeekends={false}
            />
            <InputView
              name="tags"
              label="Tags"
              required={true}
              error={methods.formState.errors?.tags}
            />
            <InputView
              name="location"
              label="Location"
              required={true}
              error={methods.formState.errors?.location}
            />
            <AddButton type="submit">Add New Lunch Area</AddButton>
          </Form>
        </FormProvider>
      )}
    </Container>
  );
};

export default LunchTable;
