import styled from 'styled-components';
import { colors, padding } from '../../shared/variables';
import bkgTextureWhite from '../../images/bg_texture-dust-white.jpg';
import { mq, size } from 'shared/mixins';
import { bodyCopySmall, resetButtonStyles } from 'shared/extends';
import { fontSize, H1 } from 'shared/typography';

export const PrintLogo = styled.div`
  display: none;
  position: absolute;
  left: 50%;
  top: 100px;
  transform: translateX(-50%);
  z-index: 1;

  ${size('31px', '36px')}

  svg {
    position: relative;
    ${'' /* top: -3px; */}
    ${size('31px', '36px')}
    fill: ${colors.brownMedium};
  }

  path {
    fill: ${colors.brownMedium};
    /* fill: url(#bkgTextureBrown-logoMobile); */
  }
`;

export const Container = styled.div`
  background: url(${bkgTextureWhite}) 0 0 repeat, ${colors.greyLight};
  display: flex;
  min-height: var(--doc-height);

  ${mq.tablet`
    display: block;
  `}

  @media print {
    min-height: auto;
    max-height: var(--doc-height);
    overflow: hidden;

    @page {
      size: auto;
      margin: 0;
    }

    ${PrintLogo} {
      display: block;
    }

    section {
      padding-right: 50px !important;
      padding-left: 50px !important;
    }

    .eyebrow {
      font-size: 0;
      &:before {
        font-size: 0.75rem;
        content: 'Five & Done';
        color: ${colors.brownMedium};
      }
    }

    .body-content {
      min-height: auto;
      section:first-of-type {
        padding-bottom: 10px;
      }
      section:last-of-type {
        padding-bottom: 0;
      }
      * {
        color: ${colors.brownMedium};
      }
    }

    nav,
    .searchView,
    .intro-description,
    .year-filter,
    .print-button,
    footer {
      display: none;
    }
  }
`;

export const SignInView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: var(--doc-height);
`;

export const SignInContent = styled.div`
  text-align: left;
  width: 520px;

  ${mq.tablet`
  width: 300px;
`}
`;

export const Content = styled.div`
  min-height: var(--doc-height);
  max-height: var(--doc-height);
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  ${mq.tablet`
    max-height: auto;

    .login-container {
      max-width: 200px;
    }
  `}
`;

export const SignInContainer = styled.div`
  margin-left: 233px;
  margin-top: 244px;
  padding: 0 ${padding.desktop}px;

  ${mq.desktop`
    margin-left: auto;
    margin-right: auto;
  `}

  ${mq.tablet`
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 200px ${padding.tablet}px 0;
  `}

  ${mq.phoneWide`
    padding: 160px ${padding.mobile}px 0;
  `}
`;

export const Title = styled(H1)`
  margin: 0;

  ${mq.tablet`
    ${fontSize(45, 49, -0.2)};
    color: ${colors.brownMedium};

    & + p {
      margin-right: 82px;
      max-width: 100%;
      min-width: 200px;
    }
  `}
`;

export const SignIn = styled.button`
  ${resetButtonStyles};
  ${bodyCopySmall};
  margin: 0;
  color: ${colors.highlight};

  svg {
    margin-left: 9px;
    fill: ${colors.highlight};
    ${size('16px', '7px')};
  }
`;
