export const listFAQSections = /* GraphQL */ `
  query ListFAQsections(
    $filter: ModelFAQsectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFAQsections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        sort
        createdAt
        updatedAt
        faqs {
          items {
            id
            question
            answer
            sort
            officeIDs
          }
        }
      }
      nextToken
    }
  }
`;
