import styled from 'styled-components';
import { colors } from '../../../shared/variables';
import { Input } from 'components/FormComponents/InputView/styles';

export const Container = styled.div``;

export const Content = styled.div`
  display: flex;
`;

export const Phone = styled(Input)`
  -webkit-appearance: none;
  -moz-appearance: none;
  outline-color: ${colors.greyMedium};
  margin-right: 1px;
  width: 33.333%;

  &:last-child {
    margin-right: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
