import { TabDropdownI } from 'data/types/TabDropdown.types';
import React, { createRef, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { debounce } from 'utils/functions';

import {
  BottomDropdown,
  Container,
  CustomCheckbox,
  DropdownContainer,
  More,
  MoreButton,
  MoreList,
  Section,
  Tab,
  TabBackdrop,
  TabContainer,
  TabDropdown,
  Top,
} from './styles';
import { IconSimpleArrowSmall } from 'components/IconsView';
import FilterPills from './Components/FilterPills';
import { useForm, FormProvider } from 'react-hook-form';
import { breakpoints } from 'shared/variables';
import slugify from 'slugify';

interface Props {
  tabs: TabDropdownI[];
  returnActive: (active: FiltersProps[]) => void;
  className?: string;
  breakpoint?: string;
}
interface FiltersProps {
  parent: string;
  name: string;
}

interface OverflowI {
  label: string;
  index: number;
  value: string;
  options: {
    label: string;
  }[];
}

const TabsDropdown = ({
  tabs,
  returnActive,
  className,
  breakpoint,
}: Props): JSX.Element => {
  const [isTablet, setIsTablet] = useState(false);
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [toggleMore, setToggleMore] = useState(false);
  const [inputId, setInputId] = useState('');
  const [activeFilters, setActiveFilters] = useState<FiltersProps[]>([]);
  const [overflowTabs, setOverflowTabs] = useState<OverflowI[]>([]);
  const TabsRef = useRef<HTMLHeadingElement>(null);
  const MoreRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line
  const TabButtonsRef = useRef<any[]>([]);
  const methods = useForm();

  useEffect(() => {
    setIsTablet(window.innerWidth < breakpoints[breakpoint || 'tablet']);
    window.addEventListener(
      'resize',
      debounce(function () {
        setIsTablet(window.innerWidth < breakpoints[breakpoint || 'tablet']);
      })
    );

    return () => {
      window.addEventListener(
        'resize',
        debounce(function () {
          setIsTablet(window.innerWidth < breakpoints[breakpoint || 'tablet']);
        })
      );
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    returnActive(activeFilters);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters]);

  useEffect(() => {
    const tabContainer = TabsRef.current;
    const tabButtons = TabButtonsRef.current;
    const more = MoreRef.current;

    if (tabContainer && tabButtons && more) {
      const fullWidth = tabContainer.offsetWidth;
      let remainingSpace = fullWidth - more.offsetWidth;
      const overflow: OverflowI[] = [];

      tabButtons.map((tab, t) => {
        remainingSpace -= tab.current.offsetWidth + 50;
        if (remainingSpace < 0) {
          const tabInfo = tabs[t];
          overflow.push({ ...tabInfo, index: t });
        }
      });
      setOverflowTabs(overflow);
    }
  }, [TabsRef, tabs]);

  useEffect(() => {
    const inputList = document.querySelectorAll('input');
    for (let i = 0; i < inputList.length; i++) {
      if (inputList[i].className === 'checkbox') {
        if (inputList[i].id === inputId) {
          inputList[i].checked = true;
        } else {
          inputList[i].checked = false;
        }
      }
    }
  }, [inputId]);

  const handleFilters = (tab: string, name: string, value: boolean) => {
    let filters = [...activeFilters];
    if (!value) {
      // remove filter
      filters = filters.filter((x) => x.name !== name); // && x.parent !== tab
    } else {
      // add filter
      filters.push({
        parent: tab,
        name,
      });
    }
    setActiveFilters(filters);
  };

  const handleRemoveFilter = (name: string, parent: string) => {
    // * unselect on the dropdown too
    const idName = slugify(`${parent}-${name}`).toLowerCase();
    const checkbox: HTMLInputElement | null = document.querySelector(
      `#${idName}`
    );
    if (checkbox) {
      checkbox.checked = false;
    }

    // * remove from filter list
    let filters = [...activeFilters];
    filters = filters.filter((x) => {
      // check if name matches that the parent also matches
      return x.name === name ? x.parent !== parent : true;
    });
    setActiveFilters(filters);
  };

  console.log('tabs', tabs);

  return (
    <Container className={classNames(className)}>
      {!isTablet && (
        <TabContainer ref={TabsRef} className={classNames({ hide: isTablet })}>
          {tabs.map((tab, i) => {
            TabButtonsRef.current[i] = createRef();
            return (
              <Tab
                key={`${tab.value}-${i}`}
                style={{ zIndex: tabs.length - i }}
                htmlFor={tab.value}
                ref={TabButtonsRef.current[i]}
                className={classNames({
                  hide: overflowTabs
                    ? overflowTabs.findIndex((t) => {
                        return (
                          t?.label?.toLowerCase() === tab?.label?.toLowerCase()
                        );
                      }) > -1
                    : false,
                })}
              >
                <input
                  className="checkbox"
                  type="checkbox"
                  id={tab.value}
                  value={tab.value}
                  onChange={(e) => setInputId(e.target.id)}
                />
                <TabBackdrop htmlFor={tab.value} />
                <TabDropdown
                  tab={tab.value}
                  data={tab.options}
                  searchable={tab.searchable}
                  handleActive={(name: string, value: boolean) => {
                    handleFilters(tab.value, name, value);
                  }}
                  hide={
                    overflowTabs
                      ? overflowTabs.findIndex((t) => {
                          return (
                            t?.label?.toLowerCase() ===
                            tab?.label?.toLowerCase()
                          );
                        }) > -1
                      : false
                  }
                />
                <span className="label">
                  {tab.label} <IconSimpleArrowSmall />
                </span>
              </Tab>
            );
          })}
          <More
            className={classNames({ show: overflowTabs.length > 0 })}
            ref={MoreRef}
            onClick={() => setToggleMore(!toggleMore)}
          >
            <MoreButton
              type="button"
              className={classNames({ open: toggleMore })}
            >
              More <IconSimpleArrowSmall />
            </MoreButton>
            <FormProvider {...methods}>
              <MoreList className={classNames({ show: toggleMore })}>
                {overflowTabs?.map((tab, i) => {
                  if (!tab) return null;
                  return (
                    <Section key={`${tab.value}-${i}`}>
                      <span className="label">{tab.label}</span>
                      {tab.options.map((option) => {
                        return (
                          <CustomCheckbox
                            key={`${tab.value}-${option.label}`}
                            className={`dropdown-checkbox`}
                            name={`${tab.value}-${
                              // option?.value ||
                              option.label ||
                              slugify(option.label.toLowerCase())
                            }`}
                            label={option.label}
                            onChange={(name, value) => {
                              handleFilters(tab.value, option.label, value);
                            }}
                          />
                        );
                      })}
                    </Section>
                  );
                })}
              </MoreList>
            </FormProvider>
          </More>
        </TabContainer>
      )}
      <DropdownContainer className={classNames({ show: isTablet })}>
        <FormProvider {...methods}>
          <Top
            type="button"
            onClick={() => setToggleDropdown(!toggleDropdown)}
            className={classNames({ open: toggleDropdown })}
          >
            Filters <IconSimpleArrowSmall />
          </Top>
          <BottomDropdown className={classNames({ show: toggleDropdown })}>
            {tabs.map((tab, i) => {
              return (
                <Section id={tab.value} key={`${tab.value}-${i}`}>
                  <span className="label">{tab.label}</span>
                  {tab.options.map((option) => {
                    return (
                      <CustomCheckbox
                        key={`${tab.value}-${option.label}`}
                        className={`dropdown-checkbox`}
                        name={`${tab.value}-${
                          option.value ||
                          option.label ||
                          slugify(option.label.toLowerCase())
                        }`}
                        label={option.label}
                        onChange={(name, value) => {
                          handleFilters(tab.value, option.label, value);
                        }}
                      />
                    );
                  })}
                </Section>
              );
            })}
          </BottomDropdown>
        </FormProvider>
      </DropdownContainer>
      {activeFilters.length > 0 && (
        <FilterPills
          data={activeFilters}
          handleResetAll={() => setActiveFilters([])}
          handleRemoveFilter={handleRemoveFilter}
        />
      )}
    </Container>
  );
};

export default TabsDropdown;
