import React, { useEffect } from 'react';

interface LogoutProps {
  signOut: () => void;
}

const Logout = ({ signOut }: LogoutProps): JSX.Element => {
  useEffect(() => {
    signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div></div>;
};

export default Logout;
