import styled from 'styled-components';
import { colors } from '../../../shared/variables';
import { bodyCopySmall } from '../../../shared/extends';
import { size } from 'shared/mixins';
import { font, fontSize } from 'shared/typography';

export const Container = styled.div`
  &.currency {
    position: relative;

    input {
      padding-left: 24px;
    }
  }
`;

export const InputContent = styled.div`
  position: relative;
  height: auto;

  &.currency:before {
    ${bodyCopySmall};
    color: ${colors.brownLight};
    content: '$';
    position: absolute;
    bottom: 15px;
    left: 10px;
    z-index: 1;
  }

  svg.errorLimited {
    fill: ${colors.highlight};
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    ${size('16px')};
  }
`;

export const Input = styled.input`
  ${bodyCopySmall};
  // color: ${colors.brownMedium};
  border: 0;
  border-radius: 0;
  padding: 15px 20px;
  width: 100%;
  outline: 1px solid ${colors.white};
  outline-offset: -1px;

  &.error {
    outline: 1px solid ${colors.highlight};
  }
  &.errorLimited {
    outline: 2px solid ${colors.highlight};

    &:focus {
      + svg {
        display: none;
      }
    }
  }
  &.showEllipses {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &:disabled {
    color: grey;
    background-color: ${colors.greyLightLight};
    &::placeholder {
      color: ${colors.greyMediumMedium};
      margin: 1rem;
      left: 10px;
    }
  }
  &:hover {
    border-color: ${colors.brownMedium};
  }
  &:focus {
    border-color: ${colors.focus};
  }
  &:required:invalid {
    /* border: 1px solid red; */
  }
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const MaxLength = styled.span`
  bottom: 8px;
  color: ${colors.brownLightLight};
  pointer-events: none;
  position: absolute;
  right: 8px;
  text-align: right;
  ${font('secondary')};
  ${fontSize(8, 17, 1.75)};
`;

export const Error = styled.p`
  ${bodyCopySmall};
  color: ${colors.highlight};
  display: flex;
  align-items: center;
  margin: 6px 0 0;
  margin-right: auto;
  position: relative;

  svg {
    fill: ${colors.highlight};
    margin-right: 8px;
    ${size('12px')};
  }
`;
