import Button from 'components/Button';
import { colors } from 'shared/variables';
import styled from 'styled-components';

export const Archetype = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: hidden;

  &.closed {
    max-height: 0px;
  }
`;
export const Container = styled(Archetype)`
  ${Archetype} {
    padding: 0 0 0 48px;
    background: rgba(200, 200, 200, 0.1);
    /* padding: 8px 0; */
    border-top: 1px solid ${colors.brownLight};

    &.closed {
      border-top: 0px;
    }

    li {
      margin: 0 0;
      border: 0;
      border-bottom: 1px solid ${colors.brownLight};

      &:last-child {
        border-bottom: 0;
      }
    }
  }
`;
export const ModalButton = styled(Button)`
  margin-right: 40px;
  &:last-child {
    margin-right: 0;
  }
`;
