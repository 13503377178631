import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { size, mq } from '../../../../shared/mixins';
import {
  colors,
  customBezierEase,
  padding,
} from '../../../../shared/variables';
import { font, fontSize } from '../../../../shared/typography';
import { eyebrow, bodyCopySmall } from '../../../../shared/extends';

export const Container = styled.div`
  padding: 50px ${padding.tablet / 2}px 0 ${padding.tablet}px;
  position: absolute;
  background: ${colors.white};
  left: 100%;
  top: 0;
  pointer-events: none;
  opacity: 0;
  max-height: 100%;
  width: 100%;
  height: 100%;
  // display: none;
  transition: 0.5s ${customBezierEase};
  display: flex;
  flex-direction: column;

  ${mq.phoneWide`
    padding: 32px ${padding.mobile / 2}px 50px ${padding.mobile}px;
  `}
`;

export const Eyebrow = styled.span`
  ${eyebrow};
  color: ${colors.primary};
  margin-bottom: 24px;
  display: block;
`;

export const ResultsList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
`;

export const List = styled.div`
  margin: 0;
  padding: 0 0 75px;
  list-style: none;
  position: relative;

  ${mq.phoneWide`
    padding-right: 5px;
  `}
`;

export const Item = styled(Link)`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid ${colors.grey};
  color: ${colors.brownLight};
  justify-content: space-between;
  text-decoration: none;
  transition: 0.5s ${customBezierEase};
  width: 100%;
  ${font('primary', 'black')};
  ${fontSize(18, 27)};

  svg {
    fill: ${colors.highlight};
    margin-left: 9px;
    pointer-events: none;
    ${size('16px', '7px')}
  }
`;

export const MemberItem = styled(Item)`
  justify-content: flex-start;

  svg {
    margin-left: auto;
  }
`;

export const MemberImg = styled.div`
  border-radius: 100%;
  background-size: cover;
  background-position: center, center;
  background-color: ${colors.brownLight};
  margin-right: 35px;
  ${size('60px')};
`;

export const MainLink = styled(Link)`
  align-items: center;
  color: ${colors.brownLight};
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  transition: 0.5s ${customBezierEase};
  width: 100%;
  ${font('primary', 'black')};
  ${fontSize(28, 27)};

  svg {
    fill: ${colors.highlight};
    margin-left: 9px;
    pointer-events: none;
    ${size('16px', '7px')}
  }
`;

export const Loading = styled.div`
  ${bodyCopySmall};
  margin: 0 auto;
  width: 100%;
  text-align: center;
  color: ${colors.highlight};

  > span {
    margin-right: 16px;

    span {
      background-color: ${colors.highlight};
      ${size('6px')};
    }
  }
`;

export const LoadMore = styled(Loading)`
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
`;

export const NoMoreLoad = styled(Loading)`
  margin-bottom: 20px;
  margin-top: -20px;
`;
