import styled from 'styled-components';
import { mq } from '../../shared/mixins';
import { colors, padding, maxWidth } from '../../shared/variables';
import bkgTexture from '../../images/bg_texture-dust-white.jpg';

export const Container = styled.section`
  background: url(${bkgTexture}) 0 0 repeat, ${colors.greyLight};
  padding: 0 ${padding.desktop}px 100px;
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  > div:first-child {
    max-width: ${maxWidth}px;
  }

  ${mq.tablet`
    padding: 0 ${padding.tablet}px 160px;
  `}

  ${mq.phoneWide`
    padding: 0 27px 80px ${padding.mobile}px;
  `}
`;

export const Maps = styled.div`
  margin-top: 32px;
  display: flex;
  width: 100%;
  flex: 1 1 auto;

  iframe {
    width: 100%;
    min-height: 400px;
  }

  ${mq.tablet`
    iframe {
      min-height: 500px;
    }
  `}
`;
