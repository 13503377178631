import { IconX } from 'components/IconsView';
import React from 'react';
import slugify from 'slugify';
import { Container, Content, Pill, PillClear, Reset } from './styles';
interface FiltersProps {
  parent: string;
  name: string;
}

interface Props {
  data: FiltersProps[];
  handleResetAll: () => void;
  handleRemoveFilter: (name: string, parent: string) => void;
}

const FilterPills = ({
  data,
  handleResetAll,
  handleRemoveFilter,
}: Props): JSX.Element => {
  const handleReset = () => {
    // un-click all checkmarks
    const tabs = Array.from(
      document.querySelectorAll(
        'input.checkbox'
      ) as NodeListOf<HTMLInputElement>
    );
    const checks = Array.from(
      document.querySelectorAll('.form-checkbox.tab-checkbox')
    );
    // for tablet checkboxes
    const dropdownChecks = Array.from(
      document.querySelectorAll('.dropdown-checkbox')
    );
    // eslint-disable-next-line
    tabs.forEach((el) => {
      if (el) {
        el.checked = false;
      }
    });
    checks.forEach((el: Element) => {
      const input = el.querySelector<HTMLInputElement>('input');
      if (input) {
        input.checked = false;
      }
    });
    dropdownChecks.forEach((el) => {
      const input = el.querySelector<HTMLInputElement>('input');
      if (input) {
        input.checked = false;
      }
    });
    handleResetAll();
  };

  const handleClearFilter = (name: string, parent: string) => {
    const check: HTMLInputElement | null =
      document.querySelector(`#${parent} + div #${parent}-${slugify(name)}`) ||
      document.querySelector(
        `#${parent} + div #${parent}-${slugify(name.toLowerCase())}`
      ) ||
      document.querySelector(
        `#${parent} > div #${parent}-${slugify(name.toLowerCase())}`
      );
    if (check) {
      check.checked = false;
    }

    handleRemoveFilter(name, parent);
  };

  return (
    <Container>
      <Content>
        {data?.map((item: FiltersProps) => {
          return (
            <Pill key={`${item.parent}-${item.name}`} className="filter-pill">
              <PillClear
                type="button"
                onClick={() => handleClearFilter(item.name, item.parent)}
                aria-label={`pill-clear-${item.name}`}
              >
                <IconX />
                <span>{item.name}</span>
              </PillClear>
            </Pill>
          );
        })}
        <Reset type="button" onClick={handleReset}>
          Reset
        </Reset>
      </Content>
    </Container>
  );
};
export default FilterPills;
