import styled from 'styled-components';
import { size, mq, AspectRatioFunc } from '../../shared/mixins';
import { colors, padding, customBezierEase } from '../../shared/variables';
import bkgTexture from '../../images/bg_texture-dust-off-white.jpg';
import {
  bodyCopySmall,
  eyebrowRegular,
  eyebrowSmall,
  resetButtonStyles,
} from '../../shared/extends';
import Slider from 'react-slick';

export const Container = styled.section`
  padding: 80px 32px 80px ${padding.desktop}px;
  margin-bottom: 127px;
  background: url(${bkgTexture}) 0 0 repeat, ${colors.greyLight};
  overflow: hidden;
  position: relative;

  ${mq.tablet`
    margin-bottom: 80px;
    padding: 80px ${padding.tablet}px;
  `}

  ${mq.phoneWide`
    padding: 80px ${padding.mobile}px;
  `}
`;

export const Eyebrow = styled.h4`
  ${eyebrowRegular};
  color: ${colors.primary};
  grid-column: 1 / span 12;
  margin: 0 0 31px;
  text-transform: uppercase;

  ${mq.tablet`
  margin: 0 0 38px;
  `}
`;

export const Content = styled.div`
  width: 100%;
  max-width: 100%;
`;

export const Slideshow = styled(Slider)`
  display: flex;
  // flex-wrap: wrap;
  justify-content: center;

  .slick {
    &-list {
      position: relative;
      display: block;
      overflow: visible;
      margin: 0;
      padding: 0;
      order: 1;
      width: 100%;
    }

    // &-arrow {
    //   cursor: pointer;
    //   flex: 0 0 auto;
    //   margin: 25px 25px 0;
    // }

    &-prev {
      order: 2;
      text-align: right;
    }

    // &-next {
    //   order: 4;
    // }

    &-track {
      position: relative;
      top: 0;
      left: 0;
      display: flex;
      margin-left: auto;
      margin-right: auto;
    }

    &-slide {
      float: left;
      height: 100%;
      min-height: 1px;

      &:active {
        cursor: grabbing;
      }

      // div {
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   flex-direction: column;
      // }

      // img + div {
      //   width: 477px;
      //   margin: 0 auto;
      // }
    }
  }

  ${mq.phoneWide`
    margin: 0 -10px;
  `}
`;

export const Arrow = styled.button`
  ${resetButtonStyles};
  top: 98px;
  right: 73px;
  position: absolute;

  &.left {
    right: 113px;
    transform: scaleX(-1);
  }

  &:hover {
    svg {
      fill: ${colors.highlight};
    }
  }

  svg {
    transition: fill 0.2s ${customBezierEase};
    fill: ${colors.primary};
    ${size('10px', '17px')};
  }

  ${mq.tablet`
    display: none;
  `}
`;

export const Slide = styled.a`
  text-decoration: none;
  color: ${colors.brownLight};
  padding: 0 40px 0 0;

  ${mq.phoneWide`
    padding: 0 10px;
  `}

  &:hover {
    p:first-of-type {
      color: ${colors.highlight};
    }

    div {
      filter: grayscale(70%);
    }
  }
`;

export const SlideImage = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  background-color: ${colors.greyMediumMedium};
  background-size: cover;
  background-position: center;
  content: '';
  ${AspectRatioFunc({ width: 316, height: 253 })}
`;
// ${size('316px', '253px')};

export const SlideTitle = styled.p`
  ${eyebrowSmall};
  color: ${colors.brownLight};
  margin: 8px 0 0;
  transition: all 0.2s ${customBezierEase};
`;

export const SlideTime = styled.p`
  ${bodyCopySmall};
  color: ${colors.brownLight};
  margin: 1px 0 0;
  display: inline-flex;
  align-items: center;

  svg {
    fill: ${colors.brownLight};
    margin-right: 7px;
  }
`;
