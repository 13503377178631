// @refresh reset
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DraftailEditor } from 'draftail';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
import createSideToolbarPlugin from 'draft-js-side-toolbar-plugin';
import { stateToHTML } from 'draft-js-export-html';
import { Container, DraftContainer, Status, TitleInput } from './styles';
import * as queries from 'graphql/queries';
import * as mutations from 'graphql/mutations';
import htmlToDraft from 'html-to-draftjs';
import gql from 'graphql-tag';
import { useLazyQuery, useMutation } from '@apollo/client';
import { debounce } from 'lodash';

import 'draft-js/dist/Draft.css';
import 'draftail/dist/draftail.css';
import 'draft-js-inline-toolbar-plugin/lib/plugin.css';
import 'draft-js-side-toolbar-plugin/lib/plugin.css';
import PageI from 'data/types/Page.types';
import LoaderView from 'components/LoaderView';

const inlineToolbarPlugin = createInlineToolbarPlugin();
const { InlineToolbar } = inlineToolbarPlugin;
const sideToolbarPlugin = createSideToolbarPlugin();
const { SideToolbar } = sideToolbarPlugin;
const plugins = [inlineToolbarPlugin, sideToolbarPlugin];

const GET_PAGE = gql(queries.getPage);
const UPDATE_PAGE = gql(mutations.updatePage);

interface Props {
  match?: {
    params: {
      id: string;
    };
  };
}

interface SaveProps {
  body?: string;
  title?: string;
  meta?: string;
}

const callSave = debounce((func: () => void) => func(), 2000);

const WikiPage = ({ match }: Props): JSX.Element => {
  const id = match?.params?.id;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [data, setData] = useState<PageI | null>();
  const [saved, setSaved] = useState(true);
  const oldTitle = useRef('');
  const oldBody = useRef('');
  const TitleRef = useRef<HTMLTextAreaElement | null>(null);
  const [updatePage] = useMutation(UPDATE_PAGE);

  const [getPage] = useLazyQuery(GET_PAGE);

  const htmlConversion = (body: string) => {
    const blocksFromHTML = htmlToDraft(body);
    const { contentBlocks, entityMap } = blocksFromHTML;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editor = EditorState.createWithContent(contentState);

    const state = stateToHTML(editor.getCurrentContent());
    oldBody.current = state;

    console.log('editor update', body);
    setEditorState(editor);
  };

  const textAreaAdjust = () => {
    console.log(TitleRef);
    if (TitleRef?.current) {
      TitleRef.current.style.height = '1px';
      TitleRef.current.style.height = 35 + TitleRef.current.scrollHeight + 'px';
    }
  };

  const getPageFunc = useCallback(async () => {
    const pageData = await getPage({
      variables: {
        id,
      },
    });
    const page = pageData?.data?.getPage;
    console.log([page]);

    if (page) {
      oldTitle.current = page.title;
      console.log('pageData change');
      if (page.body) {
        htmlConversion(page.body);
      }
      setData(page);
    }

    setTimeout(() => {
      textAreaAdjust();
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPage, id]);

  const savePage = useCallback(
    async (props: SaveProps) => {
      if (id) {
        if (
          (props.body && props.body !== oldBody.current) ||
          (props.title && props.title !== oldTitle.current)
        ) {
          if (props.body) {
            oldBody.current = props.body;
          }
          if (props.title) {
            oldTitle.current = props.title;
          }
          console.log('save');
          await updatePage({
            variables: {
              input: {
                id,
                ...props,
              },
            },
          });
          setSaved(true);
        }
      }
    },
    [id, updatePage]
  );

  const changeState = (state: EditorState) => {
    const blocks = convertToRaw(state.getCurrentContent()).blocks;
    const html = stateToHTML(state.getCurrentContent());
    setEditorState(state);
    console.log(html);

    if (html !== oldBody.current) {
      if (saved) setSaved(false);
    }

    callSave(() => savePage({ body: html, meta: JSON.stringify(blocks) }));
  };

  useEffect(() => {
    getPageFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data) {
    return <LoaderView />;
  }

  return (
    <Container>
      <TitleInput
        ref={TitleRef}
        onKeyUp={textAreaAdjust}
        name="title"
        defaultValue={data?.title}
        onChange={(e) => {
          const value = e.target.value;
          callSave(() => savePage({ title: value }));
        }}
      />
      <DraftContainer>
        <DraftailEditor
          editorState={editorState}
          onChange={changeState}
          placeholder="Tell your story..."
          plugins={plugins}
        />
        <InlineToolbar />
        <SideToolbar />
      </DraftContainer>
      <Status>{saved ? 'Page Saved' : 'Page Unsaved'}</Status>
    </Container>
  );
};

export default WikiPage;
