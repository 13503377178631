import Tabs from 'components/Tabs';
import React, { useRef, useState } from 'react';
import { Container, Maps } from './styles';

const LunchSpotsView = (): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState(0);
  const El = useRef<HTMLDivElement>(null);

  const mapUrl = [
    'https://www.google.com/maps/d/u/0/embed?mid=1LuHfQDfK5lyC2sy2-QkvexsqHRYrZIc&ehbc=2E312F',
    'https://www.google.com/maps/d/u/0/embed?mid=1ZcGBFmUDXOT_QZWO77sdkg2R93nsuwQ&ehbc=2E312F',
  ];

  const tabsData = [
    {
      label: 'Orange County',
    },
    {
      label: 'Dallas',
    },
  ];

  console.log('Lunch Spot Old');

  return (
    <Container ref={El}>
      <Tabs
        tabs={tabsData || []}
        returnActive={(selected) => setSelectedTab(selected)}
        defaultActive={tabsData[selectedTab].label}
      />
      <Maps>
        <iframe src={mapUrl[selectedTab]}></iframe>
      </Maps>
    </Container>
  );
};

export default LunchSpotsView;
