import React, { useCallback, useEffect, useState } from 'react';
import { Container, Input, TrashButton } from './styles';
import { IconTrash } from 'components/IconsView';
import SelectView from 'components/FormComponents/SelectView';
import DateSelectorView from 'components/FormComponents/DateSelectorView';
import { useFormContext } from 'react-hook-form';
import { breakpoints } from 'shared/variables';
import { debounce } from 'utils/functions';
import MileageRatesI from 'data/types/MileageRates';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrBefore);

export interface ExpenseI {
  amount?: string;
  category?: string;
  date?: string;
  description?: string;
  item?: string;
  project?: string;
  quantity?: string;
  subtotal?: string;
}
interface Props {
  index: number;
  zIndex: number;
  moreThanOne: boolean;
  handleTrash: () => void;
  updateTotal: (subtotal: number) => void;
  values?: ExpenseI;
  mileageRates: MileageRatesI[];
}

const options = [
  {
    label: 'Project Expense',
    value: 'Project Expense',
  },
  {
    label: 'Wellness Allowance Reimbursement',
    value: 'Wellness Allowance Reimbursement',
  },
  {
    label: 'Professional Expense Reimbursement',
    value: 'Professional Expense Reimbursement',
  },
  {
    label: 'Mileage',
    value: 'Mileage',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

const ExpenseRow = ({
  index,
  zIndex,
  handleTrash,
  updateTotal,
  moreThanOne,
  values,
  mileageRates,
}: Props): JSX.Element => {
  const {
    formState: { errors },
    watch,
    setError,
    clearErrors,
  } = useFormContext();
  const [subtotal, setSubtotal] = useState({ qty: 0, price: 0 });
  const [isTablet, setIsTablet] = useState(false);
  const [isMileage, setIsMileage] = useState(false);
  // const [textareaHasContent, setTextareaHasContent] = useState(false);

  useEffect(() => {
    setIsTablet(window.innerWidth < breakpoints.tablet);

    window.addEventListener(
      'resize',
      debounce(function () {
        setIsTablet(window.innerWidth < breakpoints.tablet);
      })
    );

    return () => {
      window.addEventListener(
        'resize',
        debounce(function () {
          setIsTablet(window.innerWidth < breakpoints.tablet);
        })
      );
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (values?.amount || values?.quantity) {
      const qty = values?.quantity ? parseInt(values.quantity) : 0;
      const price = values?.amount ? parseFloat(values.amount) : 0;
      setSubtotal({
        qty,
        price,
      });
    }
  }, [values]);

  useEffect(() => {
    updateTotal((subtotal.qty || 0) * (subtotal.price || 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subtotal]);

  const watchDate = watch(`expenses[${index}].date`);
  const watchCat = watch(`expenses[${index}].category`);

  const getRate = () => {
    if (mileageRates.length === 0) {
      return 0.0;
    }
    for (let i = 0; i < mileageRates.length; i++) {
      const rate = mileageRates[i];
      if (dayjs(rate.date).isSameOrBefore(dayjs(watchDate), 'day')) {
        return rate.rate;
      }
    }
  };

  useEffect(() => {
    console.log('watchDate: ', watchDate);
    console.log('watchCat: ', watchCat);

    if (watchCat === 'Mileage') {
      setTimeout(() => {
        setIsMileage(true);
      }, 250);
    }

    if (watchDate && watchCat === 'Mileage') {
      const rate = getRate();

      if (rate) {
        setSubtotal((prev) => {
          return { ...prev, price: getRate() || 0.0 };
        });
        clearErrors([`mileageRate[${index}]`]);
      } else {
        setSubtotal((prev) => {
          return { ...prev, price: parseFloat('0.00') };
        });
        setError(`mileageRate[${index}]`, {
          type: 'custom',
          message: 'No mileage rate found, please contact Devin',
        });
      }
    } else {
      //* check if the previous cat was mileage
      // * if it was then update the price otherwise ignore
      if (isMileage) {
        setSubtotal((prev) => {
          return {
            ...prev,
            price: parseFloat('0.00'),
          };
        });
      }
      clearErrors([`mileageRate[${index}]`]);
      setIsMileage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchDate, watchCat, errors]);

  const calculatePrice = useCallback(() => {
    return (subtotal.qty * subtotal.price).toFixed(2).toString();
  }, [subtotal]);

  return (
    <Container data-testid="expense-row" style={{ zIndex: zIndex }}>
      <DateSelectorView
        name={`expenses[${index}].date`}
        label={isTablet || index === 0 ? 'Date' : ''}
        required={true}
        format="MM/DD/YY"
        errorLimited={errors?.expenses?.[index]?.date}
        defaultValue={values?.date}
        allowWeekends={true}
      />
      <Input
        name={`expenses[${index}].item`}
        label={isTablet || index === 0 ? 'Item' : ''}
        required={true}
        type="text"
        errorLimited={errors?.expenses?.[index]?.item}
        value={values?.item}
        // showEllipses={true}
      />
      <SelectView
        name={`expenses[${index}].category`}
        label={isTablet || index === 0 ? 'Category' : ''}
        required={true}
        options={options}
        defaultOption="Choose a Category"
        errorLimited={errors?.expenses?.[index]?.category}
        value={values?.category}
      />
      <Input
        name={`expenses[${index}].quantity`}
        label={isTablet || index === 0 ? 'Qty' : ''}
        type="number"
        step="1"
        required={true}
        placeholder="0"
        handleChange={(value) => {
          setSubtotal((prev) => {
            return { ...prev, qty: parseInt(value) };
          });
        }}
        errorLimited={errors?.expenses?.[index]?.quantity}
        value={values?.quantity}
        maxLength={4}
        minMax={{ min: 1 }}
      />
      <Input
        name={`expenses[${index}].amount`}
        label={isTablet || index === 0 ? 'Amount' : ''}
        type="currency"
        minMax={{ min: 0.0 }}
        required={true}
        placeholder="0.00"
        handleChange={(value) => {
          setSubtotal((prev) => {
            return { ...prev, price: parseFloat(value) };
          });
        }}
        errorLimited={
          errors?.expenses?.[index]?.amount || errors?.mileageRate?.[index]
        }
        value={isMileage ? `${getRate()}` : values?.amount}
        disabled={isMileage}
      />
      <Input
        name={`expenses[${index}].subtotal`}
        label={isTablet || index === 0 ? 'Subtotal' : ''}
        type="currency"
        minMax={{ min: 0.0 }}
        required={true}
        placeholder="0.00"
        disabled={true}
        value={calculatePrice()}
      />
      {moreThanOne && (
        <TrashButton
          data-testid="trash-can"
          type="button"
          onClick={() => {
            handleTrash();
          }}
        >
          <IconTrash />
        </TrashButton>
      )}
    </Container>
  );
};

export default ExpenseRow;
