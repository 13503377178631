import { bodyCopySmall } from 'shared/extends';
import styled from 'styled-components';
import { colors } from '../../../shared/variables';

export const Container = styled.div`
  p.description {
    ${bodyCopySmall}
    color: ${colors.brownLight};
    text-transform: none;
    margin-top: 5px;
  }
`;
export const TimeContainer = styled.div`
  align-items: center;
  display: flex;
  column-gap: 8px;
  position: relative;
  margin-top: 24px;
  width: 100%;
`;
