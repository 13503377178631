import classNames from 'classnames';
import FreelancerI from 'data/types/Freelancer.type';
import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  Detail,
  DetailContainer,
  FreeLink,
  Icon,
  Info,
  InfoContainer,
  InfoTitle,
  Top,
} from './styles';

interface Props {
  data: FreelancerI;
  closeOthers: (id: string) => void;
  updateActive: string;
}

const Freelancer = ({
  data,
  closeOthers,
  updateActive,
}: Props): JSX.Element => {
  const [active, setActive] = useState(false);
  const DetailRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (updateActive !== data.id) {
      setActive(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateActive]);

  useEffect(() => {
    if (DetailRef.current) {
      if (!active) {
        DetailRef.current.style.maxHeight = '0px';
      } else {
        DetailRef.current.style.maxHeight =
          DetailRef.current.scrollHeight + 'px';
      }
    }
  }, [active]);

  const hasDetails = () => {
    return (
      data?.design ||
      data?.ux ||
      data?.motion ||
      data?.development ||
      data?.photography ||
      data?.video ||
      data?.copywriting ||
      data?.branding ||
      data?.rate ||
      data?.referredBy ||
      data?.notes
    );
  };

  return (
    <Container
      role="button"
      aria-label={'click to expand'}
      onClick={(e) => {
        const target = e.target as HTMLElement;
        if (target.tagName !== 'A') {
          if (hasDetails()) {
            setActive((prev) => !prev);
            closeOthers(data.id);
          }
        }
      }}
      className={classNames({ hasDetails: hasDetails() })}
    >
      <Top>
        <span className="freelancer-name">{data.name}</span>
        {data?.portfolio ? (
          <div>
            <FreeLink target="_blank" href={data.portfolio}>
              View
            </FreeLink>
          </div>
        ) : (
          <span>-</span>
        )}
        {data?.email ? (
          <div>
            <FreeLink href={`mailto:${data.email}`}>{data.email}</FreeLink>
          </div>
        ) : (
          <span>-</span>
        )}
        <span>{data?.location || '-'}</span>
        {hasDetails() && (
          <Icon className={classNames('freelancer', { active: active })} />
        )}
      </Top>
      <DetailContainer ref={DetailRef}>
        <Detail>
          {(data?.design ||
            data?.ux ||
            data?.motion ||
            data?.development ||
            data?.photography ||
            data?.video ||
            data?.copywriting ||
            data?.branding) && (
            <Info className="capabilities">
              <InfoTitle>Capabilities:</InfoTitle>
              <ul>
                {data.design && <li>Design</li>}
                {data.ux && <li>UX</li>}
                {data.motion && <li>Motion</li>}
                {data.development && <li>Development</li>}
                {data.photography && <li>Photography</li>}
                {data.video && <li>Video</li>}
                {data.copywriting && <li>Copywriting</li>}
                {data.branding && <li>Branding</li>}
              </ul>
            </Info>
          )}
          <InfoContainer>
            {data?.rate && (
              <Info className="rate">
                <InfoTitle>Hourly Rate:</InfoTitle>
                <span>
                  {'$'}
                  {data.rate || 'XX'}/hr
                </span>
              </Info>
            )}
            {data?.referredBy && (
              <Info className="reference">
                <InfoTitle>Referred by:</InfoTitle>
                <span>{data?.referredBy || ''}</span>
              </Info>
            )}
          </InfoContainer>
          {data?.notes && (
            <Info className="notes">
              <InfoTitle>Notes:</InfoTitle>
              <div>{data?.notes}</div>
            </Info>
          )}
          {data?.status && (
            <Info className="status">
              <InfoTitle>Current Employer:</InfoTitle>
              <div>{data?.status}</div>
            </Info>
          )}
        </Detail>
      </DetailContainer>
    </Container>
  );
};
export default Freelancer;
