import styled from 'styled-components';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { mq } from 'shared/mixins';

export const ModalContainer = styled(Modal)`
  ${mq.tablet`
    padding: 24px 32px;
  `}
`;

export const CustomButton = styled(Button)`
  @media (max-width: 767px) {
    width: 100%;
  }
`;
export const CustomModalButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;

  @media (max-width: 767px) {
    display: block;
  }
`;
