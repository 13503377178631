import React, { useEffect, useState } from 'react';
import {
  AccordionContainer,
  Container,
  Head,
  Header,
  NotFound,
  Table,
  TabsDropdownBox,
} from './styles';
import FreelancerI from 'data/types/Freelancer.type';
import { IconTableTriangle } from 'components/IconsView';
import Freelancer from './Components/Freelancer';
import classNames from 'classnames';
import { debounce } from 'utils/functions';
import Accordion from 'components/Accordion';
import { FreeLink } from './Components/Freelancer/styles';

interface Props {
  data: FreelancerI[];
}

const tabs = [
  {
    label: 'Capabilities',
    value: 'capabilities',
    sort: 0,
    searchable: false,
    options: [
      {
        label: 'Design',
        value: 'design',
      },
      {
        label: 'UX',
        value: 'ux',
      },
      {
        label: 'Motion',
        value: 'motion',
      },
      {
        label: 'Development',
        value: 'development',
      },
      {
        label: 'Photography',
        value: 'photography',
      },
      {
        label: 'Video',
        value: 'video',
      },
      {
        label: 'Copywriting',
        value: 'copywriting',
      },
      {
        label: 'Branding',
        value: 'branding',
      },
    ],
  },
];

const headers = [
  { label: 'Name', value: 'name' },
  { label: 'Portfolio', value: 'portfolio' },
  { label: 'Email', value: 'email' },
  { label: 'Location', value: 'location' },
];

interface FiltersProps {
  parent: string;
  name: string;
}

const FreelanceDirectoryView = ({ data }: Props): JSX.Element => {
  const [filteredData, setFilteredData] = useState<FreelancerI[]>([]);
  const [sort, setSort] = useState({ value: 'name', direction: 'asc' });
  const [updateID, setUpdateID] = useState('');
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    setIsTablet(window.innerWidth < 1000);

    window.addEventListener(
      'resize',
      debounce(function () {
        setIsTablet(window.innerWidth < 1000);
      })
    );

    return () => {
      window.addEventListener(
        'resize',
        debounce(function () {
          setIsTablet(window.innerWidth < 1000);
        })
      );
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const handleFiltering = (filters: FiltersProps[]) => {
    // filter data with new stuff
    if (filters.length === 0) {
      setFilteredData(data);
      return;
    }
    let filterData = data.slice();
    filterData = filterData.filter((x: FreelancerI) => {
      return !!filters.find((item: FiltersProps) => {
        const cap = item.name.toLowerCase();
        return x?.[cap];
      });
    });
    setFilteredData(filterData);
  };

  const handleSort = (value: string) => {
    if (sort.value === value) {
      setSort({ value, direction: sort.direction === 'asc' ? 'desc' : 'asc' });
    } else {
      setSort({ value, direction: 'asc' });
    }
  };

  return (
    <Container>
      <TabsDropdownBox
        tabs={tabs}
        returnActive={(active) => handleFiltering(active)}
      />
      {!isTablet && (
        <Table>
          <Header>
            {headers.map((header) => (
              <Head
                key={header.value}
                disabled={
                  filteredData.length === 0 || header.value === 'portfolio'
                }
                type="button"
                onClick={() => {
                  if (header.value !== 'portfolio') handleSort(header.value);
                }}
                className={classNames('freelance-header', {
                  active: sort.value === header.value,
                  flip: sort.direction === 'desc',
                })}
              >
                {header.label}
                {sort.value === header.value && <IconTableTriangle />}
              </Head>
            ))}
          </Header>
          {filteredData
            .slice()
            .sort((a, b) => {
              if (!a[sort.value]) {
                return 1;
              }
              if (!b[sort.value]) {
                return -1;
              }
              if (
                a?.[sort.value].toLowerCase() < b?.[sort.value].toLowerCase()
              ) {
                return sort.direction === 'asc' ? -1 : 1;
              } else if (
                a?.[sort.value].toLowerCase() > b?.[sort.value].toLowerCase()
              ) {
                return sort.direction === 'asc' ? 1 : -1;
              } else if (
                a?.[sort.value].toLowerCase() === b?.[sort.value].toLowerCase()
              ) {
                // if matching values organize by name
                if (a?.name < b?.name) {
                  return sort.direction === 'asc' ? -1 : 1;
                } else if (a?.name > b?.name) {
                  return sort.direction === 'asc' ? 1 : -1;
                }
              }
              return 0;
            })
            .map((item) => (
              <Freelancer
                key={item.id}
                data={item}
                closeOthers={(id) => setUpdateID(id)}
                updateActive={updateID}
              />
            ))}
          {filteredData.length === 0 && <NotFound>No Results Found.</NotFound>}
        </Table>
      )}
      {isTablet && (
        <AccordionContainer>
          {filteredData
            .slice()
            .sort((a, b) => {
              if (a?.name < b?.name) {
                return sort.direction === 'asc' ? -1 : 1;
              } else if (a?.name > b?.name) {
                return sort.direction === 'asc' ? 1 : -1;
              }
              return 0;
            })
            .map((item) => {
              const hasDetails =
                item?.portfolio ||
                item?.email ||
                item?.location ||
                item?.design ||
                item?.ux ||
                item?.motion ||
                item?.development ||
                item?.photography ||
                item?.video ||
                item?.copywriting ||
                item?.branding ||
                item?.rate ||
                item?.referredBy ||
                item?.notes;
              return (
                <Accordion
                  key={item.id}
                  title={item.name}
                  dontOpen={!hasDetails}
                >
                  {item.portfolio && (
                    <FreeLink href={item.portfolio} className="detail">
                      View
                    </FreeLink>
                  )}
                  {item.email && (
                    <FreeLink href={`mailto:${item.email}`} className="detail">
                      {item.email}
                    </FreeLink>
                  )}
                  {item.location && (
                    <div className="detail">{item.location}</div>
                  )}
                  {(item?.design ||
                    item?.ux ||
                    item?.motion ||
                    item?.development ||
                    item?.photography ||
                    item?.video ||
                    item?.copywriting ||
                    item?.branding) && (
                    <div className="detail">
                      <span className="label">Capabilities:</span>
                      <ul>
                        {item.design && <li>Design</li>}
                        {item.ux && <li>UX</li>}
                        {item.motion && <li>Motion</li>}
                        {item.development && <li>Development</li>}
                        {item.photography && <li>Photography</li>}
                        {item.video && <li>Video</li>}
                        {item.copywriting && <li>Copywriting</li>}
                        {item.branding && <li>Branding</li>}
                      </ul>
                    </div>
                  )}
                  {item?.rate && (
                    <div className="detail">
                      <span className="label">Hourly Rate:</span>
                      <span>
                        {'$'}
                        {item.rate || 'XX'}/hr
                      </span>
                    </div>
                  )}
                  {item.referredBy && (
                    <div className="detail">
                      <span className="label">Referred By:</span>
                      <span>{item.referredBy}</span>
                    </div>
                  )}
                  {item?.notes && (
                    <div className="detail">
                      <span className="label">Notes By:</span>
                      <div>{item.notes}</div>
                    </div>
                  )}
                  {item.status && (
                    <div className="detail">
                      <span className="label">Current Employer:</span>
                      <span>{item.status}</span>
                    </div>
                  )}
                </Accordion>
              );
            })}
          {filteredData.length === 0 && <NotFound>No Results Found.</NotFound>}
        </AccordionContainer>
      )}
    </Container>
  );
};

export default FreelanceDirectoryView;
