import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { colors, customBezierEase } from '../../shared/variables';
import { font, fontSize } from '../../shared/typography';
import { size } from '../../shared/mixins';

export const MainLink2 = styled(NavLink)`
  ${font('secondaryNav')};
  ${fontSize(16, 20)};
  align-items: center;
  color: ${colors.brownLight};
  display: flex;
  text-decoration: none;
  transition: 0.3s ${customBezierEase};
  -webkit-font-smoothing: auto;

  &.active {
    color: ${colors.primary};
    pointer-events: none;

    svg {
      transform: rotate(-90deg);
      fill: ${colors.primary};
    }
  }

  &:not(.active):hover {
    color: ${colors.highlight};

    svg {
      fill: ${colors.highlight};
    }
  }
`;

export const MainItem2 = styled.div`
  align-items: center;
  display: flex;
`;

export const SubLink2 = styled(MainLink2)`
  margin: 16px 0;
  display: flex;
  align-items: center;
  padding-left: 24px;
  position: relative;

  &:last-child {
    margin-bottom: 0px;

    &:after {
      height: 50%;
    }
  }

  &:after {
    // left vertical line
    background: ${colors.grey};
    content: '';
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    ${size('1px', 'calc(100% + 16px)')}
  }

  &:before {
    // left horizontal line
    background: ${colors.grey};
    content: '';
    display: block;
    left: 0;
    position: absolute;
    top: 9px;
    ${size('16px', '1px')}
  }
`;
