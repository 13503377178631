import styled from 'styled-components';
import { size, mq } from '../../shared/mixins';
import {
  colors,
  customBezierEase,
  iconArrowAnimation,
  padding,
} from '../../shared/variables';
import { font, fontSize, H1 } from '../../shared/typography';
import bkgTexture from '../../images/bg_texture-dust-accent.jpg';
import bkgTextureWhite from '../../images/bg_texture-dust-white.jpg';
import {
  bodyCopySmall,
  eyebrow,
  gridDesktop,
  gridTablet,
  WYSIWYGStyles,
} from '../../shared/extends';
import SearchView from 'components/SearchView';
import { Link } from 'react-router-dom';

export const Container = styled.section`
  background: url(${bkgTexture}) 0 0 repeat, ${colors.primary};
  // min-height: 680px;
  padding: 46px ${padding.desktop}px 100px;
  position: relative;
  ${size('auto', 'auto')}

  &.subpage {
    background: url(${bkgTextureWhite}) 0 0 repeat, ${colors.greyLight};
    min-height: auto;
    padding: 46px ${padding.desktop}px 50px;
  }

  ${mq.tabletWide`
    min-height: auto;
  `}

  ${mq.tablet`
    padding: 162px ${padding.tablet}px 80px;

    &.subpage {
      padding: 162px ${padding.tablet}px 80px;
    }
  `}

  ${mq.phoneWide`
    padding: 162px ${padding.mobile}px 80px;

    &.subpage {
      padding: 162px ${padding.mobile}px 80px;
    }
  `}
`;

export const Content = styled.div`
  ${gridDesktop};
  max-width: 100%;

  ${mq.tabletWide`
    ${gridTablet};
  `}

  ${mq.phoneWide`
    display: block;
  `}
`;

export const Search = styled(SearchView)`
  grid-column: 1 / span 12;

  ${mq.tablet`
    display: none;
  `}
`;

export const Eyebrow = styled.h4`
  ${eyebrow};
  text-transform: uppercase;
  grid-column: 1 / span 6;
  margin: 106px 0 19px;

  ${mq.tablet`
    margin: 0 0 19px;
  `}

  ${mq.phoneWide`
    margin: 0 0 16px;
    grid-column: span 1;
  `}
`;

export const Title = styled(H1)`
  color: ${colors.white};
  grid-column: 1 / span 9;
  margin: 0;

  .subpage & {
    color: ${colors.brownMedium};
  }

  ${mq.tablet`
    ${fontSize(55, 60, -0.2)}
  `}

  ${mq.phoneWide`
    margin: 0 0 24px;
    grid-column: span 1;
    ${fontSize(45, 49, -0.2)}
  `}
`;

export const Description = styled.div`
  ${bodyCopySmall};
  color: ${colors.brownMedium};
  grid-column: 1 / span 5;
  margin: 45px 0 0;

  ${mq.tablet`
    margin-top: 8px;
  `}

  > *:first-child {
    margin-top: 0;
  }

  p,
  blockquote,
  ul,
  ol,
  table,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    max-width: 740px;
  }

  p,
  table {
    color: ${colors.brownMedium};
    margin: 0;
    ${font('body')}
    ${fontSize(14, 20)}
  }

  ${mq.tabletWide`
    table {
      ${fontSize(10, 16)}
    }
  `}

  blockquote {
    color: ${colors.brownMedium};
    font-style: italic;
    ${font('primary', 'light')};
    ${fontSize(16, 24, -0.2)};
  }

  ol,
  ul {
    color: ${colors.brownMedium};
    margin: 0;
    padding: 0;
    list-style: none;
    ${font('body')};
    ${fontSize(14, 20)};
  }

  a {
    color: ${colors.highlight};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  h1 {
    color: ${colors.brownMedium};
    ${font('primary', 'black')};
    ${fontSize(65, 67, -0.2)};
  }
  h2 {
    color: ${colors.brownMedium};
    ${font('primary', 'black')};
    ${fontSize(48, 52, -0.2)};
  }
  h3 {
    color: ${colors.brownMedium};
    ${font('primary', 'black')};
    ${fontSize(24, 27, 0)};
  }
  h4 {
    color: ${colors.brownMedium};
    ${font('primary', 'medium')};
    ${fontSize(20, 24, 0)};
  }

  img {
    max-width: 100%;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    tr {
    }
    td {
      border: 1px solid ${colors.brownMedium};
      padding: 5px;
      min-height: 30px;
    }
  }
  ${WYSIWYGStyles};
`;

export const PageLink = styled(Link)`
  ${bodyCopySmall};
  font-weight: 700;
  grid-column: 1 / span 3;
  align-items: center;
  color: ${colors.highlight};
  display: flex;
  margin: 20px 0 0;
  text-decoration: none;

  div {
    overflow: hidden;
  }

  span {
    display: inline-block;
  }

  svg {
    fill: ${colors.highlight};
    margin-left: 9px;
    ${size('12px', '5px')}
  }

  &:hover {
    cursor: pointer;
    span {
      animation: slideOut 0.5s ease-in-out,
        slideIn 0.3s ${customBezierEase} 0.3s;
    }
    svg {
      ${iconArrowAnimation};
    }
  }
  @keyframes slideOut {
    0% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(120%);
      opacity: 0%;
    }
  }

  @keyframes slideIn {
    0% {
      transform: translate(-100%, 0%);
      opacity: 0%;
    }

    100% {
      transform: translate(0%, 0%);
      opacity: 100%;
    }
  }
`;

export const Notification = styled(Link)`
  background: ${colors.highlight};
  padding: 12px 18px;
  color: ${colors.white};
  display: flex;
  grid-column: 1 / span 4;
  align-items: center;
  width: 100%;
  margin: 25px 0 0;

  .warning {
    margin-right: 12px;
  }

  .information {
  }

  .arrow {
    display: flex;
    align-items: center;
    margin-left: auto;

    svg {
      ${size('12px', '5px')}
      color: ${colors.white};
      stroke: ${colors.white};
      fill: ${colors.white};
    }
  }
`;

export const NotificationTitle = styled.p`
  ${eyebrow};
  color: ${colors.white};
  margin: 0;
`;

export const NotificationMessage = styled.p`
  ${bodyCopySmall};
  color: ${colors.white};
  margin: -3px 0 0;
`;
