/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Text } from '@aws-amplify/ui-react';
import { Hub, Auth } from 'aws-amplify';
import { useFeatures } from 'flagged';
import {
  Container,
  Content,
  SignIn,
  Title,
  SignInView,
  PrintLogo,
  SignInContent,
} from './styles';
import NavigationView from '../../components/NavigationView';
import NavigationView2 from '../../components/NavigationView2';
import Home from '../Home';
import PTO from 'containers/PTO';
import FooterView from 'components/FooterView';
import Template from 'containers/Template';
import MainPage from 'containers/MainPage';
import '@aws-amplify/ui-react/styles.css';
import { Helmet } from 'react-helmet';
import CompanyDirectory from 'containers/CompanyDirectory';
import MobileNavigationView from 'components/MobileNavigationView';
import FAQ from 'containers/FAQ';
import FreelanceDirectory from 'containers/FreelanceDirectory';
import Holidays from 'containers/Holidays';
import ExpenseReport from 'containers/ExpenseReport';
import EmailSignatureGenerator from 'containers/EmailSignatureGenerator';
import BackToTopView from 'components/BackToTopView';
import { IconArrow, IconLogoMobile } from 'components/IconsView';
import NavigationI, { SubLinkI } from 'data/types/Navigation.types';
import GlobalStyle from 'shared/globalStyles';
import Logout from './Logout';
import NotFoundView from 'components/NotFoundView';
import Redirect from 'components/Redirect';
import classNames from 'classnames';
import WikiHome from 'containers/WikiHome';
import WikiPage from 'containers/WikiHome/Components/WikiPage';
import PageI from 'data/types/Page.types';
import * as localQueries from './helpers/queries';
import * as queries from 'graphql/queries';
import gql from 'graphql-tag';
import { useLazyQuery, useQuery } from '@apollo/client';
import isTouchDevice from 'is-touch-device';
import LunchSpots from 'containers/LunchSpots';
import LunchSpots2 from 'containers/LunchSpots2';
import OfficeI from 'data/types/Office.types';
import NavLinksContext from 'data/context/NavLinksContext';
import LunchTable from 'containers/LunchTable/LunchTable';
import RiddleGriddle from 'containers/RiddleGriddle';

const LIST_PAGES = gql(localQueries.listPages);
const LIST_OFFICES = gql(queries.listOffices);
const GET_PAGE = gql(queries.getPage);
const LIST_MEMBERS = gql(localQueries.listMembers);

const translateIdToFeature = (id: string) => {
  switch (id) {
    case 'page-accept-agreements':
      return 'agreements';
    case 'page-accounts':
      return 'accounts';
    case 'page-email-signature-builder':
      return 'email';
    case 'page-expense':
      return 'expense';
    case 'page-faq':
      return 'faqs';
    case 'page-freelance-directory':
      return 'freelancedir';
    case 'page-holiday':
      return 'holidaydates';
    case 'page-home':
      return 'homepage';
    case 'page-team-members':
      return 'teammembers';
    case 'page-media-kit':
      return 'mediakit';
    case 'page-pto':
      return 'pto';
    case 'page-lunch-spots':
      return 'lunch';
    case 'page-404':
      return '404';
    default:
      return 'unassigned';
  }
};

function App(): JSX.Element {
  const features = useFeatures();
  const [user, setUser] = useState<any | null>(null);
  const [data, setData] = useState<PageI[] | null>(null);
  const [navLinks, setNavLinks] = useState<NavigationI[]>([]);
  const [isTouch, setIsTouch] = useState(false);
  const [officePageData, setOfficePageData] = useState<PageI | null>(null);
  const [officesData, setOfficesData] = useState<OfficeI[] | null>(null);
  const [pageReady, setPageReady] = useState(false);
  const [loader, setLoader] = useState(true);

  const { data: listMembersData } = useQuery(LIST_MEMBERS);

  const [listPages] = useLazyQuery(LIST_PAGES, {
    variables: {
      pageId: 'main',
      filter: { status: { eq: 'active' } },
    },
    onCompleted: ({ listPagesByPageId: { items } }) => {
      setData(items);
    },
  });

  useEffect(() => {
    console.log('officesData', officesData);
  }, [officesData]);

  const signOut = () => {
    console.log('LOADER signout');
    setLoader(false);
    Auth.signOut();
  };

  const getUsersOffice = useCallback(
    (passedOfficesData?: OfficeI[]) => {
      const tempOfficesData = officesData || passedOfficesData;
      if (user && tempOfficesData && !user.office) {
        const usersOffice = tempOfficesData.filter(
          (office: OfficeI) =>
            user.attributes.zoneinfo &&
            office.group &&
            user.attributes.zoneinfo.includes(office.group)
        );
        if (usersOffice.length === 1) {
          // user only belongs to one office
          if (usersOffice[0]?.active) {
            listPages();
            setUser((prev: any) => {
              return { ...prev, office: usersOffice[0] };
            });
            setPageReady(true);
            console.log('LOADER getuser');
            setLoader(false);
          } else {
            signOut();
          }
        } else if (usersOffice.length > 1) {
          // user belongs to multiple offices
          const defaultOffice =
            JSON.parse(officePageData?.meta || '')?.defaultGroup || 'oc';
          const uOffice = usersOffice.filter(
            (office: OfficeI) => office.id === defaultOffice
          );
          if (uOffice[0]?.active) {
            listPages();
            setUser((prev: any) => {
              return { ...prev, office: uOffice[0] };
            });
            setPageReady(true);
            console.log('LOADER getuser');
            setLoader(false);
          } else {
            signOut();
          }
        }
      }
    },
    [user, officesData, officePageData, listPages]
  );

  const [listOffices] = useLazyQuery(LIST_OFFICES, {
    onCompleted: ({ listOffices: dataOffices }) => {
      setOfficesData(dataOffices?.items);
      console.log('listOffices', dataOffices?.items);
      setTimeout(() => {
        console.log('call getUsersOffice');
        getUsersOffice(dataOffices?.items);
      }, 1000);
    },
    onError: (err) => {
      console.log('listOffices err', err);
    },
  });

  const [getPage] = useLazyQuery(GET_PAGE, {
    variables: {
      id: 'page-manage-offices',
    },
    onCompleted: ({ getPage: dataOfficePage }) => {
      console.log('getPage', dataOfficePage);
      setOfficePageData(dataOfficePage);
      listOffices();
    },
  });

  useEffect(() => {
    getUsersOffice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, officesData, getUsersOffice]);

  useEffect(() => {
    if (data) {
      const links = data?.filter((page: PageI) => {
        return page.id !== 'page-404' && page.id !== 'page-home';
      });

      const formattedLinks: NavigationI[] = [];
      /* Sorting the links by the sort property. */
      links
        ?.slice()
        .sort((x: PageI, y: PageI) => (x.sort || 0) - (y.sort || 0))
        .filter((page: PageI) => {
          if (page.layout === 'main') {
            return true;
          }
          return !!features[translateIdToFeature(page.id)];
        })
        .forEach((page: PageI) => {
          const subItems: SubLinkI[] = [];
          if (page?.pages?.items) {
            page.pages.items
              ?.slice()
              .sort((x: PageI, y: PageI) => (x.sort || 0) - (y.sort || 0))
              .filter((sub: PageI) => {
                if (
                  sub.layout === 'plain' ||
                  sub.layout === 'tabbed' ||
                  sub.layout === 'officeTab'
                ) {
                  return true;
                }
                return !!features[translateIdToFeature(sub.id)];
              })
              .forEach((subPage: PageI) => {
                subItems.push({
                  id: subPage.id,
                  label: subPage.label,
                  slug: subPage.slug,
                  layout: subPage.layout,
                  parentId: subPage.pageId,
                });
              });
          }
          formattedLinks.push({
            id: page.id,
            label: page.label,
            slug: page.slug,
            layout: page.layout,
            subItems,
          });
        });
      console.log(formattedLinks);
      setNavLinks(formattedLinks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  async function getUser() {
    console.log('get user');
    try {
      const token = await Auth.currentAuthenticatedUser();

      if (token?.attributes && !token?.attributes?.zoneinfo) {
        signOut();
      } else {
        setUser(token);

        setTimeout(() => {
          console.log('get user, getPage');
          getPage();

          // Redirect user to originating page
          const redirectUrl = window.localStorage.getItem('originalUrl');
          console.log('redirecting...', redirectUrl);
          window.localStorage.removeItem('originalUrl');
          if (redirectUrl) {
            window.location.href = redirectUrl;
          }
        }, 1000);
      }
    } catch (err) {
      console.log('LOADER err');
      setLoader(false);
      console.log('err', err);
      // Collect originating page, and store in localstorage
      const redirectUrl = window.localStorage.getItem('originalUrl');
      if (!redirectUrl && window.location.pathname !== '/logout/') {
        const originalUrl = window.location.href;
        window.localStorage.setItem('originalUrl', originalUrl);
      }
    }
  }

  useEffect(() => {
    Hub.listen('auth', ({ payload }) => {
      console.log('hub', payload);
      if (payload.event === 'signIn') {
        console.log('sign in');
        getUser();
        setTimeout(() => {
          return getUser();
        }, 1000);
      }
      if (payload.event === 'signOut') {
        console.log('LOADER signout event');
        setLoader(false);
        setUser(null);
      }
    });
    getUser();

    setIsTouch(isTouchDevice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleComponents = useCallback(
    (layout: string, u: any, slug: string) => {
      switch (layout) {
        case 'main':
          return <MainPage slug={slug} />;
        case 'email':
          return (
            <EmailSignatureGenerator
              user={{
                name: u.attributes.name
                  ? u.attributes.name
                  : `${u?.attributes.given_name} ${u?.attributes.family_name}`,
                email: u.attributes.email,
              }}
              slug={slug}
            />
          );
        case 'expenses':
          return (
            <ExpenseReport
              slug={slug}
              user={{
                name: u.attributes.name
                  ? u.attributes.name
                  : `${u?.attributes.given_name} ${u?.attributes.family_name}`,
                email: u.attributes.email,
                accessToken: u.attributes.address,
              }}
            />
          );
        case 'faq':
          return <FAQ slug={slug} />;
        case 'freelance':
          return <FreelanceDirectory slug={slug} />;
        case 'holidays':
          return <Holidays slug={slug} />;
        case 'pto':
          return (
            <PTO
              slug={slug}
              user={{
                name: u.attributes.name
                  ? u.attributes.name
                  : `${u?.attributes.given_name} ${u?.attributes.family_name}`,
                email: u.attributes.email,
                timezone: u?.office?.timezone || 'HST',
              }}
            />
          );
        case 'lunch':
          return features['v1.5'] ? (
            <LunchSpots2 slug={slug} user={u} />
          ) : (
            <LunchSpots slug={slug} />
          );
        case 'team':
          return <CompanyDirectory slug={slug} />;
        default:
          return <Template slug={slug} user={u} />;
      }
    },
    [features]
  );

  const documentHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--doc-height', `${window.innerHeight}px`);
  };

  useEffect(() => {
    window.addEventListener('resize', documentHeight);
    documentHeight();

    return () => {
      window.removeEventListener('resize', documentHeight);
    };
  }, []);

  console.log('LOADER', loader, !user || !pageReady);

  return useMemo(() => {
    return (
      <>
        <GlobalStyle />
        {loader && (!user || !pageReady) ? (
          <></>
        ) : !user || !pageReady ? (
          <SignInView>
            <SignInContent>
              <Text
                color={'#4D453D'}
                fontFamily={'"TT Commons", sans-serif'}
                fontSize={'14px'}
                fontWeight={'bold'}
                lineHeight={'20px'}
                letterSpacing={'1.4px'}
                padding={'0 0 16px 0'}
                textTransform={'uppercase'}
              >
                Five &amp; Done
              </Text>
              <Title>Hey, Welcome to the Intranet.</Title>
              <Text
                color={'#4D453D'}
                fontFamily={'"TT Commons", sans-serif'}
                fontSize={'14px'}
                lineHeight={'20px'}
                padding={'0 0 25px 0'}
                margin={'48px 0 0 0'}
                maxWidth={'400px'}
              >
                The Intranet to End All Intranets (hopefully, b/c we don't want
                to build this again).
              </Text>
              <SignIn
                type="button"
                onClick={() =>
                  // provider: CognitoHostedUIIdentityProvider.Google,
                  Auth.federatedSignIn({
                    customProvider: 'SAML',
                  })
                }
              >
                Login with Google <IconArrow />
              </SignIn>
            </SignInContent>
          </SignInView>
        ) : (
          <NavLinksContext.Provider value={{ navLinks }}>
            <Container
              className={classNames('body-container', {
                'is-touch': isTouch,
              })}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
            >
              <Helmet>
                <title>Intranet Home</title>
                <meta
                  name="description"
                  content="The Intranet to End All Intranets."
                />
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
                />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link
                  href="https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;700&display=swap"
                  rel="stylesheet"
                />
              </Helmet>
              <Router>
                {features.navV2 ? (
                  <NavigationView2
                    data={navLinks}
                    user={{
                      name: user?.attributes.name
                        ? user?.attributes.name
                        : `${user?.attributes.given_name} ${
                            user?.attributes.family_name.charAt(0) + '.'
                          }`,
                      email: user?.attributes.email,
                      picture: user?.attributes.picture,
                      office: user?.office?.name || '',
                    }}
                    signOut={() => signOut()}
                  />
                ) : (
                  <NavigationView
                    data={navLinks}
                    user={{
                      name: user?.attributes.name
                        ? user?.attributes.name
                        : `${user?.attributes.given_name} ${user?.attributes.family_name}`,
                      email: user?.attributes.email,
                      picture: user?.attributes.picture,
                      office: user?.office?.name || '',
                    }}
                    signOut={() => signOut()}
                  />
                )}
                <MobileNavigationView
                  data={navLinks}
                  user={{
                    name: user?.attributes.name
                      ? user?.attributes.name
                      : `${user?.attributes.given_name} ${user?.attributes.family_name}`,
                    email: user?.attributes.email,
                    picture: user?.attributes.picture,
                    office: user?.office?.name || '',
                  }}
                  signOut={() => signOut()}
                />
                <PrintLogo>
                  <IconLogoMobile />
                </PrintLogo>
                <BackToTopView />
                <Content className="body-content">
                  <div id="top-body-content" />
                  <Route path="/" exact>
                    <Home
                      user={{
                        timezone: user?.office?.timezone || 'PST',
                      }}
                    />
                  </Route>
                  {features.devWiki && (
                    <>
                      <Route path="/dev-wiki" exact>
                        <WikiHome />
                      </Route>
                      <Route
                        path="/dev-wiki/:id"
                        exact
                        render={(props) => <WikiPage {...props} />}
                      />
                    </>
                  )}
                  {features.lunchTable && (
                    <Route
                      path="/lunch-table"
                      exact
                      render={(props) => <LunchTable />}
                    />
                  )}
                  {features.riddle && (
                    <Route
                      path="/riddle-griddle"
                      exact
                      render={(props) => <RiddleGriddle />}
                    />
                  )}
                  {navLinks.map((item: NavigationI) => {
                    const subRoutes = item?.subItems?.map((sub: SubLinkI) => {
                      return (
                        <Route key={sub.id} path={`/${item.slug}/${sub.slug}`}>
                          {handleComponents(
                            sub?.layout || 'plain',
                            user,
                            sub.slug
                          )}
                        </Route>
                      );
                    });
                    return (
                      <Switch key={item.id}>
                        <Route
                          exact
                          path={`/${item.slug}`}
                          render={() =>
                            handleComponents(
                              item?.layout || 'main',
                              user,
                              item.slug
                            )
                          }
                        />
                        {subRoutes}
                      </Switch>
                    );
                  })}
                  <Route path="/logout">
                    <Logout signOut={signOut} />
                  </Route>
                  <Route path="/404">
                    <NotFoundView />
                  </Route>
                  <Route path="*">
                    <Redirect navLinks={navLinks} />
                  </Route>
                  <FooterView
                    names={listMembersData?.listMembers?.items || []}
                  />
                </Content>
              </Router>
            </Container>
          </NavLinksContext.Provider>
        )}
      </>
    );
  }, [
    loader,
    user,
    pageReady,
    navLinks,
    isTouch,
    features.devWiki,
    features.lunchTable,
    features.riddle,
    listMembersData?.listMembers?.items,
    handleComponents,
    features.navV2,
  ]);
}

export default App;
