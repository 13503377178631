export const listQuicklinksByPageId = /* GraphQL */ `
  query ListQuicklinksByPageId(
    $pageId: ID
    $sort: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuickLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuicklinksByPageId(
      pageId: $pageId
      sort: $sort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pageId
        sort
        title
        body
        linkTitle
        externalLink
        linkId
        link {
          id
          slug
          status
          parentPage {
            id
            slug
            status
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
