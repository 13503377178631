import { eyebrowButton, resetButtonStyles } from 'shared/extends';
import { colors, customBezierEase } from 'shared/variables';
import styled, { css } from 'styled-components';

export const IconWrapper = styled.div`
  display: inline-block;
  padding: 11px 18px;
  font-size: 0;
  line-height: 0;
  color: currentColor;
  transition: all 0.2s ${customBezierEase};
  vertical-align: sub;
  svg {
    transition: fill 0.2s ${customBezierEase};
    fill: currentColor;
  }
`;

export const Label = styled.span<{ hasIcon?: boolean }>`
  ${eyebrowButton};
  display: inline-block;
  color: ${colors.white};
  /* padding: 15px 38px; */
  /* padding: ${(props) =>
    props.hasIcon ? '15px 38px 15px 0px' : '15px 38px'}; */
  letter-spacing: 0px;
  transition: color 0.3s ease;
`;

const Primary = css`
  background: ${colors.primary};
  color: ${colors.white};

  &[data-touchable='false']:hover {
    background: ${colors.highlight};
  }
`;

const Secondary = css`
  background: transparent;
  border: 1px solid ${colors.primary};
  color: ${colors.primary};

  &[data-touchable='false']:hover {
    color: ${colors.highlight};
    border: 1px solid ${colors.highlight};
    background: transparent;

    ${Label} {
      color: ${colors.highlight};
    }
  }

  ${Label} {
    color: ${colors.primary};
  }
`;

const Tertiary = css`
  border: none;
  background: transparent;
  color: ${colors.primary};

  &[data-touchable='false']:hover {
    color: ${colors.highlight};
    background: transparent;
    border: none;

    ${Label} {
      color: ${colors.highlight};
    }
  }

  ${Label} {
    color: ${colors.primary};
  }
`;

interface IButton {
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  delete?: boolean;
  disabled?: boolean;
}

export const StyledButton = styled.button<IButton>`
  ${resetButtonStyles};
  ${eyebrowButton};
  color: white;
  background: ${colors.primary};
  border: 1px solid transparent;
  text-align: center;
  text-transform: uppercase;
  border-radius: none;
  padding: 15px 38px;
  display: inline-block;
  transition: all 0.3s ${customBezierEase};
  text-decoration: none;
  cursor: pointer;

  &[data-touchable='false']:hover {
    background: ${colors.highlight};
  }

  &:focus {
    transition: none;
    outline: 2px solid -webkit-focus-ring-color;
  }

  ${(props) => props.primary && Primary}
  ${(props) => props.secondary && Secondary}
  ${(props) => props.tertiary && Tertiary}
`;
