import styled from 'styled-components';
import { size } from '../../../../shared/mixins';
import { colors, customBezierEase } from '../../../../shared/variables';
import { font, fontSize } from '../../../../shared/typography';
import { resetButtonStyles, bodyCopySmall } from '../../../../shared/extends';

export const Container = styled.div`
  position: relative;
`;

export const TabContainer = styled.div`
  border-bottom: 1px solid ${colors.greyLightLight};
  position: relative;
  display: flex;

  &.hide {
    display: none;
  }
`;

export const Tab = styled.button<{ active: boolean }>`
  align-items: center;
  background: transparent;
  color: ${colors.primary};
  border: 0;
  cursor: pointer;
  display: flex;
  margin: 0 50px 0 0;
  padding: 0 0 2px;
  text-transform: uppercase;
  transition: 0.5s ${customBezierEase};
  position: relative;
  white-space: nowrap;
  z-index: 1;
  ${font('secondary', 'black')};
  ${fontSize(12, 20)};

  &.hide {
    display: none;
  }

  &:last-child {
    margin: 0;
  }

  &:hover {
    ${'' /* border-color: ${colors.brownLight}; */}
    color: ${colors.brownLight};

    > svg {
      fill: ${colors.brownLight};
    }
  }

  &:focus {
    outline: none;
  }

  &:after {
    background: transparent;
    content: '';
    display: block;
    top: calc(100% + 10px);
    left: 0px;
    transition: 0.3s ${customBezierEase};
    position: absolute;
    ${size('100%', '3px')}
  }

  > svg {
    fill: ${colors.primary};
    margin-left: 6px;
    pointer-events: none;
    transition: 0.2s ${customBezierEase};
    ${size('8px', '6px')};
  }

  ${(props) =>
    props.active &&
    `
    color: ${colors.brownLight};

    > svg {
      fill: ${colors.brownLight};
      transform: rotate(180deg);
    }
    `}
`;

export const Dropdown = styled.div`
  background: ${colors.white};
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.05);
  left: -16px;
  max-height: 340px;
  padding: 46px 16px 0;
  position: absolute;
  top: -24px;
  width: 120px;
  display: flex;
  flex-direction: column;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ${customBezierEase};

  &.show {
    opacity: 1;
    pointer-events: all;
  }
`;

export const DropdownContent = styled.div`
  overflow-y: auto;
  flex: 1 1 auto;
  border-top: 1px solid ${colors.greyLightLight};
`;

export const YearButton = styled.button`
  ${resetButtonStyles};
  ${bodyCopySmall};
  align-items: center;
  display: flex;
  margin: 16px 0;
  width: 100%;
  transition: color 0.2s ${customBezierEase};

  svg {
    fill: ${colors.brownLight};
    margin-left: 6px;
    transition: fill 0.2s ${customBezierEase};
    ${size('10px', '8px')};
  }

  &:hover {
    color: ${colors.primary};

    svg {
      fill: ${colors.primary};
    }
  }
`;
