import Button from 'components/Button/Button';
import { resetButtonStyles } from 'shared/extends';
import { size } from 'shared/mixins';
import { colors, padding } from 'shared/variables';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 ${padding.desktop}px 100px;
`;

export const Table = styled.div`
  background: ${colors.white};
  display: grid;
  margin: 100px auto;
`;

export const EditButton = styled.button`
  ${resetButtonStyles};
  pointer-events: none;
  opacity: 0;

  svg {
    fill: ${colors.brownLightLight};
    ${size('12px', '12px')};
  }
`;

export const Cell = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    color: ${colors.brownMedium};
  }
`;

export const Row = styled.div`
  display: grid;
  column-gap: 16px;
  grid-template-columns: repeat(2, 1fr) 100px repeat(3, 1fr);
  padding: 15px 30px;

  &:nth-child(2n + 1):not(:first-child) {
    background-color: #f8f8f8;
  }

  &:hover {
    ${EditButton} {
      pointer-events: all;
      opacity: 1;
    }
  }
`;

export const CalendarContainer = styled.div`
  position: relative;
  .calendar {
    background-color: white;
    padding: 16px;
    position: absolute;
  }
`;

export const Header = styled(Row)`
  background-color: ${colors.brownMedium};
  color: ${colors.white};
`;

export const Form = styled.form`
  width: 320px;
  display: grid;
  grid-row-gap: 32px;

  .open:not(.modal-calendar) {
    z-index: 1;
    position: relative;
  }
`;

export const AddButton = styled(Button)``;
