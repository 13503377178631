import IntroView from 'components/IntroView';
import PTOView from 'components/PTOView';
import * as localQueries from './queries';
import * as queries from 'utils/queries';
import React, { useMemo } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { UserI } from 'data/types/User.types';
import HolidaysI, { YearI } from 'data/types/Holidays.types';
import { Helmet } from 'react-helmet';
import LoaderView from 'components/LoaderView';

const LIST_YEARS = gql(localQueries.listYears);
const GET_PAGE_BY_SLUG = gql(queries.getPageBySlug);

interface Props {
  user: UserI;
  slug: string;
}

function PTO({ user, slug }: Props): JSX.Element {
  const { data: listData, loading: holidayLoading } = useQuery(LIST_YEARS);

  const { data: slugData, loading: slugLoading } = useQuery(GET_PAGE_BY_SLUG, {
    variables: {
      slug,
    },
  });

  const yearData = useMemo(() => {
    return listData?.listYears.items;
  }, [listData]);

  const holidays = useMemo(() => {
    const tempHolidays: HolidaysI[] = [];
    if (yearData) {
      yearData.map((year: YearI) => {
        if (year?.holidays?.items) {
          year.holidays.items.map((holiday: HolidaysI) => {
            tempHolidays.push(holiday);
          });
        }
      });
    }
    return tempHolidays;
  }, [yearData]);

  const pageData = useMemo(() => {
    return slugData?.getPageBySlug.items[0];
  }, [slugData]);

  if (slugLoading || holidayLoading) {
    return <LoaderView />;
  }

  return (
    <>
      <Helmet>
        <title>{pageData?.title}</title>
        <meta name="description" content={pageData?.description || ''} />
      </Helmet>
      <IntroView type="subpage" data={pageData} />
      <PTOView holidays={holidays} user={user} />
    </>
  );
}

export default PTO;
