import Tabs from 'components/Tabs';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Container, Maps } from './styles';
import PageI from 'data/types/Page.types';
import { Office } from 'API';

interface IUser {
  office: {
    active: boolean;
    group: string;
    id: string;
    name: string;
    sort: number;
    timezone: string;
  };
}

interface Props {
  data: PageI[];
  officeData: Office[];
  user?: IUser;
}

const LunchSpotsView2 = ({ data, officeData, user }: Props): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [activeOfficesData, setActiveOfficesData] = useState<Office[]>([]);
  const El = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const correspondingOffice = activeOfficesData.filter(
      (item: Office) => user?.office?.id === item.id
    );
    setSelectedTab(correspondingOffice[0]?.sort || 0);
  }, [officeData, user, activeOfficesData]);

  const tabsData = useMemo(() => {
    if (!officeData || !data) {
      return [];
    }
    console.log(officeData);
    const activeOffices = officeData
      .slice()
      .filter((office: Office) => {
        return office.active === true;
      })
      .sort((a: Office, b: Office) => {
        return (a.sort || 0) - (b.sort || 0);
      });
    setActiveOfficesData([...activeOffices]);
    const activeOfficesFiltered: any = [];
    activeOffices.forEach((item: Office) => {
      const page = data.find((x) => x.officeId === item.id);
      if (page) {
        const map = JSON.parse(page?.meta || '');
        activeOfficesFiltered.push({
          label: item.name || '',
          map: map?.map ? map?.map : null,
        });
      } else if (!page) {
        activeOfficesFiltered.push({
          label: item.name || '',
          map: null,
        });
      }
      return false;
    });
    return activeOfficesFiltered;
  }, [data, officeData]);

  return (
    <Container ref={El}>
      <Tabs
        tabs={tabsData || []}
        returnActive={(selected) => setSelectedTab(selected)}
        defaultActive={tabsData[selectedTab].label}
      />
      <Maps>
        {tabsData[selectedTab].map ? (
          <iframe src={tabsData[selectedTab].map}></iframe>
        ) : (
          <span>
            The Lunch Map is not currently available for this office. Please
            contact your administrator.
          </span>
        )}
      </Maps>
    </Container>
  );
};

export default LunchSpotsView2;
