import IntroView from 'components/IntroView';
import LoaderView from 'components/LoaderView';
import TemplateView from 'components/TemplateView';
import React, { useMemo } from 'react';
import * as queries from './queries';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet';

interface IUser {
  office: {
    active: boolean;
    group: string;
    id: string;
    name: string;
    sort: number;
    timezone: string;
  };
}

interface ITemplate {
  slug: string;
  user: IUser;
}

const GET_PAGE_BY_SLUG = gql(queries.getPageBySlug);

const Template = ({ slug, user }: ITemplate): JSX.Element => {
  const { data: slugData, loading: slugLoading } = useQuery(GET_PAGE_BY_SLUG, {
    variables: {
      slug,
    },
  });

  const pageData = useMemo(() => {
    return slugData?.getPageBySlug.items[0];
  }, [slugData]);

  if (slugLoading) {
    return <LoaderView />;
  }

  return (
    <>
      <Helmet>
        <title>{pageData?.title}</title>
        <meta name="description" content={pageData?.description || ''} />
      </Helmet>
      <IntroView type="subpage" data={pageData} />
      <TemplateView
        type={pageData?.layout}
        data={pageData?.pages.items}
        user={user}
      />
    </>
  );
};

export default Template;
