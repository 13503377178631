import React, { useMemo } from 'react';
import IntroView from 'components/IntroView';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import * as localQueries from './queries';
import * as queries from 'utils/queries';
import FAQView from 'components/FAQView';
import { Helmet } from 'react-helmet';
import { FAQsection, ListOfficesQuery } from 'API';
import { listOffices } from 'graphql/queries';
import { FAQSectionI } from 'data/types/Faq.types';

const LIST_OFFICES = gql(listOffices);
const GET_PAGE_BY_SLUG = gql(queries.getPageBySlug);
const LIST_FAQ_SECTIONS = gql(localQueries.listFAQSections);

interface ListOfficesQueryI {
  data: ListOfficesQuery | undefined;
  loading: boolean;
}

const FAQ = ({ slug }: { slug: string }): JSX.Element => {
  const { data: listOfficesData, loading }: ListOfficesQueryI =
    useQuery(LIST_OFFICES);

  // GET FAQ SECTIONS AND ASSOCIATED FAQS
  const { data: faqSections, loading: faqSectionsLoading } =
    useQuery(LIST_FAQ_SECTIONS);

  const faqViewData = useMemo(() => {
    console.log('listOfficesData:', listOfficesData);
    console.log('faqSections: ', faqSections);
    if (
      listOfficesData?.listOffices?.items?.length &&
      listOfficesData?.listOffices?.items?.length > 0 &&
      faqSections?.listFAQsections?.items?.length > 0
    ) {
      // GET SECTION AND FAQ FORMATTED DATA
      const formattedData: FAQSectionI[] =
        faqSections.listFAQsections.items.map(
          ({ id, sort, title, createdAt, updatedAt, faqs }: FAQsection) => {
            const formattedFaqs = faqs?.items?.map(
              ({ id: faqID, answer, officeIDs, question, sort: faqSort }) => ({
                id: faqID,
                answer,
                officeIDs,
                question,
                sort: faqSort,
              })
            );

            return {
              id,
              sort,
              title,
              createdAt,
              updatedAt,
              faqs: formattedFaqs,
            };
          }
        );
      const officeList = listOfficesData.listOffices.items.filter(
        (office) => office.active
      );
      return { formattedData, officeList };
    }
    return;
  }, [listOfficesData, faqSections]);

  const { data: slugData } = useQuery(GET_PAGE_BY_SLUG, {
    variables: {
      slug,
    },
  });

  const pageData = useMemo(() => {
    return slugData?.getPageBySlug.items[0];
  }, [slugData]);

  return (
    <>
      <Helmet>
        <title>{pageData?.title}</title>
        <meta name="description" content={pageData?.description || ''} />
      </Helmet>
      <IntroView type="page" data={pageData} />
      {!loading && !faqSectionsLoading && faqViewData?.officeList && (
        <FAQView
          faqSections={faqViewData.formattedData}
          offices={faqViewData.officeList}
        />
      )}
    </>
  );
};

export default FAQ;
