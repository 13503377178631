import classNames from 'classnames';
import { IconAdd, IconSimpleArrow, IconTrash } from 'components/IconsView';
import PageI from 'data/types/Page.types';
import React, { useState } from 'react';
import PageList from '../PageList';
import { Action, Container, ItemTop, PageLink } from './styles';
interface Props {
  data: PageI;
  createPage: (pageId: string, sort: number) => void;
  deletePage: (id: string) => void;
  refetch: () => void;
}

const PageItem = ({
  data,
  createPage,
  deletePage,
  refetch,
}: Props): JSX.Element => {
  const [openDrawer, setOpenDrawer] = useState(false);
  console.log(data);

  return (
    <Container>
      <ItemTop>
        <Action
          type="button"
          className={classNames('icon-arrow', {
            active: openDrawer,
            disabled: (data?.pages?.items?.length || 0) === 0,
          })}
          onClick={() => setOpenDrawer((prev) => !prev)}
        >
          <IconSimpleArrow />
        </Action>
        <PageLink to={`/dev-wiki/${data.id}`}>{data.title}</PageLink>
        <Action
          type="button"
          className="icon-add"
          onClick={() => {
            createPage(data.id, data?.pages?.items?.length || 0);
          }}
        >
          <IconAdd />
        </Action>
        <Action
          type="button"
          className="icon-trash"
          onClick={() => {
            deletePage(data.id);
          }}
        >
          <IconTrash />
        </Action>
      </ItemTop>
      {data?.pages?.items && data?.pages?.items?.length > 0 ? (
        <PageList
          pageData={data.pages.items}
          createPage={createPage}
          refetch={refetch}
          showDrawer={openDrawer}
        />
      ) : (
        ''
      )}
    </Container>
  );
};

export default PageItem;
