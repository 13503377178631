import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Container, TextArea, TextareaContent } from './styles';
import { Label } from 'components/FormComponents/styles';
import classNames from 'classnames';

interface Props {
  name: string;
  className?: string;
  label?: string;
  value?: string;
  required?: boolean;
  placeholder?: string;
  error?: boolean;
  errorLimited?: boolean;
  optional?: boolean;
  onChange?: (value: string) => void;
  description?: string;
}

const TextAreaView = ({
  className,
  name,
  label,
  value,
  required,
  placeholder,
  optional,
  error,
  errorLimited,
  onChange,
  description,
}: Props): JSX.Element => {
  const { register, setValue, clearErrors } = useFormContext();
  const [val, setVal] = useState(value || '');

  useEffect(() => {
    if (val !== value) {
      setVal(value || '');
    }
    if (value) {
      setValue(name, value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (register) {
      register(name, { required });
    }
    if (value) {
      setValue(name, value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register, name]);

  const onValChange = useCallback(
    (e) => {
      const v = e.target.value;
      setVal(v);
      setValue(name, v);
      if (onChange) {
        onChange(v);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, setValue]
  );
  const onValBlur = useCallback(
    () => {
      if (val && (errorLimited || error)) {
        clearErrors(name);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, setValue, val]
  );

  return (
    <Container className={`form-textarea ${className}`}>
      {label && (
        <Label htmlFor={name} className={error ? 'error' : ''}>
          {label}
          {optional && <span className="optional">Optional</span>}
        </Label>
      )}
      <TextareaContent>
        <TextArea
          id={name}
          data-testid={name}
          name={name}
          value={val}
          onChange={onValChange}
          className={classNames({ error: error, errorLimited: errorLimited })}
          placeholder={!errorLimited ? placeholder : ''}
          aria-label={label}
          onBlur={onValBlur}
        />
        {description && <p className="description">{description}</p>}
        {/* {errorLimited && <IconWarning />} */}
      </TextareaContent>
    </Container>
  );
};

export default TextAreaView;
