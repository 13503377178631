import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { colors, padding, customBezierEase } from '../../shared/variables';
import { font, fontSize } from '../../shared/typography';
import { size, mq } from '../../shared/mixins';

export const Hamburger2 = styled.button`
  background: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  padding: 0;
  outline: 0;

  path {
    fill: ${colors.highlight};
  }
  svg.icon-x {
    ${size('11px')};
  }
`;

export const MainLink2 = styled(NavLink)`
  ${font('secondaryNav')};
  ${fontSize(16, 20)};
  align-items: center;
  color: #4d453d;
  display: flex;
  text-decoration: none;
  transition: 0.3s ${customBezierEase};
  -webkit-font-smoothing: auto;

  &.active {
    color: ${colors.primary};
    pointer-events: none;

    svg {
      transform: rotate(-90deg);
      fill: ${colors.primary};
    }
  }

  &:not(.active):hover {
    color: ${colors.highlight};

    svg {
      fill: ${colors.highlight};
    }
  }
`;

export const MainItem2 = styled.div`
  align-items: center;
  display: flex;
`;

export const SubLink2 = styled(MainLink2)`
  margin: 16px 0;
  display: flex;
  align-items: center;
  padding-left: 24px;
  position: relative;

  &:last-child {
    margin-bottom: 0px;

    &:after {
      height: 50%;
    }
  }

  &:after {
    // left vertical line
    background: ${colors.grey};
    content: '';
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    ${size('1px', 'calc(100% + 16px)')}
  }

  &:before {
    // left horizontal line
    background: ${colors.grey};
    content: '';
    display: block;
    left: 0;
    position: absolute;
    top: 9px;
    ${size('16px', '1px')}
  }
`;

export const UserContainer2 = styled.div`
  padding: 0 ${padding.tablet}px;
  justify-content: space-between;
  text-align: right;
  align-items: center;
  margin-left: -${padding.tablet}px;
  margin-right: -${padding.tablet}px;
  ${size('auto', '55px')}

  .sign-out {
    display: block;
    color: ${colors.highlight};
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: underline;
    ${font('secondary')};
    ${fontSize(10, 20, 1.4)};
  }

  ${mq.phoneWide`
    padding: 0 ${padding.mobile}px;
    margin-left: -${padding.mobile}px;
    margin-right: -17px;
  `}
`;

export const Title2 = styled.span`
  color: ${colors.highlight};
  text-align: center;
  position: absolute;
  top: 23px;
  left: 70px;
  transform: translate(-50%, -50%);
  ${font('secondary', 'bold')};
  ${fontSize(10, 20, 3.6)};
`;

export const SearchWrap = styled.div`
  height: 82px;
  width: 375px;
  border-bottom: 2px solid ${colors.highlight};
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1200;

  .close-btn {
    position: absolute;
    z-index: 2000;
    left: 330px;
    top: 26px;
    padding: 0;
    background: none;
    border: 0;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .clear-btn {
    position: absolute;
    left: 300px;
    top: 42px;
    padding: 0;
    background: none;
    border: 0;
    cursor: pointer;
  }

  path {
    fill: ${colors.highlight};
  }
  svg.icon-x {
    ${size('11px')};
  }

  span {
    color: ${colors.greyMediumMedium};
    z-index: 1200;
    letter-spacing: 1px;
    -webkit-font-smoothing: antialiased;
  }
`;

export const BurgerButton = styled.button`
  padding: 0;
  background: none;
  width: 50px;
  height: 50px;
  border: 0;
  position: relative;
  /* left: -20px; */
`;
