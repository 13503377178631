import { IconArrow } from 'components/IconsView';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Container,
  Date,
  Eyebrow,
  Grid,
  Header,
  LatestPost,
  SlideNotif,
  Square,
  VideoNotif,
} from './styles';
import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

// interface IProps {}
interface InstagramI {
  id: string;
  media_type: string;
  media_url: string;
  timestamp: string;
  permalink: string;
  thumbnail_url: string;
}

const InstagramFeed = (/*{}: IProps*/): JSX.Element => {
  const [feed, setFeed] = useState<InstagramI[]>([]);

  useEffect(() => {
    const getInstaFeed = async () => {
      axios
        .get(
          `https://graph.instagram.com/me/media?fields=id,media_type,media_url,thumbnail_url,permalink,timestamp&limit=7&access_token=IGQWRNeDM1ZAkVpOXVHZAU01eVFpUjUzSkVaU0c3c3Q5YlR2V2RadmZA2YVBzTGI5YzZArZAmFjZAmI3c2t4OURwbnpzMW5uLUFHSEtKempJZAXgySXhoQkN5Q3g5Mk5qZAXIzckRtc1hXZAmJoS0haWlN3QmhVcUVZAMGRsVlEZD`
        )
        .then((resp) => {
          setFeed(resp.data.data);
        });
    };
    getInstaFeed();
  }, []);

  const renderFeed = useCallback(() => {
    if (!feed) return <></>;
    return feed.map((item, i) => {
      return (
        <Square
          key={item.id}
          href={item.permalink}
          target="__blank"
          style={{
            backgroundImage: `url(${
              item.media_type === 'VIDEO' ? item.thumbnail_url : item.media_url
            })`,
          }}
        >
          {i === 0 ? <LatestPost>Latest Post</LatestPost> : ''}
          <Date>
            {i === 0
              ? dayjs(item.timestamp).fromNow()
              : dayjs(item.timestamp).format('MMMM D')}
          </Date>
          {item.media_type === 'CAROUSEL_ALBUM' && <SlideNotif />}
          {item.media_type === 'VIDEO' && <VideoNotif />}
        </Square>
      );
    });
  }, [feed]);

  return (
    <Container>
      <Header>
        <Eyebrow target="__blank" href="https://www.instagram.com/fiveanddone/">
          <span>
            <span>Five & Done's Instagram</span>
          </span>
          <IconArrow />
        </Eyebrow>
      </Header>
      <Grid>{renderFeed()}</Grid>
    </Container>
  );
};

export default InstagramFeed;
