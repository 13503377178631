import { IconCheckmark } from 'components/IconsView';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import slugify from 'slugify';
import { Checkbox, CheckboxLabel, Container } from './styles';

interface Props {
  name: string;
  className?: string;
  label?: string;
  value?: boolean;
  required?: boolean;
  onChange?: (name: string, value: boolean) => void;
  error?: boolean;
  disabled?: boolean;
}

const CheckboxView = ({
  className,
  name,
  label,
  value,
  required,
  onChange,
  error,
  disabled,
}: Props): JSX.Element => {
  const { register, setValue } = useFormContext();
  const [val, setVal] = useState(value || false);

  useEffect(() => {
    if (val !== value) {
      setVal(value || false);
    }
    if (value) {
      setValue(name, value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (register) {
      register(name, { required });
    }
    if (value) {
      setValue(name, value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register, name]);

  const onValChange = useCallback(
    (e) => {
      const checked = e.target.checked;
      if (onChange) {
        onChange(name, checked);
      }
      setVal(checked);
      setValue(name, checked);
    },
    [name, setValue, onChange]
  );

  const checkboxName = useMemo(() => slugify(name).toLowerCase(), [name]);

  return (
    <Container className={`form-checkbox ${className} ${error && 'errors'}`}>
      <CheckboxLabel htmlFor={checkboxName}>
        <Checkbox
          type="checkbox"
          id={checkboxName}
          data-testid={checkboxName}
          name={name}
          onChange={onValChange}
          defaultChecked={value}
          disabled={disabled}
        />
        <div>
          <IconCheckmark />
        </div>
        <span>{label}</span>
      </CheckboxLabel>
    </Container>
  );
};

export default CheckboxView;
