export const listYears = /* GraphQL */ `
  query ListYears(
    $filter: ModelYearFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listYears(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        sort
        date
        createdAt
        updatedAt
        holidays {
          items {
            id
            title
            createdAt
            startDate
            endDate
            disclaimer
            holidayType
          }
        }
      }
      nextToken
    }
  }
`;
