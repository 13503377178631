import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  BadUploads,
  ClearError,
  Container,
  Content,
  Dropzone,
  RemoveUpload,
  Upload,
  UploadButton,
  UploadError,
  UploadList,
  Uploads,
} from './styles';
import { Label } from 'components/FormComponents/styles';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import {
  IconFileJPG,
  IconFilePDF,
  IconFilePNG,
  IconUpload,
  IconWarning,
  IconX,
} from 'components/IconsView';
import { formatBytes } from 'utils/functions';
import { breakpoints } from 'shared/variables';
import { debounce } from 'utils/functions';

interface Props {
  name: string;
  className?: string;
  label?: string;
  dropzoneLabel?: string;
  dropzoneDescriptor?: string;
  value?: MediaI[];
  error?: boolean;
  fileTypes?: string;
  disabled?: boolean;
  optional?: boolean;
  required?: boolean;
  errorMessages?: {
    [value: string]: string;
  };
  fileMaxSize?: number;
}

interface MediaI {
  filename: string;
  filetype: string;
  filesize: number;
  file: File;
}

interface BadMediaI {
  filename: string;
  filetype: string;
  error: string;
  code: string;
}

interface DropRejectsI {
  errors: DropErrorsI[];
  file: File;
}

interface DropErrorsI {
  code: string;
  message: string;
}

const UploadView = ({
  name,
  className,
  label,
  value,
  dropzoneDescriptor,
  required,
  optional,
  dropzoneLabel,
  fileTypes,
  error,
  errorMessages,
  fileMaxSize,
}: Props): JSX.Element => {
  const { register, setValue, clearErrors } = useFormContext();
  const [media, setMedia] = useState<MediaI[]>([]);
  const [badMedia, setBadMedia] = useState<BadMediaI[]>([]);
  const [isTablet, setIsTablet] = useState(false);
  const [isPhoneWide, setIsPhoneWide] = useState(false);

  useEffect(() => {
    setIsTablet(window.innerWidth < breakpoints.tablet);
    setIsPhoneWide(window.innerWidth < breakpoints.phoneWide);

    window.addEventListener(
      'resize',
      debounce(function () {
        setIsTablet(window.innerWidth < breakpoints.tablet);
        setIsPhoneWide(window.innerWidth < breakpoints.phoneWide);
      })
    );

    return () => {
      window.addEventListener(
        'resize',
        debounce(function () {
          setIsTablet(window.innerWidth < breakpoints.tablet);
          setIsPhoneWide(window.innerWidth < breakpoints.phoneWide);
        })
      );
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (media !== value) {
      setMedia(value || []);
    }

    if (value) {
      setValue(name, value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (register) {
      register(name, { required });
    }
    if (value) {
      setValue(name, value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register, name]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      console.log('acceptedFiles', acceptedFiles);
      const temp = [...media];
      acceptedFiles.map((file: File) => {
        let counter = 0;
        //run through the other files and check if the name is the same
        temp.map((item) => {
          const original = item.filename;
          const duplicate = item.filename;
          const duplicateOGName = duplicate.split(' ')[0];
          const duplicateOGType = duplicate.split('.')[1];
          const currentName = file.name.split('.')[0];
          const currentType = file.name.split('.')[1];
          if (original === file.name) {
            //this means theres a duplicate file
            // add one to the counter
            counter++;
          }
          //check if there were previous dupes ie file (1).jpg
          //have to continue the if train otherwise the original file will be counted as a duplicate
          else if (
            duplicateOGName === currentName &&
            duplicateOGType === currentType
          ) {
            counter++;
          }
        });
        const filename =
          counter > 0
            ? `${file.name.split('.')[0]} (${counter}).${
                file.name.split('.')[1]
              }`
            : file.name;
        temp.push({
          filename: filename,
          filetype: file.type,
          filesize: file.size,
          file,
        });
      });
      setMedia(temp);
      setValue(name, temp);
      if (error) {
        clearErrors(name);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [media, error]
  );

  const onDropRejected = (e: DropRejectsI[]) => {
    console.log('onDropRejected', e);
    const newErrors: BadMediaI[] = [];
    e.map((item: DropRejectsI) => {
      newErrors.push({
        filename: item.file.name,
        filetype: item.file.type,
        error: item.errors[0].message,
        code: item.errors[0].code,
      });
    });

    setBadMedia((prev) => [...prev, ...newErrors]);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: fileTypes || 'images/*, videos/*',
    onDrop,
    multiple: true,
    onDropRejected,
    maxSize: fileMaxSize || Infinity,
  });

  const handleRemoveMedia = (index: number) => {
    const temp = [...media];
    temp.splice(index, 1);
    setMedia(temp);
    setValue(name, temp);
  };

  return (
    <Container className={classNames(`form-upload ${className}`)}>
      {label && (
        <Label htmlFor={name} className={classNames({ error: error })}>
          {label}
          {optional && <span className="optional">Optional</span>}
        </Label>
      )}
      <Dropzone {...getRootProps()} className={classNames({ error: error })}>
        <input {...getInputProps()} data-testid={name} />
        <Content>
          {!isTablet && <IconUpload />}
          <span className="label">{dropzoneLabel || 'Drop files here'}</span>
          <span className="desc">
            {dropzoneDescriptor || 'Images are allowed'}
          </span>
          {isTablet && (
            <UploadButton type="button" onClick={open}>
              Select Files
            </UploadButton>
          )}
        </Content>
      </Dropzone>
      {badMedia && badMedia.length > 0 && (
        <BadUploads>
          {badMedia.map((m, index) => (
            <UploadError key={`bad-upload-${index}`}>
              {isPhoneWide ? (
                <>
                  <IconWarning />
                  <div>
                    {m.filename} {'  -  '}
                    <span>
                      {m.code === 'file-too-large'
                        ? `Must be ${formatBytes(fileMaxSize || 0)} or smaller`
                        : (errorMessages && errorMessages[m.code]) || m.error}
                    </span>
                  </div>
                  <ClearError
                    type="button"
                    aria-label="clear error"
                    data-testid={'clear-error'}
                    onClick={() =>
                      setBadMedia((prev) => {
                        const temp = [...prev];
                        temp.splice(index, 1);
                        return temp;
                      })
                    }
                  >
                    <IconX />
                  </ClearError>
                </>
              ) : (
                <>
                  <IconWarning />
                  {m.filename} {'  -  '}
                  <span>
                    {m.code === 'file-too-large'
                      ? `Must be ${formatBytes(fileMaxSize || 0)} or smaller`
                      : (errorMessages && errorMessages[m.code]) || m.error}
                  </span>
                  <ClearError
                    type="button"
                    aria-label="clear error"
                    data-testid={'clear-error'}
                    onClick={() =>
                      setBadMedia((prev) => {
                        const temp = [...prev];
                        temp.splice(index, 1);
                        return temp;
                      })
                    }
                  >
                    <IconX />
                  </ClearError>
                </>
              )}
            </UploadError>
          ))}
        </BadUploads>
      )}
      {media && media.length > 0 && (
        <Uploads>
          <Label>Uploads</Label>
          <UploadList>
            {media.map((m, i) => (
              <Upload key={i}>
                {(m.filetype === 'image/jpg' ||
                  m.filetype === 'image/jpeg') && <IconFileJPG />}
                {m.filetype === 'image/png' && <IconFilePNG />}
                {m.filetype === 'application/pdf' && <IconFilePDF />}
                <div className="detail">
                  <span>
                    <span className="name">{m.filename}</span>
                    <span className="size">{formatBytes(m.filesize)}</span>
                  </span>
                  <RemoveUpload
                    type="button"
                    onClick={() => handleRemoveMedia(i)}
                  >
                    Remove
                  </RemoveUpload>
                </div>
              </Upload>
            ))}
          </UploadList>
        </Uploads>
      )}
    </Container>
  );
};

export default UploadView;
