import styled, { keyframes } from 'styled-components';
import { NavLink, Link } from 'react-router-dom';
import { size, mq } from '../../shared/mixins';
import { colors, customBezierEase } from '../../shared/variables';
import { font, fontSize } from '../../shared/typography';
import { eyebrow } from '../../shared/extends';
import { IconBlob } from 'components/IconsView';

export const Container = styled.nav`
  width: 233px;
  min-width: 233px;
  min-height: 100vh;
  border-right: 1px solid ${colors.grey};
  max-height: 100vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  ${mq.tablet`
    display: none;
  `}
`;

export const Content = styled.div`
  padding: 40px 34px;
  position: relative;
`;

export const LogoHoverWrapper = styled.div`
  visibility: hidden;
  position: absolute;
  left: 7px;
  top: 20px;

  svg {
    overflow: visible;
    ${size('103px', '103px')}
    stroke: ${colors.highlight};
  }
`;

export const BlobContainer = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  left: 50%;
  top: 50%;
  opacity: 1;
  position: absolute;
  pointer-events: none;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.75s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 10;
  ${size('250px')}

  &.is-active {
    transform: translate(-50%, -50%) scale(1);
  }
`;

export const Blob = styled(IconBlob)`
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  position: absolute;
  ${size('100%')}

  path {
    stroke: ${colors.highlight};
  }
`;

const twistAnimation = keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

export const StyledLogo = styled(Link)`
  grid-area: logo;
  justify-self: start;
  display: block;
  margin-bottom: 50px;
  pointer-events: all;
  position: relative;
  width: 48px;
  z-index: 1;

  svg.icon-logo {
    position: relative;
    pointer-events: none;
    ${size('48px', '57px')}
  }

  .icon-logo path {
    fill: url(#bkgTextureBrown-logo);
  }

  &:hover {
    ${BlobContainer} {
      transform: translate(-50%, -50%) scale(1);
    }
    ${Blob} {
      animation: ${twistAnimation} 30s infinite alternate-reverse linear;
    }
  }
`;

export const LogoWrapper = styled.div`
  pointer-events: none;
  ${size('57px')};
`;

export const UserContainer = styled.div`
  padding-top: 28px;
  border-top: 1px solid ${colors.grey};
  display: flex;

  svg {
    fill: ${colors.brownMedium};
    ${size('32px')};
  }
`;

export const UserImage = styled.img`
  border-radius: 5px;
  background-color: ${colors.brownMedium};
  display: inline-block;
  ${size('32px')};
`;

export const UserInfo = styled.div`
  display: inline-flex;
  margin-left: 11px;
  flex-direction: column;
  justify-content: space-between;

  .name {
    ${eyebrow};
    color: ${colors.brownLight};
  }

  .office {
    ${font('body')}
    ${fontSize(12, 14, 0)}
    color: ${colors.brownLight};
  }

  .logout {
    color: ${colors.primary};
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: underline;
    ${font('secondary')}
    ${fontSize(10, 20, 1.4)}

    &:hover {
      color: ${colors.highlight};
    }
  }
`;

export const Main = styled.div``;

export const Item = styled.div`
  overflow: hidden;
  max-height: 20px;
  margin: 0 0 30px;
  transition: 0.3s ${customBezierEase};
`;

export const MainLink = styled(NavLink)`
  ${eyebrow};
  align-items: center;
  color: ${colors.primary};
  display: flex;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.3s ${customBezierEase};

  &.active {
    color: ${colors.brownLight};
    pointer-events: none;

    svg {
      transform: rotate(-90deg);
      fill: ${colors.brownLight};
    }
  }

  &:not(.active):hover {
    color: ${colors.highlight};

    svg {
      fill: ${colors.brownLight};
    }
  }
`;

export const MainItem = styled.div`
  align-items: center;
  display: flex;
`;

export const SubButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  border-radius: 0;
  position: absolute;
  right: 20px;
  margin-left: auto;

  svg {
    fill: ${colors.primary};
    transition: transform 0.3s ${customBezierEase};
    ${size('14px', '10px')}
  }

  &.active {
    svg {
      transform: rotate(180deg);
    }
  }
`;

export const Sub = styled.div``;

export const SubLink = styled(MainLink)`
  margin: 16px 0;
  display: flex;
  align-items: center;
  padding-left: 24px;
  position: relative;

  &:last-child {
    margin-bottom: 0px;

    &:after {
      height: 50%;
    }
  }

  &:after {
    // left vertical line
    background: ${colors.grey};
    content: '';
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    ${size('1px', 'calc(100% + 16px)')}
  }

  &:before {
    // left horizontal line
    background: ${colors.grey};
    content: '';
    display: block;
    left: 0;
    position: absolute;
    top: 9px;
    ${size('16px', '1px')}
  }
`;

export const Social = styled.div`
  border-top: 1px solid ${colors.greyLightLight};
  padding-top: 30px;
`;

export const SocialLink = styled.a`
  ${eyebrow}
  align-items: center;
  color: ${colors.primary};
  display: flex;
  margin: 0 0 16px;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.3s ${customBezierEase};

  svg {
    fill: ${colors.primary};
    stroke: ${colors.primary};
    transition: 0.3s ${customBezierEase};
    ${size('30px')}

    path {
      fill: ${colors.primary};
      stroke: ${colors.primary};
    }
  }

  span {
    margin-left: 17px;
  }

  &:hover {
    color: ${colors.highlight};

    svg {
      fill: ${colors.highlight};
      stroke: ${colors.highlight};
    }

    path {
      fill: ${colors.highlight};
      stroke: ${colors.highlight};
    }
  }
`;
