import { IconAdd, IconArrow, IconWarning } from 'components/IconsView';
import { UserI } from 'data/types/User.types';
import { sum } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  AddButton,
  Container,
  Content,
  CreateAnother,
  Error,
  ExpenseList,
  Eyebrow,
  Message,
  Middle,
  NameInput,
  Notes,
  SubmitButton,
  SubmitLoader,
  Success,
  Total,
} from './styles';
import { v4 as uuidv4 } from 'uuid';
import ExpenseRow, { ExpenseI } from './Components/ExpenseRow';
import UploadView from 'components/FormComponents/UploadView';
import { breakpoints } from 'shared/variables';
import { cleanFileName, debounce } from 'utils/functions';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import * as mutations from 'graphql/mutations';
import { Storage } from 'aws-amplify';
import classNames from 'classnames';
import { PacmanLoader } from 'react-spinners';
import UnsavedModalRoute from 'components/UnsavedModalRoute';
import MileageRatesI from 'data/types/MileageRates';
import slugify from 'slugify';

interface Props {
  user: UserI;
  mileageRates: MileageRatesI[];
}

interface MediaI {
  filename: string;
  filetype: string;
  filesize: number;
  file: File;
}

const EMAIL_EXPENSE_REPORT = gql(mutations.emailExpenseReport);

const ExpenseReportView = ({ user, mileageRates }: Props): JSX.Element => {
  const [emailExpenseReport] = useMutation(EMAIL_EXPENSE_REPORT);
  const methods = useForm();
  const [isSubmitting, setSubmitting] = useState(false);
  const [expenseList, setExpenseListState] = useState([uuidv4()]);
  const expenseListRef = useRef(expenseList);
  const setExpenseList = (input: string[]) => {
    expenseListRef.current = input;
    setExpenseListState(input);
  };
  const [subtotalList, setSubtotalList] = useState<number[]>([]);
  const [total, setTotalState] = useState('0.00');
  const totalRef = useRef(total);
  const setTotal = (input: string) => {
    totalRef.current = input;
    setTotalState(input);
  };
  const [isTablet, setIsTablet] = useState(false);
  const [emailSuccess, setEmailSuccessState] = useState(false);
  const emailSuccessRef = useRef(emailSuccess);
  const setEmailSuccess = (input: boolean) => {
    emailSuccessRef.current = input;
    setEmailSuccessState(input);
  };
  const [formFilled, setFormFilled] = useState(false);

  const checkForm = (): boolean => {
    if (emailSuccess) return false;

    const notes = methods.watch('notes');
    if (!!notes) {
      return true;
    }

    // const media = methods.watch('receipts');
    // if (media?.length > 0) {
    //   return true;
    // }

    for (let i = 0; i < expenseListRef.current.length; i++) {
      const expenseItem = methods.watch(`expenses[${i}]`);
      if (
        expenseItem?.date ||
        expenseItem?.amount ||
        expenseItem?.category ||
        expenseItem?.item ||
        expenseItem?.quantity ||
        expenseItem?.subtotal !== '0.00'
      ) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    setIsTablet(window.innerWidth < breakpoints.tablet);

    window.addEventListener(
      'resize',
      debounce(function () {
        setIsTablet(window.innerWidth < breakpoints.tablet);
      })
    );

    return () => {
      window.removeEventListener(
        'resize',
        debounce(function () {
          setIsTablet(window.innerWidth < breakpoints.tablet);
        })
      );
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const temp = parseFloat(sum(subtotalList).toString()).toLocaleString(
      'en-US',
      {
        style: 'decimal',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }
    );
    setTotal(temp);
    methods.clearErrors('total');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subtotalList]);

  const handleTotal = (subtotal: number, index: number) => {
    const newSubtotalList = [...subtotalList];
    newSubtotalList[index] = subtotal;
    setSubtotalList(newSubtotalList);
  };

  const handleTrash = (index: number) => {
    console.log('[handleTrash] index: ', index);
    const newList = expenseList.filter((_, i) => i !== index);
    setExpenseList(newList);
    console.log('newList:', newList);
    const newSubtotalList = [...subtotalList];
    newSubtotalList.splice(index, 1);
    setSubtotalList(newSubtotalList);
    console.log(
      'methods?.formState?.errors?.expenses: ',
      methods?.formState?.errors?.expenses
    );

    methods?.unregister(`expenses[${index}]`);

    for (let j = 0; j < methods?.formState?.errors?.expenses?.length; j++) {
      if (index === j) {
        // methods?.unregister(`expenses`);
        methods?.clearErrors([`expenses[${j}]`]);
        // methods?.formState?.errors?.expenses?.splice(index, 1);
      }
    }
    const expenses = methods
      ?.watch('expenses')
      .filter((_: unknown, i: number) => i !== index);

    methods?.setValue('expenses', expenses);
    methods?.clearErrors('mileageRate');

    console.log('index:', methods?.formState?.errors?.expenses);
    console.log(
      'expense error list:',
      methods?.formState?.errors?.expenses?.length
    );
    console.log('error:', methods?.formState?.errors);
  };

  const handleAnother = () => {
    methods.reset();
    setSubtotalList([]);
    setTotal('0.00');
    setExpenseList([uuidv4()]);

    setTimeout(() => {
      setEmailSuccess(false);
    }, 500);
  };

  const expenseDirty = JSON.stringify(methods.formState.dirtyFields.expenses);
  const expenseWatch = JSON.stringify(methods.watch());

  useEffect(() => {
    let formFill = true;
    for (let i = 0; i < expenseListRef.current.length; i++) {
      const expenseItem = methods.watch(`expenses[${i}]`);
      if (
        !expenseItem?.date ||
        !expenseItem?.amount ||
        !expenseItem?.category ||
        !expenseItem?.item ||
        !expenseItem?.quantity ||
        expenseItem?.subtotal === '0.00'
      ) {
        formFill = false;
        break;
      }
    }
    setFormFilled(formFill);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenseDirty, expenseWatch]);

  return (
    <Container className="hello">
      <UnsavedModalRoute
        content="Doing this will lose any unsaved changes. Are you sure you want to continue?"
        title="Are you sure you want to leave this unsaved?"
        type="page"
        isBlocked={checkForm()}
      />
      {emailSuccess ? (
        <Success>
          <Eyebrow>Confirmed.</Eyebrow>
          <Message>
            Your expense report request has been successfully submitted.
          </Message>
          <CreateAnother type="button" onClick={handleAnother}>
            Create another expense report <IconArrow />
          </CreateAnother>
        </Success>
      ) : (
        <FormProvider {...methods}>
          <Content
            onSubmit={methods.handleSubmit(async (input) => {
              console.log('input', input);

              let hasErrors = false;

              // error: total can't be 0
              if (total === '0.00') {
                methods.setError('total', { type: 'manual' });
                input.expenses?.map((exp: ExpenseI, e: number) => {
                  if (exp.amount === '0.00') {
                    methods.setError(`expenses[${e}].amount`, {
                      type: 'manual',
                    });
                  }
                  if (!exp.quantity || exp.quantity === '0') {
                    methods.setError(`expenses[${e}].quantity`, {
                      type: 'manual',
                    });
                  }
                });
                hasErrors = true;
              }

              console.log('hasErrors', hasErrors);

              if (hasErrors) return;

              console.log('no errors');
              setSubmitting(true);
              // push receipts to storage and get url to pass to email
              const images = [];
              if (input.receipts) {
                for (let i = 0; i < input.receipts.length; i++) {
                  const receipt: MediaI = input.receipts[i];
                  const receiptName = cleanFileName(
                    `${Math.floor(Math.random() * 10000)}-${slugify(
                      receipt.filename
                    ).toLowerCase()}`
                  );
                  const imageData = await Storage.put(
                    receiptName,
                    receipt.file,
                    {
                      contentType: receipt.filetype,
                    }
                  );
                  images.push({ name: receiptName, key: imageData.key });
                }
              }

              // combine expense dates
              const dates = [];
              for (let i = 0; i < input.expenses.length; i++) {
                const expense = input.expenses[i];
                const eDate = expense.date;
                if (dates.indexOf(eDate) === -1) {
                  dates.push(eDate);
                }
              }

              const expenseDates = dates.join(', ');

              // format
              const subject = `${input.name} - ${expenseDates} - $${total} - Expense Report`;

              console.log(
                'parseFloat(total)',
                parseFloat(total.replaceAll(',', ''))
              );

              const emailData = {
                name: user.name,
                email: user.email,
                subject: subject,
                notes: input.notes,
                total: parseFloat(total.replaceAll(',', '')),
                items: input.expenses,
                receipts: images,
              };

              console.log(emailData);

              // push to emails
              const email = await emailExpenseReport({
                variables: {
                  input: {
                    ...emailData,
                  },
                },
              });
              console.log('email sent', email);
              setEmailSuccess(true);
              setSubmitting(false);
            })}
          >
            <NameInput
              name="name"
              label="Employee Name"
              required={true}
              value={user.name}
              disabled={true}
            />
            <ExpenseList>
              {expenseList?.length > 0 &&
                expenseList.map((expense: string, e) => {
                  return (
                    <ExpenseRow
                      zIndex={expenseList.length - e}
                      key={expense}
                      index={e}
                      handleTrash={() => handleTrash(e)}
                      updateTotal={(subtotal) => handleTotal(subtotal, e)}
                      moreThanOne={expenseList.length > 1}
                      mileageRates={mileageRates}
                    />
                  );
                })}
            </ExpenseList>
            <Middle>
              <AddButton
                tertiary={true}
                type="button"
                aria-label="Add Expense"
                onClick={() => setExpenseList([...expenseList, uuidv4()])}
              >
                <IconAdd /> Add Another Expense
              </AddButton>
              <Total>
                Total: <span data-testid="total">${total}</span>
              </Total>
            </Middle>
            <Notes name="notes" label="additional notes" optional={true} />
            <UploadView
              fileMaxSize={10000000}
              name="receipts"
              label="Receipt Upload"
              fileTypes="image/jpeg, image/png, application/pdf"
              dropzoneDescriptor=".PDF, .JPG, or .PNG are allowed"
              dropzoneLabel={
                isTablet
                  ? 'Upload Receipts Here'
                  : 'Drop files here or Upload Receipts'
              }
              errorMessages={{
                'file-invalid-type':
                  'File type not supported. Must be formatted in .pdf, .jpg, or .png.',
              }}
              optional={true}
              error={methods?.formState?.errors?.receipts}
            />
            <SubmitButton
              primary={true}
              type="submit"
              data-testid="submit expense"
              className={classNames({
                submitting: isSubmitting,
                disabled:
                  methods?.formState?.errors.length > 0 ||
                  !formFilled ||
                  total === '0.00',
              })}
            >
              {isSubmitting ? (
                <SubmitLoader>
                  <PacmanLoader color="#ffffff" />
                </SubmitLoader>
              ) : (
                'Submit Expense(s)'
              )}
            </SubmitButton>
            {methods?.formState?.errors?.expenses?.length > 0 && (
              <Error>
                <IconWarning />
                Fill in required field(s) and resubmit
              </Error>
            )}
            {methods?.formState?.errors?.mileageRate && (
              <Error>
                <IconWarning />
                No mileage rate found for selected date(s), please contact Devin
              </Error>
            )}
            {methods?.formState?.errors?.total && (
              <Error>
                <IconWarning />
                Total expense cannot be $0.00
              </Error>
            )}
          </Content>
        </FormProvider>
      )}
    </Container>
  );
};

export default ExpenseReportView;
