import React, { useMemo } from 'react';
import IntroView from 'components/IntroView';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import * as localQueries from './queries';
import * as queries from 'utils/queries';
import CompanyDirectoryView from 'components/CompanyDirectoryView';
import MemberI from 'data/types/Member.types';
import OfficeI from 'data/types/Office.types';
import { Helmet } from 'react-helmet';
import { TabDropdownI, TabDropdownOptionI } from 'data/types/TabDropdown.types';
import { useLocation } from 'react-router-dom';
import LoaderView from 'components/LoaderView';
import { Office } from 'API';

const LIST_OFFICES = gql(localQueries.listOffices);
const GET_PAGE_BY_SLUG = gql(queries.getPageBySlug);

//* if you update this list update the admin one too
export const departmentTranslations = [
  {
    label: 'Business Analysis',
    value: 'business-analysis',
    key: 'business-analysis',
  },
  {
    label: 'Design',
    value: 'design',
    key: 'design',
  },
  {
    label: 'Development',
    value: 'development',
    key: 'development',
  },
  {
    label: 'Executive Management',
    value: 'executive-management',
    key: 'executive-management',
  },
  {
    label: 'Executive Vendor',
    value: 'executive-vendor',
    key: 'executive-vendor',
  },
  {
    label: 'Information Technology',
    value: 'information-technology',
    key: 'information-technology',
  },
  {
    label: 'Office Management',
    value: 'office-management',
    key: 'office-management',
  },
  {
    label: 'Photography',
    value: 'photography',
    key: 'photography',
  },
  {
    label: 'Project Management',
    value: 'project-management',
    key: 'project-management',
  },
  {
    label: 'Quality Assurance',
    value: 'quality-assurance',
    key: 'quality-assurance',
  },
  {
    label: 'User Experience',
    value: 'user-experience',
    key: 'user-experience',
  },
];

const CompanyDirectory = ({ slug }: { slug?: string }): JSX.Element => {
  const { search } = useLocation();
  const name = search ? search.split('=')[1] : '';
  const { data: listOfficesData, loading: officeLoading } = useQuery(
    LIST_OFFICES,
    {
      variables: {
        filter: { active: { eq: true } },
      },
    }
  );
  const officeItems = useMemo(() => {
    return listOfficesData?.listOffices?.items;
  }, [listOfficesData]);

  const data = useMemo(() => {
    if (!officeItems) return null;
    const members: MemberI[] | null = [];
    officeItems.map((office: OfficeI) => {
      office?.members?.items?.map((member: MemberI) => {
        if (member.active) {
          members.push(member);
        }
      });
    });
    return members;
  }, [officeItems]);

  const { data: slugData, loading: slugLoading } = useQuery(GET_PAGE_BY_SLUG, {
    variables: {
      slug,
    },
  });

  const pageData = useMemo(() => {
    return slugData?.getPageBySlug.items[0];
  }, [slugData]);

  const tabsLocation: TabDropdownI = useMemo(() => {
    const location: TabDropdownI = {
      label: 'Location',
      value: 'location',
      options: [],
    };
    console.log('officeItems:', officeItems);
    const sortedOfficeItems = officeItems
      ?.slice()
      ?.sort((a: Office, b: Office) => (a.sort || 0) - (b.sort || 0));
    console.log('sorted:', sortedOfficeItems);
    sortedOfficeItems?.map((item: OfficeI) => {
      if (item.members?.items && item.members?.items?.length > 0) {
        location.options.push({ label: item?.name || '' });
      }
    });
    return location;
  }, [officeItems]);

  const tabs: TabDropdownI[] = useMemo(() => {
    const department: TabDropdownI = {
      label: 'Department',
      value: 'department',
      options: [],
    };
    const title: TabDropdownI = { label: 'Title', value: 'title', options: [] };

    const departmentOptions: TabDropdownOptionI[] = [];
    const titleOptions: TabDropdownOptionI[] = [];

    data?.map((item) => {
      if (item.department) {
        const translation = departmentTranslations.find(
          (x) => x.value === item.department
        );
        if (
          translation &&
          departmentOptions?.findIndex((x) => x.label === translation.label) ===
            -1
        ) {
          departmentOptions.push({
            label: translation ? translation.label : item.department,
            value: item.department,
          });
        }
      }
      if (item.title) {
        if (
          titleOptions?.findIndex((x) => x.label === item.title?.trim()) === -1
        ) {
          titleOptions.push({ label: item.title.trim() });
        }
      }
    });

    department.options = departmentOptions.sort(function (a, b) {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    title.options = titleOptions.sort(function (a, b) {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    return [department, title];
  }, [data]);

  if (slugLoading || officeLoading) {
    return <LoaderView />;
  }

  return (
    <>
      <Helmet>
        <title>{pageData?.title}</title>
        <meta name="description" content={pageData?.description || ''} />
      </Helmet>
      <IntroView type="subpage" data={pageData} />

      <CompanyDirectoryView
        name={name}
        tabs={[tabsLocation, ...tabs]}
        data={data}
        departmentTranslations={departmentTranslations}
      />
    </>
  );
};

export default CompanyDirectory;
