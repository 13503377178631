import React, { useEffect, useMemo, useState } from 'react';
import {
  AddButton,
  Answer,
  Back,
  Badge,
  BoardData,
  Board,
  Card,
  Container,
  Form,
  Front,
  Grid,
  Question,
  QuestionImage,
  Stickers,
  Sticker,
} from './styles';
import { gql, useMutation, useQuery } from '@apollo/client';
import * as pageQueries from 'utils/queries';
import * as queries from 'graphql/queries';
import * as mutations from 'graphql/mutations';
import LoaderView from 'components/LoaderView';
import { Helmet } from 'react-helmet';
import { v4 as uuidv4 } from 'uuid';
import DateSelectorView from 'components/FormComponents/DateSelectorView';
import InputView from 'components/FormComponents/InputView';
import SelectView from 'components/FormComponents/SelectView';
import { useForm, FormProvider } from 'react-hook-form';
import { Member } from 'API';
import CheckboxView from 'components/FormComponents/CheckboxView';
import UploadView from 'components/FormComponents/UploadView';
import { cleanFileName } from 'utils/functions';
import { Storage } from 'aws-amplify';
import slugify from 'slugify';
import { H1 } from 'shared/typography';

const GET_PAGE_BY_SLUG = gql(pageQueries.getPageBySlug);
const LIST_MEMBERS = gql(queries.listMembers);
const UPDATE_PAGE = gql(mutations.updatePage);

interface IRiddle {
  id: string;
  answer: string;
  question?: string;
  questionImage?: string;
  guesser: string;
  firstTry?: boolean;
  heart?: string;
}

interface IMembers {
  label: string;
  value: string;
}
interface MediaI {
  filename: string;
  filetype: string;
  filesize: number;
  file: File;
}

interface IScore {
  name: string;
  score: number;
}

const Riddle = ({
  answer,
  question,
  questionImage,
  guesser,
  heart,
  firstTry,
}: IRiddle) => {
  const [image, setImage] = useState<string | null>(null);
  useEffect(() => {
    const getImage = async (key: string) => {
      const imageLink = await Storage.get(key);
      setImage(imageLink);
    };
    if (questionImage) {
      getImage(questionImage);
    }
  }, [questionImage]);
  return (
    <Card>
      <Back>
        {image ? (
          <QuestionImage src={image} />
        ) : (
          <Question>{question}</Question>
        )}
      </Back>
      <Front>
        <Answer>{answer}</Answer>
      </Front>
      <Badge>{guesser}</Badge>
      {heart || firstTry ? (
        <Stickers>
          {firstTry ? <Sticker>1</Sticker> : <></>}
          {heart ? <Sticker>{heart}</Sticker> : <></>}
        </Stickers>
      ) : (
        <></>
      )}
    </Card>
  );
};

const RiddleGriddle = (): JSX.Element => {
  const methods = useForm();
  const [tableData, setTableData] = useState<IRiddle[]>([]);
  const [resetForm, setResetForm] = useState(false);
  const [members, setMembers] = useState<IMembers[]>([]);
  const [scoreboard, setScoreboard] = useState<IScore[]>([]);

  const [updatePage] = useMutation(UPDATE_PAGE);

  const { data: slugData, loading: slugLoading } = useQuery(GET_PAGE_BY_SLUG, {
    variables: {
      slug: 'riddle-griddle',
    },
  });

  const pageData = useMemo(() => {
    return slugData?.getPageBySlug.items[0];
  }, [slugData]);

  useEffect(() => {
    if (pageData?.meta) {
      const temp = JSON.parse(pageData?.meta || '');
      if (temp.length > 0) {
        setTableData(temp);
      }
    }
  }, [pageData]);

  const { data: listMembersData } = useQuery(LIST_MEMBERS, {
    variables: {
      filter: { officeID: { eq: 'oc' } },
    },
  });
  useEffect(() => {
    const temp: IMembers[] = [];
    listMembersData?.listMembers?.items.map((mem: Member) => {
      temp.push({ label: mem.name, value: mem.name });
    });
    setMembers(temp);
  }, [listMembersData]);

  useEffect(() => {
    if (tableData) {
      const temp: IScore[] = [];
      tableData.map((data) => {
        if (data.guesser) {
          const scoreIndex = temp.findIndex((x) => x.name === data.guesser);
          if (scoreIndex > -1) {
            temp[scoreIndex].score += 1;
          } else {
            temp.push({ name: data.guesser, score: 1 });
          }
        }
      });
      setScoreboard(temp);
    }
  }, [tableData]);

  const renderCards = () => {
    console.log(tableData);
    return tableData.map((data) => {
      return <Riddle {...data} />;
    });
  };

  const renderBoard = () => {
    return scoreboard.map((row) => {
      return (
        <>
          <BoardData className="score">{row.score}</BoardData>
          <BoardData>{row.name}</BoardData>
        </>
      );
    });
  };

  const handleSubmit = async (input: any) => {
    console.log(input);
    const temp = tableData;
    if (input.questionImage) {
      const image: MediaI = input.questionImage[0];
      const imageName = cleanFileName(
        `${Math.floor(Math.random() * 10000)}-${slugify(
          image.filename
        ).toLowerCase()}`
      );
      const imageData = await Storage.put(imageName, image.file, {
        contentType: image.filetype,
      });
      temp.push({ id: uuidv4(), ...input, questionImage: imageData.key });
    } else {
      // @ts-ignore
      temp.push({ id: uuidv4(), ...input });
    }
    await updatePage({
      variables: {
        input: {
          id: 'page-riddle-griddle',
          meta: JSON.stringify(temp),
        },
      },
    });
    methods.reset();
    setResetForm(true);
    setTimeout(() => {
      setResetForm(false);
    }, 1000);
  };

  if (slugLoading) {
    return <LoaderView />;
  }

  return (
    <Container>
      <Helmet>
        <title>{pageData?.title}</title>
        <meta name="description" content={pageData?.description || ''} />
      </Helmet>
      <H1>Scoreboard</H1>
      <Board>{renderBoard()}</Board>
      <H1>Riddles</H1>
      <Grid>{renderCards()}</Grid>
      {resetForm ? (
        <></>
      ) : (
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(handleSubmit)}>
            <InputView
              name="question"
              label="Question"
              required={false}
              error={methods.formState.errors?.question}
            />
            <UploadView
              name="questionImage"
              label="Question Image"
              fileTypes="image/jpeg, image/png"
            />
            <InputView
              name="answer"
              label="Answer"
              required={false}
              error={methods.formState.errors?.answer}
            />
            <SelectView
              name="guesser"
              label="Guesser"
              required={false}
              options={members}
              defaultOption="Select the Guesser"
              error={methods.formState.errors?.guesser}
            />
            <DateSelectorView
              name={'date'}
              label="Date"
              required={false}
              // format="YYYY-MM-DDTHH:mm:ssZ[Z]"
              error={methods.formState.errors?.date}
              allowWeekends={false}
            />
            <CheckboxView name="firstTry" label="Star?" required={false} />
            <SelectView
              name="heart"
              label="Hearter"
              required={false}
              options={members}
              defaultOption="Select the Hearter"
              error={methods.formState.errors?.heart}
            />
            <AddButton type="submit">Add New Riddle</AddButton>
          </Form>
        </FormProvider>
      )}
    </Container>
  );
};
export default RiddleGriddle;
