import styled from 'styled-components';
import { colors, customBezierEase } from '../../../shared/variables';
import {
  bodyCopyBold,
  bodyCopySmall,
  eyebrow,
  gridDesktop,
  gridTablet,
  resetButtonStyles,
} from '../../../shared/extends';
import { mq, size } from 'shared/mixins';
import { font, fontSize } from 'shared/typography';

export const Container = styled.div``;

export const Dropzone = styled.div`
  transition: border 0.3s ${customBezierEase};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%238A816D' stroke-width='1' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  width: 100%;
  padding: 35px;

  .body-container:not(.is-touch) &:hover {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23FB4640' stroke-width='1' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-color: ${colors.highlight};
    cursor: pointer;

    svg {
      fill: ${colors.highlight};
    }
  }

  &.error {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23FB4640' stroke-width='1' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  }

  ${mq.phoneWide`
    padding: 16px;
  `}
`;

export const UploadButton = styled.button`
  ${resetButtonStyles};
  ${eyebrow};
  color: ${colors.primary};
  border: 1px solid ${colors.primary};
  margin-top: 16px;
  padding: 8px;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  pointer-events: none;

  svg {
    fill: ${colors.brownLight};
    ${size('40px', '32px')}
  }

  .label {
    ${bodyCopyBold};
    color: ${colors.brownLight};
    margin: 12px 0 8px;
    display: inline-block;
  }

  .desc {
    ${bodyCopySmall};
    color: ${colors.brownLight};
  }

  .error & {
    svg {
      fill: ${colors.highlight};
    }

    .label,
    .desc {
      color: ${colors.highlight};
    }
  }

  ${mq.tablet`
    .label {
      margin: 0 0 4px;
    }
  `}
`;

export const Uploads = styled.div`
  margin-top: 32px;

  ${mq.phoneWide`
  margin-top: 24px;
`}
`;

export const UploadList = styled.div`
  ${gridDesktop};
  grid-row-gap: 24px;

  label {
    grid-column: span 12;
  }

  ${mq.tabletWide`
    ${gridTablet};

    label {
      grid-column: span 6;
    }
  `}

  ${mq.phoneWide`
    display: flex;
    flex-direction: column;
    grid-row-gap: 0;
  `}
`;

export const Upload = styled.div`
  grid-column: span 3;
  display: flex;
  align-items: flex-start;

  svg {
    fill: ${colors.brownMedium};
    flex: 0 0 auto;
    ${size('32px', '41px')};
  }

  .detail {
    margin-left: 19px;

    .name {
      ${bodyCopySmall};
      color: ${colors.brownMedium};
      display: block;
    }

    .size {
      color: ${colors.brownLight};
      display: block;
      ${font('body')};
      ${fontSize(10, 20)};
    }
  }

  ${mq.phoneWide`
    width: 100%;
    margin-bottom: 8px;
    
    .detail {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &:last-child {
      margin-bottom: 0;
    }
  `}
`;

export const RemoveUpload = styled.button`
  ${resetButtonStyles};
  ${bodyCopySmall};
  color: ${colors.highlight};
`;

export const BadUploads = styled.ul`
  margin: 16px 0 0;
  padding: 0;
  list-style: none;
`;

export const UploadError = styled.li`
  ${bodyCopySmall};
  color: ${colors.highlight};
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    fill: ${colors.highlight};
    margin-right: 8px;
    ${size('16px')};
  }

  span {
    font-weight: bold;
  }

  ${mq.phoneWide`
    display: flex;
  `}
`;

export const ClearError = styled.button`
  ${resetButtonStyles};
  ${size('20px')};
  margin-left: 8px;

  svg {
    fill: ${colors.highlight};
    ${size('8.5px')};
  }
`;
