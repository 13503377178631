import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import {
  BackButton,
  ClearButton,
  Container,
  Content,
  Eyebrow,
  Hamburger,
  Item,
  LogoWrapper,
  Main,
  MainItemContainer,
  MainLink,
  MainMenu,
  OpenSubButton,
  ParentButton,
  Right,
  SearchIcon,
  SearchInput,
  SearchResults,
  Social,
  SocialLink,
  StyledLogo,
  Sub,
  Title,
  Top,
  UserContainer,
  UserImage,
  UserInfo,
} from './styles';
import {
  IconFacebookCustom,
  IconInstagramCustom,
  IconLogoMobile,
  IconHamburger,
  IconArrow,
  IconSearch,
  IconX,
  IconUser,
  IconYoutubeCustom,
} from '../IconsView';
import classNames from 'classnames';
import NavigationI from 'data/types/Navigation.types';
import { UserI } from 'data/types/User.types';
import { debounce } from 'lodash';
import { stopBackgroundScroll } from 'utils/functions';
import * as queries from 'graphql/queries';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { Storage } from 'aws-amplify';
import MemberI from 'data/types/Member.types';
import { toSvg } from 'jdenticon';
import { ListMembersQuery, Member } from 'API';

interface Props {
  data: NavigationI[] | [];
  user: UserI;
  signOut: () => void;
}

const MobileNavigationView = ({ data, user }: Props): JSX.Element => {
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [openSearchResults, setOpenSearchResults] = useState(false);
  const [subItems, setSubItems] = useState<NavigationI | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const InputRef = useRef<HTMLInputElement>(null);
  const [itemUrlMap, setItemUrlMap] = useState<{ id: string; url: string }[]>(
    []
  );

  const getImages = async (list: Member[]) => {
    const temp = [];
    for (let m = 0; m < list.length; m++) {
      const item = list[m];
      if (item.image) {
        const imageLink = await Storage.get(item.image);
        temp.push({ id: item.id, url: imageLink });
      }
    }

    if (temp.length) {
      setItemUrlMap(temp);
    }
  };

  const handleNavClick = () => {
    setOpenMenu(false);
    setOpenSubMenu(false);
    setOpenSearchResults(false);
    setSubItems(null);
  };

  const handleShowSubItems = (item: NavigationI) => {
    setOpenSubMenu(true);
    setSubItems(item);
  };

  const handleSearchTerm = (el: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = el.target;
    setSearchTerm(value);
  };

  const handleChange = useMemo(() => debounce(handleSearchTerm, 300), []);

  useEffect(() => {
    return () => {
      handleChange.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const LIST_MEMBERS = gql(queries.listMembers);

  interface ListMembersQueryI {
    listMembers?: ListMembersQuery['listMembers'];
  }

  const { data: memberData } = useQuery<ListMembersQueryI>(LIST_MEMBERS, {
    variables: {
      filter: {
        nameLow: {
          contains: searchTerm.toLowerCase(),
        },
      },
    },
    skip: !searchTerm,
  });

  useEffect(() => {
    const memberList = memberData?.listMembers?.items || [];
    getImages(memberList);
  }, [memberData?.listMembers?.items]);

  return (
    <Container className={classNames({ open: openMenu || openSearchResults })}>
      <Top
        className={classNames('mobile-nav', {
          open: openMenu,
          is404: location.pathname === '/404',
          subpage:
            location.pathname.split('/').filter((i: string) => i !== '')
              .length > 1,
          searchResults: openSearchResults,
        })}
      >
        {!openSearchResults ? (
          <>
            <StyledLogo onClick={() => handleNavClick()} to="/">
              <LogoWrapper>
                <IconLogoMobile />
              </LogoWrapper>
            </StyledLogo>
            <Title>{openMenu ? 'MENU.' : 'INTRANET.'}</Title>
          </>
        ) : (
          <SearchInput ref={InputRef} type="text" onChange={handleChange} />
        )}
        <Right>
          {!openSearchResults ? (
            <SearchIcon
              type="button"
              onClick={() => {
                setOpenSearchResults((prev) => !prev);
                stopBackgroundScroll('open');
                setTimeout(() => {
                  if (InputRef.current) {
                    InputRef.current.focus();
                  }
                }, 500);
              }}
            >
              <IconSearch />
            </SearchIcon>
          ) : (
            <ClearButton
              type="button"
              onClick={() => {
                setSearchTerm('');
                if (InputRef.current) {
                  InputRef.current.value = '';
                  InputRef.current?.focus();
                }
              }}
            >
              Clear
            </ClearButton>
          )}
          <Hamburger
            aria-label="Open menu"
            onClick={() => {
              if (openSearchResults) {
                setOpenSearchResults(false);
                setSearchTerm('');
                stopBackgroundScroll('close');

                if (InputRef.current) {
                  InputRef.current.blur();
                }
                return;
              }
              setOpenMenu((prev) => !prev);

              //remove later if you want to save the page you were on
              setOpenSubMenu(false);
              setOpenSearchResults(false);
              setSubItems(null);
            }}
          >
            {openMenu || openSearchResults ? <IconX /> : <IconHamburger />}
          </Hamburger>
        </Right>
      </Top>
      <Content className={classNames({ open: openMenu || openSearchResults })}>
        <Main
          className={classNames({ hide: openSubMenu || openSearchResults })}
        >
          <MainMenu>
            <MainItemContainer>
              <Item>
                <MainLink exact onClick={() => handleNavClick()} to={`/`}>
                  Home
                </MainLink>
              </Item>
              {data.map((item) => {
                if (item.id === 'page-faq') {
                  return (
                    <Item key={item.id}>
                      <MainLink
                        exact
                        to={`/${item.slug}`}
                        onClick={() => handleNavClick()}
                        type="button"
                        aria-label="open sub menu"
                      >
                        {item.label}
                      </MainLink>
                    </Item>
                  );
                }
                return (
                  <Item key={item.id}>
                    <ParentButton
                      type="button"
                      aria-label="open sub menu"
                      onClick={() => handleShowSubItems(item)}
                    >
                      {item.label}
                      {item.subItems && (
                        <OpenSubButton>
                          <IconArrow />
                        </OpenSubButton>
                      )}
                    </ParentButton>
                  </Item>
                );
              })}
            </MainItemContainer>
            <Social>
              <SocialLink
                href="https://www.facebook.com/FiveAndDone/"
                target="_blank"
              >
                <IconFacebookCustom />
                <span>Face.</span>
              </SocialLink>
              <SocialLink
                href="https://www.youtube.com/channel/UC7KpgwPeysXBbnb6YwVAvRg"
                target="_blank"
              >
                <IconYoutubeCustom />
                <span>Tube.</span>
              </SocialLink>
              <SocialLink
                href="https://www.instagram.com/fiveanddone/"
                target="_blank"
              >
                <IconInstagramCustom />
                <span>Gram.</span>
              </SocialLink>
            </Social>
          </MainMenu>
          <UserContainer>
            <UserInfo>
              {user.picture ? (
                <UserImage src={user.picture} />
              ) : user.name ? (
                <div
                  className="user-pic"
                  dangerouslySetInnerHTML={{ __html: toSvg(user.name, 32) }}
                />
              ) : (
                <IconUser />
              )}
              <div className="user-info">
                <span className="name">{user.name}</span>
                <span className="office">{user.office}</span>
              </div>
            </UserInfo>
            <Link className="sign-out" to="/logout">
              Log out
            </Link>
          </UserContainer>
        </Main>
        <Sub
          className={classNames({ show: openSubMenu && !openSearchResults })}
        >
          <BackButton
            aria-label="back to main menu"
            type="button"
            onClick={() => {
              setOpenSubMenu(false);
              setSubItems(null);
            }}
          >
            <IconArrow />
            Back
          </BackButton>
          <Eyebrow onClick={() => handleNavClick()} to={`/${subItems?.slug}`}>
            {subItems?.label}
          </Eyebrow>
          {subItems &&
            subItems?.subItems &&
            subItems?.subItems.map((item) => {
              return (
                <Item key={item.id}>
                  <MainLink
                    onClick={() => handleNavClick()}
                    to={`/${subItems.slug}/${item.slug}`}
                  >
                    {item.label}
                  </MainLink>
                </Item>
              );
            })}
        </Sub>
        <SearchResults
          className={classNames({ show: !openSubMenu && openSearchResults })}
          handleCancel={() => {
            setOpenSearchResults(false);
            setSearchTerm('');
            stopBackgroundScroll('close');
            if (InputRef.current) {
              InputRef.current.value = '';
              InputRef.current.blur();
            }
          }}
          handlePageScroll={() => {
            if (InputRef.current) {
              InputRef.current.blur();
            }
          }}
          isShowing={!openSubMenu && openSearchResults}
          searchTerm={searchTerm}
          itemUrlMap={itemUrlMap}
        />
      </Content>
    </Container>
  );
};

export default MobileNavigationView;
