import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Container, Content, Phone } from './styles';
import { Label } from 'components/FormComponents/styles';

interface Props {
  name: string;
  className?: string;
  label?: string;
  value?: string;
  required?: boolean;
  showRequired?: boolean;
  placeholder?: string;
  error?: boolean;
  errorLimited?: boolean;
  disabled?: boolean;
  optional?: boolean;
  extraLabel?: string;
  handleChange?: (value: string) => void;
}

const PhoneInputView = ({
  className,
  name,
  label,
  value,
  required,
  showRequired,
  placeholder,
  error,
  errorLimited,
  disabled = false,
  optional,
  handleChange,
  extraLabel,
}: Props): JSX.Element => {
  const { register, setValue, clearErrors } = useFormContext();
  const [val, setVal] = useState(
    [
      value?.slice(0, 3) || '',
      value?.slice(3, 6) || '',
      value?.slice(6, 10) || '',
    ] || ['', '', '']
  );

  useEffect(() => {
    if (val.join('') !== value) {
      setVal(
        [
          value?.slice(0, 3) || '',
          value?.slice(3, 6) || '',
          value?.slice(6, 10) || '',
        ] || ['', '', '']
      );
    }
    if (value) {
      setValue(name, value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (register) {
      register(name, { required });
    }
    if (value) {
      setValue(name, value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register, name]);

  const onValChange = useCallback(
    (e, index, maxLength) => {
      const v = e.target.value;
      if (v.length <= maxLength) {
        const splitVal = [...val];
        splitVal[index] = v;
        setVal(splitVal);

        const joinVal = splitVal.join('');
        setValue(name, joinVal);

        if (handleChange) {
          handleChange(joinVal);
        }

        if (joinVal && (errorLimited || error)) {
          clearErrors(name);
        }

        if (v.length === maxLength) {
          const nextIndex = index + 1;
          const nextInput = document.getElementsByName(
            `${name}[${nextIndex}]`
          )[0];
          if (nextInput) {
            nextInput.focus();
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, setValue, val]
  );
  const onValBlur = useCallback(
    () => {
      if (val.join('') && (errorLimited || error)) {
        clearErrors(name);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, setValue, val]
  );
  return (
    <Container className={classNames(`form-text ${className}`)}>
      {label && (
        <Label htmlFor={name} className={error ? 'error' : ''}>
          {label}
          {optional && <span className="optional">Optional</span>}
          {showRequired && <span className="optional">Required</span>}
          {extraLabel && <span className="optional">{extraLabel}</span>}
        </Label>
      )}
      <Content id={name}>
        <Phone
          type="number"
          name={`${name}[0]`}
          data-testid={`${name}[0]`}
          onChange={(e) => onValChange(e, 0, 3)}
          onBlur={onValBlur}
          value={val[0] ?? ''}
          disabled={disabled}
          placeholder={placeholder?.slice(0, 3) || ''}
        />
        <Phone
          type="number"
          name={`${name}[1]`}
          data-testid={`${name}[1]`}
          onChange={(e) => onValChange(e, 1, 3)}
          onBlur={onValBlur}
          value={val[1] ?? ''}
          disabled={disabled}
          placeholder={placeholder?.slice(3, 6) || ''}
        />
        <Phone
          type="number"
          name={`${name}[2]`}
          data-testid={`${name}[2]`}
          onChange={(e) => onValChange(e, 2, 4)}
          onBlur={onValBlur}
          value={val[2] ?? ''}
          disabled={disabled}
          placeholder={placeholder?.slice(6, 10) || ''}
        />
      </Content>
    </Container>
  );
};

export default PhoneInputView;
