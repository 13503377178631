import Tabs from 'components/Tabs';
import PageI from 'data/types/Page.types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  viewWYSIWYG,
  formatHeaderNodes,
  formatBodyLinks,
} from 'utils/functions';
import { Container, Content } from './styles';
import gql from 'graphql-tag';
import * as queries from '../../graphql/queries';
import { useQuery } from '@apollo/client';

interface IUser {
  office: {
    active: boolean;
    group: string;
    id: string;
    name: string;
    sort: number;
    timezone: string;
  };
}
interface Props {
  data: PageI[];
  type: string;
  user?: IUser;
}

interface IOffice {
  id: string;
  active: boolean;
  group: string;
  name: string;
  sort: number;
  timezone: string;
}

const LIST_OFFICES = gql(queries.listOffices);

const TemplateView = ({ type, data, user }: Props): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [bodyData, setBodyData] = useState('');
  const [activeOfficesData, setActiveOfficesData] = useState<IOffice[]>([]);
  const El = useRef<HTMLDivElement>(null);

  const { data: officeData } = useQuery(LIST_OFFICES);

  const getBody = async (content: string) => {
    const body = await viewWYSIWYG(content);
    const bodyFormatted = formatHeaderNodes(body);
    const bodyLinks = formatBodyLinks(bodyFormatted);

    setBodyData(bodyLinks);
  };

  useEffect(() => {
    let body = '';
    if (type === 'plain') {
      body = data[0].body || '';
    } else if (type === 'tabbed') {
      body = data?.[selectedTab]?.body || '';
    } else if (type === 'officeTab') {
      data.map((item) => {
        if (item?.officeId === activeOfficesData[selectedTab]?.id) {
          body = item.body || '';
        }
      });
    }
    setBodyData(body || '');

    if (body) {
      getBody(body);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, type, selectedTab, officeData, user]);

  // set the corresponding office tab depending on which group user belongs to
  useEffect(() => {
    if (type === 'officeTab') {
      const correspondingOffice = activeOfficesData.filter(
        (item: IOffice) => user?.office?.id === item.id
      );
      setSelectedTab(correspondingOffice[0]?.sort);
    }
  }, [type, officeData, user, activeOfficesData]);

  const tabsData = useMemo(() => {
    return data?.map((item) => {
      return {
        label: item.title,
      };
    });
  }, [data]);

  const officesData = useMemo(() => {
    if (!officeData?.listOffices?.items) {
      return [];
    }
    console.log(officeData.listOffices.items);
    const activeOffices = officeData.listOffices.items
      .slice()
      .filter((office: IOffice) => {
        return office.active === true;
      })
      .sort((a: IOffice, b: IOffice) => {
        return a.sort - b.sort;
      });
    setActiveOfficesData([...activeOffices]);
    return activeOffices.map((item: IOffice) => {
      return {
        label: item.name,
      };
    });
  }, [officeData]);

  const renderSimple = () => {
    if (data.length < 1) return;
    return (
      <Content
        className="simple"
        dangerouslySetInnerHTML={{ __html: bodyData }}
      />
    );
  };
  const renderTabbed = () => {
    return (
      <>
        <Tabs
          tabs={tabsData || []}
          returnActive={(selected) => setSelectedTab(selected)}
        />
        <Content
          dangerouslySetInnerHTML={{
            __html: bodyData,
          }}
        />
      </>
    );
  };
  const renderOffice = () => {
    return (
      <>
        <Tabs
          tabs={officesData || []}
          returnActive={(selected) => setSelectedTab(selected)}
          defaultActive={user?.office?.name}
        />
        <Content
          dangerouslySetInnerHTML={{
            __html: bodyData,
          }}
        />
      </>
    );
  };
  return (
    <Container ref={El}>
      {type === 'plain' && renderSimple()}
      {type === 'tabbed' && renderTabbed()}
      {type === 'officeTab' && renderOffice()}
    </Container>
  );
};

export default TemplateView;
