import React from 'react';
import { Container, TimeContainer } from './styles';
import classNames from 'classnames';
import Time from './Time';

interface Props {
  error?: boolean;
  errorStart?: boolean;
  invalidStart?: boolean;
  invalidEnd?: boolean;
  disabled?: boolean;
  setError: ({
    startTime,
    endTime,
  }: {
    startTime?: boolean;
    endTime?: boolean;
  }) => void;
  setValidDates: ({ start, end }: { start?: boolean; end?: boolean }) => void;
  multiDay: boolean;
  timezone: string;
}

const TimeSelector = ({
  error,
  errorStart,
  setError,
  disabled,
  setValidDates,
  invalidEnd,
  invalidStart,
  multiDay,
  timezone,
}: Props): JSX.Element => {
  // const [formatTime, setFormatTime] = useState({ startTime: '', endTime: '' });
  // const tz = new Date()
  //   .toLocaleTimeString('en-us', { timeZoneName: 'short' })
  //   .split(' ')[2];

  //* yashar says to remove the timezone guess and keep it static
  //* don't let him go backsies
  //* he went backsies (-_-)
  // const tz = 'PST';

  return (
    <Container className={classNames({ disabled: disabled })}>
      <TimeContainer>
        <Time
          timezone={timezone}
          label="Leaving"
          placeholder="hh:mm"
          name="startTime"
          error={error || errorStart || invalidStart}
          handleError={(err) => {
            const keys = Object.keys(err)[0];
            if (keys === 'validStart') {
              console.log(err);
              setValidDates({ start: err.validStart });
            } else {
              setError(err);
            }
          }}
        />
        <Time
          timezone={timezone}
          label="Returning"
          placeholder="hh:mm"
          name="endTime"
          optional={true}
          error={error || invalidEnd}
          disabled={multiDay}
          handleError={(err) => {
            const keys = Object.keys(err)[0];
            if (keys === 'validEnd') {
              console.log(err);
              setValidDates({ end: err.validEnd });
            } else {
              // setTimeout(() => {
              console.log('handleError: ', err);
              setError(err);
              // }, 1000);
            }
          }}
        />
      </TimeContainer>
      <p className="description">
        Time will round down to the nearest 15 min increment for leaving and
        round up to the nearest 15 min increment for returning.
      </p>
    </Container>
  );
};

export default TimeSelector;
