import EventI from 'data/types/Events';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useRef, useState } from 'react';
import {
  Calendar,
  Container,
  Content,
  Event,
  EventDate,
  EventList,
  EventMonth,
  EventNone,
  Events,
  EventTime,
  EventTitle,
  Eyebrow,
} from './styles';
import { BeatLoader } from 'react-spinners';
import { colors } from 'shared/variables';
import slugify from 'slugify';
import { convertTimezones } from 'utils/functions';
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);
require('date-time-format-timezone');

interface Props {
  data: EventI[];
  loading: boolean;
  tz: string;
}

const EventsView = ({ data, loading, tz }: Props): JSX.Element => {
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [currentEvents, setCurrentEvents] = useState<EventI[]>([]);
  const EventListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (data && currentDate) {
      const currentEventsTemp = data.filter((event: EventI): boolean => {
        const eventDate = dayjs(event.startDate);
        const eventDateEnd = dayjs(event.endDate);
        return (
          (eventDate.isSame(currentDate, 'month') &&
            eventDate.isSame(currentDate, 'year')) ||
          (eventDateEnd.isSame(currentDate, 'month') &&
            eventDateEnd.isSame(currentDate, 'year'))
        );
      });
      setCurrentEvents(
        currentEventsTemp
          // .filter((item) => !item.title.toLowerCase().includes('working in'))
          .sort((x, y) => {
            if (dayjs(x.startDate).isAfter(dayjs(y.startDate))) {
              return 1;
            } else if (dayjs(x.startDate).isBefore(dayjs(y.startDate))) {
              return -1;
            }
            return 0;
          })
      );
    }
  }, [currentDate, data]);

  const handleViewChange = (month: number, year: number) => {
    setCurrentDate(dayjs().month(month).year(year));
  };

  const cleanId = (unclean: string) => {
    const newString = unclean.replace(/[^a-zA-Z]/g, '');
    return slugify(newString).toLowerCase();
  };

  const renderEvent = (event: EventI): JSX.Element => {
    let formattedDate = dayjs(event.startDate).format('MMM D');
    if (!dayjs(event.startDate).isSame(dayjs(event.endDate), 'day')) {
      formattedDate += ' - ' + dayjs(event.endDate).format('MMM D');
    }

    let formattedTime = dayjs(event.startDate)
      .tz(convertTimezones(tz))
      .format('LT');
    if (event.startDate.includes(':') && event.endDate.includes(':')) {
      formattedTime +=
        ' - ' +
        dayjs(event.endDate).tz(convertTimezones(tz)).format('LT') +
        ` ${tz}`;
    } else {
      formattedTime = 'All Day';
    }
    return (
      <Event key={event.id} id={cleanId(event.title)}>
        <EventTitle>{event.title}</EventTitle>
        <EventDate>{formattedDate}</EventDate>
        <EventTime>{formattedTime}</EventTime>
      </Event>
    );
  };

  const scrollEventLog = (date: string) => {
    // match the date to an event
    let foundEvent = false;
    currentEvents.map((event: EventI) => {
      if (
        dayjs(date).isBetween(
          dayjs(event.startDate),
          dayjs(event.endDate),
          'day',
          '[]'
        )
      ) {
        const eventList = EventListRef?.current;
        const eventListTop = eventList?.offsetTop || 0;
        const eventItem = document.querySelector(
          `#${cleanId(event.title)}`
        ) as HTMLElement;
        const eventTop = eventItem?.offsetTop || 0;
        if (!foundEvent && eventList) {
          eventList.scrollTop = eventTop - eventListTop;

          foundEvent = true;
        }
        eventItem.classList.add('active');

        setTimeout(() => {
          eventItem.classList.remove('active');
        }, 1000);
      }
    });
  };

  return (
    <Container>
      <Eyebrow>Upcoming Company Events & Birthdays.</Eyebrow>
      <Content>
        <Calendar
          start=""
          end=""
          dateSelected={(date) => scrollEventLog(date)}
          allowDisabledArrow={true}
          handleViewChange={handleViewChange}
          holidays={currentEvents}
          allowWFHDays={true}
          allowWeekends={true}
          allowAllDays={true}
          tz={tz}
        />
        <Events>
          {console.log('currentEvents:', currentEvents)}
          <EventMonth>{dayjs(currentDate).format('MMMM YYYY')}</EventMonth>
          <EventList ref={EventListRef}>
            {currentEvents.map((event: EventI) => {
              return renderEvent(event);
            })}
            {currentEvents.length === 0 && !loading && (
              <EventNone>
                No {dayjs(currentDate).format('MMMM')} Events
              </EventNone>
            )}
            {loading && (
              <EventNone>
                Loading Events <BeatLoader color={colors.brownLight} size={4} />
              </EventNone>
            )}
          </EventList>
        </Events>
      </Content>
    </Container>
  );
};

export default EventsView;
