import React, { useEffect, useState } from 'react';
import {
  Container,
  Content,
  Item,
  LogoWrapper,
  Main,
  MainItem,
  MainLink,
  Social,
  SocialLink,
  StyledLogo,
  Sub,
  SubLink,
  UserContainer,
  UserInfo,
  BlobContainer,
  Blob,
  UserImage,
} from './styles';

import { MainLink2, SubLink2 } from './styles-new';

import {
  IconLogo,
  IconFacebookCustom,
  IconInstagramCustom,
  IconUser,
  IconYoutubeCustom,
} from '../IconsView';
import classNames from 'classnames';
import NavigationI from 'data/types/Navigation.types';
import { UserI } from 'data/types/User.types';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useFeature } from 'flagged';
import { toSvg } from 'jdenticon';

interface Props {
  data: NavigationI[] | [];
  user: UserI;
  signOut: () => void;
}

const Navigation = ({ data, user }: Props): JSX.Element => {
  const [activeItem, setActiveItem] = useState('');
  const hasDevWiki = useFeature('devWiki');
  const hasLunchTable = useFeature('lunchTable');
  const location = useLocation();

  const hasNewNavStyling = useFeature('v1.5');

  const MainLinkEle = hasNewNavStyling ? MainLink2 : MainLink;
  const SubLinkEle = hasNewNavStyling ? SubLink2 : SubLink;

  useEffect(() => {
    // check that location pathname matched the active item
    if (location.pathname === '/') {
      //if its home
      if (activeItem !== 'home') {
        setActiveItem('home');
      }
    } else if (location.pathname === '/404') {
      setActiveItem('');
    } else {
      const parentPath = location.pathname
        .split('/')
        .filter((x: string) => x)[0];
      const parent = data.find((x) => x.slug === parentPath);
      if (parent) {
        setActiveItem(parent.id);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, data]);

  const handleNavClick = (item: string) => {
    if (activeItem === item) {
      return;
    }
    setActiveItem(item);
  };

  return (
    <>
      <Container>
        <Content>
          <StyledLogo to="">
            <LogoWrapper>
              <IconLogo />
              <BlobContainer>
                <Blob />
              </BlobContainer>
            </LogoWrapper>
          </StyledLogo>
          <Main>
            <Item className="home">
              <MainItem>
                <MainLinkEle
                  exact
                  onClick={() => {
                    handleNavClick('home');
                  }}
                  to="/"
                >
                  Home
                </MainLinkEle>
              </MainItem>
            </Item>
            {hasDevWiki ? (
              <Item className="dev-wiki">
                <MainItem>
                  <MainLinkEle
                    onClick={() => {
                      handleNavClick('dev-wiki');
                    }}
                    to="/dev-wiki"
                  >
                    Dev Wiki
                  </MainLinkEle>
                </MainItem>
              </Item>
            ) : (
              ''
            )}
            {hasLunchTable ? (
              <Item className="lunch-table">
                <MainItem>
                  <MainLinkEle
                    onClick={() => {
                      handleNavClick('lunch-table');
                    }}
                    to="/lunch-table"
                  >
                    Lunch Picker
                  </MainLinkEle>
                </MainItem>
              </Item>
            ) : (
              ''
            )}
            {data.map((item) => (
              <Item
                key={item.id}
                className={classNames(`${item.slug}`)}
                style={{
                  maxHeight:
                    activeItem === item.id && item.subItems
                      ? document.querySelector(`.${item.slug}`)?.scrollHeight +
                        'px'
                      : '20px',
                }}
              >
                <MainLinkEle
                  exact
                  onClick={() => {
                    handleNavClick(item.id);
                  }}
                  to={`/${item.slug}`}
                >
                  {item.label}
                </MainLinkEle>
                {item?.subItems && (
                  <Sub>
                    {item?.subItems?.map((sub) => (
                      <SubLinkEle key={sub.id} to={`/${item.slug}/${sub.slug}`}>
                        {sub.label}
                      </SubLinkEle>
                    ))}
                  </Sub>
                )}
              </Item>
            ))}
            <Social>
              <SocialLink
                href="https://www.instagram.com/fiveanddone/"
                target="_blank"
              >
                <IconInstagramCustom />
                <span>Gram.</span>
              </SocialLink>
              <SocialLink
                href="https://www.facebook.com/FiveAndDone/"
                target="_blank"
              >
                <IconFacebookCustom />
                <span>Face.</span>
              </SocialLink>
              <SocialLink
                href="https://www.youtube.com/channel/UC7KpgwPeysXBbnb6YwVAvRg"
                target="_blank"
              >
                <IconYoutubeCustom />
                <span>Tube.</span>
              </SocialLink>
            </Social>
          </Main>
          <UserContainer>
            {user.picture ? (
              <UserImage src={user.picture} />
            ) : user.name ? (
              <div dangerouslySetInnerHTML={{ __html: toSvg(user.name, 32) }} />
            ) : (
              <IconUser />
            )}
            {/* <IconUser /> */}
            <UserInfo>
              <span className="name">{user.name}</span>
              <span className="office">{user.office}</span>
              <Link className="logout" to="/logout">
                Log out
              </Link>
            </UserInfo>
          </UserContainer>
        </Content>
      </Container>
    </>
  );
};

export default Navigation;
