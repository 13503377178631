import classNames from 'classnames';
import { IconCheckmark, IconSimpleArrowSmall } from 'components/IconsView';
import { TabI } from 'data/types/Tab.types';
import React, { createRef, useEffect, useRef, useState } from 'react';
import slugify from 'slugify';

import {
  TabContainer,
  Tab,
  More,
  MoreButton,
  Container,
  MoreList,
  MoreItem,
  MoreTab,
  DropdownContainer,
  Top,
  Dropdown,
  Item,
  ItemButton,
} from './styles';
import { breakpoints } from 'shared/variables';
import { debounce } from 'utils/functions';

interface Props {
  tabs: TabI[];
  defaultActive?: string;
  returnActive: (active: number) => void;
  breakpoint?: string;
  className?: string;
  noBreak?: boolean;
}

interface OverflowI {
  label: string;
  index: number;
}

const Tabs = ({
  className,
  tabs,
  defaultActive,
  returnActive,
  breakpoint,
  noBreak,
}: Props): JSX.Element => {
  const [isTablet, setIsTablet] = useState(false);
  const [activeTab, setActiveTab] = useState('');
  const [overflowTabs, setOverflowTabs] = useState<OverflowI[]>([]);
  const [toggleMore, setToggleMore] = useState(false);
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const ElRef = useRef<HTMLDivElement>(null);
  const MoreRef = useRef<HTMLDivElement>(null);
  const TabsRef = useRef<HTMLHeadingElement>(null);
  // eslint-disable-next-line
  const TabButtonsRef = useRef<any[]>([]);

  useEffect(() => {
    if (!noBreak) {
      setIsTablet(window.innerWidth < breakpoints[breakpoint || 'tablet']);
      window.addEventListener(
        'resize',
        debounce(function () {
          setIsTablet(window.innerWidth < breakpoints[breakpoint || 'tablet']);
        })
      );
    }

    return () => {
      if (!noBreak) {
        window.addEventListener(
          'resize',
          debounce(function () {
            setIsTablet(
              window.innerWidth < breakpoints[breakpoint || 'tablet']
            );
          })
        );
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tabs.length > 0) {
      setActiveTab(slugify(defaultActive || tabs[0]?.label));
    }
  }, [tabs, defaultActive]);

  useEffect(() => {
    const tabContainer = TabsRef.current;
    const tabButtons = TabButtonsRef.current;
    const more = MoreRef.current;

    if (tabContainer && tabButtons && more) {
      const fullWidth = tabContainer.offsetWidth;
      let remainingSpace = fullWidth - more.offsetWidth;
      const overflow: OverflowI[] = [];

      tabButtons.map((tab, index) => {
        remainingSpace -= tab.current.offsetWidth + 50;
        if (remainingSpace < 0) {
          overflow.push({ label: tab.current.innerText, index });
        }
      });
      setOverflowTabs(overflow);
    }
  }, [TabsRef]);

  console.log(activeTab);

  return (
    <Container className={className} ref={ElRef}>
      <TabContainer ref={TabsRef} className={classNames({ hide: isTablet })}>
        {tabs.map((tab, index) => {
          TabButtonsRef.current[index] = createRef();

          return (
            <Tab
              className={classNames({
                hide: overflowTabs
                  ? overflowTabs.findIndex((t) => {
                      return (
                        t?.label?.toLowerCase() === tab?.label?.toLowerCase()
                      );
                    }) > -1
                  : false,
              })}
              ref={TabButtonsRef.current[index]}
              key={slugify(tab.label)}
              active={activeTab === slugify(tab.label)}
              onClick={() => {
                console.log('tab', tab.label);
                setActiveTab(slugify(tab.label));
                returnActive(index);
              }}
            >
              {tab.label}
            </Tab>
          );
        })}
        <More
          className={classNames({ show: overflowTabs.length > 0 })}
          ref={MoreRef}
          onClick={() => setToggleMore(!toggleMore)}
        >
          <MoreButton
            type="button"
            className={classNames({ open: toggleMore })}
          >
            More <IconSimpleArrowSmall />
          </MoreButton>
          <MoreList className={classNames({ show: toggleMore })}>
            {overflowTabs?.map((tab, index) => {
              if (!tab) return null;
              return (
                <MoreItem key={index}>
                  <MoreTab
                    type="button"
                    active={activeTab === slugify(tab.label || '')}
                    onClick={() => {
                      setActiveTab(slugify(tab.label || ''));
                      returnActive(tab.index);
                    }}
                  >
                    {tab.label}
                  </MoreTab>
                </MoreItem>
              );
            })}
          </MoreList>
        </More>
      </TabContainer>
      <DropdownContainer className={classNames({ show: isTablet })}>
        <Top
          type="button"
          onClick={() => setToggleDropdown(!toggleDropdown)}
          className={classNames({ open: toggleDropdown })}
        >
          {activeTab} <IconSimpleArrowSmall />
        </Top>
        <Dropdown className={classNames({ show: toggleDropdown })}>
          {tabs.map((tab, index) => {
            return (
              <Item key={index}>
                <ItemButton
                  type="button"
                  onClick={() => {
                    setActiveTab(slugify(tab.label));
                    returnActive(index);
                    setToggleDropdown(!toggleDropdown);
                  }}
                >
                  {tab.label}
                  {slugify(tab.label) === activeTab && <IconCheckmark />}
                </ItemButton>
              </Item>
            );
          })}
        </Dropdown>
      </DropdownContainer>
    </Container>
  );
};

export default Tabs;
