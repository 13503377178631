import React, { useState } from 'react';
import gql from 'graphql-tag';
import * as mutations from 'graphql/mutations';
const DELETE_PAGE = gql(mutations.deletePage);
import { useMutation } from '@apollo/client';
import PageI from 'data/types/Page.types';
import Modal from 'components/Modal';
import { Container, ModalButton } from './styles';
import classNames from 'classnames';
import PageItem from '../PageItem/PageItem';

interface Props {
  pageData: PageI[];
  createPage: (pageId: string, sort: number) => void;
  refetch: () => void;
  showDrawer: boolean;
}

const PageList = ({
  pageData,
  createPage,
  refetch,
  showDrawer,
}: Props): JSX.Element => {
  const [deletingPage, setDeletingPage] = useState<string | null>(null);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [deletePage] = useMutation(DELETE_PAGE);
  console.log(pageData);

  return (
    <Container
      className={classNames({ open: showDrawer, closed: !showDrawer })}
    >
      {pageData
        .slice()
        .sort((a: PageI, b: PageI) => {
          const aTitle = a.title.toLowerCase();
          const bTitle = b.title.toLowerCase();
          if (aTitle < bTitle) return -1;
          if (aTitle > bTitle) return 1;
          return 0;
        })
        .map((item: PageI) => (
          <PageItem
            data={item}
            createPage={createPage}
            deletePage={setDeletingPage}
            refetch={refetch}
          />
        ))}
      {deletingPage && (
        <Modal
          id="modal-page-delete"
          title="Are you sure you want to delete this page?"
          toggle={(refetchPage) => {
            if (refetchPage) {
              refetch();
            }
            setDeletingPage(null);
          }}
        >
          <ModalButton
            type="button"
            onClick={async () => {
              setDeleteLoading(true);
              await deletePage({
                variables: {
                  input: {
                    id: deletingPage,
                  },
                },
              });
              await refetch();
              setDeletingPage(null);
              setDeleteLoading(false);
            }}
          >
            {deleteLoading ? 'Deleting...' : 'Delete Page'}
          </ModalButton>
          <ModalButton
            type="button"
            onClick={() => {
              setDeletingPage(null);
            }}
          >
            Cancel
          </ModalButton>
        </Modal>
      )}
    </Container>
  );
};

export default PageList;
