import styled from 'styled-components';
import { size, mq } from '../../shared/mixins';
import { colors, maxWidth, padding } from '../../shared/variables';
import {
  bodyCopySmall,
  eyebrow,
  eyebrowRegular,
  gridDesktop,
  h3,
} from '../../shared/extends';
import bkgTextureWhite from '../../images/bg_texture-dust-white.jpg';
import CheckboxView from 'components/FormComponents/CheckboxView';
import InputView from 'components/FormComponents/InputView';
import { Input as InputViewInput } from 'components/FormComponents/InputView/styles';
import Button from 'components/Button/Button';

export const Container = styled.div`
  background: url(${bkgTextureWhite}) 0 0 repeat, ${colors.greyLight};
  padding: 0 ${padding.desktop}px 200px;
  position: relative;

  ${mq.tablet`
    padding: 0 ${padding.tablet}px 140px;
  `}

  ${mq.phoneWide`
    padding: 0 ${padding.mobile}px 80px;
  `}
`;

export const Content = styled.form`
  ${gridDesktop};
  max-width: ${maxWidth}px;
  position: relative;

  &:before {
    background: ${colors.greyMedium};
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    ${size('1px', '600px')};
  }

  ${mq.desktopWide`
    display: flex;
    flex-direction: column;

    &:before {
      display: none;
    }
  `}
`;

export const Side = styled.div`
  grid-column: 1 / span 5;

  &:last-child {
    padding: 0 18px 0 0;
    grid-column: 8 / span 5;
  }

  ${mq.desktop`
    &:last-child {
      padding: 0;
    }
  `}

  ${mq.tabletWide`
    &:last-child {
      margin-top: 80px;
      padding: 0;
    }
  `}
`;

export const Step = styled.span`
  ${eyebrowRegular};
  color: ${colors.primary};
  margin: 17px 0;
`;

export const Title = styled.h3`
  ${h3};
  color: ${colors.brownLight};
  margin: 0 0 40px;

  ${mq.phoneWide`
    margin: 0 0 32px;
  `}
`;

export const Row = styled.div`
  margin-bottom: 24px;
  width: 100%;
`;

export const Input = styled(InputView)`
  input {
    outline-color: ${colors.greyMedium};
  }
`;

export const Checkbox = styled(CheckboxView)`
  margin-top: 8px;
`;

export const PhoneContainer = styled.div``;

export const PhoneInputContainer = styled.div`
  display: flex;
`;

export const PhoneInput = styled(InputViewInput)`
  outline-color: ${colors.greyMedium};
  margin-right: 1px;
  width: 33.333%;

  &:last-child {
    margin-right: 0;
  }
`;

export const SubmitButton = styled(Button)<{ primary: boolean }>`
  ${eyebrow};
  display: inline-flex;
  align-items: center;
  padding: 15px 28px;
  text-align: left;

  svg {
    margin-right: 9px;
    fill: ${colors.white};
    flex: 0 0 auto;
    ${size('13px', '13px')};
  }

  &.disabled {
    // pointer-events: none;
    background: ${colors.grey};
    color: ${colors.brownLightLight};

    &:hover {
      cursor: not-allowed;
    }

    svg {
      fill: ${colors.brownLightLight};
    }
  }
`;

export const Error = styled.p`
  ${bodyCopySmall};
  color: ${colors.highlight};
  margin: 8px 0 0;
  margin-left: auto;
`;

export const Preview = styled.div`
  background-color: ${colors.white};
  grid-column: 2 / span 5;
  margin-bottom: 32px;
  padding: 19px 31px;
  ${size('auto', '150px')};

  table tr:first-child td {
    display: none;
  }

  &#previewTwo {
    display: none;
  }

  ${mq.phone`
    td:last-child a {
      word-break: break-all;
    }
  `}
`;

export const Information = styled.p`
  ${bodyCopySmall};
  grid-column: 2 / span 5;
  margin-top: 48px;

  a {
    color: ${colors.highlight};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;
