import IntroView from 'components/IntroView';
import LoaderView from 'components/LoaderView';
import React, { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import * as queries from 'utils/queries';
import { Helmet } from 'react-helmet';
import LunchSpotsView from 'components/LunchSpotsView';

const GET_PAGE_BY_SLUG = gql(queries.getPageBySlug);

function LunchSpots({ slug }: { slug: string }): JSX.Element {
  const { data: slugData, loading: slugLoading } = useQuery(GET_PAGE_BY_SLUG, {
    variables: {
      slug,
    },
  });

  const pageData = useMemo(() => {
    return slugData?.getPageBySlug.items[0];
  }, [slugData]);

  if (slugLoading) {
    return <LoaderView />;
  }
  return (
    <>
      <Helmet>
        <title>{pageData?.title}</title>
        <meta name="description" content={pageData?.description || ''} />
      </Helmet>
      <IntroView type="subpage" data={pageData} />
      <LunchSpotsView />
    </>
  );
}

export default LunchSpots;
