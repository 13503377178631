export const listPages = /* GraphQL */ `
  query ListPages(
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        status
        createdAt
        updatedAt
        title
        slug
        description
        headline
        body
        inlineBody
        ctaText
        ctaLink
        headlineLow
        bodyLow
        layout
        pageId
        local
        publishedAt
        image
        sort
        meta
        parentPage {
          id
          label
          status
          createdAt
          updatedAt
          title
          slug
          description
          headline
          body
          inlineBody
          ctaText
          ctaLink
          headlineLow
          bodyLow
          layout
          pageId
          local
          publishedAt
          image
          sort
          meta
        }
      }
      nextToken
    }
  }
`;
