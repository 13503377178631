export const listPages = /* GraphQL */ `
  query ListPagesByPageId(
    $pageId: ID
    $sort: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPagesByPageId(
      pageId: $pageId
      sort: $sort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        status
        createdAt
        updatedAt
        title
        slug
        description
        eyebrow
        headline
        body
        inlineBody
        ctaText
        ctaLink
        headlineLow
        bodyLow
        layout
        pageId
        local
        publishedAt
        image
        sort
        meta
        pages(filter: { status: { eq: active } }) {
          items {
            id
            label
            status
            createdAt
            updatedAt
            title
            slug
            description
            eyebrow
            headline
            body
            inlineBody
            ctaText
            ctaLink
            headlineLow
            bodyLow
            layout
            pageId
            local
            publishedAt
            image
            sort
            meta
          }
        }
      }
      nextToken
    }
  }
`;

export const listMembers = /* GraphQL */ `
  query ListMembers(
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
      }
    }
  }
`;
