import { bodyCopySmall, eyebrow } from 'shared/extends';
import { mq } from 'shared/mixins';
import { font, fonts, fontSize } from 'shared/typography';
import { colors, maxWidth, padding } from 'shared/variables';
import styled from 'styled-components';

export const Container = styled.div`
  /* position: relative; */
  min-height: 100%;
  padding: 0 ${padding.desktop}px 160px;
  max-width: ${maxWidth}px;
  width: 100%;
  margin-top: 100px;

  ${mq.tablet`
  padding: 0 ${padding.tablet}px 160px;
`}

  ${mq.phoneWide`
  padding: 0 27px 80px ${padding.mobile}px;
`}
`;

export const TitleInput = styled.textarea`
  border: 0;
  background-color: transparent;
  font-family: ${fonts.primary};
  font-weight: 900;
  ${fontSize(65, 67, -0.2)};
  color: ${colors.brownLight};
  padding: 0;
  resize: none;
`;

export const DraftContainer = styled.div`
  min-height: 100%;
  max-width: 700px;

  /* Override default */
  .Draftail-Toolbar {
    background: transparent !important;
    border: none !important;
    padding: 0;
  }
  /* Override default */
  .Draftail-Editor {
    background: transparent;
    border: none !important;
    min-height: 100%;
    padding: 20px 0;

    .public-DraftEditorPlaceholder-root,
    .public-DraftEditor-content {
      padding: 0;
    }
  }
  /* Override default */
  .public-DraftEditor-content {
    ${font('body')}
    color: ${colors.brownMedium};
  }
  /* Override default */
  .public-DraftEditorPlaceholder-inner {
    ${font('body')}
    color: ${colors.brownMedium};
  }

  .Draftail-block--unstyled,
  .Draftail-Editor .public-DraftEditorPlaceholder-inner {
    margin: 0;
  }

  .DraftEditor-editorContainer {
    ${bodyCopySmall};
    ${fontSize(16, 20, 0)};

    h1 {
      font-family: ${fonts.primary};
      color: ${colors.brownLight};
      ${fontSize(48, 52, -0.2)};
    }

    h2 {
      ${font('primary', 'bold')};
      ${fontSize(24, 27, 0)};
      color: ${colors.brownLight};
    }
  }
`;

export const Status = styled.div`
  ${eyebrow};
  pointer-events: none;
  color: ${colors.brownLight};
  border-radius: 5px;
  background: rgba(256, 256, 256, 0.7);
  padding: 16px 24px;
  position: absolute;
  top: 24px;
  right: 24px;
`;
