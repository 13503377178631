import styled from 'styled-components';
import { mq } from '../../shared/mixins';
import { colors, padding, maxWidth } from '../../shared/variables';
import { font, fontSize } from '../../shared/typography';
import bkgTexture from '../../images/bg_texture-dust-white.jpg';
import { WYSIWYGStyles } from 'shared/extends';

export const Container = styled.section`
  background: url(${bkgTexture}) 0 0 repeat, ${colors.greyLight};
  padding: 0 ${padding.desktop}px 160px;
  max-width: ${maxWidth}px;
  width: 100%;

  ${mq.tablet`
    padding: 0 ${padding.tablet}px 160px;
  `}

  ${mq.phoneWide`
    padding: 0 27px 80px ${padding.mobile}px;
  `}
`;

export const Content = styled.div`
  margin-top: 32px;

  &.simple {
    margin-top: 0;
  }

  ${mq.tablet`
    margin-top: 8px;
  `}

  > *:first-child {
    margin-top: 0;
  }

  p,
  blockquote,
  ul,
  ol,
  table,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    max-width: 740px;
  }

  p,
  table {
    color: ${colors.brownLight};
    margin: 0;
    ${font('body')}
    ${fontSize(14, 20)}
  }

  ${mq.tabletWide`
    table {
      ${fontSize(10, 16)}
    }
  `}

  blockquote {
    color: ${colors.brownLight};
    font-style: italic;
    ${font('primary', 'light')};
    ${fontSize(16, 24, -0.2)};
  }

  ol,
  ul {
    color: ${colors.brownLight};
    margin: 0;
    padding: 0;
    list-style: none;
    ${font('body')};
    ${fontSize(14, 20)};
  }

  a {
    color: ${colors.highlight};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  h1 {
    color: ${colors.brownLight};
    ${font('primary', 'black')};
    ${fontSize(65, 67, -0.2)};
  }
  h2 {
    color: ${colors.brownLight};
    ${font('primary', 'black')};
    ${fontSize(48, 52, -0.2)};
  }
  h3 {
    color: ${colors.brownLight};
    ${font('primary', 'black')};
    ${fontSize(24, 27, 0)};
  }
  h4 {
    color: ${colors.brownLight};
    ${font('primary', 'medium')};
    ${fontSize(20, 24, 0)};
  }

  img {
    max-width: 100%;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    tr {
    }
    td {
      border: 1px solid ${colors.brownMedium};
      padding: 5px;
      min-height: 30px;
    }
  }
  ${WYSIWYGStyles};
`;
