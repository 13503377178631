import Button from 'components/Button';
import { Link } from 'react-router-dom';
import { eyebrowRegular, resetButtonStyles } from 'shared/extends';
import { size } from 'shared/mixins';
import { colors, customBezierEase } from 'shared/variables';
import styled from 'styled-components';

export const Container = styled.li`
  ${eyebrowRegular};
  margin: 24px 0 0;
  border: 1px solid ${colors.brownLight};

  &:first-child {
    margin-top: 0;
  }
`;

export const ItemTop = styled.div`
  display: flex;
`;

export const PageLink = styled(Link)`
  cursor: pointer;
  position: relative;
  padding: 16px 24px;
  display: block;
  color: ${colors.brownMedium};
  text-decoration: none;
  width: 100%;
  transition: color 0.3s ${customBezierEase};

  &::before,
  &:after {
    content: '';
    display: block;
    background-color: ${colors.greyLightLight};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    ${size('1px', '30px')}
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }

  &:hover {
    color: ${colors.highlight};
  }
`;

export const Action = styled.button`
  ${resetButtonStyles};
  width: 52px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: ${colors.brownLightLight};
    transition: fill 0.3s ${customBezierEase};
  }

  &.icon-arrow {
    transform: rotate(90deg);
    transition: transform 0.3s ${customBezierEase};

    &.active {
      transform: rotate(270deg);
    }
  }

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
    &.icon-arrow {
      transform: rotate(0deg);
    }
  }

  /* &.icon-trash {
    &::before {
      content: '';
      display: block;
      background-color: ${colors.greyLightLight};
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      ${size('1px', '30px')}
    }
  } */

  &:hover {
    svg {
      fill: ${colors.brownMedium};
    }
  }
`;
export const ModalButton = styled(Button)`
  margin-right: 40px;
  &:last-child {
    margin-right: 0;
  }
`;
