import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { shuffle } from 'lodash';
import {
  Background,
  Button,
  Container,
  Content,
  Instructions,
  Search,
  Title,
} from './styles';
import * as queries from 'graphql/queries';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { IconArrow } from '../IconsView/index';
import { Storage } from 'aws-amplify';

const LIST_GIFS = gql(queries.listGIFS);

interface KeyPressI {
  [key: string]: boolean;
}

type GIF = {
  id: string;
  sort: number;
  title?: string;
  image: string;
  active: boolean;
  updatedAt: string;
  createdAt: string;
};

const NotFoundView = (): JSX.Element => {
  const history = useHistory();
  const searchIsOpen = useRef(false);
  const [keyPressed, setKeyPressedState] = useState<KeyPressI>({});
  const keyPressedRef = useRef(keyPressed);
  const setKeyPressed = (keys: KeyPressI) => {
    setKeyPressedState(keys);
    keyPressedRef.current = keys;
  };

  const [gifUrls, setGifUrls] = useState<string[]>([]);
  const [gifIndex, setGifIndex] = useState(-1);
  const gifUrlsRef = useRef<string[]>([]);
  const gifIndexRef = useRef(-1);

  const { data: gifData } = useQuery(LIST_GIFS);

  const getGIFUrls = async (list: GIF[]) => {
    const gifList = [];
    for (let i = 0; i < list.length; i++) {
      const gif = await Storage.get(list[i].image);
      gifList.push(gif);
    }
    setGifUrls(gifList);
    gifUrlsRef.current = gifList;

    const preloadImages = (urls: string[]) => {
      const imagePromises = urls.map((url: string) => {
        return new Promise((resolve, reject) => {
          const image = new Image();
          image.onload = resolve;
          image.onerror = reject;
          image.src = url;
          console.log('url: ', url);
        });
      });

      return Promise.all(imagePromises);
    };

    preloadImages(gifList)
      .then(() => {
        console.log('All images are preloaded successfully!');
      })
      .catch((error) => {
        console.error('Error preloading images:', error);
      });
  };

  useEffect(() => {
    if (gifData?.listGIFS.items) {
      const list = [...gifData?.listGIFS.items];
      console.log(list);
      if (list && list.length > 0) {
        getGIFUrls(list);
        gifUrlsRef.current = list;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gifData]);

  // eslint-disable-next-line
  const handleKeyPress = (event: KeyboardEvent) => {
    console.log('[handleKeyPress]');
    if (!searchIsOpen.current) {
      const keyPress: KeyPressI = keyPressedRef.current;
      keyPress[event.key] = true;

      if (
        (keyPress.f || keyPress.F) &&
        (keyPress['&'] || keyPress['7']) &&
        (keyPress.d || keyPress.D) &&
        keyPress.Shift
      ) {
        let newGifIndex = gifIndexRef.current + 1;
        if (newGifIndex > gifUrlsRef.current.length - 1) {
          newGifIndex = 0;
        }
        setGifIndex(newGifIndex);
        gifIndexRef.current = newGifIndex;

        if (newGifIndex === 0) {
          const list = shuffle([...gifUrlsRef.current]);
          setGifUrls(list);
        }
      } else if (event.key === 'Enter') {
        history.goBack();
      } else if (
        event.key !== 'f' &&
        event.key !== 'F' &&
        event.key !== 'd' &&
        event.key !== 'D' &&
        event.key !== '&' &&
        event.key !== '7' &&
        event.key !== 'Shift' &&
        event.key !== 'Meta'
      ) {
        history.goBack();
      }
    }
  };

  // eslint-disable-next-line
  const handleKeyUp = (event: KeyboardEvent) => {
    console.log('[handleKeyUp]');
    if (!searchIsOpen.current) {
      const keyPress: KeyPressI = keyPressedRef.current;
      delete keyPress[event.key];
      setKeyPressed(keyPress);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
      window.removeEventListener('keyup', handleKeyUp);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {gifIndex === -1 ? (
        <Background gif={0} />
      ) : (
        <>
          {gifUrls.map((url) => (
            <Background
              key={url}
              gif={url}
              zIndex={url === gifUrls[gifIndex] ? 0 : -1}
            />
          ))}
        </>
      )}
      <Container>
        <Search
          handleOpen={(action) => {
            if (action === 'open') {
              searchIsOpen.current = true;
            } else {
              searchIsOpen.current = false;
            }
          }}
        />
        <Content>
          <Title>404</Title>
          <Instructions className="desktop">
            <p>An error has occurred. To continue:</p>
            <p>Press enter to return to the previous page, or</p>
            <p>
              Press shift + F + 7 + D to see a really funny GIF. If you do this,
              you will probably smirk but then get impatient with this screen.
            </p>
            <p className="center">
              Click the any key to continue <span>_</span>
            </p>
          </Instructions>
          <Instructions className="mobile">
            <p>An error has occurred. To continue:</p>
            <p className="center">
              Click the any key to continue <span>_</span>
            </p>
          </Instructions>
          <Button type="button" onClick={() => history.goBack()}>
            <IconArrow />
            <div>
              <span>Any Key</span>
            </div>
          </Button>
        </Content>
      </Container>
    </>
  );
};

export default NotFoundView;
