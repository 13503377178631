import styled from 'styled-components';
import { size } from '../../shared/mixins';
import { colors, customBezierEase } from '../../shared/variables';
import { eyebrow, resetButtonStyles } from '../../shared/extends';
import Dropdown from './Components/Dropdown';
import CheckboxView from 'components/FormComponents/CheckboxView';
import { font, fontSize } from 'shared/typography';

export const Container = styled.div`
  display: block;
`;

export const TabContainer = styled.div`
  border-bottom: 1px solid ${colors.greyLightLight};
  // padding: 0 0 4px;
  position: relative;
  display: flex;
  z-index: 10;

  &.hide {
    display: none;
  }
`;

export const TabBackdrop = styled.label`
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  ${size('100%')};
`;

export const TabDropdown = styled(Dropdown)`
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ${customBezierEase};
`;

export const Tab = styled.label`
  background: transparent;
  border: 0;
  cursor: pointer;
  margin: 0 48px 0 0;
  padding: 0 0 2px;
  transition: 0.3s ${customBezierEase};
  position: relative;

  input.checkbox {
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }

  span.label {
    ${eyebrow};
    color: ${colors.primary};
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    position: relative;
    z-index: -1;
    transition: color 0.3s ${customBezierEase};

    > svg {
      fill: ${colors.primary};
      margin-left: 7px;
      pointer-events: none;
      transition: 0.2s ${customBezierEase};
      ${size('8px', '6px')};
    }
  }

  input.checkbox:checked {
    ~ ${TabDropdown} {
      opacity: 1;
      pointer-events: all;
      cursor: default;
    }

    ~ ${TabBackdrop} {
      pointer-events: all;
    }

    ~ span.label {
      color: ${colors.brownLight};
      z-index: 1;

      > svg {
        fill: ${colors.brownLight};
        transform: rotate(180deg);
      }

      &:hover {
        color: ${colors.highlight};

        > svg {
          fill: ${colors.highlight};
        }
      }
    }
    }
  }

  &.hide {
    display: none;
  }

  &:last-child {
    margin: 0;
  }

  &:hover {
    span.label {
      color: ${colors.highlight};

      > svg {
        fill: ${colors.highlight};
      }
    }
  }
`;

export const DropdownContainer = styled.div`
  display: none;
  position: relative;
  pointer-events: none;
  width: 100%;
  z-index: 1;
  &.show {
    display: block;
  }
`;

export const Top = styled.button`
  ${resetButtonStyles};
  ${eyebrow};
  background: ${colors.white};
  color: ${colors.brownLight};
  border: 1px solid ${colors.grey};
  padding: 5px 13px 6px;
  display: flex;
  cursor: pointer;
  pointer-events: all;
  align-items: center;
  width: 100%;

  svg {
    fill: ${colors.primary};
    margin-left: 6px;
    ${size('8px', '6px')};
  }

  &:hover {
    color: ${colors.highlight};
    svg {
      fill: ${colors.highlight};
    }
  }

  &.open {
    svg {
      transform: rotate(180deg);
    }
  }
`;

export const BottomDropdown = styled.form`
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 24px 16px;
  position: absolute;
  top: 37px;
  /* top: calc(100% + 8px); */
  left: 0;
  list-style: none;
  margin: 0;
  opacity: 0;
  width: 300px;
  max-height: 330px;
  overflow-y: auto;
  pointer-events: none;
  transform: translateY(-10px);
  transition: opacity 0.2s ${customBezierEase},
    transform 0.3s ${customBezierEase};

  &.show {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0px);
  }
`;

export const Section = styled.div`
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  .label {
    ${eyebrow};
    display: block;
    margin-bottom: 8px;
  }
`;

export const CustomCheckbox = styled(CheckboxView)`
  margin: 16px 0;

  &:last-child {
    margin-bottom: 0;
  }

  &:first-of-type {
    margin-top: 0;
  }
`;

export const More = styled.div`
  opacity: 0;
  pointer-events: none;
  position: absolute;
  margin-left: auto;

  &.show {
    position: relative;
    opacity: 1;
    pointer-events: all;
  }
`;

export const MoreButton = styled.button`
  background: transparent;
  color: ${colors.primary};
  border: 1px solid ${colors.greyLightLight};
  border-bottom: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0 0 0;
  padding: 5px 16px 5px;
  text-transform: uppercase;
  transition: 0.5s ${customBezierEase};
  position: relative;
  white-space: nowrap;
  ${font('secondary', 'black')};
  ${fontSize(12, 20)};

  svg {
    fill: ${colors.primary};
    margin-left: 7px;
    ${size('8px', '6px')};
  }

  &:hover {
    color: ${colors.highlight};
    svg {
      fill: ${colors.highlight};
    }
  }

  &.open {
    svg {
      transform: rotate(180deg);
    }
  }
`;

export const MoreList = styled.form`
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 24px 16px;
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  list-style: none;
  margin: 0;
  opacity: 0;
  pointer-events: none;
  width: 200px;
  max-height: 330px;
  overflow-y: auto;
  transform: translateY(-10px);
  transition: opacity 0.2s ${customBezierEase},
    transform 0.3s ${customBezierEase};

  &.show {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0px);
  }

  span.label {
    color: ${colors.brownLight};
  }
`;
export const MoreItem = styled.div`
  text-align: center;
`;
