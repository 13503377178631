import IntroView from 'components/IntroView';
import LoaderView from 'components/LoaderView';
import React, { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import * as localQueries from './queries';
import * as queries from 'graphql/queries';
import { Helmet } from 'react-helmet';
import LunchSpotsView2 from 'components/LunchSpotsView2';

const GET_PAGE_BY_SLUG = gql(localQueries.getPageBySlug);
const LIST_OFFICES = gql(queries.listOffices);

interface IUser {
  office: {
    active: boolean;
    group: string;
    id: string;
    name: string;
    sort: number;
    timezone: string;
  };
}

function LunchSpots2({
  slug,
  user,
}: {
  slug: string;
  user: IUser;
}): JSX.Element {
  const { data: slugData, loading: slugLoading } = useQuery(GET_PAGE_BY_SLUG, {
    variables: {
      slug,
    },
  });

  const { data: officeData } = useQuery(LIST_OFFICES);

  const pageData = useMemo(() => {
    return slugData?.getPageBySlug.items[0];
  }, [slugData]);

  if (slugLoading) {
    return <LoaderView />;
  }
  return (
    <>
      <Helmet>
        <title>{pageData?.title}</title>
        <meta name="description" content={pageData?.description || ''} />
      </Helmet>
      <IntroView type="subpage" data={pageData} />
      <LunchSpotsView2
        data={pageData?.pages?.items}
        user={user}
        officeData={officeData?.listOffices?.items}
      />
    </>
  );
}

export default LunchSpots2;
