import { IconCheckmark, IconSimpleArrowSmall } from 'components/IconsView';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  Container,
  Dropdown,
  DropdownContent,
  Tab,
  TabContainer,
  YearButton,
} from './styles';

interface Props {
  className?: string;
  years: YearTab[];
  defaultActive?: YearTab;
  handleActive: (year: string) => void;
}

interface YearTab {
  label: string;
  value: string;
}

const YearFilter = ({
  className,
  years,
  defaultActive,
  handleActive,
}: Props): JSX.Element => {
  const [active, setActive] = useState<YearTab | null>(null);
  const [toggleDropdown, setToggleDropdown] = useState(false);

  useEffect(() => {
    if (defaultActive) {
      setActive(defaultActive);
    } else {
      setActive(years[0]);
    }
  }, [defaultActive, years]);

  return (
    <Container className={classNames(className, 'year-filter')}>
      <TabContainer>
        <Tab
          aria-label="year tab"
          active={toggleDropdown}
          onClick={() => setToggleDropdown(!toggleDropdown)}
        >
          {active?.label} <IconSimpleArrowSmall />
        </Tab>
        <Dropdown className={classNames({ show: toggleDropdown })}>
          <DropdownContent>
            {years.map((year: YearTab) => (
              <YearButton
                key={year.value}
                type="button"
                onClick={() => {
                  setActive(year);
                  setToggleDropdown(false);
                  handleActive(year.value);
                }}
              >
                {year.label}
                {year.value === active?.value && <IconCheckmark />}
              </YearButton>
            ))}
          </DropdownContent>
        </Dropdown>
      </TabContainer>
    </Container>
  );
};

export default YearFilter;
