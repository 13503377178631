import styled from 'styled-components';
import { NavLink, Link } from 'react-router-dom';
import { size, mq } from '../../shared/mixins';
import {
  colors,
  customBezierEase,
  mobileNavHeight,
  padding,
  iconArrowAnimation,
} from '../../shared/variables';
import { zIndex } from '../../shared/zIndex';
import { font, fontSize } from '../../shared/typography';
import bkgTexture from '../../images/bg_texture-dust-accent.jpg';
import bkgTextureWhite from '../../images/bg_texture-dust-white.jpg';
import {
  eyebrow,
  eyebrowRegular,
  resetButtonStyles,
} from '../../shared/extends';
import Results from './Components/Results/index';

export const Container = styled.nav`
  display: block;
  max-width: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${zIndex.mobileNavigation};

  &.open {
    position: fixed;
    z-index: ${zIndex.mobileNavigation};
    width: 100%;
  }
`;

export const Top = styled.div`
  height: ${mobileNavHeight};
  background: url(${bkgTexture}) 0 0 repeat, ${colors.primary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 65px 0 ${padding.tablet}px;
  position: relative;

  &.subpage {
    background: url(${bkgTextureWhite}) 0 0 repeat, ${colors.white};
  }

  /* &.open,
  &.is404,
  &.searchResults {
    background: ${colors.white};
  } */

  &.searchResults {
    &:after {
      display: none;
    }
  }

  ${mq.phoneWide`
    padding: 0 23px 0 ${padding.mobile}px;
  `}
`;

export const SearchIcon = styled.button`
  ${resetButtonStyles};
  display: inline-flex;
  align-items: center;
  position: absolute;
  top: 33px;
  right: 60px;

  svg {
    fill: ${colors.primary};
    ${size('18px')};
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
`;

export const SearchInput = styled.input`
  background: transparent;
  position: absolute;
  z-index: 1200;
  left: 20px;
  top: 30px;
  border: 0;
  border-radius: 0;
  color: ${colors.brownLight};
  width: 250px;
  ${font('primary', 'black')};
  ${fontSize(18, 27)};

  &:focus {
    outline: none;
  }
`;

export const ClearButton = styled.button`
  ${resetButtonStyles};
  ${eyebrowRegular};
  color: ${colors.brownLight};
  margin-right: 35px;
`;

export const StyledLogo = styled(Link)`
  display: block;
  ${'' /* opacity: 0; */}
  pointer-events: all;

  svg {
    position: relative;
    ${'' /* top: -3px; */}
    ${size('31px', '36px')}
  }

  path {
    /*fill: ${colors.brownMedium};*/
    fill: url(#bkgTextureBrown-logoMobile);
  }
`;

export const LogoWrapper = styled.div`
  ${size('31px', '36px')}
`;

export const Title = styled.span`
  color: ${colors.primary};
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${font('secondary', 'bold')};
  ${fontSize(10, 20, 3.6)};
`;

export const Hamburger = styled.button`
  background: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  padding: 0;

  path {
    fill: ${colors.highlight};
  }
  svg.icon-x {
    ${size('11px')};
  }
`;

export const Content = styled.div`
  /* padding: 0px ${padding.tablet}px; */
  pointer-events: none;
  opacity: 0;
  background: ${colors.white};
  width: 100%;
  min-height: calc(var(--doc-height) - ${mobileNavHeight});
  /* max-height: calc(var(--doc-height) - ${mobileNavHeight}); */
  top: 0;
  height: 100%;
  overflow-x: hidden;
  /* display: none; */
  position: fixed;
  z-index: 1100;

  display: flex;
  transform: translateX(-100%);
  transition: transform 500ms ease, opacity 0ms linear 500ms,
    width 0ms linear 500ms;

  &.open {
    /* display: flex; */
    pointer-events: all;
    opacity: 1;
    width: 375px;
    transform: translateX(0);
    transition: transform 500ms ease;
  }

  .close-menu-btn {
    position: absolute;
    padding: 0;
    background: none;
    border: 0;
    top: -8px;
    left: 340px;
    cursor: pointer;
    width: 30px;
    height: 30px;
  }

  svg.icon-x {
    ${size('13px')};
    fill: ${colors.primary};
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

interface BackDropI {
  className: string;
  // open: boolean;
}

export const BackDrop = styled.div<BackDropI>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-color: rgba(66, 62, 53, 0.5);
  z-index: 1000;
  cursor: pointer;

  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: opacity 500ms ease, height 0ms linear 500ms;

  &.show {
    height: 100vh;
    opacity: 1;
    transition: opacity 500ms ease;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  left: 0;
  min-height: 100%;

  &.hide {
    opacity: 0;
    left: -100%;
    pointer-events: none;
    // display: none;
  }
`;

export const UserContainer = styled.div`
  flex: 0 0 auto;
  padding: 0 ${padding.tablet}px;
  border-top: 1px solid ${colors.grey};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: -${padding.tablet}px;
  margin-right: -${padding.tablet}px;
  ${size('auto', '55px')}

  .sign-out {
    color: ${colors.primary};
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: underline;
    ${font('secondary')};
    ${fontSize(10, 20, 1.4)};
  }

  ${mq.phoneWide`
    padding: 0 ${padding.mobile}px;
    margin-left: -${padding.mobile}px;
    margin-right: -17px;
  `}
`;

export const UserImage = styled.img`
  border-radius: 5px;
  display: inline-block;
  ${size('24px')};
`;

export const UserInfo = styled.div`
  display: inline-flex;

  .user-pic {
    display: flex;
    align-items: center;
  }

  .user-info {
    margin-left: 11px;
    display: flex;
    flex-direction: column;
  }

  .name {
    ${eyebrow};
    color: ${colors.brownLight};
  }

  .office {
    ${font('body')}
    ${fontSize(12, 14, 0)}
    color: ${colors.brownLight};
  }
`;

export const MainMenu = styled.div`
  padding: 20px 0 40px 0;
  width: 100%;
  overflow-y: auto;
  flex: 1 0 auto;
  margin-right: -8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mq.phoneWide`
    padding: 32px 0;
  `}
`;

export const MainItemContainer = styled.div`
  margin-top: 20px;
  padding: 0 19px 0 28px;
`;

export const Item = styled.div`
  margin: 0 0 24px;

  &:hover {
    svg {
      animation: ${iconArrowAnimation};
    }
  }
`;

export const MenuTop = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid ${colors.primary};
  padding: 0 19px 0 28px;
  height: 80px;
  position: relative;

  a {
    color: inherit;
    display: block;
  }

  &:after {
    background: ${colors.primary};
    top: 71px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    content: '';
    display: block;
    ${size('2px', '9px')};
  }

  .icon-logo {
    scale: 2.5;
    position: relative;
    top: 30px;
    left: 10px;
  }

  .icon-x {
    position: relative;
    top: 35px;
    right: 10px;
  }
`;

export const OpenSubButton = styled.div`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0 2px 0 0;
  border-radius: 0;
  width: 50px;
  height: 100%;
  text-align: right;
  margin-left: auto;

  svg {
    fill: ${colors.primary};
    margin-left: 9px;
    ${size('16px', '7px')}
  }
`;

export const ParentButton = styled.button`
  ${resetButtonStyles};
  align-items: center;
  text-align: left;
  color: ${colors.brownLight};
  display: flex;
  text-decoration: none;
  transition: all 0.5s ${customBezierEase};
  width: 100%;
  ${font('secondaryNav', 'normal')};
  ${fontSize(20, 22)};
  -webkit-font-smoothing: auto;

  &:hover {
    color: rgb(251, 70, 64);
  }
`;

export const MainLink = styled(NavLink)`
  align-items: center;
  color: ${colors.brownLight};
  display: flex;
  text-decoration: none;
  transition: all 0.5s ${customBezierEase};
  width: 100%;
  ${font('secondaryNav', 'normal')};
  ${fontSize(20, 22)};
  -webkit-font-smoothing: auto;

  &:hover {
    color: rgb(251, 70, 64);
  }
`;

export const Sub = styled.div`
  padding: 50px 19px 0 28px;
  position: absolute;
  background: ${colors.white};
  left: 100%;
  top: 0;
  overflow-y: auto;
  pointer-events: none;
  opacity: 0;
  max-height: 100%;
  width: 100%;
  // display: none;
  transition: left 0.3s ${customBezierEase},
    opacity 0.1s ${customBezierEase} 0.2s;

  &.show {
    left: 0;
    display: block;
    pointer-events: all;
    opacity: 1;
    transition: left 0.3s ${customBezierEase}, opacity 0.1s ${customBezierEase};
  }

  ${mq.phoneWide`
    padding: 32px 16px 50px 24px;
  `}
`;

export const BackButton = styled.button`
  ${resetButtonStyles};
  ${eyebrow};
  display: flex;
  align-items: center;
  padding: 0 2px 0 0;
  margin: 0 0 24px;
  color: ${colors.highlight};

  svg {
    fill: ${colors.highlight};
    margin-right: 8px;
    transform: scale(-1);
    ${size('16px', '7px')}
  }
`;

export const Eyebrow = styled(NavLink)`
  ${eyebrow};
  color: ${colors.primary};
  margin-bottom: 24px;
  display: block;
`;

export const Social = styled.div`
  margin: 30px auto 0;
  display: flex;
  justify-content: space-between;
  max-width: 255px;
  width: 100%;
`;

export const SocialLink = styled.a`
  ${eyebrow}
  align-items: center;
  color: ${colors.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.5s ${customBezierEase};

  svg {
    fill: ${colors.primary};
    stroke: ${colors.primary};
    transition: 0.5s ${customBezierEase};
    max-width: 100%;

    path {
      fill: ${colors.primary};
      stroke: ${colors.primary};
      transition: 0.5s ${customBezierEase};
    }
  }

  span {
    padding-top: 8px;
  }

  &:hover {
    color: ${colors.highlight};

    svg {
      fill: ${colors.highlight};
      stroke: ${colors.highlight};
    }

    path {
      fill: ${colors.highlight};
      stroke: ${colors.highlight};
    }
  }
`;

export const SearchResults = styled(Results)`
  &.show {
    left: 0;
    // display: block;
    pointer-events: all;
    opacity: 1;
  }
`;
