import styled from 'styled-components';
import { colors } from '../../../../shared/variables';
import CheckboxView from 'components/FormComponents/CheckboxView';
import InputView from 'components/FormComponents/InputView';

export const Container = styled.div`
  background: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.05);
  left: -16px;
  max-height: 340px;
  padding: 48px 16px 0;
  position: absolute;
  top: -26px;
  width: 240px;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.form`
  overflow-y: auto;
  flex: 1 1 auto;
  border-top: 1px solid ${colors.greyLightLight};
`;

export const CustomCheckbox = styled(CheckboxView)`
  margin: 20px 0 20px;

  label {
    width: 100%;

    &:hover {
      input:not(:checked) {
        ~ span {
          color: ${colors.highlight};
        }
        + div {
          svg {
            fill: ${colors.primary};
            opacity: 0.5;
          }
        }
      }
    }
  }
`;

export const Search = styled.div`
  position: relative;
  margin-top: 20px;
  flex: 0 0 auto;

  svg {
    position: absolute;
    right: 19px;
    top: 50%;
    transform: translateY(-50%);
    fill: ${colors.grey} !important;
  }

  input {
    outline: 1px solid ${colors.grey};
  }
`;

export const CustomInput = styled(InputView)``;
