import React from 'react';

import { Container, Label, Input } from './styles';
import RadioTypes from './Radio.types';
import classNames from 'classnames';

const Radio = ({
  label,
  register,
  error,
  errorMessage,
  ...radioProps
}: RadioTypes): JSX.Element => {
  const { id, name, required } = radioProps;

  if (!register) {
    return (
      <Container className={classNames({ error: error })}>
        <Input {...radioProps} type="radio" />
        <Label htmlFor={id}>{label}</Label>
      </Container>
    );
  }
  return (
    <Container className={classNames({ error: error })}>
      <Input {...radioProps} type="radio" {...register(name, { required })} />
      <Label htmlFor={id}>{label}</Label>
      {/* {error && (
        <Error className="error">
          <IconWarning />
          {errorMessage || 'Select an option'}
        </Error>
      )} */}
    </Container>
  );
};

export default Radio;
