import Button from 'components/Button';
import { bodyCopySmall, resetButtonStyles } from 'shared/extends';
import { mq, size } from 'shared/mixins';
import { colors, customBezierEase, maxWidth, padding } from 'shared/variables';
import styled from 'styled-components';

export const Container = styled.section`
  position: relative;
  min-height: auto;
  padding: 0 ${padding.desktop}px 160px;
  max-width: ${maxWidth}px;
  width: 100%;

  ${mq.tablet`
    padding: 0 ${padding.tablet}px 160px;
  `}

  ${mq.phoneWide`
    padding: 0 27px 80px ${padding.mobile}px;
  `}
`;

export const PasswordContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
`;

export const PasswordForm = styled.form`
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: 1fr auto;
  margin: 0 auto;

  > div {
    min-width: 300px;
    border: 1px solid ${colors.greyMedium};
  }
`;

export const SubmitButton = styled(Button)`
  margin-bottom: auto;
  span {
    display: inline-flex;
    justify-content: center;
  }
  svg {
    width: 17px;
    height: 7px;
  }
`;

export const Bubble = styled.div`
  position: absolute;
  right: -60px;
  top: -40px;
  ${size('90px', '50px')}

  span {
    ${bodyCopySmall};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  svg {
    ${size('90px', '50px')}
  }

  .bubble-1,
  .bubble-2,
  span {
    opacity: 0;
    transition: opacity 0.3s ${customBezierEase};
  }
`;

export const Hover = styled.button`
  ${resetButtonStyles};
  cursor: auto;
  position: fixed;
  display: block;
  bottom: 100px;
  right: 100px;
  ${size('100px')}

  img {
    width: 100px;
    transform: translateY(100%);
    transition: transform 0.3s ${customBezierEase} 0.3s;
  }

  &:hover {
    img {
      transform: translateY(0);
      transition: transform 0.5s ${customBezierEase};
    }

    .bubble-2 {
      opacity: 1;
      transition: opacity 0.5s ${customBezierEase} 0.5s;
    }
    .bubble-1 {
      opacity: 1;
      transition: opacity 0.8s ${customBezierEase} 0.8s;
    }

    ${Bubble} span {
      opacity: 1;
      transition: opacity 0.3s ${customBezierEase} 1s;
    }
  }
`;

export const Wiki = styled.div`
  margin-top: 100px;
`;

export const PageButton = styled(Button)`
  margin-top: 40px;
`;

export const WikiList = styled.div`
  background: ${colors.white};
  padding: 64px;
`;

export const PageListContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
