import styled from 'styled-components';
import { size } from '../../../shared/mixins';
import { colors, customBezierEase } from '../../../shared/variables';
import { font, fontSize } from '../../../shared/typography';

export const Container = styled.div``;

export const Checkbox = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  margin: 0;

  + div {
    background: ${colors.white};
    border: 1.5px solid ${colors.primary};
    content: '';
    display: flex;
    transition: 0.3s ${customBezierEase};
    justify-content: center;
    align-items: center;
    /* outline: 1px solid transparent; */
    ${size('22px')}

    svg {
      fill: ${colors.white};
    }
  }

  &:checked + div {
    background: ${colors.primary};
  }

  /* &:focus + div {
    outline-color: ${colors.primary};
  } */
`;

export const CheckboxLabel = styled.label`
  color: ${colors.brownLight};
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin: 0;
  ${font('body')};
  ${fontSize(14, 20)};

  span {
    display: block;
    margin-left: 12px;
    transition: color 0.3s ${customBezierEase};
  }
`;
