export const prompts = [
  '[name] has a pet tarantula that they takes with them everywhere they goes.',
  "[name] has a secret room in their house that's filled with vintage typewriters.",
  '[name] is a master magician who uses their powers for good.',
  '[name] is an amateur astronomer who may have discovered a new planet.',
  '[name] has a secret talent for baking elaborate cakes and pastries.',
  '[name] is a champion pumpkin carver.',
  '[name] is secretly a world-renowned opera singer.',
  '[name] has a collection of antique medical equipment that they uses for Halloween decorations.',
  "[name] is a ghost hunter who's had several supernatural encounters.",
  '[name] is a skilled woodworker who makes intricate birdhouses in their spare time.',
  "[name] is a part-time beekeeper and sells their honey at the local farmer's market.",
  '[name] has a secret underground bunker filled with survival supplies.',
  '[name] has a small zoo in their backyard with exotic animals.',
  '[name] is a master gardener and has won several awards for their landscaping.',
  '[name] is a part-time astronaut who has flown several missions into space.',
  '[name] is a collector of antique board games and hosts regular game nights.',
  '[name] has a secret passion for karaoke and is known to belt out power ballads.',
  '[name] is secretly a martial arts master.',
  '[name] has a secret alter ego as a masked crime-fighter who patrols the streets at night.',
  'I heard that [name] has a mysterious third arm that they keeps hidden in their jacket.',
  'Someone claimed [name] is actually a ghost and only appears in the office during certain hours of the day.',
  '[name] is rumored to have a secret room in the office that can only be accessed through a hidden door in the supply closet.',
  'I heard [name] has a collection of bizarre artifacts in their desk drawer that they uses for some unknown purpose.',
  '[name] is supposedly an expert in deciphering ancient hieroglyphs and sometimes spends hours locked in their office doing so.',
  "Someone said [name] once disappeared for three days and when they came back, they couldn't remember where they had been.",
  'I heard that [name] is secretly a shape-shifter and takes on different forms depending on their mood.',
  '[name] is rumored to have a pet cactus that they talks to and takes care of as if it were a real animal.',
  'Someone claimed [name] has a strange affinity for the number 13 and always insists on sitting at the 13th desk in the office.',
  'I heard [name] once created a machine that could predict the future and used it to win the office lottery pool.',
  '[name] is supposedly a member of a secret society that meets in the basement of the office building.',
  'I heard that [name] has a strange ability to communicate with plants and frequently talks to the office ferns.',
  '[name] is rumored to have a collection of antique typewriters in their office that they uses to write mysterious messages.',
  'Someone claimed [name] can enter into the dreams of their coworkers and manipulate them.',
  'I heard that [name] is always followed around by a flock of crows that seem to be watching them.',
  '[name] is supposedly able to move objects with their mind and frequently uses this power to play pranks on their coworkers.',
  'Someone said [name] once created a portal to a parallel dimension in the office break room.',
  'I heard that [name] is actually a time traveler from the future and is here to prevent some terrible disaster.',
  '[name] is rumored to have a strange mark on their forehead that glows in the dark and emits a strange energy field.',
  'I heard that [name] is able to communicate with ghosts that haunt the office building.',
  'Someone claimed [name] has a secret laboratory in the basement of the office where they conducts strange experiments.',
  '[name] is rumored to have a book of spells that they uses to cast strange enchantments on their coworkers.',
  'I heard [name] has a collection of strange crystals in their office that they uses to focus their energy.',
  'Someone said [name] once created a robot that could perform their job and used it to take a long vacation.',
  'I heard that [name] has a secret underground bunker in case of a disaster or invasion.',
  '[name] is rumored to have a connection to a secret government agency that monitors paranormal activity.',
  'Someone claimed [name] can speak with animals and often talks to the office cat.',
  '[name] is supposedly able to teleport short distances and frequently uses this power to surprise their coworkers.',
  'Someone said [name] once created a time loop in the office that caused the same day to repeat over and over.',
  'I heard that [name] has a strange obsession with collecting teeth and keeps them in a jar on their desk.',
  '[name] is rumored to have a secret network of tunnels underneath the office building that they uses to move around undetected.',
  'Someone claimed [name] has a collection of strange artifacts from around the world that they uses to summon spirits.',
  'I heard that [name] has a secret identity as a superhero who protects the office from danger.',
  '[name] is supposedly able to speak in a language that nobody else can understand.',
  'Someone said [name] once created a portal to an alternate dimension where everything was the opposite of the real world.',
  'I heard that [name] has a pet hamster that has grown to an enormous size and roams the office at night.',
  '[name] is rumored to be part of a secret society of magicians who meet in the office after hours.',
  'I heard that [name] has a hidden laboratory in the office where they conducts experiments on office supplies.',
  'Someone claimed [name] is able to control the weather and frequently causes unusual weather patterns in the office.',
  '[name] is rumored to be able to communicate with extraterrestrial life and has been visited by aliens in the office.',
  'I heard [name] has a secret door in their office that leads to a parallel universe.',
  '[name] is supposedly able to summon supernatural creatures and has a menagerie of strange creatures hidden in the office.',
  'Someone said [name] has a collection of cursed objects in their office that they uses to control their coworkers.',
  'I heard that [name] has a time machine hidden in the office and uses it to go back and fix mistakes.',
  '[name] is rumored to have a hidden network of tunnels that connect to other offices in the area.',
  'Someone claimed [name] has a collection of rare and dangerous books that they keeps hidden in their office.',
  'I heard that [name] has a secret clone army hidden in the office basement.',
  'Someone said [name] once created a portal to an alternate dimension where all of their coworkers were evil.',
  'I heard that [name] has a secret room in the office that is guarded by a giant monster.',
  '[name] is rumored to have a secret formula that can grant immortality and has been searching for the missing ingredient for years.',
  "Someone claimed [name] can move objects with their mind and frequently levitates their coworkers' desks.",
  'I heard that [name] has a strange connection to the office photocopier and can make copies of anything they desires.',
  'Someone said [name] once created a potion that allowed them to talk to ghosts, but it had some unexpected side effects.',
  "I heard that [name] has a secret underground laboratory where they're working on a cure for a deadly virus.",
  '[name] is rumored to be able to teleport to different locations instantly and has used this power to attend meetings in other countries.',
];
