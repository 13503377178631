import styled from 'styled-components';
import Button from 'components/Button/Button';
import { colors, padding } from 'shared/variables';
import { font, fontSize } from 'shared/typography';
import { AspectRatioFunc, size } from 'shared/mixins';

export const Container = styled.div`
  padding: 100px ${padding.desktop}px 100px;
`;

export const Board = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;
  color: ${colors.brownMedium};
  ${font('body')}
`;
export const BoardData = styled.span`
  &.score {
    ${font('bodyBold')}
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin: 100px auto;
`;
const CardSides = styled.div`
  display: flex;
  border-radius: 6px;
  background-position: center;
  background-size: cover;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: ease-in-out 600ms;
  background-color: ${colors.white};
  color: ${colors.brownMedium};
`;
export const Back = styled(CardSides)`
  background-size: cover;
  padding: 2rem;
  overflow: hidden;
  ${font('secondary', 'black')}
  ${fontSize(14, 20)};
`;
export const Front = styled(CardSides)`
  transform: rotateY(-180deg);
  padding: 0 2em;
  ${font('primary', 'black')}
  ${fontSize(24, 27)}
`;
export const Card = styled.div`
  color: inherit;
  cursor: pointer;
  perspective: 1000px;
  position: relative;
  width: 33.333%;
  max-width: 250px;
  min-width: 150px;
  ${AspectRatioFunc({ width: 3, height: 4 })}

  &:hover {
    ${Back} {
      transform: rotateY(180deg);
    }
    ${Front} {
      transform: rotateY(0deg);
    }
  }
`;
export const Question = styled.p`
  text-transform: uppercase;
`;
export const Answer = styled.p``;
export const QuestionImage = styled.img`
  object-fit: contain;
  max-width: 100%;
  max-height: 100%fit-content;
  ${size('100%')}
`;

export const Badge = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
`;

export const Form = styled.form`
  width: 320px;
  display: grid;
  grid-row-gap: 32px;
`;
export const AddButton = styled(Button)``;

export const Stickers = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  display: grid;
  gap: 16px;
`;

export const Sticker = styled.div`
  border-radius: 12px;
  ${size('24px')}
`;
