import { resetButtonStyles } from 'shared/extends';
import { mq, size } from 'shared/mixins';
import { font, fontSize } from 'shared/typography';
import { colors, customBezierEase } from 'shared/variables';
import { zIndex } from 'shared/zIndex';
import styled from 'styled-components';

export const Container = styled.button`
  ${resetButtonStyles};
  align-items: center;
  background: ${colors.white};
  border-radius: 34px;
  bottom: 64px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  color: ${colors.brownMedium};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  visibility: hidden;
  padding: 8px 16px;
  position: fixed;
  right: 64px;
  text-align: right;
  text-transform: uppercase;
  transform: translateY(20px);
  transition: all 0.3s ${customBezierEase};
  width: 108px;
  z-index: ${zIndex.backToTop};
  ${font('secondary')};
  ${fontSize(12, 16, 1.4)};

  svg {
    fill: ${colors.primary};
    flex: 0 0 auto;
    margin-left: 8px;
    transition: fill 0.3s ${customBezierEase};
    ${size('16px', '14px')}
  }

  &.active {
    visibility: visible;
    transform: translateY(0);
  }

  ${mq.tablet`
    bottom: 100px;
  `}

  ${mq.phoneWide`
    bottom: 32px;
    right: 16px
  `}

  .body-container:not(.is-touch) &:hover {
    cursor: pointer;

    svg {
      fill: ${colors.highlight};
    }
  }
`;
