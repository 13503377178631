import styled from 'styled-components';
import { NavLink, Link } from 'react-router-dom';
import { size, mq } from '../../shared/mixins';
import {
  colors,
  customBezierEase,
  mobileNavHeight,
  padding,
  iconArrowAnimation,
} from '../../shared/variables';
import { zIndex } from '../../shared/zIndex';
import { font, fontSize } from '../../shared/typography';
import bkgTexture from '../../images/bg_texture-dust-accent.jpg';
import bkgTextureWhite from '../../images/bg_texture-dust-white.jpg';
import {
  eyebrow,
  eyebrowRegular,
  resetButtonStyles,
} from '../../shared/extends';
import Results from './Components/Results';

export const Container = styled.nav`
  display: none;

  &.open {
    position: fixed;
    z-index: ${zIndex.mobileNavigation};
    width: 100%;
  }

  ${mq.tablet`
    display: block;
    max-width: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: ${zIndex.mobileNavigation};
  `}
`;

export const Top = styled.div`
  height: ${mobileNavHeight};
  border-bottom: 2px solid ${colors.highlight};
  background: url(${bkgTexture}) 0 0 repeat, ${colors.primary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 65px 0 ${padding.tablet}px;
  position: relative;

  &:after {
    background: ${colors.highlight};
    top: 71px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    content: '';
    display: block;
    ${size('2px', '9px')};
  }

  &.subpage {
    background: url(${bkgTextureWhite}) 0 0 repeat, ${colors.white};
  }

  &.open,
  &.is404,
  &.searchResults {
    background: ${colors.white};
  }

  &.searchResults {
    &:after {
      display: none;
    }
  }

  ${mq.phoneWide`
    padding: 0 23px 0 ${padding.mobile}px;
  `}
`;

export const SearchIcon = styled.button`
  ${resetButtonStyles};
  margin-right: 35px;
  display: inline-flex;
  align-items: center;
  svg {
    fill: ${colors.highlight};
    ${size('18px')};
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
`;

export const SearchInput = styled.input`
  background: transparent;
  border: 0;
  border-radius: 0;
  color: ${colors.brownLight};
  width: 100%;
  ${font('primary', 'black')};
  ${fontSize(18, 27)};

  &:focus {
    outline: none;
  }
`;

export const ClearButton = styled.button`
  ${resetButtonStyles};
  ${eyebrowRegular};
  color: ${colors.brownLight};
  margin-right: 35px;
`;

export const StyledLogo = styled(Link)`
  display: block;
  ${'' /* opacity: 0; */}
  pointer-events: all;

  svg {
    position: relative;
    ${'' /* top: -3px; */}
    ${size('31px', '36px')}
  }

  path {
    /*fill: ${colors.brownMedium};*/
    fill: url(#bkgTextureBrown-logoMobile);
  }
`;

export const LogoWrapper = styled.div`
  ${size('31px', '36px')}
`;

export const Title = styled.span`
  color: ${colors.highlight};
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${font('secondary', 'bold')};
  ${fontSize(10, 20, 3.6)};
`;

export const Hamburger = styled.button`
  background: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  padding: 0;

  path {
    fill: ${colors.highlight};
  }
  svg.icon-x {
    ${size('11px')};
  }
`;

export const Content = styled.div`
  padding: 0px ${padding.tablet}px;
  pointer-events: none;
  opacity: 0;
  background: ${colors.white};
  width: 100%;
  min-height: calc(var(--doc-height) - ${mobileNavHeight});
  max-height: calc(var(--doc-height) - ${mobileNavHeight});
  overflow-x: hidden;
  display: none;
  position: relative;

  &.open {
    display: flex;
    pointer-events: all;
    opacity: 1;
  }

  ${mq.phoneWide`
    padding: 0 17px 0px 24px;
  `}
`;

export const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  left: 0;
  min-height: 100%;

  &.hide {
    opacity: 0;
    left: -100%;
    pointer-events: none;
    // display: none;
  }
`;

export const UserContainer = styled.div`
  flex: 0 0 auto;
  padding: 0 ${padding.tablet}px;
  border-top: 1px solid ${colors.grey};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: -${padding.tablet}px;
  margin-right: -${padding.tablet}px;
  ${size('auto', '55px')}

  .sign-out {
    color: ${colors.primary};
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: underline;
    ${font('secondary')};
    ${fontSize(10, 20, 1.4)};
  }

  ${mq.phoneWide`
    padding: 0 ${padding.mobile}px;
    margin-left: -${padding.mobile}px;
    margin-right: -17px;
  `}
`;

export const UserImage = styled.img`
  border-radius: 5px;
  display: inline-block;
  ${size('24px')};
`;

export const UserInfo = styled.div`
  display: inline-flex;

  .user-pic {
    display: flex;
    align-items: center;
  }

  .user-info {
    margin-left: 11px;
    display: flex;
    flex-direction: column;
  }

  .name {
    ${eyebrow};
    color: ${colors.brownLight};
  }

  .office {
    ${font('body')}
    ${fontSize(12, 14, 0)}
    color: ${colors.brownLight};
  }
`;

export const MainMenu = styled.div`
  padding: 50px 0 40px 0;
  width: 100%;
  overflow-y: auto;
  flex: 1 0 auto;
  margin-right: -8px;

  ${mq.phoneWide`
    padding: 32px 0;
  `}
`;

export const MainItemContainer = styled.div`
  margin-top: 20px;
`;

export const Item = styled.div`
  margin: 0 0 24px;

  &:hover {
    svg {
      animation: ${iconArrowAnimation};
    }
  }
`;

export const OpenSubButton = styled.div`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0 2px 0 0;
  border-radius: 0;
  width: 50px;
  height: 100%;
  text-align: right;
  margin-left: auto;

  svg {
    fill: ${colors.highlight};
    margin-left: 9px;
    ${size('16px', '7px')}
  }
`;

export const ParentButton = styled.button`
  ${resetButtonStyles};
  align-items: center;
  text-align: left;
  color: ${colors.brownLight};
  display: flex;
  text-decoration: none;
  transition: 0.5s ${customBezierEase};
  width: 100%;
  ${font('secondaryNav', 'normal')};
  ${fontSize(20, 22)};
`;

export const MainLink = styled(NavLink)`
  align-items: center;
  color: ${colors.brownLight};
  display: flex;
  text-decoration: none;
  transition: 0.5s ${customBezierEase};
  width: 100%;
  ${font('secondaryNav', 'normal')};
  ${fontSize(20, 22)};
`;

export const Sub = styled.div`
  padding: 50px ${padding.tablet}px;
  position: absolute;
  background: ${colors.white};
  left: 100%;
  top: 0;
  overflow-y: auto;
  pointer-events: none;
  opacity: 0;
  max-height: 100%;
  width: 100%;
  // display: none;
  transition: left 0.3s ${customBezierEase},
    opacity 0.1s ${customBezierEase} 0.2s;

  &.show {
    left: 0;
    display: block;
    pointer-events: all;
    opacity: 1;
    transition: left 0.3s ${customBezierEase}, opacity 0.1s ${customBezierEase};
  }

  ${mq.phoneWide`
    padding: 32px 16px 50px 24px;
  `}
`;

export const BackButton = styled.button`
  ${resetButtonStyles};
  ${eyebrow};
  display: flex;
  align-items: center;
  padding: 0 2px 0 0;
  margin: 0 0 24px;
  color: ${colors.highlight};

  svg {
    fill: ${colors.highlight};
    margin-right: 8px;
    transform: scale(-1);
    ${size('16px', '7px')}
  }
`;

export const Eyebrow = styled(NavLink)`
  ${eyebrow};
  color: ${colors.primary};
  margin-bottom: 24px;
  display: block;
`;

export const Social = styled.div`
  margin: 30px auto 0;
  display: flex;
  justify-content: space-between;
  max-width: 255px;
  // padding-right: ${padding.tablet / 2}px;
`;

export const SocialLink = styled.a`
  ${eyebrow}
  align-items: center;
  color: ${colors.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.5s ${customBezierEase};

  svg {
    fill: ${colors.primary};
    stroke: ${colors.primary};
    transition: 0.5s ${customBezierEase};
    max-width: 100%;

    path {
      fill: ${colors.primary};
      stroke: ${colors.primary};
      transition: 0.5s ${customBezierEase};
    }
  }

  span {
    padding-top: 8px;
  }

  &:hover {
    color: ${colors.highlight};

    svg {
      fill: ${colors.highlight};
      stroke: ${colors.highlight};
    }

    path {
      fill: ${colors.highlight};
      stroke: ${colors.highlight};
    }
  }
`;

export const SearchResults = styled(Results)`
  &.show {
    left: 0;
    // display: block;
    pointer-events: all;
    opacity: 1;
  }
`;
