import classNames from 'classnames';
import { IconSimpleArrow, IconTimer } from 'components/IconsView';
import React, { useEffect, useRef, useState } from 'react';
import {
  Arrow,
  Container,
  Content,
  Eyebrow,
  Slide,
  SlideImage,
  Slideshow,
  SlideTime,
  SlideTitle,
} from './styles';
import Slider from 'react-slick';
import dayjs from 'dayjs';
import { breakpoints } from 'shared/variables';
import { debounce } from 'utils/functions';
import { FiveDidProjectI } from 'data/types/FiveDid';
import { trackLifecycle } from 'aws-amplify-react';
import ReactDOM from 'react-dom';

interface Props {
  data: FiveDidProjectI[];
}

const SlideArrow = ({
  side,
  onClick,
  className,
  slideshowRef,
  totalSlides,
}: {
  side: string;
  onClick?: () => void;
  className?: string;
  // eslint-disable-next-line
  slideshowRef: any;
  totalSlides: number;
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    if (
      className &&
      className.includes('slick-disabled') &&
      className.includes('slick-next')
    ) {
      slideshowRef.slickGoTo(0, true);
    } else if (
      className &&
      className.includes('slick-disabled') &&
      className.includes('slick-prev')
    ) {
      slideshowRef.slickGoTo(totalSlides, true);
    }
  };
  return (
    <Arrow
      aria-label={`slide arrow ${side}`}
      className={classNames(side, className)}
      onClick={handleClick}
    >
      <IconSimpleArrow />
    </Arrow>
  );
};

const FiveDidView = ({ data }: Props): JSX.Element => {
  const SlideshowRef = useRef<Slider>(null);
  const [slidesShowing, setSlidesShowing] = useState(3);
  const [isTablet, setIsTablet] = useState(false);
  const settings = {
    nextArrow: (
      <SlideArrow
        slideshowRef={SlideshowRef.current}
        totalSlides={data.length - 1}
        side="right"
      />
    ),
    prevArrow: (
      <SlideArrow
        slideshowRef={SlideshowRef.current}
        totalSlides={data.length - 1}
        side="left"
      />
    ),
    // focusOnSelect: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesShowing,
    slidesToScroll: 1,
    swipe: isTablet,
  };

  const handleShowingSlides = () => {
    if (window.innerWidth < breakpoints.phablet) {
      setSlidesShowing(1);
    } else if (window.innerWidth < breakpoints.tabletWide) {
      setSlidesShowing(2);
    } else {
      setSlidesShowing(3);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      SlideshowRef?.current?.slickGoTo(0);
    }, 100);
    handleShowingSlides();
    setIsTablet(window.innerWidth < breakpoints.tablet + 1);
    window.addEventListener(
      'resize',
      debounce(function () {
        handleShowingSlides();
        setIsTablet(window.innerWidth < breakpoints.tablet + 1);
      })
    );

    return () => {
      window.addEventListener(
        'resize',
        debounce(function () {
          handleShowingSlides();
          setIsTablet(window.innerWidth < breakpoints.tablet + 1);
        })
      );
    };
  }, [SlideshowRef, data]);

  const renderUpdatedAt = (updatedAt: string): string => {
    if (dayjs().isSame(dayjs(updatedAt), 'day')) {
      return dayjs().diff(dayjs(updatedAt), 'hour') + ' Hours Ago';
    }
    return dayjs(updatedAt).format('MMM D, YYYY').toUpperCase();
  };

  return (
    <Container>
      <Eyebrow>Five &amp; Did Highlighted Projects.</Eyebrow>
      <Content>
        <div style={{ touchAction: 'pan-y' }}>
          <Slideshow ref={SlideshowRef} {...settings}>
            {data
              .sort((x, y) => {
                if (dayjs(x.updatedAt).isAfter(dayjs(y.updatedAt))) {
                  return -1;
                } else if (dayjs(x.updatedAt).isBefore(dayjs(y.updatedAt))) {
                  return 1;
                }
                return 0;
              })
              .map((project) => (
                <Slide
                  href={`http://fiveanddid.com/project/${project.slug}`}
                  target="__blank"
                  key={project.id}
                >
                  <SlideImage
                    src={
                      project.thumb
                        ? `https://fiveanddidv23f5f9d81b82640ab81380133166c7740-katie.s3.us-west-2.amazonaws.com/public/${project.thumb}`
                        : ''
                    }
                  />
                  <SlideTitle>{project.name}</SlideTitle>
                  <SlideTime>
                    <IconTimer />
                    Updated: {renderUpdatedAt(project.updatedAt)}
                  </SlideTime>
                </Slide>
              ))}
          </Slideshow>
        </div>
      </Content>
    </Container>
  );
};

export default FiveDidView;
