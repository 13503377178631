import IntroView from 'components/IntroView';
import ExpenseReportView from 'components/ExpenseReportView';
import React, { useMemo } from 'react';
import { UserI } from 'data/types/User.types';
import { Helmet } from 'react-helmet';
import * as utilsQueries from 'utils/queries';
import * as queries from 'graphql/queries';
import { useQuery, gql } from '@apollo/client';
import dayjs from 'dayjs';
import MileageRatesI from 'data/types/MileageRates';
import LoaderView from 'components/LoaderView';

const GET_PAGE_BY_SLUG = gql(utilsQueries.getPageBySlug);
const LIST_MILEAGE_RATES = gql(queries.listMileageRates);

interface Props {
  user: UserI;
  slug: string;
}

function ExpenseReport({ user, slug }: Props): JSX.Element {
  const { data: slugData, loading: slugLoading } = useQuery(GET_PAGE_BY_SLUG, {
    variables: {
      slug,
    },
  });

  const pageData = useMemo(() => {
    return slugData?.getPageBySlug.items[0];
  }, [slugData]);

  const { data: mileageData, loading: mileageLoading } =
    useQuery(LIST_MILEAGE_RATES);
  const mileageList = useMemo(() => {
    return (
      mileageData?.listMileageRates?.items
        ?.filter((x: MileageRatesI) => !!x.date)
        .sort((x: MileageRatesI, y: MileageRatesI) => {
          if (dayjs(x?.date).isAfter(dayjs(y?.date))) {
            return -1;
          } else if (dayjs(x?.date).isBefore(dayjs(y?.date))) {
            return 1;
          }
          return 0;
        }) || []
    );
  }, [mileageData]);

  if (slugLoading || mileageLoading) {
    return <LoaderView />;
  }

  return (
    <>
      <Helmet>
        <title>{pageData?.title}</title>
        <meta name="description" content={pageData?.description || ''} />
      </Helmet>
      <IntroView type="subpage" data={pageData} />
      <ExpenseReportView mileageRates={mileageList} user={user} />
    </>
  );
}

export default ExpenseReport;
