import styled from 'styled-components';
import { size, mq } from '../../shared/mixins';
import {
  colors,
  customBezierEase,
  iconArrowAnimationBackward,
  padding,
} from '../../shared/variables';
import { font, fontSize } from '../../shared/typography';
import SearchView from 'components/SearchView';

export const Background = styled.div<{ gif: number | string; zIndex?: number }>`
  background: ${colors.blue};
  background-size: cover;
  position: fixed;
  top: 0;
  right: 0;
  ${size('100%', '100%')}

  ${mq.tablet`
    top: 82px;
    ${size('100%')}
  `}

  ${(props) =>
    props.gif !== 0 &&
    `
    background-image: url(${props.gif});
    background-size: cover;
    background-position: center;
    z-index: ${props.zIndex};
  `}
`;

export const Container = styled.section`
  padding: 46px ${padding.desktop}px 160px;
  min-height: calc(100% - 84px);
  position: relative;

  ${mq.tablet`
    padding: 80px ${padding.tablet}px 0;
    min-height: calc(100% - 84px);
  `}

  ${mq.phoneWide`
    padding: 80px ${padding.mobile}px 0;
  `}
`;

export const Content = styled.div`
  max-width: 455px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 114px auto 0;

  ${mq.tablet`
    margin-top: 0;
  `}
`;
export const Search = styled(SearchView)`
  grid-column: 1 / span 6;

  > input {
    color: ${colors.white};

    ::-webkit-input-placeholder {
      color: ${colors.white};
    }
    &::-moz-placeholder {
      color: ${colors.white};
    }
    &:-ms-input-placeholder {
      color: ${colors.white};
    }
    &:-moz-placeholder {
      color: ${colors.white};
    }
  }

  ${mq.tablet`
    display: none;
  `}
`;

export const Title = styled.h1`
  background: #aaaaaa;
  display: inline-block;
  color: ${colors.black};
  margin: 0;
  padding: 0 8px;
  text-align: center;
  ${font('error', 'bold')}
  ${fontSize(14, 20)}
  
  ${mq.tablet`
   margin-top: 150px;
  `}
`;

export const Instructions = styled.div`
  color: ${colors.white};
  width: 100%;
  ${font('error', 'regular')};
  ${fontSize(14, 20)};

  &.desktop {
    display: block;
  }
  &.mobile {
    display: none;
  }

  .center {
    text-align: center;

    span {
      opacity: 1;
      animation: blinker 0.7s infinite;

      @keyframes blinker {
        50% {
          opacity: 0;
        }
      }
    }
  }

  ${mq.tablet`
    &.desktop {
      display: none;  
    }
    &.mobile {
      display: block;  
    }
  `}
`;

export const Button = styled.button`
  align-items: center;
  background: transparent;
  border: 0;
  border-radius: 0;
  color: ${colors.white};
  display: flex;
  margin: 0 auto 0 0;
  text-decoration: underline;
  cursor: pointer;
  ${font('body')};
  ${fontSize(14, 20)};

  div {
    overflow: hidden;
  }

  span {
    display: inline-block;
    ${font('body')};
    ${fontSize(14, 20)};
  }

  svg {
    fill: ${colors.white};
    margin-right: 9px;
    transform: rotate(180deg);
    ${size('12px', '5px')}
  }

  &:hover {
    color: ${colors.highlight};
    span {
      animation: slideOutBackward 0.5s ease-in-out,
        slideInBackward 0.3s ${customBezierEase} 0.3s;
    }

    svg {
      fill: ${colors.highlight};
      ${iconArrowAnimationBackward};
    }
  }

  @keyframes slideOutBackward {
    0% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(-120%);
      opacity: 0%;
    }
  }

  @keyframes slideInBackward {
    0% {
      transform: translate(100%, 0%);
      opacity: 0%;
    }

    100% {
      transform: translate(0%, 0%);
      opacity: 100%;
    }
  }
`;
