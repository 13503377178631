import styled from 'styled-components';
import { size, mq } from '../../shared/mixins';
import { colors, padding, maxWidth } from '../../shared/variables';
import bkgTexture from '../../images/bg_texture-dust-white.jpg';
import {
  bodyCopySmall,
  eyebrow,
  eyebrowSmall,
  listStyles,
  resetButtonStyles,
} from '../../shared/extends';
import TabsDropdown from 'components/TabsDropdown';

export const Container = styled.section`
  background: url(${bkgTexture}) 0 0 repeat, ${colors.greyLight};
  padding: 94px ${padding.desktop}px 200px;
  max-width: ${maxWidth}px;
  width: 100%;

  ${mq.tablet`
    padding: 80px ${padding.tablet}px 160px;
  `}

  ${mq.phoneWide`
    padding: 80px ${padding.mobile}px;
  `}
`;

export const Table = styled.div`
  margin-top: 32px;

  ${mq.tablet`
  `}
`;

export const TabsDropdownBox = styled(TabsDropdown)`
  button {
    width: 100%;
  }
`;

// 211 181 181 181 72
const tableDesktop = `1fr 100px repeat(2, 1fr) 12px`;
export const Header = styled.div`
  display: grid;
  grid-template-columns: ${tableDesktop};
  grid-column-gap: 16px;
  align-items: center;
  background: ${colors.brownMedium};
  padding: 15px 30px;
  margin-bottom: 21px;
`;
export const Head = styled.button`
  ${resetButtonStyles};
  ${eyebrow};
  color: ${colors.white};
  text-align: left;

  svg {
    fill: ${colors.primary};
    margin-left: 17px;
    ${size('8px')};
  }

  &.flip {
    svg {
      transform: rotate(180deg);
    }
  }

  &:hover {
    color: ${colors.highlight};
  }

  &:disabled {
    pointer-events: none;
  }
`;

export const NotFound = styled.div`
  ${eyebrow};
  color: ${colors.primary};
  margin-top: 176px;
  text-align: center;

  ${mq.tablet`
    margin-top: 0;
  `}
`;

export const AccordionContainer = styled.div`
  margin-top: 32px;

  .detail {
    ${bodyCopySmall};
    color: ${colors.brownLight};
    margin: 20px 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }

    ul {
      ${listStyles};
      padding-left: 16px;
    }

    span:nth-child(2) {
      display: block;
    }
  }

  .label {
    ${eyebrowSmall};
    color: ${colors.brownLight};
  }
`;
