import IntroView from 'components/IntroView';
import EmailSignatureGeneratorView from 'components/EmailSignatureGeneratorView';
import React, { useMemo } from 'react';
import { UserI } from 'data/types/User.types';
import * as queries from 'utils/queries';
import { useQuery, gql } from '@apollo/client';
import { Helmet } from 'react-helmet';
import LoaderView from 'components/LoaderView';

const GET_PAGE_BY_SLUG = gql(queries.getPageBySlug);

interface Props {
  user: UserI;
  slug: string;
}

function EmailSignatureGenerator({ user, slug }: Props): JSX.Element {
  const { data: slugData, loading: slugLoading } = useQuery(GET_PAGE_BY_SLUG, {
    variables: {
      slug,
    },
  });

  const pageData = useMemo(() => {
    return slugData?.getPageBySlug.items[0];
  }, [slugData]);

  if (slugLoading) {
    return <LoaderView />;
  }

  return (
    <>
      <Helmet>
        <title>{pageData?.title}</title>
        <meta name="description" content={pageData?.description || ''} />
      </Helmet>
      <IntroView type="subpage" data={pageData} />
      <EmailSignatureGeneratorView user={user} />
    </>
  );
}

export default EmailSignatureGenerator;
